/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactElement } from 'react'
import { createElement } from 'react'

import { componentMap } from './component-map'

export const renderArticleElements = (articleBody: any = []): ReactElement => {
  const e = createElement

  return articleBody.reduce((elements, currentElement) => {
    if (!currentElement?.contentTag && !currentElement?.tagName && !currentElement?.component) {
      console.error('ARTICLE RENDERER ERROR: Invalid article body json element:', {
        currentElement,
        elements,
        articleBody,
      })
      return elements
    }

    if (currentElement.contentTag) {
      return elements.concat(renderArticleElements(currentElement.children))
    }
    /**
     * Text type elements are broken chunks that form a paragraph.
     * These are usually returned if a <p> has nested elements e.g.
     * <p>chunk <a href="url"> chunk</p>
     */
    if (currentElement.tagName === 'text') {
      return elements.concat(currentElement.innerText)
    }

    if (currentElement.component) {
      const component = componentMap[currentElement.component]
      const componentRequiresRenderer = componentsWithRenderers.includes(currentElement.component)

      return elements.concat(
        e(
          component || 'div',
          component
            ? {
                key: currentElement.props?.key || currentElement.id,
                ...currentElement.props,
                ...(componentRequiresRenderer ? { renderArticleElements } : {}),
              }
            : { key: currentElement.props?.key || currentElement.id },
          calculateElementContent(currentElement)
        )
      )
    }

    return elements.concat(
      e(
        currentElement.tagName,
        { ...currentElement.props, key: currentElement.props?.key || currentElement.id },
        calculateElementContent(currentElement)
      )
    )
  }, [])
}

///////// IMPLEMENTATION /////////

const calculateElementContent = (element): string | ReactElement | null => {
  const { innerText, children } = element

  if (innerText) {
    return innerText
  }

  if (children) {
    return renderArticleElements(children)
  }

  return null
}

const componentsWithRenderers = ['CsvTable']
