export const getPreviousUrl = ({ backStack }: Props) => {
  if (!backStack || backStack.length < 2) {
    return undefined
  }

  return backStack[backStack.length - 2]
}

type Props = {
  backStack: string[] | undefined
}
