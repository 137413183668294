import type { FunctionComponent } from 'react'
import { Heading, Modal, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../../../../shared/components/Link'
import style from './ErrorModal.module.scss'

export const ErrorModal: FunctionComponent<Props> = ({
  httpErrorCode,
  emailAddress,
  showModal,
  setShowModal,
}) => {
  if (!showModal) {
    return null
  }

  const supportParagraph = (
    <Typography textStyle="sb-text-body-x-small-regular">
      If the problem persists, please contact Which? member services on{' '}
      <Link href="tel:+442922670000">029 2267 0000</Link> to sign up (Mon - Fri: 8:30am to 6pm; Sat:
      9am to 1pm; Standard call rates apply) or email{' '}
      <Link href="mailto:support@which.co.uk">support@which.co.uk</Link>
    </Typography>
  )

  const renderIntro = (introText) => {
    return (
      <>
        <Heading headingTag="h3" heading={introText} headingType="medium" />
        {httpErrorCode && (
          <Typography textStyle="sb-text-body-x-small-regular">Error {httpErrorCode}</Typography>
        )}
      </>
    )
  }

  const tryAgain = (
    <Typography textStyle="sb-text-body-x-small-regular">Please try again.</Typography>
  )

  const modalContent = (errorCode: string) => {
    switch (errorCode) {
      case '202':
        return (
          <>
            <Heading headingTag="h3" heading="Your password has been reset" headingType="medium" />
            <Typography textStyle="sb-text-body-x-small-regular">
              In order to secure your account we have sent a password reset email to the inbox for{' '}
              {emailAddress}.
            </Typography>
            <Typography textStyle="sb-text-body-x-small-regular">
              Please use the link in that email to set a new secure password for your account, log
              in, then return here to sign up.
            </Typography>
          </>
        )

      case '409':
        return (
          <>
            {renderIntro("There's already an account with this email.")}
            <Typography textStyle="sb-text-body-x-small-regular">
              You already have an account but the password you have given is incorrect.
            </Typography>
            <Typography textStyle="sb-text-body-x-small-regular">
              Please <Link href="/login">log in</Link> or{' '}
              <Link href="/forgot-password">reset your password</Link> to proceed.
            </Typography>
          </>
        )

      default:
        // 400,500,504'
        return (
          <>
            {renderIntro('Sorry, a server error occurred')}
            {tryAgain}
            {supportParagraph}
          </>
        )
    }
  }

  return (
    <Modal closeModal={() => setShowModal(false)} className={style.errorModal}>
      <div className={style.errorModalContent}>{modalContent(httpErrorCode)}</div>
    </Modal>
  )
}

///////// IMPLEMENTATION /////////
type Props = {
  httpErrorCode: string
  showModal: boolean
  emailAddress?: string
  setShowModal: (showModal: boolean) => void
}
