import React from 'react'

export const mail = (
  <g data-testid="svg-mail" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g>
      <circle id="Oval-Copy-2" fill="#424242" cx="16" cy="16" r="16"></circle>
      <g transform="translate(7.000000, 10.000000)" fill="#FFFFFF">
        <path d="M17.9368261,3.3568 L10.6028261,7.9456 C10.2484348,8.168 9.83869565,8.284 9.4173913,8.284 C9.006,8.284 8.60534783,8.1736 8.25756522,7.9624 L0.665,3.3568 L3.90798505e-14,2.9536 L3.90798505e-14,3.3568 L3.90798505e-14,9.4616 C3.90798505e-14,10.7032 1.03921739,11.7088 2.32130435,11.7088 L16.2152609,11.7088 C17.4973478,11.7088 18.5365652,10.7032 18.5365652,9.4616 L18.5365652,3.3568 L18.5365652,2.9816 L17.9368261,3.3568 Z"></path>
        <path d="M9.13470435,6.8752 C9.22061739,6.9272 9.31974783,6.9536 9.41722609,6.9536 C9.5180087,6.9536 9.61796522,6.9256 9.70553043,6.8712 L14.8925304,3.6256 L18.3769652,1.4456 C18.0424,0.6016 17.2047478,4.4408921e-15 16.2150957,4.4408921e-15 L2.32113913,4.4408921e-15 C1.33726957,4.4408921e-15 0.501269565,0.596 0.1634,1.4336 L3.77753043,3.6256 L9.13470435,6.8752 Z"></path>
      </g>
    </g>
  </g>
)
