import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Button } from '@which/seatbelt'
import { ResetIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/Reset'
import { FilterFunnelIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicDatalayerPush } from '@which/shared'

import { ComparisonTableContext } from '../../ComparisonTableContext'
import { REFINE_TABLE_DATA } from '../../reducers/comparisonTableActionTypes'
import styles from './RefineComparisonTableButtons.module.scss'

export const RefineComparisonTableButtons: FunctionComponent = () => {
  const {
    state: { rowsToBeRefined, refineStatus },
    dispatch,
  } = useContext(ComparisonTableContext)
  const onClick = ({ status, eventLabel }: OnClickArgs) => {
    dispatch({
      type: REFINE_TABLE_DATA,
      status: status,
    })

    dynamicDatalayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Refine Results',
      eventLabel: eventLabel,
    })
  }

  switch (refineStatus) {
    case 'default':
      return (
        <Button
          onClick={() => onClick({ status: 'refine', eventLabel: 'Refine' })}
          data-testid="refine-button"
        >
          <FilterFunnelIcon
            className={styles.filterButtonIcon}
            data-testid="refine-button-filter-funnel"
          />
          Refine
        </Button>
      )
    case 'refine':
      return (
        <div className={styles.wrapper}>
          <Button
            className={styles.apply}
            onClick={() => onClick({ status: 'apply', eventLabel: 'Apply' })}
            data-testid="refine-apply-button"
            disabled={!Object.keys(rowsToBeRefined).some((key) => rowsToBeRefined[key]?.length)}
          >
            Apply
          </Button>
          <Button
            data-testid="refine-cancel-button"
            onClick={() => onClick({ status: 'default', eventLabel: 'Cancel' })}
          >
            Cancel
          </Button>
        </div>
      )
    case 'apply':
      return (
        <Button
          onClick={() => onClick({ status: 'reset', eventLabel: 'Reset' })}
          data-testid="refine-reset-button"
        >
          <ResetIcon
            width={16}
            height={16}
            viewBox="0 0 16 16"
            data-testid="refine-button-filter-funnel"
          />
          Reset
        </Button>
      )
    default:
      return null
  }
}

///////// IMPLEMENTATION /////////

type OnClickArgs = { status: string; eventLabel: string }
