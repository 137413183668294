import type { FunctionComponent } from 'react'
import React from 'react'
import type { LinkType } from '@which/seatbelt'
import { Grid, GridItem, MultiLinkCard } from '@which/seatbelt'
import { createSlugGenerator } from '@which/shared'

import type { ImageWithSources } from '../../../generated/frontend'
import styles from './MultiLinkBlocks.module.scss'

export const MultiLinkBlocks: FunctionComponent<Props> = ({ hubMultiLinks }) => {
  if (!hubMultiLinks.length) {
    return null
  }

  const createSlug = createSlugGenerator()

  return (
    <Grid className="w-page-wrapper">
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <div className={styles.multiLinkBlocksGrid} data-testid="multi-link-blocks">
          {hubMultiLinks.map((hubMultiLink) => (
            <MultiLinkCard
              {...hubMultiLink}
              id={createSlug(hubMultiLink.title)}
              key={hubMultiLink.title}
              className={styles.card}
            />
          ))}
        </div>
      </GridItem>
    </Grid>
  )
}

///////// IMPLEMENTATION /////////

export type HubMultiLink = {
  title: string
  links: LinkType[]
  image: string
  imageSources: ImageWithSources['sources']
  imageAlt: ImageWithSources['alt']
}

type Props = {
  hubMultiLinks: HubMultiLink[]
}
