export const UpdateUrl = (data: updateUrlProps) => {
  const { pathname, search, keys, values } = data
  const queryParams = search.split('&').filter((n) => n)
  const url = pathname.replace('/search', '')

  keys.forEach((key, index) => {
    const indexOfKey = queryParams.findIndex((param) => {
      if (param.includes(key)) {
        return true
      }
    })

    // Key already exists in queryParams
    if (indexOfKey !== -1) {
      if (values[index] === '') {
        queryParams.splice(indexOfKey, 1)
      } else {
        queryParams.splice(indexOfKey, 1)
        queryParams.push(`${key}=${values[index]}`)
      }
    } else if (values[index] !== '') {
      queryParams.push(`${key}=${values[index]}`)
    }
  })

  let updatedUrl = `${url}/search`

  if (queryParams.length > 0) {
    queryParams.forEach((param, i) => {
      const paramFormatted = param.replace('?', '')

      if (queryParams.length === 1 || (queryParams.length > 1 && i === 0)) {
        updatedUrl += `?${paramFormatted}`
      } else {
        updatedUrl += `&${paramFormatted}`
      }
    })
  }

  return updatedUrl
}

type updateUrlProps = {
  keys: string[]
  values: string[]
  pathname: string
  search: string
}
