import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { Breadcrumb } from '../../../../../shared/components/Breadcrumb'
import { useFullUrl } from '../../../../../shared/hooks/useFullUrl'
import { ComparisonTableContext } from '../../ComparisonTableContext'

export const ComparisonBreadcrumb: FunctionComponent = () => {
  const {
    state: { breadcrumb },
  } = useContext(ComparisonTableContext)
  const { getFullUrl } = useFullUrl()
  const fullUrl = getFullUrl()

  if (!breadcrumb || Object.keys(breadcrumb).length === 0) {
    return null
  }

  return <Breadcrumb {...breadcrumb} currentPage={{ href: fullUrl, text: '' }} />
}
