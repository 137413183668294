import React, { useContext } from 'react'

import type { Props as TrackonomicsLinkProps } from '../../../../shared/components/TrackonomicsLink/TrackonomicsLink'
import { TrackonomicsLink } from '../../../../shared/components/TrackonomicsLink/TrackonomicsLink'
import { ArticleTemplateContext } from '../../ArticleTemplateContext'

type Props = Omit<TrackonomicsLinkProps, 'taxonomyHierarchy'>

export const ArticleTrackonomicsLink: React.FC<Props> = (props) => {
  const { meta } = useContext(ArticleTemplateContext)
  const { taxonomyHierarchy } = meta
  const { vertical, subVertical, category, superCategory } = taxonomyHierarchy

  return (
    <TrackonomicsLink
      {...props}
      taxonomyHierarchy={{
        vertical: vertical?.slug ?? '',
        subVertical: subVertical?.slug ?? '',
        category: category?.slug ?? '',
        superCategory: superCategory?.slug ?? '',
      }}
    />
  )
}
