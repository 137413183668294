import type { FunctionComponent } from 'react'
import React from 'react'
import { AccordionItem, ProsCons, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { handleAccordionToggle } from './ComparisonTableV2'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonPageProsCons: FunctionComponent<ComparisonProsConsProps> = ({
  pros,
  cons,
  businessKeys,
  isDesktopOrAbove,
  totalProductColumns = 4,
  isPaidMember,
}) => {
  const renderProsAndConsData = () => (
    <div
      key={`pros-and-cons-section`}
      className={classnames(styles.compareSection, styles.prosAndCons)}
    >
      <div
        className={classnames(styles.productFeatureRow, {
          [styles.mobileGreyRow]: !isDesktopOrAbove,
        })}
        data-testid={'pros-row'}
      >
        <div className={styles.rowLabel}>
          <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
            Pros
          </Typography>
        </div>
        {pros.map((productPros, index) => (
          <div className={styles.tableDataRowValue} key={`pros-${businessKeys[index]}`}>
            <ProsCons
              className={styles.prosConsList}
              type="pros"
              list={isPaidMember ? productPros : []}
              displayHeading={false}
            />
          </div>
        ))}
        {isDesktopOrAbove && pros.length < totalProductColumns && <div className={styles.filler} />}
      </div>
      <div className={styles.productFeatureRow} data-testid={'cons-row'}>
        <div className={styles.rowLabel}>
          <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
            Cons
          </Typography>
        </div>
        {cons.map((productCons, index) => (
          <div className={styles.tableDataRowValue} key={`cons-${businessKeys[index]}`}>
            <ProsCons
              className={styles.prosConsList}
              type="cons"
              list={isPaidMember ? productCons : []}
              displayHeading={false}
            />
          </div>
        ))}
        {isDesktopOrAbove && cons.length < totalProductColumns && <div className={styles.filler} />}
      </div>
    </div>
  )

  return (
    <AccordionItem
      label="Pros & cons"
      content={renderProsAndConsData()}
      renderOpen={isDesktopOrAbove}
      animatesOpen={false}
      callback={(isOpen: boolean) => {
        handleAccordionToggle(isOpen, 'Pros & cons')
      }}
    />
  )
}

type ComparisonProsConsProps = {
  pros: string[] | []
  cons: string[] | []
  businessKeys: string[]
  isDesktopOrAbove: boolean
  totalProductColumns?: number
  isPaidMember: boolean
}
