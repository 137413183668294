import type { FunctionComponent } from 'react'
import {
  Grid,
  GridItem,
  List,
  ListItem,
  PageTitle,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import { Link } from '../Link'
import repeatedStyles from '../News404Error/News404Error.module.scss'
import { Status } from '../StatusContext'
import styles from './ToolFramework404Error.module.scss'

export const ToolFramework404Error: FunctionComponent<Props> = ({ message }) => {
  return (
    <Status code="404">
      <main className="w-page-wrapper">
        <Grid className={repeatedStyles.grid}>
          <GridItem
            span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
            columnStart={{ medium: 7, large: 7, xlarge: 7 }}
          >
            <Picture
              {...imageProps}
              className={repeatedStyles.image}
              aspectRatioMain="one-to-one"
              alt=""
            />
          </GridItem>
          <GridItem
            span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
            columnStart={{ xlarge: 2 }}
            className={repeatedStyles.textContainer}
          >
            <PageTitle pageTitle="This page can't be found" pageTitleTag="h1" />
            <Typography textStyle="sb-text-body-default-strong" data-error={message}>
              Error: Invalid_request
            </Typography>
            <Typography textStyle="sb-text-body-default-regular">
              You may not be able to visit this page because of:
            </Typography>
            <List>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                A mistyped address
              </ListItem>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                An out-of-date listing for this site
              </ListItem>
              <ListItem className={styles.toolFrameworkErrorBulletBlack}>
                You have no access to this page
              </ListItem>
            </List>
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.toolFrameworkErrorText}
            >
              Please re-try to click the link in your email.
            </Typography>
            <Typography textStyle="sb-text-body-default-regular">
              If difficulties persist, <Link href="/about-which/contact-us">contact us</Link> the
              problem.
            </Typography>
          </GridItem>
        </Grid>
      </main>
    </Status>
  )
}

///////// IMPLEMENTATION /////////

const imageJPG = 'https://media.product.which.co.uk/prod/images/original/5be675f85eaf-image.jpg'
const imageWEBP = 'https://media.product.which.co.uk/prod/images/original/d3a82edd46d4-image.webp'

const imageProps = {
  src: imageJPG,
  sources: [
    {
      srcset: imageWEBP,
      type: 'image/webp',
    },
    {
      srcset: imageJPG,
      type: 'image/jpeg',
    },
  ],
}

type Props = {
  message: string
}
