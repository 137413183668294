import { useParams } from 'react-router-dom'
import { ButtonLink, CardContainer, Heading, TypographyV2 as Typography } from '@which/seatbelt'
import { BroadbandComparisonIcon } from '@which/seatbelt/src/components/Icons/Marketing'
import {
  ChevronRightIcon,
  ExternalLinkIcon,
} from '@which/seatbelt/src/components/Icons/Navigational'

import type { PromoBlock as PromoBlockType } from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import styles from './PromoBlock.module.scss'

export const PromoBlock = (props: PromoBlockType) => {
  const { superCategorySlug } = useParams<PageParams>()
  const isBroadbandArticle = superCategorySlug === 'broadband'

  return isBroadbandArticle ? (
    <PromoBlockBroadbandArticle {...props} />
  ) : (
    <PromoBlockDefault {...props} />
  )
}

const PromoBlockDefault = ({ title, description, ctaLabel, ctaLink }: PromoBlockType) => {
  if (!ctaLabel && !ctaLink) {
    return null
  }

  return (
    <div className="promo-block" data-testid="promo-block">
      {title && <Heading heading={title} headingTag="h2" headingType="small" />}
      {description && (
        <Typography textStyle="sb-text-interface-body-small-regular">{description}</Typography>
      )}
      <ButtonLink
        href={ctaLink}
        className={styles.PromoCta}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <ExternalLinkIcon />
        {ctaLabel}
      </ButtonLink>
    </div>
  )
}

const PromoBlockBroadbandArticle = ({ title, ctaLink }: PromoBlockType) => {
  return (
    <div className={styles.PromoVariation} data-testid="promo-block-variation">
      <Heading heading={title} headingTag="h2" headingType="small">
        Next Steps
      </Heading>
      <div className={styles.PromoVariationContainersWrapper1}>
        <div className={styles.PromoVariationContainer}>
          <CardContainer primaryLink={ctaLink} ariaLabel={title}>
            <div className={styles.PromoVariationWrapper}>
              <BroadbandComparisonIcon />
              <Typography
                tag="h3"
                textStyle="sb-text-body-default-strong"
                className={styles.PromoTitleVariation}
              >
                Compare broadband deals
              </Typography>
              <div className={styles.PromoChevronIcon}>
                <ChevronRightIcon fill="sb-colour-background-inverse" />
              </div>
            </div>
          </CardContainer>
        </div>
      </div>
    </div>
  )
}
