export const removeFalsy = (obj: Record<string, any> = {}) => {
  return Object.keys(obj).reduce((result, value) => {
    if (Boolean(obj[value]) || obj[value] === 0) {
      return {
        ...result,
        [value]: obj[value],
      }
    }
    return result
  }, {})
}
