import type { BreadcrumbProps } from '../Breadcrumb'

export const getItem = (href: string, baseUrl: string) => {
  if (href && baseUrl) {
    return href.startsWith(baseUrl) ? href : `${baseUrl}${href}`
  }
  return ''
}

export const breadcrumbJsonLd = ({
  heading,
  links,
  currentPage,
  baseUrl = '',
}: BreadcrumbProps) => {
  const headingItem =
    heading?.text && heading?.href
      ? [
          {
            '@type': 'ListItem',
            position: 1,
            name: heading.text,
            item: getItem(heading.href, baseUrl),
          },
        ]
      : []

  const linksItems = Array.isArray(links)
    ? links
        .map(
          ({ text, href }, index) =>
            href && {
              '@type': 'ListItem',
              position: headingItem.length + index + 1,
              name: text,
              item: getItem(href, baseUrl),
            }
        )
        .filter(Boolean)
    : []

  const currentPageItem = currentPage?.text
    ? [
        {
          '@type': 'ListItem',
          position: headingItem.length + linksItems.length + 1,
          name: currentPage.text,
        },
      ]
    : []

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [...headingItem, ...linksItems, ...currentPageItem],
  })
}
