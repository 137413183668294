import type { ReactChild, ReactNode } from 'react'
import React, { Children, createElement, isValidElement } from 'react'
import { isElement } from 'react-is'
import { Table as t } from '@which/seatbelt'

import styles from './Table.module.scss'

const tableMap = new Map([
  ['tbody', t.Body],
  ['thead', t.Head],
  ['tr', t.Row],
  ['td', t.Cell],
  ['th', t.Header],
])

export const mapScrollableTable = (tree: ReactNode) => {
  const columnSizing: t.Sizing[] = []

  const mapChildren = (children: ReactNode, depth = 1): ReactNode =>
    Children.toArray(children).map((child, index) => {
      if (isElement(child)) {
        const isTableHeader = child.type === 'th'
        const hasClassName = child.props.className !== undefined

        if (isTableHeader) {
          columnSizing.push(hasClassName ? child.props.className : null)
        }

        const Child = tableMap.get(child.type as string)

        return Child ? (
          <Child key={`child_${depth}_${index}`} align={getCellAlignment(child)} {...child.props}>
            {mapChildren(child.props.children, depth + 1)}
          </Child>
        ) : (
          child
        )
      }

      return child
    })

  return (
    <t.Table className={styles.tableControl} sizing={columnSizing} tableClassName="scrollable">
      {mapChildren(tree)}
    </t.Table>
  )
}

// HTML table structure from Glide RTE doesn't always contain thead/tbody tags
// This function fixes that supporting semantic structure, react warnings and accessibiity
export const formatTableChildren = (children: ReactNode) => {
  const childrenAsArray = Children.toArray(children)
  const containsAllTrTags = childrenAsArray.every(
    (child) => isElement(child) && child.type === 'tr'
  )

  // All direct children are <tr> tags ie no <tbody>/<thead>
  if (childrenAsArray.length > 1 && containsAllTrTags) {
    return createTableMarkup(childrenAsArray)
  }

  // Children are wrapped in one <tbody> tag ie no <thead> (default RTE behaviour)
  if (
    childrenAsArray.length === 1 &&
    isElement(childrenAsArray[0]) &&
    childrenAsArray[0].type === 'tbody'
  ) {
    const { children: elementChildren } = childrenAsArray[0].props

    return createTableMarkup(elementChildren)
  }

  return children
}

export const getCellAlignment = (child: ReactChild) => {
  const { children } = isValidElement(child) && (child.props as any)

  if (children?.length) {
    return children[0].props?.['data-center-align'] === true ? 'centre' : 'auto'
  }
}

const createTableMarkup = (childrenArray: ReactNode[]) => [
  createElement('thead', { key: 'thead' }, childrenArray[0]),
  createElement('tbody', { key: 'tbody' }, childrenArray.slice(1, childrenArray.length)),
]
