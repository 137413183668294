import type { FunctionComponent } from 'react'
import {
  ButtonLink,
  Grid,
  GridItem,
  Heading,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import { Link } from '../Link'
import { Status } from '../StatusContext'
import styles from './News404Error.module.scss'

export const News404Error: FunctionComponent<Props> = ({ message }) => (
  <Status code="404">
    <main className="w-page-wrapper">
      <Grid className={styles.grid}>
        <GridItem
          span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
          columnStart={{ medium: 7, large: 7, xlarge: 7 }}
        >
          <Picture {...imageProps} className={styles.image} aspectRatioMain="one-to-one" alt="" />
        </GridItem>
        <GridItem
          span={{ small: 2, medium: 6, large: 6, xlarge: 5 }}
          columnStart={{ xlarge: 2 }}
          className={styles.textContainer}
        >
          <Heading heading="This page can't be found" headingTag="h1" headingType="large" />
          <Typography
            textStyle="sb-text-body-default-strong"
            className={styles.errorCode}
            data-error={message}
          >
            404 error
          </Typography>
          <Typography textStyle="sb-text-body-default-regular" className={styles.text}>
            That means the page you’re looking for isn’t here or no longer exists. Please check the
            URL or go back to News.
          </Typography>
          <nav aria-label="News links">
            <ButtonLink href="/news" className={styles.buttonLink}>
              See all news from Which?
            </ButtonLink>
            <Typography
              textStyle="sb-text-body-default-strong"
              tag="h2"
              className={styles.errorCode}
              data-error={message}
              id="links-label"
              aria-hidden
            >
              Popular links within news:
            </Typography>
            <ul className={styles.linkList} aria-labelledby="links-label">
              {links.map((link) => (
                <li className={styles.linkItem} key={link.href}>
                  <Link includeIcon={true} href={link.href} className={styles.link}>
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <Typography textStyle="sb-text-interface-body-small-regular" className={styles.text}>
            Alternatively, <Link href="/about-which/get-in-touch-aBnhv8z1Uslr">contact us</Link>{' '}
            about the problem
          </Typography>
        </GridItem>
      </Grid>
    </main>
  </Status>
)

///////// IMPLEMENTATION /////////

const imageJPG = 'https://media.product.which.co.uk/prod/images/original/5be675f85eaf-image.jpg'
const imageWEBP = 'https://media.product.which.co.uk/prod/images/original/d3a82edd46d4-image.webp'

const imageProps = {
  src: imageJPG,
  sources: [
    {
      srcset: imageWEBP,
      type: 'image/webp',
    },
    {
      srcset: imageJPG,
      type: 'image/jpeg',
    },
  ],
}

const links = [
  {
    href: '/news/technology',
    text: 'Technology news',
  },
  {
    href: '/news/appliances',
    text: 'Appliances news',
  },
  {
    href: '/news/home-and-garden',
    text: 'Home & Garden news',
  },
  {
    href: '/news/money',
    text: 'Money news',
  },
  {
    href: '/news/baby-and-child',
    text: 'Baby & Child news',
  },
  {
    href: '/news/cars',
    text: 'Cars news',
  },
  {
    href: '/news/travel',
    text: 'Travel news',
  },
  {
    href: '/news/consumer-rights',
    text: 'Consumer Rights news',
  },
]

type Props = {
  message: string
}
