import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { Link } from '../../Link'
import styles from './PIArticleCard.module.scss'

export const PIArticleCard: FunctionComponent<Props> = ({
  imgObj,
  title,
  body,
  linkObj,
  grandparentText,
  cardIndex,
}) => {
  const dataTestId = title.replace(' ', '-').toLowerCase()

  const handleDataLayer = () => {
    dynamicGa4DataLayerPush({
      event: 'click_homepage_link',
      item_grandparent_text: grandparentText,
      item_parent_text: title,
      item_text: linkObj?.text,
      item_index: cardIndex + 1,
    })
  }

  return (
    <div className={styles.articleCard}>
      <div className={styles.imgWrapper}>
        <img src={imgObj.src} alt={imgObj.alt || ''} />
      </div>
      <Typography className={styles.articleCardHeading} tag="h2" textStyle="sb-text-heading-small">
        {title}
      </Typography>
      <Typography tag="p" textStyle="sb-text-body-default-regular">
        {body}
      </Typography>
      {linkObj && (
        <Link
          className={styles.link}
          href={linkObj.href}
          data-testid={`ea-view-all-${dataTestId}`}
          aria-label={linkObj?.ariaLabel}
          onClick={() => {
            handleDataLayer()
          }}
        >
          {linkObj.text}
        </Link>
      )}
    </div>
  )
}

type Props = {
  cardIndex: number
  grandparentText: string
  imgObj: { src: string; alt: string }
  title: string
  body: string
  linkObj?: { href: string; text: string; ariaLabel?: string }
}
