import React from 'react'

export const switching = (
  <g fill="none" fillRule="evenodd" data-testid="svg-switching">
    <path
      fill="#424242"
      d="M17,2 C8.729,2 2,8.729 2,17 C2,25.271 8.729,32 17,32 C25.271,32 32,25.271 32,17 C32,8.729 25.271,2 17,2 M17,34 C7.626,34 0,26.374 0,17 C0,7.626 7.626,0 17,0 C26.374,0 34,7.626 34,17 C34,26.374 26.374,34 17,34"
    />
    <path
      fill="#1866C8"
      d="M25.384,10.795 L22.616,8.992 L19.849,7.188 C19.027,6.653 18,7.322 18,8.393 L18,11 L13,11 C12.967,11 12.939,11.016 12.907,11.019 C11.911,10.95 10.855,11.178 10.02,11.734 C9.133,12.324 8.486,13.196 8.188,14.222 C8.022,14.792 7.968,15.409 8.02,16 C8.065,16.52 8.447,17.026 9.02,17 C9.522,16.977 10.068,16.558 10.02,16 C9.995,15.716 10,15.435 10.024,15.151 C10.025,15.149 10.025,15.149 10.025,15.146 C10.037,15.08 10.05,15.013 10.066,14.947 C10.092,14.834 10.124,14.722 10.161,14.612 C10.177,14.562 10.196,14.513 10.215,14.464 C10.218,14.457 10.22,14.454 10.225,14.444 C10.28,14.329 10.341,14.217 10.409,14.109 C10.434,14.069 10.46,14.029 10.487,13.99 C10.496,13.977 10.51,13.961 10.523,13.945 C10.506,13.964 10.499,13.972 10.471,14.002 C10.489,13.983 10.507,13.963 10.524,13.944 C10.561,13.898 10.597,13.861 10.574,13.888 C10.622,13.831 10.669,13.774 10.722,13.722 C10.797,13.646 10.88,13.58 10.96,13.509 C10.963,13.507 10.967,13.503 10.971,13.501 C11.016,13.469 11.062,13.438 11.109,13.409 C11.217,13.341 11.329,13.28 11.444,13.225 C11.454,13.22 11.457,13.218 11.465,13.214 C11.514,13.196 11.562,13.177 11.612,13.161 C11.722,13.124 11.834,13.092 11.947,13.066 C12.013,13.05 12.08,13.037 12.146,13.025 C12.149,13.025 12.149,13.025 12.151,13.024 C12.435,13 12.716,12.995 13,13.02 C13.039,13.023 13.075,13.003 13.113,13 L18,13 L18,15.606 C18,16.678 19.027,17.347 19.849,16.811 L22.616,15.008 L25.384,13.205 C26.205,12.669 26.205,11.33 25.384,10.795"
    />
    <path
      fill="#1866C8"
      d="M10.5228 13.9449C10.5438 13.9209 10.5668 13.8959 10.5738 13.8879 10.5578 13.9069 10.5398 13.9249 10.5238 13.9439 10.5238 13.9439 10.5228 13.9449 10.5228 13.9449M24.9803 16.9995C24.4773 17.0225 23.9313 17.4415 23.9803 17.9995 24.0053 18.2845 24.0003 18.5645 23.9753 18.8485 23.9753 18.8515 23.9753 18.8515 23.9753 18.8535 23.9633 18.9205 23.9493 18.9865 23.9343 19.0525 23.9073 19.1655 23.8763 19.2775 23.8393 19.3885 23.8223 19.4375 23.8043 19.4865 23.7853 19.5355 23.7813 19.5425 23.7803 19.5455 23.7753 19.5555 23.7203 19.6705 23.6583 19.7825 23.5913 19.8905 23.5653 19.9315 23.5393 19.9705 23.5133 20.0095 23.5043 20.0225 23.4903 20.0385 23.4773 20.0545 23.4943 20.0355 23.5013 20.0275 23.5283 19.9975 23.5113 20.0165 23.4933 20.0365 23.4763 20.0555 23.4393 20.1015 23.4043 20.1385 23.4263 20.1125 23.3783 20.1685 23.3313 20.2255 23.2783 20.2785 23.2023 20.3535 23.1203 20.4205 23.0393 20.4905 23.0363 20.4935 23.0323 20.4975 23.0293 20.4995 22.9843 20.5305 22.9373 20.5615 22.8913 20.5905 22.7823 20.6585 22.6703 20.7205 22.5553 20.7755 22.5453 20.7805 22.5433 20.7815 22.5353 20.7855 22.4863 20.8035 22.4383 20.8225 22.3883 20.8395 22.2783 20.8765 22.1663 20.9075 22.0523 20.9345 21.9863 20.9495 21.9203 20.9635 21.8533 20.9745 21.8513 20.9755 21.8513 20.9755 21.8493 20.9755 21.5653 21.0005 21.2843 21.0055 21.0003 20.9805 20.9613 20.9765 20.9253 20.9975 20.8863 20.9995L16.0003 20.9995 16.0003 18.3935C16.0003 17.3225 14.9723 16.6525 14.1503 17.1885L11.3833 18.9915 8.6163 20.7945C7.7943 21.3305 7.7943 22.6695 8.6163 23.2045L11.3833 25.0085 14.1503 26.8115C14.9723 27.3475 16.0003 26.6775 16.0003 25.6065L16.0003 22.9995 21.0003 22.9995C21.0333 22.9995 21.0613 22.9845 21.0933 22.9815 22.0893 23.0495 23.1453 22.8225 23.9803 22.2665 24.8673 21.6755 25.5143 20.8035 25.8123 19.7785 25.9773 19.2075 26.0323 18.5905 25.9803 17.9995 25.9353 17.4805 25.5533 16.9745 24.9803 16.9995"
    />
    <path
      fill="#1866C8"
      d="M23.4777,20.0545 C23.4557,20.0785 23.4337,20.1045 23.4257,20.1125 C23.4427,20.0935 23.4597,20.0755 23.4767,20.0565 C23.4767,20.0555 23.4777,20.0555 23.4777,20.0545"
    />
  </g>
)
