/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const myAppointmentsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(my-appointments)',
    component: () => <Page pagePath="vertical/VerticalPage" template="My Appointments Vertical" />,
  },
]
