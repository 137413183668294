import type { FunctionComponent } from 'react'
import React from 'react'
import type { AspectRatio } from '@which/seatbelt'
import { ContentCardV2 } from '@which/seatbelt'
import type { Arrangement, ImageSource } from '@which/seatbelt/src/types'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { mapIconType } from '../utils/icon-map'

export const PIArticleContentCard: FunctionComponent<Props> = ({
  orientation = 'default',
  date,
  image = '',
  imageAlt = '',
  imageSources = [],
  strapline,
  title,
  href,
  heading,
  estimatedReading,
  largeCard = false,
  parentText,
  cardIndex,
  imageRight,
}) => {
  const handleDataLayer = () => {
    dynamicGa4DataLayerPush({
      event: 'click_homepage_card',
      item_parent_text: parentText,
      item_text: title,
      item_index: cardIndex + 1,
    })
  }

  const aspectRatio: AspectRatio = largeCard ? 'four-to-three' : 'two-to-one'

  const { text, icon } = heading || {}

  const mappedIcon = icon ? mapIconType(icon) : ''

  const imgObj = image
    ? {
        imageAlt: imageAlt,
        imageSources: imageSources,
        lazyLoad: false,
        str: image,
        aspectRatioMain: aspectRatio,
      }
    : null

  return (
    <ContentCardV2
      ariaLabel={`Click here to read ${title}`}
      title={title}
      titleTag={'h2'}
      description={strapline}
      imgObj={imgObj}
      onClickHandler={() => handleDataLayer()}
      readTime={estimatedReading}
      readTimeText=""
      date={date}
      contentLabel={text}
      icon={mappedIcon}
      primaryLink={href}
      arrangement={orientation}
      imageRight={imageRight}
    />
  )
}

type Props = {
  orientation?: Arrangement
  date?: Date
  image?: string
  imageAlt?: string
  imageSources?: ImageSource[]
  strapline?: string
  title: string
  href: string
  tall?: boolean
  heading?: { icon: string; category: string; text: string }
  estimatedReading?: string
  className?: string
  largeCard?: boolean
  cardIndex: number
  parentText: string
  imageRight?: boolean
}
