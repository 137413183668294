import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  Grid,
  GridItem,
  List,
  ListItem,
  PageTitle,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import { useResetPasswordMutation } from '../../../generated/frontend'
import { ErrorComponent } from '../../../shared/components/Error/ErrorComponent'
import type { Props } from '../../../shared/components/InfoBanner'
import { InfoBanner } from '../../../shared/components/InfoBanner'
import { Link } from '../../../shared/components/Link'
import { useGetQueryString } from '../../../shared/hooks/useGetQueryString'
import { PasswordFieldView } from '../components/PasswordField'
import { QuestionAccordion } from '../components/QuestionAccordion'
import { Links, ResetPasswordPageBannerText } from '../data'
import { accountPagesDataLayer, setNewRelicTransaction } from '../utils'
import styles from './ResetPasswordPage.module.scss'

const ResetPasswordPage: FunctionComponent = () => {
  const sessionToken = useGetQueryString('token')
  const [content, setContent] = useState('')
  const [context, setContext] = useState<Props['context']>('error')
  const [showInfoBanner, setShowInfoBanner] = useState(false)
  const [title, setTitle] = useState('')

  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit, getValues } = methods

  const [resetPasswordMutation, { data, error, loading }] = useResetPasswordMutation()

  setNewRelicTransaction('account/resetPassword')

  useEffect(() => {
    if (data && data.resetPassword) {
      const { resetPassword } = data

      switch (resetPassword.__typename) {
        case 'ResetPasswordSuccess':
          const today = new Date()
          today.setDate(today.getDate() + 30)
          const thirtyDaysFromNowUTC = today.toUTCString()
          const expires = ' Expires=' + thirtyDaysFromNowUTC + ';'
          document.cookie = 'ref=reset-password ;' + expires
          location.replace('/login')
          break
        case 'ResetPasswordError':
          const { ErrorResponse } = resetPassword
          setShowInfoBanner(true)
          setContent(ResetPasswordPageBannerText.linkExpiredContentText)
          setContext('error')
          setTitle(ErrorResponse?.message)
          break
      }
    }
  }, [data])

  useEffect(() => {
    if (!sessionToken) {
      setShowInfoBanner(true)
      setContent(ResetPasswordPageBannerText.linkExpiredContentText)
      setContext('error')
      setTitle(ResetPasswordPageBannerText.linkExpiredErrorText)
    }
  }, [sessionToken])

  const needHelpContent = (
    <>
      <Typography textStyle="sb-text-body-default-regular">
        If you’re still having problems resetting your password please visit our{' '}
        <Link href={Links.helpCenter}>help centre.</Link> Alternatively, call us on{' '}
        <Link href="tel:+ 0292 2670000">0292 2670000</Link> (Mon-Fri 08:30-18:00, Sat 09:00-13:00)
        at the standard network rate - or email us at{' '}
        <Link href="mailto:support@which.co.uk">support@which.co.uk.</Link>
      </Typography>
    </>
  )

  const passwordTipsContent = (
    <List>
      <ListItem appearance="number">
        Combine three or more random words - the longer the safer
      </ListItem>
      <ListItem appearance="number">Add numbers and/or symbols</ListItem>
      <ListItem appearance="number">Combine capital and small letters</ListItem>
      <ListItem appearance="number">
        Avoid the obvious - like family or pet names and dates of birth
      </ListItem>
    </List>
  )

  const handleOnSubmit = (formObj) => {
    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Reset my password',
    })

    resetPasswordMutation({
      variables: {
        token: sessionToken || 'undefined',
        password: formObj.password,
      },
    })
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  return (
    <>
      <Helmet>
        <title>Login to which.co.uk - Reset Password</title>
        <meta
          name="description"
          content="From this page members can log directly into which.co.uk. Access thousands of expertly tested independent reviews to help you make confident choices. Please have your email address or username and password ready."
        />
        <script>
          {accountPagesDataLayer({
            content_type: 'Reset Password',
          })}
        </script>
      </Helmet>
      <Grid className={classnames(styles.resetPasswordPage, showInfoBanner && styles[context])}>
        <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
          {showInfoBanner && <InfoBanner content={content} context={context} title={title} />}
          <PageTitle pageTitle="Reset your password" pageTitleTag="h1" />
          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.resetPasswordPageTitleCopy}
          >
            Please enter your new password.
          </Typography>
          <FormProvider {...methods}>
            <form method="post">
              <PasswordFieldView
                displayPasswordCheckList={true}
                name="password"
                label="Password"
                rulesRequired={true}
                maxLength="32"
                calledFrom="resetPassword"
                errorMessageText="Please amend your password so that it complies with all 3 requirements above"
                showRequiredText={false}
                autoComplete="new-password"
                validation={{
                  required: true,
                  pattern:
                    /^(?=.*[a-zA-Z])(?=.*[0-9!#£$%^&*()_+="\-])(?!.*[@#;?<.,>{/}'\\|\[\]]).{8,32}$/,
                }}
              />
              <PasswordFieldView
                name="repeat-password"
                label="Repeat password"
                displayPasswordCheckList={false}
                rulesRequired={false}
                calledFrom="resetPassword"
                errorMessageText="Sorry, this doesn't match the new password you've entered above."
                maxLength="32"
                showRequiredText={false}
                autoComplete="repeat-password"
                validation={{
                  required: true,
                  validate: () => getValues('repeat-password') === getValues('password'),
                }}
              />
              <div className={styles.resetPasswordPageFormFooter}>
                <Button
                  data-testid="reset-password-button"
                  enableSpinner={loading}
                  onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                  className={styles.resetPasswordPageSubmit}
                >
                  Reset my password
                </Button>
                <div className={styles.resetPasswordPageReturnUrl}>
                  <Link
                    appearance="primary"
                    href={Links.returnUrl}
                    textStyle="sb-text-body-default-regular"
                    data-which-id="link"
                    className={styles.resetPasswordPageReturnUrlLink}
                  >
                    Cancel and return to login
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
          <QuestionAccordion
            label="Password tips"
            content={passwordTipsContent}
            calledFrom="resetPassword"
          />
          <QuestionAccordion
            label="Need help?"
            content={needHelpContent}
            calledFrom="resetPassword"
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default ResetPasswordPage
