import type { FunctionComponent } from 'react'
import React, { useRef, useState } from 'react'
import { Button, Checkbox, Picture, TypographyV2 as Typography } from '@which/seatbelt'
import { MinusIcon, PlusMinusIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { DownloadIcon, PrintIcon } from '@which/seatbelt/src/components/Icons/Social'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { Link } from '../Link'
import styles from './CRScamsChecklist.module.scss'

export const CRScamsChecklist: FunctionComponent<Props> = ({ image, contentObject }) => {
  const getLocalStorage = (key: string) => {
    try {
      const value = window.localStorage.getItem(key)
      return value ? JSON.parse(value) : []
    } catch (e) {
      // if error, return initial value
      return []
    }
  }

  const { calloutBody, downloadText, printText, title, checkboxes } = JSON.parse(contentObject)
  const checklistRef = useRef<HTMLDivElement>(null)
  const checklistHeader = useRef(null)
  const checklistBody = useRef(null)
  const checklistImage = useRef(null)

  const [localStorageCheckList, setLocalStorageCheckList] = useState(
    getLocalStorage('CRScamsChecklist')
  )

  const [isExpanded, setIsExpanded] = useState(false)
  const ButtonIcon = !isExpanded ? PlusMinusIcon : MinusIcon

  const updateLocalStorageChecklist = ({ id }: { id?: string }) => {
    if (!id) {
      return
    }
    const lsCheckList = localStorageCheckList
    if (!lsCheckList.includes(id)) {
      lsCheckList.push(id)
    } else {
      lsCheckList.splice(lsCheckList.indexOf(id), 1)
    }
    dynamicDatalayerPush({
      eventCategory: 'Engagement',
      eventAction: 'Checkbox',
      eventLabel: id,
    })
    setLocalStorageCheckList(lsCheckList)
    localStorage.setItem('CRScamsChecklist', JSON.stringify(lsCheckList))
  }

  const downloadChecklistClick = async ({ pdfMode = 'save', headerHtml, imageHtml, bodyHtml }) => {
    if (typeof window !== 'undefined') {
      import('./utils/pdfGenerator').then(async ({ createPdf }) => {
        await createPdf({
          headerHtml,
          imageHtml,
          bodyHtml,
          pdfMode,
          pdfSettings: {
            lineHeight: 1.7,
            defaultSpacing: 4,
            xMargin: 85,
            pageYMargin: 75,
            yMargin: 50,
            bodyFontSize: 12,
          },
        })
      })
    }
  }

  const createCheckbox = (checkbox: {
    copyOfCheckbox: [
      {
        copy: string
        type: string
        link: string
      },
    ]
    id: string
    label: string
  }) => {
    const { copyOfCheckbox, id, label } = checkbox
    return (
      <div className={styles.scamChecklist__listInnerItem} key={id}>
        <Checkbox
          id={id}
          label={label}
          value={id}
          name={id}
          className={styles.scamChecklist__checkbox}
          onChangeCallback={(e) => updateLocalStorageChecklist(e)}
          checked={localStorageCheckList.indexOf(id) > -1}
        />
        <Typography textStyle="sb-text-body-default-regular" className={styles.scamChecklist__text}>
          {copyOfCheckbox.map((checkboxCopy) => {
            if (checkboxCopy.type === 'link') {
              return (
                <Link href={checkboxCopy.link} key={checkboxCopy.link}>
                  {checkboxCopy.copy}
                </Link>
              )
            } else {
              return checkboxCopy.copy
            }
          })}
        </Typography>
      </div>
    )
  }
  return (
    <div ref={checklistRef}>
      <div className={styles.scamChecklistCallout} ref={checklistHeader}>
        <Typography
          tag="h2"
          textStyle="sb-text-heading-large"
          className={styles.scamChecklistCalloutTitle}
          id="scams-protection-checklist"
        >
          {title}
        </Typography>
        <Typography
          textStyle="sb-text-body-default-regular"
          className={styles.scamChecklistCalloutBody}
          dangerouslySetInnerHTML={{ __html: calloutBody }}
        />
        <div className={styles.scamChecklistButtons}>
          <Button
            data-which-id="scams-protection-checklist-download"
            onClick={() =>
              downloadChecklistClick({
                bodyHtml: checklistBody.current,
                imageHtml: checklistImage.current,
                headerHtml: checklistHeader.current,
              })
            }
          >
            <DownloadIcon />
            {downloadText}
          </Button>
          <Button
            appearance="secondary"
            className={styles.scamChecklistButtons__printButton}
            data-which-id="scams-protection-checklist-print"
            onClick={() =>
              downloadChecklistClick({
                pdfMode: 'print',
                bodyHtml: checklistBody.current,
                imageHtml: checklistImage.current,
                headerHtml: checklistHeader.current,
              })
            }
          >
            <PrintIcon />
            {printText}
          </Button>
        </div>
      </div>
      <div className={styles.scamChecklist}>
        <div ref={checklistImage}>
          {image?.src && (
            <Picture
              src={image.src}
              alt={image.alt}
              className={styles.scamChecklistImageContainer}
              imageClassName={styles.scamChecklistImage}
              sources={image.sources}
              aspectRatioMain="three-to-one"
              aspectRatioSm="two-to-one"
            />
          )}
        </div>
        <div
          className={classnames(
            styles.scamChecklist__list,
            isExpanded && styles.scamChecklist__expanded
          )}
        >
          <div
            className={styles.scamChecklist__listInner}
            ref={checklistBody}
            data-testid="checklistBody"
          >
            {checkboxes.map((checkbox) => createCheckbox(checkbox))}
          </div>
        </div>
        <div className={styles.scamChecklist__expandingContainer}>
          {!isExpanded && (
            <div className={styles.scamChecklist__gradient} data-testid="checklistGradient" />
          )}
          <Button
            appearance="secondary"
            className={styles.scamChecklist__expandButton}
            data-testid="checklist-expand-button"
            onClick={() => {
              setIsExpanded(!isExpanded)
              if (isExpanded && checklistRef.current) {
                const y = checklistRef.current.getBoundingClientRect().top + window.pageYOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          >
            <ButtonIcon />
            {!isExpanded ? 'Continue Reading' : 'Read less'}
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = {
  image?: {
    src: string
    alt: string
    sources: [{ srcset: string; media: string; sizes: string }]
  }
  contentObject: string
}
