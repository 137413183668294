import type { KeyboardEventHandler } from 'react'
import { useRef } from 'react'

export const useTabLoop = () => {
  const firstElementRef = useRef(null)
  const lastElementRef = useRef(null)

  const containerTabEventHandler: KeyboardEventHandler<HTMLElement> = (e) => {
    e.stopPropagation()
    const eventTarget = e.target as HTMLElement
    const { key, shiftKey } = e

    const firstElement = firstElementRef?.current as HTMLElement | null
    const lastElement = lastElementRef?.current as HTMLElement | null

    if (key === 'Tab') {
      if (shiftKey && firstElement === eventTarget) {
        e.preventDefault()
        lastElement?.focus()
      }
      if (!shiftKey && lastElement === eventTarget) {
        e.preventDefault()
        firstElement?.focus()
      }
    }
  }

  return { firstElementRef, lastElementRef, containerTabEventHandler }
}
