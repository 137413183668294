import type { FunctionComponent } from 'react'
import React from 'react'
import type { BadgeTheme } from '@which/seatbelt'
import { GridItem } from '@which/seatbelt'

import type { LatestNews as LatestNewsType, NewsCard } from '../../../generated/frontend'
import { FeaturedArticleCard } from '../FeaturedArticleCard'
import { LatestNews } from '../LatestNews'
import styles from './FeaturedContent.module.scss'

export const FeaturedContent: FunctionComponent<Props> = ({
  mainFeaturedArticle,
  latestNews,
  latestNewsTitle,
  latestNewsLink,
  withSideTrack = false,
  lazyLoad = true,
}) => (
  <>
    <GridItem
      data-testid="featured-article-card-section"
      className={styles.featuredContentGridItem}
      span={{ medium: 8, large: withSideTrack ? 5 : 6, xlarge: 4 }}
      columnStart={{ medium: 3, large: withSideTrack ? 2 : 1, xlarge: 2 }}
    >
      <FeaturedArticleCard
        className={styles.featuredArticleCardWrapper}
        article={mainFeaturedArticle}
        badge={{
          theme: 'top story',
        }}
        lazyLoad={lazyLoad}
      />
    </GridItem>
    <GridItem
      data-testid="latest-news-section"
      className={styles.featuredContentGridItem}
      span={{ medium: 10, large: withSideTrack ? 5 : 6, xlarge: 5 }}
      columnStart={{ medium: 2, large: 7, xlarge: 7 }}
    >
      <LatestNews
        name={latestNewsTitle}
        articles={latestNews.articles}
        className={styles.latestNews}
        navigationLink={latestNewsLink}
        lazyLoad={lazyLoad}
      />
    </GridItem>
  </>
)

///////// IMPLEMENTATION /////////

type Props = {
  mainFeaturedArticle: NewsCard
  latestNews: LatestNewsType
  latestNewsTitle: string
  latestNewsLink?: { text: string; url: string }
  withSideTrack?: boolean
  lazyLoad?: boolean
}
