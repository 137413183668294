import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Button,
  Checkbox,
  Dropdown,
  FormField,
  InputV2,
  Loader,
  Radio,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import { useCreateCaseInSfMutation } from '../../../generated/frontend'
import { Link } from '../Link'
import styles from './contactUs.module.scss'
import { contactUsIssues } from './contactUsIssues'
import { ContactUsSupportingCopy } from './ContactUsSupportingCopy'

export const ContactUs: FunctionComponent<ContactUsProps> = (contactUsProps?) => {
  const cancelMembershipUrl = contactUsProps?.cancelMembershipUrl || ''

  const [contactUsData, setContactUsData] = useState(
    contactUsProps && Object.keys(contactUsProps).length > 4
      ? contactUsProps
      : {
          whichMember: true,
          membershipNumber: '',
          knowMembershipNumber: true,
          name: '',
          issue: contactUsIssues.option0,
          email: '',
          contactNumber: '',
          message: '',
          reChaptaValidated: process.env.NR_ENV === 'preprod' ? true : false,
          reChaptaValue: '',
          submitted: false,
          submitFailed: false,
          submitInProgress: false,
        }
  )
  const [createCaseInSfMutation] = useCreateCaseInSfMutation({
    variables: {
      formRequest: {
        subject: contactUsData.issue || '',
        name: contactUsData.name || '',
        email: contactUsData.email || '',
        isMember: contactUsData.whichMember || false,
        message: contactUsData.message || '',
        contactNumber: contactUsData.contactNumber || '',
        membershipNumber: contactUsData.membershipNumber || '',
      },
    },
  })

  const submitFailedContactUsData = () => {
    setContactUsData((prevState) => ({
      ...prevState,
      submitInProgress: false,
      submitted: true,
      submitFailed: true,
    }))
  }

  const submitContactUsCase = async () => {
    const { data: createCaseData } = await createCaseInSfMutation()

    if (createCaseData && createCaseData.createCaseInSF) {
      const { status: createCaseStatus } = createCaseData.createCaseInSF

      if (createCaseStatus === '201') {
        const form = document.getElementById('contact-form') as HTMLFormElement
        form.reset()
        setContactUsData((prevState) => ({
          ...prevState,
          whichMember: true,
          membershipNumber: '',
          knowMembershipNumber: true,
          name: '',
          issue: contactUsIssues.option0,
          email: '',
          contactNumber: '',
          message: '',
          reChaptaValidated: false,
          reChaptaValue: '',
        }))
      }

      setContactUsData((prevState) => ({
        ...prevState,
        submitInProgress: false,
        submitted: true,
        submitFailed: createCaseStatus !== '201' ? true : false,
      }))
    }
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (contactUsData.submitInProgress) {
      return
    }
    setContactUsData((prevState) => ({
      ...prevState,
      submitInProgress: true,
      submitted: false,
    }))

    try {
      await submitContactUsCase()
    } catch {
      submitFailedContactUsData()
    }
  }

  return (
    <div data-testid="which-contact-us" className={styles.contactUsWrapper}>
      <form
        className={styles.contactUsForm}
        data-errors=""
        data-sentstatus=""
        id="contact-form"
        onSubmit={handleFormSubmit.bind(contactUsData)}
      >
        <Typography className={styles.contactUsHeading} textStyle="sb-text-body-default-regular">
          Send us a message and we’ll do our best to get back to you within three working days. If
          you’re messaging us about cancelling your membership, please visit our{' '}
          <Link href={cancelMembershipUrl}>Cancelling your membership page</Link> for more details.
        </Typography>
        <div
          className={styles.contactUsFormLeftColumn}
          onChange={(e) => {
            e.stopPropagation()
            setContactUsData((prevState) => ({
              ...prevState,
              whichMember: !contactUsData.whichMember,
              knowMembershipNumber: !contactUsData.whichMember,
            }))
          }}
        >
          <fieldset className={styles.contactUsFieldset}>
            <legend>Are you a Which? member?</legend>
            <div className={styles.contactUsMemberRadioContainer}>
              <Radio
                id="ContactForm_member_0"
                value="No"
                type="radio"
                name="ContactForm[member]"
                checked={!contactUsData.whichMember}
                label="No"
                className={styles.contactUsMemberRadio}
              />
              <Radio
                id="ContactForm_member_1"
                value="Yes"
                type="radio"
                name="ContactForm[member]"
                checked={contactUsData.whichMember}
                label="Yes"
                className={styles.contactUsMemberRadio}
              />
            </div>
          </fieldset>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <div className={styles.contactUsMembershipGroup} id="membership-number-form-fields">
            {contactUsData.whichMember ? (
              <>
                <FormField
                  label="Membership number"
                  labelFor="membership-number"
                  required={true}
                  showRequiredText={false}
                >
                  <InputV2
                    id="membership-number"
                    data-testid="membership-number-input"
                    name="ContactForm[number]"
                    type="text"
                    required={contactUsData.knowMembershipNumber}
                    disabled={!contactUsData.knowMembershipNumber}
                    value={contactUsData.membershipNumber || ''}
                    onChange={(e) => {
                      setContactUsData((prevState) => ({
                        ...prevState,
                        membershipNumber: e.target.value,
                      }))
                    }}
                  />
                </FormField>
                <div>
                  <Checkbox
                    id="membership-number-checkbox"
                    name="ContactForm[knowNumber]"
                    data-testid="membership-number-checkbox"
                    value="1"
                    type="checkbox"
                    label="I don’t know my number"
                    onChangeCallback={() => {
                      setContactUsData((prevState) => ({
                        ...prevState,
                        knowMembershipNumber: !contactUsData.knowMembershipNumber,
                        membershipNumber: '',
                      }))
                    }}
                  ></Checkbox>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField
            label="Name"
            labelFor="contact-us-name"
            required={true}
            showRequiredText={false}
          >
            <InputV2
              id="contact-us-name"
              value={contactUsData.name || ''}
              name="ContactForm[name]"
              type="text"
              required
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <FormField
            label="Email"
            labelFor="contact-us-email"
            required={true}
            showRequiredText={false}
          >
            <InputV2
              id="contact-us-email"
              value={contactUsData.email || ''}
              name="ContactForm[email]"
              type="email"
              required
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField label="Contact number" labelFor="contact-us-number">
            <InputV2
              id="contact-us-number"
              value={contactUsData.contactNumber || ''}
              name="ContactForm[contactNumber]"
              type="tel"
              className={styles.numberInput}
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  contactNumber: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsFormRightColumn}></div>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField
            label="Select option"
            labelFor="contact-us-issue"
            required={true}
            showRequiredText={false}
          >
            <Dropdown
              className={styles.contactUsSelectIssue}
              variant="short"
              id="contact-us-issue"
              defaultValue="issue0"
              options={Object.keys(contactUsIssues).map((key) => ({
                value: key,
                label: contactUsIssues[key],
              }))}
              callback={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  issue: contactUsIssues[e],
                }))
              }}
            ></Dropdown>
          </FormField>
        </div>
        <div className={styles.contactUsMessageWrapper}>
          <ContactUsSupportingCopy issue={contactUsData.issue} />
        </div>
        <div className={styles.contactUsFormRightColumn}></div>
        <div className={styles.contactUsMessageWrapper}>
          <FormField
            label="Message"
            labelFor="contact-us-message"
            required={true}
            showRequiredText={false}
          >
            <textarea
              required
              id="contact-us-message"
              defaultValue={contactUsData.message}
              name="ContactForm[message]"
              rows={8}
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  message: e.target.value,
                }))
              }}
              className={styles.contactUsTextArea}
            ></textarea>
          </FormField>
        </div>
        <div className={styles.contactUsFormLeftColumn}>
          <Typography textStyle="sb-text-body-x-small-regular">
            Information is held under the provision of the General Data Protection Regulation
            (GDPR). We will not pass your details on to any other organisation. We will process your
            information in accordance with our privacy policy.<br></br> <br></br> By sending this
            you agree to Which?’s terms & conditions
          </Typography>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <ReCAPTCHA
            className={styles.contactUsRechaptcha}
            data-testid="contact-us-rechapta"
            sitekey="6LeMHDEgAAAAAIFIfwVWebGbHFAzz9ANPRRAYJPU"
            value={contactUsData.reChaptaValue}
            name="ContactForm[recaptca]"
            size="compact"
            onChange={(value) => {
              if (value) {
                setContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: true,
                  reChaptaValue: value,
                }))
              } else {
                setContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: false,
                  reChaptaValue: '',
                }))
              }
            }}
          />
          <Button
            data-testid="contact-us-submit-button"
            className={styles.contactUsSubmit}
            label="Send"
            disabled={!contactUsData.reChaptaValidated}
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
      {contactUsData.submitInProgress && (
        <div className={styles.processing}>
          <div className={styles.processingLoader}>
            <p>Processing...</p>
            <Loader />
          </div>
        </div>
      )}
      {contactUsData.submitted &&
        !contactUsData.submitFailed &&
        !contactUsData.submitInProgress && (
          <div className={styles.messageSent}>
            <div className={styles.messageSentBlock}>
              <Typography textStyle="sb-text-body-default-strong">Message sent.</Typography>
              <Typography textStyle="sb-text-body-default-regular">
                Thank you for contacting us, one of our friendly staff will be happy to assist you.
                We aim to respond within 3 working days.
              </Typography>
            </div>
          </div>
        )}
      {contactUsData.submitted && contactUsData.submitFailed && !contactUsData.submitInProgress && (
        <div className={styles.messageSent}>
          <div className={styles.messageSentBlock}>
            <Typography textStyle="sb-text-body-default-strong">
              Error while sending message
            </Typography>
            <Typography textStyle="sb-text-body-default-regular">
              We have encountered issues while sending your message. Please try again.
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type ContactUsProps = {
  cancelMembershipUrl?: string
  contactNumber?: string
  email?: string
  issue?: string
  knowMembershipNumber?: boolean
  membershipNumber?: string
  message?: string
  name?: string
  reChaptaValidated?: boolean
  reChaptaValue?: string
  submitFailed?: boolean
  submitInProgress?: boolean
  submitted?: boolean
  whichMember?: boolean
}
