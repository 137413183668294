import React from 'react'

import type { MakeOptional, Meta } from '../../generated/frontend'
import { usePageProps } from '../usePageProps'
import { useFullUrl } from './useFullUrl'

// @NOTE: If you are adding a new page or maintain a simple existing page
// consider using the ./useGenericMetaTags.tsx hook instead of this one
export const useBaseMetaTags = () => {
  const { getFullUrl } = useFullUrl()
  const fullUrl = getFullUrl()

  const { template: templateName } = usePageProps()
  let titleSuffix = ''

  if (templateName?.includes('News')) {
    titleSuffix = ' News'
  } else if (templateName?.includes('Policy and Insight')) {
    titleSuffix = ' Policy and insight'
  }

  const titleSection = `Which?${titleSuffix}`
  const isAboutWhichSiteMap = fullUrl.includes('about-which/sitemap')

  return {
    getBaseMetaTags({ meta, imageAlt, imageUrl, twitterCard, noIndex = false, context }: Params) {
      const metaTags = [<title key="title">{`${meta?.title ?? ''} - ${titleSection}`}</title>]

      if (isAboutWhichSiteMap) {
        metaTags.push(
          <link
            key="canonical"
            rel="canonical"
            href={'https://www.which.co.uk/about-which/sitemap'}
          />
        )
      } else if (meta?.canonical) {
        metaTags.push(<link key="canonical" rel="canonical" href={meta?.canonical} />)
      }

      if (meta?.twitterTitle || meta?.title) {
        metaTags.push(
          <meta
            key="twitter:title"
            name="twitter:title"
            content={`${meta?.twitterTitle || meta?.title} - ${titleSection}`}
          />
        )
      }

      if (meta?.facebookTitle || meta?.title) {
        metaTags.push(
          <meta
            key="og:title"
            property="og:title"
            content={`${meta?.facebookTitle || meta?.title} - ${titleSection}`}
          />
        )
      }

      if (meta?.twitterImage || imageUrl) {
        metaTags.push(
          <meta key="twitter:image" name="twitter:image" content={meta?.twitterImage || imageUrl} />
        )
      }

      if (meta?.twitterTitle || imageAlt) {
        metaTags.push(
          <meta
            key="twitter:image:alt"
            name="twitter:image:alt"
            content={meta?.twitterTitle || imageAlt}
          />
        )
      }

      if (meta?.facebookImage || imageUrl) {
        metaTags.push(
          <meta key="og:image" property="og:image" content={meta?.facebookImage || imageUrl} />
        )
      }

      if (meta?.desc) {
        metaTags.push(<meta key="description" name="description" content={meta.desc} />)
      }

      if (meta?.facebookDesc || meta?.desc) {
        metaTags.push(
          <meta
            key="og:description"
            property="og:description"
            content={meta?.facebookDesc || meta?.desc}
          />
        )
      }

      if (meta?.twitterDesc || meta?.desc) {
        metaTags.push(
          <meta
            key="twitter:description"
            name="twitter:description"
            content={meta?.twitterDesc || meta?.desc}
          />
        )
      }

      const contentUrl = meta?.canonical && meta.canonical.length > 0 ? meta.canonical : fullUrl

      metaTags.push(
        <meta key="fb:pages" property="fb:pages" content="" />,

        <meta key="twitter:card" name="twitter:card" content={twitterCard || 'summary'} />,
        <meta key="twitter:site" name="twitter:site" content="@whichuk" />,
        <meta key="twitter:creator" name="twitter:creator" content="@whichuk" />,
        <meta key="twitter:url" name="twitter:url" content={contentUrl} />,

        <meta key="og:type" property="og:type" content="website" />,
        <meta key="og:url" property="og:url" content={contentUrl} />,
        <meta key="og:site_name" property="og:site_name" content="Which?" />,
        <meta key="og:locale" property="og:locale" content="en_GB" />,
        <meta key="language" name="language" content="en" />,
        <meta key="country" name="country" content="GB" />,

        <meta key="bingbot" name="bingbot" content="noarchive" />,

        <link key="favicon" rel="shortcut icon" href="/favicon.ico" type="image/ico" />
      )

      if (meta?.fbAppId) {
        metaTags.push(<meta key="fb:app_id" property="fb:app_id" content={meta.fbAppId} />)
      }

      if (noIndex) {
        metaTags.push(<meta key="robots" name="robots" content="noindex, follow" />)
      } else {
        metaTags.push(<meta key="robots" name="robots" content="index, follow" />)
      }

      if (context === 'news') {
        metaTags.push(
          <link
            rel="alternate"
            href="https://www.which.co.uk/news/feed"
            type="application/rss+xml"
            title="Which? news RSS feed"
          />
        )
      }

      return metaTags
    },
  }
}

///////// IMPLEMENTATION /////////

type OptionalTaxonomyHierarchy = MakeOptional<
  Meta['taxonomyHierarchy'],
  keyof Meta['taxonomyHierarchy']
>

type OptionalMetaWithoutTaxonomyHierarchy = Omit<
  MakeOptional<Meta, keyof Meta>,
  'taxonomyHierarchy'
>

type Params = {
  meta: OptionalMetaWithoutTaxonomyHierarchy & { taxonomyHierarchy?: OptionalTaxonomyHierarchy }
  fullUrl?: string
  imageAlt?: string
  imageUrl?: string
  twitterCard?: string
  noIndex?: boolean
  context?: string
}
