/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const hubsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/topic/:subVerticalSlug',
    component: () => (
      <Page pagePath="sub-vertical/SubVerticalPage" template="Hub Page" context="topic" />
    ),
  },
]
