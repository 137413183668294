import type { FunctionComponent } from 'react'
import React from 'react'

import { Link } from '../Link'
import styles from './ThirdPartyCTA.module.scss'

export const ThirdPartyCTA: FunctionComponent<Props> = ({ linkText, linkUrl, children }) => {
  if (!children) {
    return null
  }

  return (
    <div className={styles.CTAContainer}>
      {children}
      {linkText && linkUrl ? (
        <Link href={linkUrl} target="_blank">
          {linkText}
        </Link>
      ) : null}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  linkText?: string
  linkUrl?: string
}
