import type { FunctionComponent } from 'react'
import React from 'react'
import {
  ButtonLink,
  Grid,
  GridItem,
  SectionHeader,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import type { PodcastCollection } from '../../../generated/frontend'
import { IframeEmbed } from '../IframeEmbed'
import styles from './PodcastInfo.module.scss'

export const PodcastInfo: FunctionComponent<Props> = ({
  header = 'Podcast',
  title = '',
  info,
  iframeContent,
  link,
}) => (
  <Grid data-testid="podcast-info" className="w-page-wrapper">
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      {header && <SectionHeader className={styles.podcastInfoHeader} text={header} />}
    </GridItem>
    <GridItem
      span={{ medium: 5, large: 5 }}
      columnStart={{ medium: 2, large: 2 }}
      className={styles.podcastInfoContent}
    >
      {title && (
        <Typography className={styles.podcastInfoTitle} tag="h2" textStyle="sb-text-heading-medium">
          {title}
        </Typography>
      )}
      {info && <Typography textStyle="sb-text-body-default-regular">{info}</Typography>}
    </GridItem>
    <GridItem span={{ medium: 5, large: 5 }} columnStart={{ medium: 7, large: 7 }}>
      {iframeContent && (
        <div className={styles.podcastInfoPlayer}>
          <IframeEmbed content={iframeContent} />
          {link && (
            <ButtonLink className={styles.podcastInfoCta} appearance="secondary" href={link?.href}>
              <ChevronRightIcon />
              {link.text}
            </ButtonLink>
          )}
        </div>
      )}
    </GridItem>
  </Grid>
)

///////// IMPLEMENTATION /////////

type Props = PodcastCollection
