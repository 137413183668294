import type { FunctionComponent, ReactElement } from 'react'
import React, { useState } from 'react'
import type { PageContext } from '@which/glide-ts-types'

import type { PageParams } from '../../../routes'
import { get } from '../../utils/get'
import { MoreButton } from '../MoreButton'
import styles from './FetchMore.module.scss'

export const FetchMore: FunctionComponent<Props> = ({
  children,
  initialData,
  lazyFetch,
  lazyFetchVars,
  dataTarget,
  dataKey = 'articles',
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(2)
  const [localData, setLocalData] = useState<Props['initialData']>(initialData)
  const limitReached = localData[dataKey].length === localData.total

  const [fetchMore, { loading }] = lazyFetch({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const latestData = get(data, `${dataTarget}.${dataKey}`)
      setCurrentPageNumber(currentPageNumber + 1)
      setLocalData({
        total: localData?.total,
        [dataKey]: [...localData[dataKey], ...latestData],
      })
    },
  })

  const fetchMoreHandler = () =>
    fetchMore({
      variables: {
        ...lazyFetchVars,
        pageNumber: currentPageNumber,
      },
    })

  return (
    <div className={styles.fetchMoreWrapper} data-testid="fetch-more">
      {childrenWithProps(children, { ...localData })}
      <MoreButton
        dataTestId="fetch-more-button"
        hideButton={limitReached}
        onClick={fetchMoreHandler}
        isLoading={loading && !limitReached}
        className={styles.fetchMoreButton}
        align="center"
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const childrenWithProps = (children: ReactElement, props: Record<string, unknown>) => {
  return React.cloneElement(children, { ...props })
}

export type PagedData = {
  total: number
  [key: string]: any
}

type Props = {
  initialData: PagedData
  children: ReactElement
  lazyFetchVars: Partial<PageParams> & {
    url?: string
    context?: PageContext | string
  }
  dataTarget?: string
  lazyFetch?: any
  dataKey?: string
}
