import React from 'react'
import { MultiLinkBlock } from '@which/seatbelt'

import classnames from 'classnames'

import { groupLinksByType } from '../../../shared/utils/group-links-by-type'
import { useShowMoreAssociatedArticles } from '../../hooks/useShowMoreAssociatedArticles'
import { AssociatedArticlesWithShowMore } from './AssociatedArticlesWithShowMore'

export const AssociatedArticles: React.FC<Props> = ({
  links,
  title,
  isOrdered,
  maxNumLinks = 5,
  componentType,
  position = 'bottom',
  className,
}) => {
  const renderShowMoreAssociatedArticles = useShowMoreAssociatedArticles([
    'Article Advice Multi Page',
  ])

  if (!links?.length || !title) {
    return null
  }

  const groupedLinks = groupLinksByType(links, { standard: maxNumLinks }) ?? []

  return renderShowMoreAssociatedArticles ? (
    <AssociatedArticlesWithShowMore
      links={links}
      title={title}
      isOrdered={isOrdered}
      maxNumLinks={maxNumLinks}
      componentType={componentType}
      position={position}
      className={className}
    />
  ) : (
    <div
      className={classnames('associated-articles', className)}
      data-testid="associated-article-links-wrapper"
    >
      <MultiLinkBlock
        componentType={componentType && `${componentType}-${position}`}
        title={title}
        links={groupedLinks}
        isOrdered={isOrdered}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type Props = {
  title: string
  componentType: string
  position: string
  links?: Link[]
  viewAllLink?: Link
  isOrdered?: boolean
  maxNumLinks?: number
  className?: string
}

type Link = {
  href: string
  text: string
}
