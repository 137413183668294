import type { FunctionComponent } from 'react'
import { Grid, GridItem, PageTitle, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './ProductHubIntro.module.scss'

export const ProductHubIntro: FunctionComponent<ProductHubIntroProps> = ({
  heading,
  standFirst,
  image,
  className,
  fullWidthLayout,
}) => (
  <section className={classnames(className, { [styles.fullWidthLayout]: fullWidthLayout })}>
    <Grid className={styles.grid} includeGutters={!fullWidthLayout}>
      <GridItem
        className={styles.gridContentWrapper}
        columnStart={{ medium: 1, large: 1 }}
        span={{ medium: 8, large: fullWidthLayout ? 8 : 7 }}
      >
        <Grid className={styles.grid} includeGutters={false}>
          <GridItem
            columnStart={{ medium: 1, large: 1 }}
            span={{ medium: 12, large: fullWidthLayout ? 9 : 12 }}
          >
            <PageTitle pageTitle={heading} pageTitleTag="h1" />
            <Typography textStyle="sb-text-heading-standfirst">{standFirst}</Typography>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        span={{
          medium: 4,
          large: 4,
          xlarge: 4,
        }}
        columnStart={{ medium: 9, large: 9, xlarge: 9 }}
        className={styles.image}
      >
        <Picture {...image} lazyLoad={false} imageClassName={styles.image} />
      </GridItem>
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubIntroProps = {
  heading: string
  standFirst: string
  image: ImageWithSources
  className?: string
  fullWidthLayout?: boolean
}
