import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Picture, Quote, TypographyV2 as Typography } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import styles from './CRScamsRealLifeStory.module.scss'

export const CRScamsRealLifeStory: FunctionComponent<Props> = ({
  title,
  image,
  imageAlt,
  copy,
  quote,
  quoteName,
  quoteLocation,
  link,
}) => (
  <>
    {title && image && copy && link ? (
      <section className={styles.wrapper} data-testid="cr-real-life-story">
        <div className={classnames(styles.half, styles.gap)}>
          <Picture
            src={image}
            alt={imageAlt}
            className={styles.image}
            aspectRatioMain="one-to-one"
          />
        </div>
        <div className={styles.half}>
          <Typography
            tag="h2"
            id={setJumplinkID(title)}
            textStyle="sb-text-heading-medium"
            className={styles.realLifeStoryHeading}
          >
            {title}
            <hr />
          </Typography>
          <Typography textStyle="sb-text-body-default-regular" tag="p" className={styles.copy}>
            {copy}
          </Typography>
          {quote && <Quote authorName={quoteName} jobTitle={quoteLocation} quote={quote} />}
          <div className={styles.button}>
            <ButtonLink appearance="secondary" href={link.href}>
              <ChevronRightIcon />
              {link.text}
            </ButtonLink>
          </div>
        </div>
      </section>
    ) : null}
  </>
)

///////// IMPLEMENTATION /////////

const setJumplinkID = (title: string) => {
  return title.toLowerCase().replace(/\s+/g, '-')
}

type Props = {
  title: string
  copy: string
  quote?: string
  quoteName?: string
  quoteLocation?: string
  link: { href: string; text: string }
  image: string
  imageAlt: string
}
