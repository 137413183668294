import { useCallback, useMemo } from 'react'
import { Grid, GridItem, Heading } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classNames from 'classnames'

import type { RecommendedModel } from '../../../../generated/frontend'
import { Tabs } from '../../../../shared/components/Tabs'
import { RecommendationCard } from '../RecommendationCard/ReccomendationCard'
import { SurveyLink } from '../RecommendedForYou/SurveyLink'
import styles from './TabbedRecommendations.module.scss'

const TabContent = ({ content, whichId, modelId }: TabContentProps) => {
  return (
    <div className={classNames(styles.tabContent)}>
      {content.map((contentItem, index) => (
        <RecommendationCard
          content={contentItem}
          whichId={whichId}
          modelId={modelId}
          index={index}
          key={contentItem.id}
          large={index === 0}
        />
      ))}
    </div>
  )
}

export const TabbedRecommendations = ({ groups, whichId, modelId }: TabbedRecommendationsProps) => {
  const tabbedContent = useMemo(() => {
    return groups.map((group) => {
      return {
        title: group.title,
        content: <TabContent whichId={whichId} modelId={modelId} content={group.content} />,
      }
    })
  }, [groups, whichId, modelId])

  const onTabChange = useCallback((idx: number, title: string) => {
    dynamicGa4DataLayerPush({
      event: 'click_content_tab',
      utagid: 'DSPOC-146',
      item_text: title,
      item_parent_text: 'Based on your interests',
      item_group: 'recommended for you',
      item_index: idx,
    })
  }, [])

  return (
    <Grid
      data-testid={'r4u-tabbed'}
      className={classNames('w-page-wrapper', styles.sectionSpacing)}
    >
      <GridItem
        columnStart={{ large: 2 }}
        span={{ large: 10 }}
        className={styles.mobileMaxWidthWrapper}
      >
        <Heading headingType="large" heading="Based on your interests" headingTag="h2" />
        <Tabs tabs={tabbedContent} onChange={onTabChange} />
        <SurveyLink variationName="tabbed" />
      </GridItem>
    </Grid>
  )
}

type TabbedRecommendationsProps = {
  groups: RecommendedModel[]
  whichId: string
  modelId: string
}

type TabContentProps = {
  content: RecommendedModel['content']
  whichId: string
  modelId: string
}
