import type { FunctionComponent } from 'react'
import React from 'react'
import { ImageGallery } from '@which/seatbelt'
import type { GalleryImage, GalleryItem } from '@which/shared'

import { useFullUrl } from '../../hooks/useFullUrl'
import styles from './GalleryEmbed.module.scss'

export const Gallery: FunctionComponent<Props> = ({ data }) => {
  const { getFullUrl } = useFullUrl()
  const url = getFullUrl()

  return (
    <div className={styles.embedGallery} data-which-component="embedGallery" data-testid="gallery">
      <ImageGallery
        title={data?.title ?? ''}
        images={data?.images.map(formatGalleryImages)}
        url={url}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const formatGalleryImages = ({ alt, caption, credit, sources }: GalleryImage) => ({
  alt,
  caption,
  credit: credit,
  src: sources?.main?.tablet2x || '',
  sources: {
    main: [
      {
        srcset: `${sources?.main?.tablet2x} 2x, ${sources?.main?.tablet1x} 1x`,
        media: '(min-width: 768px)',
      },
      {
        srcset: `${sources?.main?.mobile2x} 2x, ${sources?.main?.mobile1x} 1x`,
      },
    ],
    thumb: [
      {
        srcset: `${sources?.thumb?.tablet2x} 2x, ${sources?.thumb?.tablet1x} 1x`,
        media: '(min-width: 768px)',
      },
      {
        srcset: `${sources?.thumb?.mobile2x} 2x, ${sources?.thumb?.mobile1x} 1x`,
      },
    ],
  },
})

type Props = {
  data: GalleryItem
}
