import React from 'react'
import { GridItem } from '@which/seatbelt'
import type { StaticToolBaseProps } from '@which/static-tools'

import * as importers from './importers'

type StaticToolProps = StaticToolBaseProps & {
  toolName: keyof typeof importers
}

export const StaticTool = ({ toolName, onwardJourneys }: StaticToolProps) => {
  const Component = importers[toolName]

  return (
    <GridItem data-testid="static-tool">
      <Component
        data-testid="static-tool-component"
        toolName={toolName}
        onwardJourneys={onwardJourneys}
      />
    </GridItem>
  )
}
