import type { Reducer } from 'react'

import type { Breadcrumb, ProductDetail } from '../../../../generated/frontend'
import type { ComparisonTableActions, ComparisonTableState } from '../ComparisonTableContext'
import { removeTableDataColumn } from '../utils/remove-table-data-column'
import {
  REFINE_TABLE_DATA,
  REMOVE_TECH_SPECS_COLUMN,
  TABLE_ROWS_TO_BE_REFINED,
  UPDATE_PRODUCT_COMPARISON_PAGE_DATA,
} from './comparisonTableActionTypes'

export const comparisonTableReducer: Reducer<ComparisonTableState, ComparisonTableActions> = (
  state,
  action
) => {
  switch (action.type) {
    case REFINE_TABLE_DATA: {
      const { status } = action

      if (status === 'apply') {
        return {
          ...state,
          refineStatus: status,
          tableData: state.tableData.flatMap((section) => {
            const rows = section.rows.filter((row) =>
              state.rowsToBeRefined?.[section.label]?.includes(row.rowLabel)
            )

            return rows.length ? [{ ...section, rows }] : []
          }),
        }
      }

      if (status === 'reset') {
        return {
          ...state,
          refineStatus: 'default',
          rowsToBeRefined: {},
          originalTableData: state.originalTableData,
          tableData: state.originalTableData,
          productDetails: state.productDetails as ProductDetail[],
        }
      }

      return { ...state, refineStatus: status, rowsToBeRefined: {} }
    }

    case TABLE_ROWS_TO_BE_REFINED: {
      const { row, sectionLabel } = action

      if (!state.rowsToBeRefined[sectionLabel]) {
        state.rowsToBeRefined[sectionLabel] = []
      }

      if (state.rowsToBeRefined[sectionLabel].includes(row)) {
        return {
          ...state,
          rowsToBeRefined: {
            ...state.rowsToBeRefined,
            [sectionLabel]: state.rowsToBeRefined[sectionLabel].filter(
              (refinedRow) => refinedRow !== row
            ),
          },
        }
      }

      return {
        ...state,
        rowsToBeRefined: {
          ...state.rowsToBeRefined,
          [sectionLabel]: [...state.rowsToBeRefined[sectionLabel], row],
        },
      }
    }

    case REMOVE_TECH_SPECS_COLUMN: {
      const { index } = action

      return {
        ...state,
        tableData: removeTableDataColumn(state.tableData, index),
        originalTableData: removeTableDataColumn(state.originalTableData, index),
        productDetails: state.productDetails.filter(
          (_, productDetailIndex) => productDetailIndex !== index
        ) as ProductDetail[],
      }
    }

    case UPDATE_PRODUCT_COMPARISON_PAGE_DATA: {
      const { data } = action

      if (state.originalTableData.length) {
        return {
          ...state,
          tableData: data.comparisonTable.tableData,
          productDetails: data.comparisonTable.productDetails as ProductDetail[],
          breadcrumb: data.breadcrumb,
        }
      }

      return {
        ...state,
        tableData: data.comparisonTable.tableData,
        originalTableData: data.comparisonTable.tableData,
        productDetails: data.comparisonTable.productDetails as ProductDetail[],
        breadcrumb: data.breadcrumb,
      }
    }

    /* istanbul ignore next */
    default:
      return state
  }
}

export const comparisonTableInitialState = {
  refineStatus: 'default',
  rowsToBeRefined: {},
  tableData: [],
  originalTableData: [],
  productDetails: [],
  isSidebarHidden: false,
  breadcrumb: {} as Breadcrumb,
} as ComparisonTableState
