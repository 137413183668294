import type { FunctionComponent } from 'react'
import React from 'react'

import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll'

export const WithHideOnArticleScroll = (
  Component: FunctionComponent<any>,
  { className, ...rest }: Props
) =>
  className?.includes('hideOnArticleScroll') ? (
    <HideOnArticleScroll>
      <Component className={className} position="right" {...rest} />
    </HideOnArticleScroll>
  ) : (
    <Component className={className} position="bottom" {...rest} />
  )

///////// IMPLEMENTATION /////////

type Props = {
  className: string
} & Record<string, unknown>
