import type { FunctionComponent } from 'react'
import React from 'react'
import {
  ButtonLink,
  Grid,
  GridItem,
  Heading,
  Image,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import styles from './CRScamsSignUp.module.scss'

export const CRScamsSignUp: FunctionComponent = () => (
  <Grid includeGaps={false} includeGutters={false} className={styles.wrapper}>
    <GridItem
      className={styles.textAndButton}
      columnStart={{ medium: 1, large: 1 }}
      span={{ medium: 12, large: 8 }}
    >
      <Heading heading="Sign up for scam alerts" headingTag="h2" headingType="large" />
      <Typography textStyle="sb-text-body-default-regular" className={styles.signUpText}>
        Our emails will alert you to scams doing the rounds, and provide practical advice to keep
        you one step ahead of fraudsters.
      </Typography>
      <ButtonLink
        href="https://signup.which.co.uk/wlp-scamalert-newsletter"
        className={styles.button}
      >
        Sign up for scam alerts
      </ButtonLink>
    </GridItem>
    <GridItem
      columnStart={{ medium: 1, large: 9 }}
      span={{ medium: 12, large: 4 }}
      className={styles.imageContainer}
    >
      {/* @TDOD: Use Seatbelt <Picture> component here */}
      <Image
        src="https://media.product.which.co.uk/prod/images/original/3858a9a6b94f-image-desktop.png"
        alt="Sign up"
        wrapperClassName={styles.imageWrapper}
        imageClassName={styles.image}
      />
    </GridItem>
  </Grid>
)
