import type { FunctionComponent } from 'react'
import React from 'react'
import { AnimationWrapper, Heading, TypographyV2 as Typography } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { Link } from '../Link'
import styles from './AdviceCards.module.scss'

export const AdviceCards: FunctionComponent<AdviceCardProps> = ({
  heading,
  cards = [],
  className,
}) => {
  return (
    <div data-testid="advice-cards-wrapper" className={className}>
      {heading && <Heading heading={heading} headingTag="h2" headingType="large" />}
      {cards?.length > 0 && (
        <div className={styles.adviceCards}>
          {cards.map(({ title, strapline, href, linkText }, index) => (
            <Link
              href={href}
              className={styles.adviceCardsLink}
              includeAnimation={false}
              key={`${title}=${index}`}
            >
              <Typography
                tag="h2"
                textStyle="sb-text-heading-medium"
                className={styles.adviceCardsLinkTitle}
              >
                {title}
              </Typography>
              <Typography
                textStyle="sb-text-body-default-regular"
                className={styles.adviceCardsLinkStrapline}
              >
                {strapline}
              </Typography>
              <ChevronRightIcon className={styles.adviceCardsLinkIcon} />
              <AnimationWrapper className={styles.adviceCardsLinkAnimation}>
                {linkText}
              </AnimationWrapper>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type AdviceCardProps = {
  heading: string
  cards: Array<{
    href: string
    linkText: string
    strapline: string
    title: string
  }>
  className?: string
}
