import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Jumplinks as SBJumplinks } from '@which/seatbelt'

import { JumpLinkHeaderBar } from '../../../../shared/components/JumpLinkHeaderBar'
import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './JumpLinks.module.scss'

export const JumpLinks: FunctionComponent<Props> = ({ handleOnClick, showJumpLinkBar, links }) => {
  const [jumpLinkBarHeight, setJumpLinkBarHeight] = useState(0)

  if (links.length <= 3) {
    return null
  }

  const scrollTo = (slug: string) => {
    const header = document.getElementById(slug)

    if (header) {
      const scrollPos = window.scrollY + header.getBoundingClientRect().top

      window.scrollTo({
        top: scrollPos - jumpLinkBarHeight,
        behavior: 'smooth',
      })
      window.history.pushState(null, '', `#${slug}`)
    }
  }

  const RenderLinks = () => (
    <div className={styles.jumpLinksWrapper}>
      <SBJumplinks
        items={links.map(({ name, slug }) => ({
          text: name,
          path: `#${slug}`,
          onClick: (event: MouseEvent): void => {
            event.preventDefault()

            if (handleOnClick) {
              handleOnClick()

              setTimeout(() => {
                scrollTo(slug)
              }, 0)
            } else {
              scrollTo(slug)
            }
          },
        }))}
      />
    </div>
  )

  return (
    <>
      {showJumpLinkBar ? (
        <JumpLinkHeaderBar jumpLinkBarHeightCallback={setJumpLinkBarHeight} links={links}>
          <RenderLinks />
        </JumpLinkHeaderBar>
      ) : (
        <RenderLinks />
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  handleOnClick?: () => void
  showJumpLinkBar?: boolean
  links: ArticleReviewsPageData['jumpLinks']
}
