import type { FunctionComponent } from 'react'
import { ButtonLink, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import styles from './ProductHubBrandLinks.module.scss'

export const ProductHubBrandLinks: FunctionComponent<ProductHubBrandLinksProps> = ({
  heading,
  description,
  brandLinks,
}) => (
  <section
    aria-label={heading}
    className={styles.productHubBrandLinks}
    id="product-hub-brand-links"
  >
    <Heading headingType="medium" headingTag="h3" heading={heading} subHeading={description} />

    <ul className={styles.brandLinksList}>
      {brandLinks.map(({ text, href }) => (
        <li className={styles.brandLink} key={href}>
          <ButtonLink href={href} appearance="secondary" data-which-id="producthub-button">
            <ChevronRightIcon />
            {text}
          </ButtonLink>
        </li>
      ))}
    </ul>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubBrandLinksProps = {
  heading: string
  description?: string
  brandLinks: Array<{
    text: string
    href: string
  }>
}
