import type { FunctionComponent } from 'react'
import { Heading, PromoCard } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './RelatedProducts.module.scss'

export const RelatedProducts: FunctionComponent<RelatedProductsProps> = ({
  className,
  relatedProducts,
  productCategory,
}) => {
  return (
    <section
      aria-label="Other reviews"
      data-testid="related-products"
      className={className}
      id="other-reviews"
    >
      <Heading
        headingTag="h2"
        headingType="large"
        heading={
          productCategory === 'Mattresses' ? 'More reviews to improve your sleep' : 'Other reviews'
        }
      />
      <div className={styles.container}>
        {relatedProducts &&
          relatedProducts.map((relatedProduct, index) => (
            <PromoCard
              {...relatedProduct}
              className={styles.productCard}
              imageAspectRatio="two-to-one"
              key={relatedProduct.href}
              data-testid="related-product-card"
              gtmProps={{
                'data-which-id': 'producthub-card',
                'data-section': 'related products',
                'data-index': index + 1,
              }}
              titleTag="span"
            />
          ))}
      </div>
    </section>
  )
}

export type RelatedProducts = {
  href: string
  strapline: string
  title: string
  image: ImageWithSources
}[]

type RelatedProductsProps = {
  className?: string
  relatedProducts: RelatedProducts
  productCategory: string
}
