import type { FunctionComponent } from 'react'
import React from 'react'
import { Loader as SBLoader } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './Loader.module.scss'

export const Loader: FunctionComponent<LoaderProps> = ({ className }) => {
  return (
    <div data-testid="loader-wrapper" className={classnames(styles.loaderWrapper, className)}>
      <div className="sr-only">Loading content</div>
      <SBLoader className={styles.loaderSvg} />
    </div>
  )
}

/////IMPLEMENTATION//////

type LoaderProps = {
  className?: string
}
