import type { FunctionComponent } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { createSlugGenerator } from '@which/shared'

import { CardedDynamicTable } from '../CardedDynamicTable'
import type { CardedDynamicTableProduct } from '../CardedDynamicTable/CardedDynamicTable'
import styles from '../CardedDynamicTable/CardedDynamicTable.module.scss'

export const SmartProductPicker: FunctionComponent<Props> = ({
  title,
  description,
  logInCTA,
  products = [],
  signUpCTA,
}) => {
  if (!products.length) {
    return null
  }

  const slugGenerator = createSlugGenerator()

  return (
    <div data-testid="smart-product-picker" className={styles.container}>
      {title && (
        <Typography tag="h2" textStyle="sb-text-heading-large" id={slugGenerator(title, true)}>
          {title}
        </Typography>
      )}

      {description && (
        <div data-testid="spp-description" dangerouslySetInnerHTML={{ __html: description }} />
      )}

      <CardedDynamicTable
        products={products}
        logInCTA={logInCTA}
        signUpCTA={signUpCTA}
        trackingPrefix="spp"
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type Props = {
  description?: string
  logInCTA?: { url: string; label: string; copy: string }
  title?: string
  products: CardedDynamicTableProduct[]
  signUpCTA?: { url: string; label: string; smallPrint?: string }
}
