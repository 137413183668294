import React, { createElement, useState } from 'react'
import type { ProductColour, TestScoreProps } from '@which/seatbelt'
import {
  AnimationWrapper,
  ColourSwatchGroup,
  ProductScoreGauge,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { AMAZON_AFFILIATE_TAG, dynamicGa4DataLayerPush, getTaggedAmazonUrl } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import type { ProductOffer } from '../../../../../../generated/frontend'
import { Link } from '../../../../../../shared/components/Link'
import type { ContentType } from '../../../../../../shared/components/TrackonomicsLink/TrackonomicsLink.tsx'
import { compareProductsStorageName } from '../../../../../../shared/constants/compare-products-storage-name'
import { useCompareTray } from '../../../../../../shared/hooks/useCompareTray'
import { formatRetailers } from '../../../../../../shared/utils/formatRetailers'
import { ArticleTrackonomicsLink } from '../../../../../article/components/ArticleTrackonomicsLink'
import { ProductBadges } from '../../../../shared/components/ProductBadges/ProductBadges'
import { convertPriceValueToFloat } from '../../../../utils/convert-price-value-to-float'
import comparisonTableStyles from '../ComparisonTable.module.scss'
import styles from './ProductDetails.module.scss'
import { ProductDetailThumbnail } from './ProductDetailThumbnail'

export const Product: React.FC<ProductProps> = ({
  alertType,
  badges,
  businessKey,
  hasOneProductBadge,
  image,
  manufacturer,
  model,
  productScoreGauge,
  price,
  slug,
  taxonomySlug,
  tooltipOpenHandler,
  variants,
  offers = [],
  tag = 'td',
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0])
  const { testScoreGauge, variant } = productScoreGauge
  const renderBadgesSpacer = hasOneProductBadge && !badges?.length
  const validOffers = formatRetailers(offers)
  const { compareTrayItems } = useCompareTray(compareProductsStorageName, taxonomySlug)
  const compareItem = compareTrayItems?.find((item) => item.businessKey === businessKey)
  const priceNotInLink = useFeatureIsOn('whc-compare-retailers')

  const handleAffiliateClick = (validOffer: ProductOffer) => {
    const item_spec = new URL(validOffer.url).hostname

    dynamicGa4DataLayerPush({
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${
        validOffer.retailer.name
      } | ${compareItem?.manufacturer} ${compareItem?.model} | ${convertPriceValueToFloat(
        validOffer.priceValue,
        true
      )}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(validOffer.priceValue))),
      item_url: validOffer.url,
      item_spec,
      item_group: 'compare tray',
    })
  }

  const renderLink = ({
    url,
    validOffer,
    formattedPrice,
    retailerName,
    trackable,
  }: RenderLinkProps) => {
    const linkText = priceNotInLink ? retailerName : `${formattedPrice} from ${retailerName}`
    const linkStyle = priceNotInLink
      ? 'sb-text-interface-body-small-strong'
      : 'sb-text-body-small-regular'

    const LinkComponent = trackable ? ArticleTrackonomicsLink : Link

    const trackableProps = {
      href: url,
      contentType: 'article' as ContentType,
      ...(priceNotInLink && { className: styles.link }),
      optionalTracking: {
        item_group: 'compare tray',
      },
      onClick: () => handleAffiliateClick(validOffer),
    }

    const nonTrackableProps = {
      href: getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG),
      onClick: () => handleAffiliateClick(validOffer),
      ...(priceNotInLink && { className: styles.link }),
      'aria-label': `Buy from ${retailerName} at ${formattedPrice}`,
      'data-which-id': 'affiliate-link',
      target: '_blank',
      rel: 'nofollow',
      contentType: 'article' as ContentType,
    }

    const componentProps = trackable ? trackableProps : nonTrackableProps

    return (
      <Typography tag="span" textStyle={linkStyle}>
        {priceNotInLink && formattedPrice}
        <LinkComponent {...componentProps}>
          <Typography tag="span" textStyle={linkStyle}>
            <AnimationWrapper>{linkText}</AnimationWrapper>
          </Typography>
        </LinkComponent>
      </Typography>
    )
  }

  const props = {
    key: `product-details-${businessKey}`,
    className: classnames(styles.tableData, comparisonTableStyles.tableDataRowValue),
  }

  const children = (
    <>
      <ProductBadges badges={badges} />

      {renderBadgesSpacer && (
        <div className={styles.badgeSpacer} data-testid="product-details-badge-spacer"></div>
      )}
      <Link
        href={slug}
        data-which-id="compare-thumbnail-link"
        data-section={`${manufacturer.name}|${model}`}
      >
        <ProductDetailThumbnail image={image} alertType={alertType} />
      </Link>
      <div className={styles.productScoreGauge}>
        <ProductScoreGauge
          {...productScoreGauge}
          testScoreGauge={testScoreGauge}
          variant={variant}
          tooltip={{ ...productScoreGauge.tooltip, openHandler: tooltipOpenHandler }}
        />
      </div>

      {price && validOffers.length === 0 && (
        <Typography
          textStyle={
            priceNotInLink ? 'sb-text-interface-body-small-strong' : 'sb-text-body-default-strong'
          }
          tag={'p'}
          className={styles.price}
        >
          {price}
          <Typography
            tag="span"
            textStyle={
              priceNotInLink ? 'sb-text-interface-body-small-strong' : 'sb-text-body-small-regular'
            }
            className={styles.typicalPriceLabel}
          >
            Typical price
          </Typography>
        </Typography>
      )}

      <ol className={styles.offersList}>
        {validOffers.map((validOffer) => {
          const { retailer, url, isTrackable, formattedPrice } = validOffer
          return (
            <li key={retailer.name} className={styles.offersListItem}>
              {renderLink({
                url,
                validOffer,
                formattedPrice,
                retailerName: retailer.name,
                trackable: isTrackable,
              })}
            </li>
          )
        })}
      </ol>

      {variants && selectedVariant && (
        <ColourSwatchGroup
          classNameRadioGroup={styles.radioGroup}
          name={`colour-swatch-group-${businessKey}`}
          onChange={setSelectedVariant}
          selectedVariant={selectedVariant}
          variants={variants}
        />
      )}
    </>
  )

  return createElement(tag, props, children)
}

type ProductProps = {
  alertType: string
  badges: string[]
  businessKey?: string
  hasOneProductBadge: boolean
  image?: {
    alt: string
    caption: string
    dimensions: {
      width: string
      height: string
    }
    id: string
    renditions: string[]
    src: string
    sources: {
      media: string
      sizes: string
      srcset: string
      type: string
    }[]
  }
  manufacturer: {
    name: string
  }
  model: string
  productScoreGauge: {
    className?: string
    label?: string
    tooltip: {
      contents: string
      ariaLabel: string
      title?: string
    }
    testScoreGauge: TestScoreProps
    variant?: TestScoreProps['variant']
  }
  price?: string
  slug: string
  taxonomySlug: string
  tooltipOpenHandler?: () => void
  variants?: ProductColour[][] | null
  offers?: ProductOffer[]
  tag?: string
}

type RenderLinkProps = {
  url: string
  validOffer: ProductOffer
  formattedPrice: string
  retailerName: string
  trackable: boolean
}
