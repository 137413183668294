import React from 'react'

export const rightsCR = (
  <g fill="none" fillRule="evenodd" data-testid="svg-rightsCR">
    <path
      d="M32.1 33.8a36.2 36.2 0 01-8.66-1.04c-3.9-.97-5.9-1.7-6.7-3.16-.59-1.07-.34-2.19-.05-3.04.24-.73.94-1.95 2.93-1.95 1 0 2.15.28 3.62.64 1.45.36 3.1.77 5.03 1 .65.09 1.32.13 1.98.13 4.48 0 9.06-1.8 14.03-5.53a23.63 23.63 0 0113-4.58h1.4l.1 1.39c.71 9.24.12 9.89-.32 10.36l-.45.48-.72.03c-.8.05-9.3 1.54-14.44 3.47a31.02 31.02 0 01-10.75 1.8z"
      fill="#FFF"
    />
    <path
      d="M60.06 14.77H57.3c-.3 0-7.49.06-13.91 4.88-4.63 3.47-9.05 5.23-13.13 5.23-.6 0-1.2-.04-1.8-.11a41.02 41.02 0 01-4.86-.97c-1.56-.39-2.8-.7-3.97-.7-2.1 0-3.74 1.12-4.36 2.99-.22.67-.81 2.45.17 4.23 1.08 1.97 3.23 2.8 7.65 3.9 2.83.7 5.95 1.08 9.02 1.08 4.07 0 7.97-.65 11.28-1.9 4.85-1.81 12.85-3.26 14.02-3.37a3.11 3.11 0 002.16-1c.83-.89 1.48-1.6.72-11.49l-.22-2.77zm-2.77 3s.7 9.26 0 9.26c-.72 0-9.58 1.55-14.96 3.56A29.47 29.47 0 0132.1 32.3c-2.88 0-5.73-.35-8.3-1-5.7-1.42-6.4-2.13-5.7-4.27.23-.67.75-.92 1.52-.92 1.67 0 4.57 1.15 8.46 1.64.72.09 1.45.13 2.17.13 4.98 0 9.95-2.1 14.93-5.83 5.7-4.28 12.1-4.28 12.1-4.28z"
      fill="#424242"
    />
    <path
      d="M83.28 33.8c-3.9 0-7.62-.62-10.76-1.8-5.14-1.93-13.64-3.42-14.47-3.47-.4 0-.83-.19-1.14-.51-.43-.48-1.03-1.12-.32-10.36l.1-1.39h1.4c.28 0 6.98.05 13 4.58 4.9 3.67 9.62 5.53 14.03 5.53.66 0 1.33-.04 1.98-.12 1.93-.24 3.58-.65 5.04-1 1.46-.37 2.62-.65 3.61-.65 1.99 0 2.7 1.22 2.94 1.95.28.85.53 1.97-.06 3.04-.8 1.47-2.8 2.19-6.7 3.16-2.72.68-5.7 1.04-8.65 1.04"
      fill="#FFF"
    />
    <path
      d="M58.09 14.77H55.3l-.21 2.77c-.76 9.9-.11 10.6.71 11.5.56.6 1.34.96 2.16 1 1.17.1 9.17 1.55 14.02 3.36 3.31 1.24 7.21 1.9 11.29 1.9 3.06 0 6.18-.37 9.01-1.08 4.42-1.1 6.58-1.93 7.66-3.9.98-1.78.38-3.57.16-4.24-.62-1.86-2.25-2.97-4.36-2.97-1.17 0-2.4.3-3.97.69-1.42.34-3.02.74-4.86.97-.6.07-1.2.11-1.8.11-4.08 0-8.5-1.76-13.12-5.23a25.27 25.27 0 00-13.91-4.88m0 3s6.4 0 12.1 4.28c4.98 3.73 9.96 5.83 14.93 5.83.72 0 1.45-.04 2.17-.13 3.9-.5 6.79-1.64 8.46-1.64.78 0 1.29.25 1.51.92.72 2.14 0 2.85-5.7 4.28-2.56.64-5.4.99-8.28.99-3.53 0-7.1-.53-10.23-1.7-5.38-2.02-14.25-3.57-14.96-3.57-.71 0 0-9.26 0-9.26"
      fill="#424242"
    />
    <path
      d="M24 33l16.23 35.73"
      stroke="#424242"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.85 23.83s5.34 2.14 10.33 4.27a34.8 34.8 0 0014.24 2.85c5.7 0 9.97-1.42 9.97-1.42s2.14-.72 1.43 0c-1.97 1.97-9.97 2.85-15.44 2.72-4.5-.1-8.32-1.77-12.47-2.96-4.14-1.19-8.06-1.84-8.06-1.84v-3.62m-5.72 0s-5.7 2.14-10.68 4.27a34.8 34.8 0 01-14.25 2.85c-5.7 0-9.97-1.42-9.97-1.42s-2.14-.72-1.43 0c1.98 1.97 9.98 2.85 15.44 2.72 4.51-.1 8.32-1.77 12.47-2.96a86.68 86.68 0 018.42-1.9v-3.56"
      fill="#E0E0E0"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      d="M82.65 81.96c-5.39 0-9.77-1.8-9.77-3.99v-1.01h39.33v1.07c0 2.16-4.32 3.93-9.63 3.93H82.65z"
      fill="#E0E0E0"
    />
    <path
      d="M113.7 68.2H71.39v3.99c0 6.21 5.06 11.27 11.27 11.27h19.93c6.13 0 11.13-5 11.13-11.13V68.2zm-3 3v1.13a8.13 8.13 0 01-8.12 8.13H82.65a8.27 8.27 0 01-8.27-8.27v-1h36.33z"
      fill="#424242"
    />
    <path
      d="M14.27 81.96c-5.39 0-9.77-1.8-9.77-3.99v-1.01h39.33v1.07c0 2.16-4.32 3.93-9.63 3.93H14.27z"
      fill="#E0E0E0"
    />
    <path
      d="M45.33 68.2H3v3.99c0 6.21 5.05 11.27 11.27 11.27H34.2c6.13 0 11.13-5 11.13-11.13V68.2zm-3 3v1.13a8.13 8.13 0 01-8.13 8.13H14.27A8.27 8.27 0 016 72.19v-1h36.33z"
      fill="#424242"
    />
    <path
      d="M29.43 100.48v-2.2a5.8 5.8 0 015.08-5.75c1.47-1.57 6.58-6.72 12.85-9.85 6.3-3.16 7.7-15.1 7.72-15.21l.15-1.33h5.53l.15 1.33c.02.12 1.43 12.06 7.73 15.2 6.26 3.14 11.37 8.3 12.85 9.86a5.8 5.8 0 015.08 5.75v2.2H29.43z"
      fill="#FFF"
    />
    <path
      d="M59.42 64.64H53.9l-.3 2.66c-.35 3.12-2.28 11.73-6.9 14.03-6.04 3.02-11.03 7.85-12.92 9.8a7.3 7.3 0 00-5.84 7.15v3.7h60.14v-3.7a7.3 7.3 0 00-5.84-7.14c-1.9-1.97-6.88-6.79-12.92-9.8-4.62-2.32-6.55-10.92-6.9-14.04l-.3-2.66h-2.69zm0 3s1.43 12.82 8.55 16.38c7.12 3.56 12.82 9.97 12.82 9.97h-.01a4.29 4.29 0 014.29 4.29v.7H30.93v-.7a4.29 4.29 0 014.29-4.3h-.01s5.7-6.4 12.82-9.96c7.12-3.56 8.54-16.38 8.54-16.38h2.85z"
      fill="#424242"
    />
    <path
      d="M61.78 75.48s.53 5.3 6.9 8.49a34.51 34.51 0 0112.44 10.6h-7.15s0-2.12-5.3-6.36-7.95-7.42-7.95-12.73h1.06z"
      fill="#E0E0E0"
    />
    <path
      d="M24 34l-.2 35.06m-16.11.32L24 33m69 0L76.02 69.38m16.6-37.08l.32 36.43m16.53 1.41L93 33"
      stroke="#424242"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fill="#BDBDBD" d="M58.35 72.98h2.5V14.92h-2.5z" />
    <path
      d="M54.4 76.97a2.9 2.9 0 01-2.89-2.9c0-1.38.9-2.51 2.14-2.85V19.05a2.9 2.9 0 01-2.14-2.8c0-1.65 1.3-2.95 2.9-2.95h7.18a2.9 2.9 0 012.9 2.9c0 1.38-.91 2.51-2.14 2.85v52.17a2.9 2.9 0 012.13 2.8 2.9 2.9 0 01-2.89 2.95H54.4z"
      fill="#FFF"
    />
    <path
      d="M61.6 11.8h-7.2a4.4 4.4 0 00-4.39 4.4v.06c0 1.6.86 3 2.14 3.77v50.21A4.4 4.4 0 0050 74.01v.07a4.4 4.4 0 004.4 4.39h7.18a4.4 4.4 0 004.4-4.4v-.06a4.4 4.4 0 00-2.14-3.77V20.03a4.4 4.4 0 002.13-3.77v-.07a4.4 4.4 0 00-4.39-4.39m0 3c.77 0 1.4.62 1.4 1.4v.06c0 .77-.63 1.4-1.4 1.4h-.74v54.96h.74a1.39 1.39 0 011.4 1.4v.06c0 .77-.63 1.39-1.4 1.39H54.4a1.4 1.4 0 01-1.39-1.4v-.06a1.39 1.39 0 011.4-1.39h.74V17.65h-.75a1.4 1.4 0 01-1.39-1.4v-.06c0-.77.62-1.39 1.4-1.39h7.18"
      fill="#424242"
    />
    <path d="M112.46 102.3a15.94 15.94 0 110-31.9 15.94 15.94 0 010 31.9z" fill="#01892E" />
    <path
      d="M128.4 86.35a15.94 15.94 0 11-31.89 0 15.94 15.94 0 0131.89 0z"
      stroke="#FFF"
      strokeWidth="3"
    />
    <path stroke="#FFF" strokeWidth="3" d="M104.74 87.1l4.79 4.78 10.41-10.41" />
    <path d="M83.6 94.1H32.12" fill="#FFF" />
    <path d="M83.6 94.1H32.12" stroke="#424242" strokeWidth="2" />
    <path
      d="M58 14.47A6.5 6.5 0 0151.5 8 6.5 6.5 0 0158 1.5a6.5 6.5 0 016.48 6.49A6.5 6.5 0 0158 14.47"
      fill="#FFF"
    />
    <path
      d="M58 0A8 8 0 1058 15.99 8 8 0 0058 0m0 3a4.99 4.99 0 110 9.97A4.99 4.99 0 0158 3"
      fill="#424242"
    />
    <path
      d="M59.57 5.68a3 3 0 11-6 0 3 3 0 016 0"
      fill="#E0E0E0"
      style={{ mixBlendMode: 'multiply' }}
    />
  </g>
)
