import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'

import classnames from 'classnames'

import { loadScript } from '../../utils/loadScript'
import styles from './Brightcove.module.scss'

export const Brightcove: FunctionComponent<Props> = ({
  videoId,
  accountId,
  playlist,
  className,
}) => {
  useEffect(() => {
    loadScript({
      src: `//players.brightcove.net/${accountId}/default_default/index.min.js`,
      async: true,
    })
  }, [accountId])

  return (
    <>
      <section className={classnames(styles.embedBrightcove, className)}>
        <video
          data-testid="brightcove-video"
          {...(playlist ? { 'data-playlist-id': videoId } : { 'data-video-id': videoId })}
          data-account={accountId}
          data-player="default"
          data-embed="default"
          data-application-id
          className={classnames('video-js', 'vjs-16-9')}
          controls
        />
      </section>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  videoId: string
  accountId: string
  playlist: boolean
  className?: string
}
