import type { ReactNode } from 'react'
import { Children, cloneElement, isValidElement } from 'react'

import type { VariationSettings } from './getVariantSettings'

/**
 * This mapper will re-structure the table's children from:
 *
 *  [header, header, header]
 *  [provider, score, score]
 *  [provider, score, score]
 *  [provider, score, score]
 *
 * to a single score cell which will span all across and to the bottom:
 *  [header, header, header]
 *  [provider, score       ]
 *  [provider              ]
 *  [provider              ]
 */

export const mapScoresLockedTree = (children: ReactNode, variationSettings: VariationSettings) =>
  mapChildren(Children.toArray(children), variationSettings)

const mapChildren = (allChildrenArray: ChildrenTree, variationSettings: VariationSettings) =>
  allChildrenArray.map((tableSection) => {
    if (isValidElement(tableSection) && tableSection.type === 'tbody') {
      const restructuredRows = Children.toArray(tableSection.props.children).map((row, index) => {
        if (isValidElement(row)) {
          // First row
          if (index === 0) {
            const {
              props: { children: firstRowChildren },
            } = row

            // Slice up to two cells (provider + single score cell)
            const twoCellsRow = firstRowChildren.slice(0, 2)
            const [firstCell, secondCell] = Children.toArray(twoCellsRow)

            if (isValidElement(secondCell)) {
              return cloneElement(
                row,
                {
                  ...row.props,
                },
                [
                  firstCell,
                  cloneElement(secondCell, {
                    ...secondCell.props,
                    className: 'singleCtaCell',
                    colSpan: variationSettings?.colSpan,
                    rowSpan: variationSettings?.rowSpan,
                  }),
                ]
              )
            }

            return row
          }

          if (index >= 1) {
            const {
              props: { children: rowChildren },
            } = row

            return cloneElement(row, {
              ...row.props,
              children: rowChildren.slice(0, 1),
            })
          }
        }

        return row
      })

      const clonedTbody = cloneElement(tableSection, {
        ...tableSection.props,
        children: restructuredRows,
      })

      return clonedTbody
    }

    return tableSection
  })

type ChildrenTree = (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
