import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureValue } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import { checkUrlExistsInList } from '../../utils/checkUrlExistsInList'
import { ShowMore } from '../ShowMore'
import { SinglePadlockCta } from '../SinglePadlockCta'
import styles from './Table.module.scss'
import { formatTableChildren, mapScrollableTable } from './Table.utils'
import { getVariantSettings, variationTableRenderer } from './Variation/utils'

type Props = {
  className: string
}

type FeatureTableVariation = 'control' | 'cta-to-offers-page' | 'cta-to-modal'

export const Table: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const includePageInTest = checkUrlExistsInList(pathname)

  return includePageInTest ? <ConditionalTableRender {...props} /> : <TableRender {...props} />
}

const ConditionalTableRender: React.FC<Props> = (props) => {
  // Check loaded page is part of the URLs for testing the new variant
  // Comes from Growthbook (control, cta-to-offers-page, cta-to-modal)
  const featureTableVariation = useFeatureValue(
    'ces-locked-table-variation-string',
    'control'
  ) as FeatureTableVariation

  return <TableRender {...{ ...props, featureTableVariation }} />
}

const TableRender: React.FC<Props & { featureTableVariation?: FeatureTableVariation }> = ({
  className,
  children,
  featureTableVariation = 'control',
  ...props
}) => {
  const tableNoScrolls = String(className).includes('non-scrollable')
  const formattedChildren = formatTableChildren(children)

  const variationSettings = getVariantSettings(formattedChildren)
  const hasVariation = !!variationSettings?.type && featureTableVariation !== 'control'

  const wrapTable = useCallback(
    () => (
      <div className={classnames(styles.tableWrapper, styles.tableControl)}>
        <div className={styles.scrollTableWrapper}>
          <table {...props}>{children}</table>
        </div>
        <span className={styles.tableBorder} />
      </div>
    ),
    [children, props]
  )
  const tableRenderer = tableNoScrolls ? wrapTable : mapScrollableTable
  const showMoreHandler = () => {
    const loadDynSquirrelEvent = new Event('LoadDynSquirrel', { bubbles: true, cancelable: true })

    document.dispatchEvent(loadDynSquirrelEvent)
  }

  return hasVariation ? (
    <>
      {variationTableRenderer({
        formattedChildren,
        variationSettings,
      })}
      <SinglePadlockCta />
    </>
  ) : (
    <ShowMore
      buttonLabel="Show all rows"
      contentRenderer={tableRenderer}
      callback={showMoreHandler}
      className={styles.showMoreWrapper}
    >
      {formattedChildren}
    </ShowMore>
  )
}
