import React from 'react'

export const downArrow = (
  <path
    data-testid="svg-downArrow"
    d="M14.167 7l-1.175-1.175-4.659 4.65V.333H6.667v10.142l-4.65-4.658L.833 7 7.5 13.667z"
    fill="#1866C2"
    fillRule="nonzero"
  />
)
