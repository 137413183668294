import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { InfoBox, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../Link'
import { contactUsIssues } from './contactUsIssues'
import styles from './contactUsSupportingCopy.module.scss'

export const ContactUsSupportingCopy: FunctionComponent<ContactUsSupportingCopyProps> = ({
  issue,
}) => {
  const [scrollIsActive, setScrollIsActive] = useState(false)

  useEffect(() => {
    if (scrollIsActive) {
      const liveChatButton: HTMLElement | null = document.querySelector('#start-live-chat')
      const liveChatPosition = liveChatButton?.getBoundingClientRect().top || 0
      const liveChatOffsetPosition = liveChatPosition + window.scrollY

      if (liveChatButton) {
        window.scrollTo({ top: liveChatOffsetPosition, behavior: 'smooth' })
      }
      setScrollIsActive(false)
    }
  }, [scrollIsActive])

  const handleLiveChatLink = (e) => {
    e.preventDefault()
    setScrollIsActive(true)
  }

  switch (issue) {
    case contactUsIssues.option0:
      return (
        <InfoBox borderColour="blue" byline="Did you know" className={styles.additionalInfo}>
          <Typography textStyle="sb-text-body-small-regular" data-testid="selected-option-0">
            You can <Link onClick={(e) => handleLiveChatLink(e)}>chat with us online</Link> for 1 to
            1 product buying advice as part of a{' '}
            <Link href="https://join.which.co.uk/join/subscribe">
              Which? full access subscription
            </Link>
            . Our live chat team are here to help from Monday to Friday 8:30am-6pm, Saturday
            9am-1pm.
          </Typography>
        </InfoBox>
      )
    case contactUsIssues.option1:
      return (
        <InfoBox borderColour="blue" byline="Did you know" className={styles.additionalInfo}>
          <Typography textStyle="sb-text-body-small-regular" data-testid="selected-option-1">
            Which? offers a range of{' '}
            <Link href="https://www.which.co.uk/about-which/our-products-and-services-aXyiw9x8Ka2z">
              products, services and advice
            </Link>{' '}
            to help you with life's big decisions and important moments.
          </Typography>
        </InfoBox>
      )
    case contactUsIssues.option2:
      return (
        <InfoBox borderColour="blue" byline="Did you know" className={styles.additionalInfo}>
          <Typography textStyle="sb-text-body-small-regular" data-testid="selected-option-2">
            Our <Link href="/about-which/which-money-helpline-a1Q479l2VUcs">Money</Link>,{' '}
            <Link href="https://legalservice.which.co.uk/">Legal</Link> and{' '}
            <Link href="https://signup.which.co.uk/wlp-tech-support">Tech Experts</Link> can offer
            guidance on a wide range of issues.
          </Typography>
        </InfoBox>
      )
    case contactUsIssues.option3:
      return (
        <InfoBox borderColour="blue" byline="Did you know" className={styles.additionalInfo}>
          <Typography textStyle="sb-text-body-small-regular" data-testid="selected-option-3">
            Our <Link href="https://www.which.co.uk/help/my-account-a9bIq8y15WCF">Help Centre</Link>{' '}
            has guidance on how to{' '}
            <Link href="https://www.which.co.uk/forgot-password">reset your password</Link> and
            update details for your Which? subscription.
          </Typography>
        </InfoBox>
      )
    case contactUsIssues.option4:
      return (
        <InfoBox borderColour="blue" byline="Did you know" className={styles.additionalInfo}>
          <Typography textStyle="sb-text-body-small-regular" data-testid="selected-option-4">
            You can update your details{' '}
            <Link href="https://www.which.co.uk/help/my-account-a9bIq8y15WCF">online</Link> with a
            Which? subscription, or quickly update them by speaking to our{' '}
            <Link onClick={(e) => handleLiveChatLink(e)} type="Link">
              live chat team
            </Link>
            .
          </Typography>
        </InfoBox>
      )
  }

  return null
}

type ContactUsSupportingCopyProps = {
  issue: string | undefined
}
