import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, PageTitle, Picture } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './HeroImageWithText.module.scss'

export const HeroImageWithText: FunctionComponent<Props> = ({
  image,
  link,
  title,
  triggerCtaTracking,
}) => {
  const handleTriggerCtaTracking = () => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: 'used car',
      eventLabel: 'cta - click',
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
  }

  const { sources, src } = image
  const imageSource = sources.map((imageValue) => ({ ...imageValue, media: '(min-width: 768px)' }))

  return (
    <section className={styles.heroImageWithText}>
      <div className={styles.heroImageWithTextContent}>
        <PageTitle pageTitle={title} pageTitleTag="h1" />
        <ButtonLink
          href={link.href}
          {...(triggerCtaTracking && { onClick: () => handleTriggerCtaTracking() })}
        >
          {link.text}
        </ButtonLink>
      </div>
      <Picture alt="" aria-hidden sources={imageSource} src={src} />
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  image: ImageWithSources
  link: {
    href: string
    text: string
  }
  title: string
  triggerCtaTracking: boolean
}
