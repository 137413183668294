import type { TemplateName } from '@which/glide-ts-types'

export const determineTemplateType = (templateName: TemplateName) => {
  const isArticlePage =
    templateName === 'Article CR Single Page' ||
    templateName === 'Article Advice Multi Page' ||
    templateName === 'Article Advice Single Page' ||
    templateName === 'Article News Single Page' ||
    templateName === 'Article Policy and Insight Single Page' ||
    templateName === 'Article About Which' ||
    templateName === 'Policy and Insight Author'

  return isArticlePage ? 'Article' : ''
}
