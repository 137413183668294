import type { FunctionComponent } from 'react'
import React from 'react'
import { createBreakpoint } from 'react-use'
import { CardRow } from '@which/seatbelt'

import classnames from 'classnames'

import { PIArticleContentCard } from '../PIArticleContentCard'
import styles from './PITrendingTopicsSection.module.scss'

const useBreakpoint = createBreakpoint({ mobile: 320, tablet: 768, desktop: 1024 })

export const PITrendingTopicsSection: FunctionComponent<Props> = ({ articleCards, parentText }) => {
  const breakpoint = useBreakpoint()

  if (!articleCards || articleCards.length < 4) {
    return null
  }

  const renderCards = () =>
    articleCards.map((articleCard, i) => (
      <PIArticleContentCard
        key={articleCard.title}
        date={articleCard.publishedAt}
        href={articleCard.url}
        image={articleCard.image?.src}
        imageAlt={articleCard.image?.alt}
        strapline={articleCard.summary}
        title={articleCard.heading}
        heading={articleCards[i].topicHeading}
        estimatedReading={articleCard.estimatedReadingTime}
        parentText={parentText}
        cardIndex={i + 1}
      />
    ))

  return (
    <div data-testid="ea-trending-topics-section" className={styles.topicsSection}>
      {breakpoint !== 'desktop' && (
        <CardRow className={styles.mobileCarousel}>{renderCards()}</CardRow>
      )}
      {breakpoint === 'desktop' && (
        <div data-testid="ea-topics-desktop-cards" className={styles.topicsSectionOne}>
          <div className={classnames(styles.topicsSectionColumn, styles.topicsSectionColumnOne)}>
            <div className={styles.topicsSectionTopCardWrapper}>
              <div className={styles.topicsSectionSpacing}>
                <PIArticleContentCard
                  cardIndex={0}
                  className={styles.topicsSectionTopCard}
                  date={articleCards[0].publishedAt}
                  estimatedReading={articleCards[0].estimatedReadingTime}
                  heading={articleCards[0].topicHeading}
                  href={articleCards[0].url}
                  image={articleCards[0].image?.src}
                  imageAlt={articleCards[0].image?.alt}
                  imageSources={articleCards[0].image?.sources}
                  strapline={!articleCards[0].image?.src && articleCards[0].summary}
                  parentText={parentText}
                  title={articleCards[0].heading}
                />
              </div>
              <div className={styles.topicsSectionSpacing}>
                <PIArticleContentCard
                  cardIndex={1}
                  className={styles.topicsSectionTopCard}
                  date={articleCards[1].publishedAt}
                  estimatedReading={articleCards[1].estimatedReadingTime}
                  heading={articleCards[1].topicHeading}
                  href={articleCards[1].url}
                  image={articleCards[1].image?.src}
                  imageAlt={articleCards[1].image?.alt}
                  imageSources={articleCards[1].image?.sources}
                  strapline={!articleCards[1].image?.src && articleCards[1].summary}
                  parentText={parentText}
                  title={articleCards[1].heading}
                />
              </div>
            </div>
            <div className={styles.topicsSectionBottomCardSpacing}>
              <PIArticleContentCard
                cardIndex={2}
                className={styles.topicsSectionBottomCard}
                date={articleCards[2].publishedAt}
                estimatedReading={articleCards[2].estimatedReadingTime}
                heading={articleCards[2].topicHeading}
                href={articleCards[2].url}
                parentText={parentText}
                strapline={articleCards[2].summary}
                title={articleCards[2].heading}
                orientation={'horizontal'}
              />
            </div>
          </div>
          <div className={styles.topicsSectionColumn}>
            <PIArticleContentCard
              cardIndex={3}
              className={styles.topicsSectionLargeCard}
              date={articleCards[3].publishedAt}
              estimatedReading={articleCards[3].estimatedReadingTime}
              heading={articleCards[3].topicHeading}
              href={articleCards[3].url}
              image={articleCards[3].image?.src}
              imageAlt={articleCards[3].image?.alt}
              imageSources={articleCards[3].image?.sources}
              largeCard
              parentText={parentText}
              strapline={!articleCards[3].image?.src && articleCards[3].summary}
              title={articleCards[3].heading}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export type Props = {
  articleCards: any
  parentText: string
}
