export const dataProviderUrl = () => {
  const localIpAddress = process.env.LOCAL_IP_ADDRESS
  const originUsingLocalIpRegex = new RegExp(`${localIpAddress}:3(0|2)00`)

  if (typeof window === 'undefined') {
    return process.env.DATA_PROVIDER_GRAPHQL || ''
  } else if (typeof window.WCD_URLS?.dataProviderGraphql !== 'undefined') {
    return window.WCD_URLS.dataProviderGraphql
  } else if (window.location?.origin.includes('localhost')) {
    return 'http://localhost:3100/graphql'
  } else if (originUsingLocalIpRegex.test(window.location?.origin)) {
    return process.env.LOCAL_DATA_PROVIDER_USING_IP_ADDRESS || ''
  }

  return `${window.location?.origin}/data-provider/graphql`
}
