export const mapScamsJumpLinks = (data: Data) => {
  if (!data?.labels) {
    return []
  }

  return data.labels.map((label = '') => {
    return {
      id: scamsLinkMap[label] || label.toLowerCase().replace(/\s+/g, '-'),
      label: label,
    }
  })
}

///////// IMPLEMENTATION /////////

const scamsLinkMap = {
  'Beware of these scams': 'how-to-recognise-different-types-of-scams',
  'Scams Protection Checklist': 'scams-protection-checklist',
  'How to report scams': 'how-to-report-a-scam',
  'Get your money back': 'how-did-you-pay',
}

type Data = {
  labels?: string[]
}
