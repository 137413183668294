import { GrowthBook } from '@growthbook/growthbook'
import { IncomingMessage } from 'http'
import { getAttributesFromMessage } from './getExperimentAttributes'
import {
  GetInstanceConfig,
  defaultSdkKey,
  defaultGbHost
} from '../shared/InstanceConfig'

/**
 * @param {IncomingMessage} msg Cookies/Headers in this message will be used to construct the Attributes.
 * @param {GetInstanceConfig} config Optional overrides for customizing the initialization logic.
 * @returns {Promise<GrowthBook>} An initialized Growthbook Object. See https://docs.growthbook.io/lib/js#growthbook-instance-reference
 *
 * Given an incoming request, extract relevant cookies (id, member status etc.) and return a configured Growthbook Instance.
 */
export const getServerSideInstance = async (
  msg: IncomingMessage,
  config?: GetInstanceConfig
): Promise<GrowthBook> => {
  try {
    const clientKey = config?.clientKey ?? defaultSdkKey
    const apiHost = config?.apiHost ?? defaultGbHost

    const attributes = getAttributesFromMessage(msg)

    const gbInstance = new GrowthBook({
      apiHost,
      clientKey,
      attributes
    })

    await gbInstance.init()

    return gbInstance
  } catch (e) {
    if (e instanceof Error) {
      const errorCallback =
        config?.errorCallback ??
        ((e: Error) =>
          console.error(
            `Unable to setup Growthbook instance; experiments will use fallback behavior. error: ${e}`
          ))

      errorCallback(e)
    }

    return new GrowthBook({
      features: {},
      attributes: {}
    })
  }
}
