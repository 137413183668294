import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import type { ProductScoreGaugeProps } from '@which/seatbelt'
import {
  ButtonLink,
  CardContainer,
  CardRow,
  Grid,
  GridItem,
  Heading,
  ProductCardPortrait,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Link as LinkType, ProductHubPage } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import { useMatchMedia } from '../../../../shared/hooks/useMatchMedia'
import styles from './ProductHubReviews.module.scss'

export const ProductHubReviews: FunctionComponent<ProductHubReviewsProps> = ({
  isLoggedIn,
  products,
  productSlug,
  heading,
  description,
  buttonCTAText,
  buttonCTAHref,
  leftAlignButtonCTA,
  memberExclusiveCard,
  noTopSpacing,
}) => {
  const isTabletOrAbove = useMatchMedia('(min-width: 768px)')
  const history = useHistory()

  return (
    <section
      aria-label={heading}
      className={classnames({
        [styles.reviewsWrapper]: !noTopSpacing,
      })}
      id="product-reviews"
    >
      <Grid className={styles.grid} includeGutters={false}>
        <GridItem>
          <Heading heading={heading} headingTag="h2" headingType="large" />
          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.reviewsStandfirst}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </GridItem>
        <GridItem>
          {!isLoggedIn && !isTabletOrAbove && (
            <MemberExclusiveCard
              {...memberExclusiveCard}
              className={styles.reviewsMobileMemberCard}
            />
          )}
          <CardRow className={styles.reviewsCardRow}>
            {!isLoggedIn && isTabletOrAbove && (
              <MemberExclusiveCard
                {...memberExclusiveCard}
                className={styles.reviewsDesktopMemberCard}
              />
            )}
            {products.map(
              (
                { image, manufacturer, model, price, tags, retailers, productScoreGauge, slug },
                index
              ) => {
                const url = `/reviews/${productSlug}/${slug}`

                return (
                  <ProductCardPortrait
                    key={index}
                    className={styles.reviewsProductCard}
                    image={image}
                    manufacturer={manufacturer}
                    model={model}
                    price={price}
                    retailers={retailers}
                    slug={slug}
                    tags={tags}
                    productScoreGauge={productScoreGauge as ProductScoreGaugeProps}
                    cardCallback={() => {
                      dynamicGa4DataLayerPush({
                        event: 'click_card',
                        item_text: `${manufacturer} ${model}`,
                        item_url: url,
                        item_parent_text: 'Product Reviews',
                        item_index: index + 1,
                      })
                      history.push(url)
                    }}
                    productUrl={`/reviews/${productSlug}/${slug}`}
                    taxonomySlug={productSlug}
                    businessKey={''}
                    handleAddCompare={() => null}
                    handleRemoveCompare={() => null}
                  />
                )
              }
            )}
          </CardRow>
          <div
            className={classnames(styles.reviewsButtonWrapper, {
              [styles.leftAlignButton]: leftAlignButtonCTA,
            })}
          >
            <ButtonLink
              href={buttonCTAHref}
              appearance="secondary"
              data-which-id="producthub-button"
            >
              <ChevronRightIcon />
              {buttonCTAText}
            </ButtonLink>
          </div>
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

const MemberExclusiveCard: FunctionComponent<MemberExclusiveCardProps> = ({
  heading,
  description,
  joinLink,
  loginLink,
  disclaimer,
  className,
  ...rest
}) => (
  <div aria-label={heading} className={classnames(className, styles.exclusiveCard)} {...rest}>
    <CardContainer>
      <Heading headingTag="h3" headingType="small" heading={heading} />

      <Typography textStyle="sb-text-body-default-regular" className={styles.exclusiveCardText}>
        {description}
      </Typography>
      <div className={styles.exclusiveCardLinkWrapper}>
        <ButtonLink
          href={joinLink.href}
          className={styles.exclusiveCardJoinLink}
          data-which-id="producthub-cta"
        >
          <SolidPadlockIcon data-testid="padlock-icon" width={15} height={15} viewBox="0 0 15 15" />
          {joinLink.text}
        </ButtonLink>
        <span className={styles.loginWrapper}>
          <Typography
            tag="span"
            textStyle="sb-text-body-default-regular"
            className={styles.exclusiveCardJoinText}
          >
            {' '}
            or{' '}
          </Typography>
          <Link className={styles.exclusiveCardLoginLink} href={loginLink.href}>
            {loginLink.text}
          </Link>
        </span>
      </div>
      <Typography textStyle="sb-text-body-default-regular">{disclaimer}</Typography>
    </CardContainer>
  </div>
)

export type ProductHubReviewsProps = ProductHubReviewsHardcodedProps &
  Pick<ProductHubPage, 'isLoggedIn' | 'products'>

export type ProductHubReviewsHardcodedProps = {
  productSlug: string
  heading: string
  description: string
  buttonCTAText: string
  buttonCTAHref: string
  leftAlignButtonCTA?: boolean
  memberExclusiveCard: MemberExclusiveCardProps
  noTopSpacing?: boolean
}

type MemberExclusiveCardProps = {
  heading: string
  description: string
  joinLink: LinkType
  loginLink: LinkType
  disclaimer: string
} & ComponentPropsWithoutRef<'article'>
