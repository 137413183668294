import { generateId } from './utils'

export class Resizer {
  targets: NodeList
  iframes = new Map()
  origin = '*'
  msgId = '[iFrameSizer]'

  constructor(target: string) {
    this.targets = document.querySelectorAll(target)

    if (this.targets.length > 0) {
      this.attachEventListeners()
      this.initialiseIFrames()
    }
  }

  attachEventListeners(): void {
    window.addEventListener('message', ({ data }) => {
      const isRelevantEvent = typeof data === 'string' && data.indexOf(':') > -1

      if (isRelevantEvent) {
        const [msgId, iframeId, height] = data.split(':')
        const target = this.iframes.get(iframeId)

        target && msgId === this.msgId && (target.style.height = `${height}px`)
      }
    })
  }

  initialiseIFrames(): HTMLIFrameElement[] {
    const postMessage = (target: HTMLIFrameElement, id: string): void => {
      const messageData = `${this.msgId}:${id}`
      target.contentWindow?.postMessage(messageData, this.origin)
    }

    return Array.from(this.targets).map((target) => {
      const id = generateId()
      this.iframes.set(id, target)

      target.addEventListener('load', () => postMessage(target, id))
      postMessage(target, id)

      return target
    })
  }
}

///////// IMPLEMENTATION /////////

type NodeList = NodeListOf<HTMLIFrameElement>
