export const getSessionStorageItem = (sessionItem: string) => {
  if (typeof window === 'undefined') {
    return null
  }
  const sessionData = sessionStorage.getItem(sessionItem)
  if (sessionData) {
    return JSON.parse(`${sessionStorage.getItem(sessionItem)}`)
  }
  return null
}
