import type { FunctionComponent } from 'react'

import classnames from 'classnames'

import { GridItemProductListing, GridProductListing } from '../../grids/product-listing'

export const getProductListingGrid = (
  tagName = '',
  className = ''
): ProductListingGridComponent => {
  if (tagName.startsWith('griditemproductlisting')) {
    const area = tagName.slice(tagName.indexOf('-') + 1)

    return {
      componentToRender: GridItemProductListing,
      extraProps: {
        area,
        className: classnames(className),
      },
    }
  }

  if (tagName === 'grid') {
    return {
      componentToRender: GridProductListing,
      extraProps: { className },
    }
  }

  return {
    componentToRender: tagName || 'div',
    extraProps: { className },
  }
}

///////// IMPLEMENTATION /////////

type ProductListingGridComponent = {
  componentToRender: FunctionComponent<any> | string
  extraProps?: { area?: string; className?: string }
}
