import type { FunctionComponent } from 'react'
import React from 'react'
import { ApolloError } from '@apollo/client'

import { getGraphQLError } from '../../utils/get-graphql-error'
import { ErrorComponent } from './ErrorComponent'

export const PageError: FunctionComponent<Props> = ({ pageName }) => {
  const errorMessage = `No data returned for ${pageName}`
  return (
    <ErrorComponent
      error={
        new ApolloError({
          graphQLErrors: [getGraphQLError(errorMessage, '404')],
        })
      }
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  pageName: string
}
