import { useLocation } from 'react-router-dom'

export const useGetQueryString = (key: string) => {
  const { search } = useLocation()

  if (!search || key === '') {
    return
  }

  const params = new URLSearchParams(search)
  return params.get(key)
}
