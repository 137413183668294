/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

/**
 * @TODO Break apart as more sub routes get added
 */
export const consumerRightsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:articleType(advice)/the-car-i-bought-has-a-problem-what-are-my-rights-:articleSlug',
    component: () => (
      <Page pagePath="article/ArticlePage" template="CR Tool Article" context="consumer-rights" />
    ),
  },
  {
    live: true,
    exact: true,
    path: ['/:verticalSlug(consumer-rights)/:subVerticalSlug(scams)'],
    component: () => (
      <Page
        pagePath="sub-vertical/SubVerticalPage"
        template="CR Scams Topic"
        context="consumer-rights"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: [
      '/:verticalSlug(consumer-rights)/:articleType(advice)/:articleName(how-to-get-your-money-back-after-a-scam)-:articleSlug',
      '/:verticalSlug(consumer-rights)/:articleType(advice)/:articleName(how-to-spot-a-scam)-:articleSlug',
      '/:verticalSlug(consumer-rights)/:articleType(advice)/:articleName(how-to-report-a-scam)-:articleSlug',
    ],
    component: () => (
      <Page pagePath="article/ArticlePage" template="CR Scams Article" context="consumer-rights" />
    ),
  },
  {
    live: true,
    exact: true,
    // No need to add letter | regulation to types as it only gets used for redirects
    path: '/:verticalSlug(consumer-rights)/:articleTypeSlug(letter|letters|regulation|regulations)',
    component: () => <Page pagePath="cr-article-type/ArticleTypePage" template="CR Article Type" />,
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:articleType(advice|letter|regulation)/:articleSlug',
    component: () => (
      <Page
        pagePath="article/ArticlePage"
        template="Article CR Single Page"
        context="consumer-rights"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:subVerticalSlug/:categorySlug',
    component: (props) => (
      <Page
        {...props}
        pagePath="article-listing/ArticleListingPage"
        template="CR Subtopic"
        context="consumer-rights"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:subVerticalSlug',
    component: () => (
      <Page pagePath="sub-vertical/SubVerticalPage" template="CR Topic" context="consumer-rights" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="CR Vertical" context="consumer-rights" />
    ),
  },
]
