/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { DevHomepage } from '../components/DevHomepage'
import { Page } from '../components/Page'

export const homeRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/',
    component: () => <Page pagePath="home/HomePage" template="Homepage" context="home" />,
  },
  {
    live: false,
    exact: true,
    path: '/dev',
    component: () => <DevHomepage />,
  },
]
