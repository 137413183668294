import type { ReactNode } from 'react'
import React, { Children, cloneElement, createElement } from 'react'
import { isElement } from 'react-is'
import { Table as t } from '@which/seatbelt'
import type { Sizing } from '@which/seatbelt/build/src/components/Table'

import classnames from 'classnames'

import { SinglePadlockCta } from '../../../SinglePadlockCta'
import styles from '../../Table.module.scss'
import { getCellAlignment } from '../../Table.utils'
import { mapProvidersLockedTree, mapScoresLockedTree } from './'
import type { VariationSettings } from './getVariantSettings'

const tableMap = new Map([
  ['tbody', t.Body],
  ['thead', t.Head],
  ['tr', t.Row],
  ['td', t.Cell],
  ['th', t.Header],
])

const treeMapper = {
  providersLocked: mapProvidersLockedTree,
  scoresLocked: mapScoresLockedTree,
}

export const variationTableRenderer = ({
  formattedChildren: tree,
  variationSettings,
}: VariationTableRendererArgs) => {
  if (!variationSettings) {
    return
  }

  const columnSizing: t.Sizing[] = []
  const variationTreeMapper = treeMapper[variationSettings?.type]
  const variationTree = variationTreeMapper(tree, variationSettings)

  const mapChildren = (children: ReactNode, depth = 1): ReactNode =>
    Children.toArray(children).map((child, index) => {
      if (!isElement(child)) {
        return child
      }

      const { props } = child
      const className: string | null = props.className ?? null
      const isTableHeader = child.type === 'th'

      if (isTableHeader) {
        columnSizing.push(className as Sizing)
      }

      // Switch the old padlock icon with the new CTA component
      if (className === 'singleCtaCell') {
        const clonedChild = cloneElement(
          child,
          {
            ...props,
            className: 'lockCtaCell',
          },
          [
            createElement(() => (
              <span>
                <SinglePadlockCta type="desktop" />
              </span>
            )),
          ]
        )

        return clonedChild
      }

      const Child = tableMap.get(child.type as string)

      return Child ? (
        <Child key={`child_${depth}_${index}`} align={getCellAlignment(child)} {...child.props}>
          {mapChildren(child.props.children, depth + 1)}
        </Child>
      ) : (
        child
      )
    })

  return (
    <>
      <t.Table
        className={classnames(styles.tableControl, 'tableVariation')}
        sizing={columnSizing}
        tableClassName="scrollable"
      >
        {mapChildren(variationTree)}
      </t.Table>
    </>
  )
}

type VariationTableRendererArgs = {
  formattedChildren: ReactNode
  variationSettings: VariationSettings
}
