import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { ButtonLink, Grid, GridItem, TypographyV2 } from '@which/seatbelt'
import { CrossIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classNames from 'classnames'

import styles from './ResubscribeBanner.module.scss'

export const ResubscribeBanner: FunctionComponent<ResubscribeBannerProps> = ({ showBanner }) => {
  const renderBanner = showBanner === true ? true : false
  const bannerVariant = useFeatureIsOn('resubscribe-banner-variant')
  const [showBannerState, setShowBannerState] = useState<boolean>(renderBanner)

  if (!showBannerState) {
    return null
  }

  const handleKeypressCloseBanner = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleCloseBanner()
    }
  }

  const handleCloseBanner = () => {
    const cookieName = 'resub-banner-closed'

    document.cookie = `${cookieName}=1; path=/`
    setShowBannerState(false)
  }

  if (bannerVariant) {
    return (
      <div className={styles.resubBanner} data-testid="resub-banner">
        <Grid className={classNames('w-page-wrapper', styles.resubBannerGrid)}>
          <GridItem
            columnStart={{ large: 1, xlarge: 1 }}
            span={{ large: 9, xlarge: 10 }}
            className={styles.resubBannerCopy}
          >
            <TypographyV2 textStyle="sb-text-body-default-strong">
              Rejoin Which? and get 50% off
            </TypographyV2>
            <TypographyV2 textStyle="sb-text-body-default-regular">
              Sorry, you have an inactive subscription and no longer have access to our premium
              content. Re-subscribe to get one year of membership from just £39.50 and receive a £20
              M&S voucher.
            </TypographyV2>
          </GridItem>
          <GridItem columnStart={{ large: 10, xlarge: 11 }} span={{ large: 3, xlarge: 2 }}>
            <Grid includeGutters={false} className={styles.resubBannerActions}>
              <GridItem
                span={{ large: 12, xlarge: 12 }}
                className={styles.resubBannerButtonWrapper}
              >
                <ButtonLink
                  className={styles.resubBannerButton}
                  href="https://join.which.co.uk/join/subscribe/rejoin?source_code=324AAA"
                  data-which-id="resubscribe-banner-button"
                >
                  Rejoin Which?
                </ButtonLink>
              </GridItem>
            </Grid>
          </GridItem>
          <button
            onClick={() => handleCloseBanner()}
            onKeyPress={(e) => handleKeypressCloseBanner(e)}
            data-testid="closeButton"
            data-which-id="resubscribe-banner-close"
            className={styles.resubBannerClose}
            aria-label="Close this resubscribe banner"
          >
            <CrossIcon
              width={15}
              height={15}
              className={styles.resubBannerCloseIcon}
              data-which-id="resubscribe-banner-close"
            />
          </button>
        </Grid>
      </div>
    )
  }

  return (
    <div className={styles.resubBanner} data-testid="resub-banner">
      <Grid className={classNames('w-page-wrapper', styles.resubBannerGrid)}>
        <GridItem
          columnStart={{ large: 1, xlarge: 1 }}
          span={{ large: 9, xlarge: 10 }}
          className={styles.resubBannerCopy}
        >
          <TypographyV2 textStyle="sb-text-body-default-strong" tag="h2">
            You currently don’t have full access to all our premium content
          </TypographyV2>
          <TypographyV2 textStyle="sb-text-body-default-regular" tag="span">
            You may see locked content as you browse the Which? website. Renew or upgrade your
            subscription to gain access to our expert product reviews, advice and services
          </TypographyV2>
        </GridItem>
        <GridItem columnStart={{ large: 10, xlarge: 11 }} span={{ large: 3, xlarge: 2 }}>
          <Grid includeGutters={false} className={styles.resubBannerActions}>
            <GridItem span={{ large: 12, xlarge: 12 }} className={styles.resubBannerButtonWrapper}>
              <ButtonLink
                className={styles.resubBannerButton}
                href="https://join.which.co.uk/join/subscribe"
                data-which-id="resubscribe-banner-button"
              >
                Continue
              </ButtonLink>
            </GridItem>
          </Grid>
        </GridItem>
        <button
          onClick={() => handleCloseBanner()}
          onKeyPress={(e) => handleKeypressCloseBanner(e)}
          data-testid="closeButton"
          data-which-id="resubscribe-banner-close"
          className={styles.resubBannerClose}
          aria-label="Close this resubscribe banner"
        >
          <CrossIcon
            width={15}
            height={15}
            className={styles.resubBannerCloseIcon}
            data-which-id="resubscribe-banner-close"
          />
        </button>
      </Grid>
    </div>
  )
}

type ResubscribeBannerProps = {
  showBanner?: boolean
}
