import type { ChangeEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { FormField, InputV2 } from '@which/seatbelt'
import { SmallSearchIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { debounce } from '../utils/debounce'

export const useTableSearch = (id?: string, searchEnabled?: boolean) => {
  if (!searchEnabled || !id) {
    return {
      TableSearch: () => null,
    }
  }

  const search = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.trim()
    const table = document.getElementById(id)
    const tableRows = (table && Array.from(table.getElementsByTagName('tr'))) ?? []
    tableRows.shift()

    tableRows.forEach((row) => {
      const rowText = row.textContent?.toUpperCase() || ''

      if (rowText.includes(searchValue.toUpperCase())) {
        row.style.display = ''
      } else {
        row.style.display = 'none'
      }
    })
  }, 300)

  return {
    TableSearch: ({ className, tableId, setRenderFullContent, renderFullContent }) => {
      const [value, setValue] = useState<string>('')
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)
      const searchRef = useRef<HTMLInputElement>(null)

      // Setting this timeout to address a rendering bug in Chrome
      useEffect(() => {
        if (searchRef.current && renderFullContent) {
          setTimeout(() => {
            searchRef.current?.focus()
          }, 100)
        }
      }, [renderFullContent, tableId])

      return (
        <div className={className}>
          <FormField
            label="Search this table"
            labelFor={`search-for-${tableId}`}
            showRequiredText={false}
          >
            <InputV2
              data-testid={`input-for-${tableId}`}
              key={`search-for-${tableId}`}
              placeholder="What are you looking for?"
              icon={SmallSearchIcon}
              name={`search-for-${tableId}`}
              value={value}
              onChange={handleChange}
              onKeyUpCapture={search}
              onFocus={(e) => {
                e.preventDefault()
                setRenderFullContent(true)
              }}
              inputRef={searchRef}
            />
          </FormField>
        </div>
      )
    },
  }
}
