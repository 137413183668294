import type { FocusEventHandler } from 'react'

export const focusAboveCompareTray: FocusEventHandler<HTMLElement> = (event) => {
  const compareTray = document.querySelector('[data-testid="reviews-compare-tray"]')

  if (compareTray) {
    const { top } = compareTray.getBoundingClientRect()
    const scrollThreshold = top - 20

    const eventTarget = event.target as HTMLElement
    const { bottom } = eventTarget.getBoundingClientRect()

    if (bottom > scrollThreshold) {
      window.scrollBy(0, bottom - scrollThreshold)
    }
  }
}
