import type { FunctionComponent } from 'react'
import { Heading } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import { ContentCardWithLink } from '../ContentCardWithLink/ContentCardWithLink'
import styles from './ProductHubProductTypes.module.scss'

export const ProductHubProductTypes: FunctionComponent<ProductTypesDataProps> = ({
  productTypes,
  sectionTitle,
}) => (
  <section aria-label={sectionTitle} data-testid="product-types" id="product-types">
    <Heading heading={sectionTitle} headingTag="h2" headingType="large" />

    <div className={styles.productTypeContainer}>
      {productTypes &&
        productTypes.map((productType, index) => {
          const {
            image,
            title: cardTitle,
            description: cardDescription,
            secondaryLink,
          } = productType

          return (
            <div className={styles.productTypeCard} key={`product-type-${index + 1}`}>
              <ContentCardWithLink
                title={cardTitle}
                sectionTitle={sectionTitle}
                description={cardDescription}
                image={image}
                secondaryLink={{
                  ...secondaryLink,
                  dataIndex: index,
                }}
                smallText
              />
            </div>
          )
        })}
    </div>
  </section>
)

export type ProductTypes = {
  title: string
  description: string
  image: ImageWithSources
  secondaryLink: {
    contentHtmlId: string
    href: string
    label: string
  }
}[]

export type ProductTypesDataProps = {
  sectionTitle: string
  productTypes: ProductTypes
}
