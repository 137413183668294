/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const savedRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/saved',
    component: () => <Page pagePath="saved/SavedPage" />,
  },
]
