import type { Context } from 'koa'

import { gbTargetCookies } from '../../../../server/middleware/addExperimentHeaders'

const SAVED_POPUP_COOKIE = 'is_saved_products_popup_shown'

export const getFragmentCookies = (ctx: Context) => {
  const cookie: string = [
    `${gbTargetCookies.EXPERIMENTATION_COOKIE_NAME}=${getExperimentationCookieValue(ctx)}`,
    `${gbTargetCookies.WM_COOKIE_NAME}=${getWmCookieValue(ctx)}`,
    `${SAVED_POPUP_COOKIE}=${getSavedPopupCookieValue(ctx)}`,
  ].join(';')

  return cookie
}

export const getWmCookieValue = (ctx: Context): 'True' | 'False' => {
  return ctx.cookies.get(gbTargetCookies.WM_COOKIE_NAME) === 'True' ? 'True' : 'False'
}

export const getExperimentationCookieValue = (ctx: Context): string => {
  return ctx.cookies.get(gbTargetCookies.EXPERIMENTATION_COOKIE_NAME) || ''
}

export const getSavedPopupCookieValue = (ctx: Context): 'true' | 'false' => {
  return ctx.cookies.get(SAVED_POPUP_COOKIE) === 'true' ? 'true' : 'false'
}
