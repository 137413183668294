import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Checkbox, Link, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { useKindeAuth } from '@kinde-oss/kinde-auth-react'

import { usePaywallUrlQuery } from '../../../generated/frontend'
import { FormFields } from '../components/FormFields'
import { PasswordFieldView } from '../components/PasswordField'
import { Links, LoginPageBannerText, UsernameField } from '../data'
import type { LoginFormProps } from './LoginPage'
import styles from './LoginPage.module.scss'
import type { LoginState } from './validateUserIdentifier'
import { validateUserIdentifier } from './validateUserIdentifier'

const TwoStepLoginForm: FC<LoginFormProps> = ({
  handleOnSubmit,
  loading: loginAttemptLoading,
  stayLoggedInState,
  showAuthenticationError,
}) => {
  const { stayLoggedIn, setStayLoggedIn } = stayLoggedInState
  const [userIdentifier, setUserIdentifier] = useState<string | null>(null)
  const [loginState, setLoginState] = useState<LoginState>('USER_IDENTIFIER_REQUIRED')
  const kinde = useKindeAuth()
  const { data: paywallState } = usePaywallUrlQuery()
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  useEffect(() => {
    if (userIdentifier !== null && paywallState) {
      setLoginState('AUTH_STATE_LOADING')
      validateUserIdentifier(paywallState.paywallUrl, userIdentifier).then(setLoginState)
    }
  }, [userIdentifier, paywallState])

  useEffect(() => {
    if (loginState === 'KINDE_LOGIN') {
      dynamicGa4DataLayerPush({
        event: 'click_button',
        item_text: 'Log in',
      })

      kinde.login({
        authUrlParams: {
          connection_id: process.env.KINDE_LOGIN_CONNECTION_ID,
          login_hint: userIdentifier,
        },
      })
    }
  }, [loginState, kinde, showAuthenticationError, userIdentifier])

  useEffect(() => {
    if (loginState === 'INCORRECT_USER_IDENTIFIER') {
      showAuthenticationError(LoginPageBannerText.userIdentifier)
    }
  }, [loginState, showAuthenticationError])

  return (
    <FormProvider {...methods}>
      <form method="post">
        {['USER_IDENTIFIER_REQUIRED', 'INCORRECT_USER_IDENTIFIER', 'AUTH_STATE_LOADING'].includes(
          loginState
        ) && (
          <>
            <FormFields fields={UsernameField} />
            <Button
              type="submit"
              data-testid="continue-button"
              enableSpinner={loginState === 'AUTH_STATE_LOADING'}
              onClick={handleSubmit((formObj) => setUserIdentifier(formObj.username))}
              className={styles.loginPageSubmit}
            >
              Continue
            </Button>
          </>
        )}
        {loginState === 'ZEPHR_LOGIN' && (
          <>
            <PasswordFieldView
              displayPasswordCheckList={false}
              name="password"
              label="Password"
              rulesRequired={false}
              calledFrom="login"
              errorMessageText="Please enter your password"
              maxLength="50"
              showRequiredText={false}
              autoComplete="password"
              validation={{ required: true }}
            />
            <div className={styles.loginPageFormFooter}>
              <Checkbox
                type="checkbox"
                className={styles.loginPageStayLoggedInDiv}
                name="stay-logged-in"
                id="stay-logged-in"
                value=""
                label="Stay logged in"
                checked={stayLoggedIn}
                onChangeCallback={() => setStayLoggedIn(!stayLoggedIn)}
              />
              <div className={styles.loginPageForgotPassword}>
                <Link
                  appearance="primary"
                  href={Links.forgotPassword}
                  textStyle="sb-text-interface-body-small-regular"
                  data-which-id="link"
                  className={styles.loginPageForgotPasswordLink}
                >
                  Forgotten password?
                </Link>
              </div>
            </div>
            <Typography
              textStyle="sb-text-body-x-small-regular"
              className={styles.loginPageUntickText}
            >
              Untick if you're using a public device
            </Typography>
            <Button
              type="submit"
              data-testid="login-button"
              enableSpinner={loginAttemptLoading}
              onClick={handleSubmit((formObj) => {
                dynamicGa4DataLayerPush({
                  event: 'click_button',
                  item_text: 'Log in',
                })
                handleOnSubmit(formObj.username, formObj.password, stayLoggedIn)
              })}
              className={styles.loginPageSubmit}
            >
              Log in
            </Button>
          </>
        )}
      </form>
    </FormProvider>
  )
}

export default TwoStepLoginForm
