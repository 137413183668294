import type { FunctionComponent } from 'react'
import React from 'react'
import { Heading, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../Link'
import styles from './CRScamsEmotionalSupport.module.scss'

export const CRScamsEmotionalSupport: FunctionComponent = () => (
  <div
    className={styles.scamEmotionalSupport}
    data-which-section="Emotional support"
    id="emotional-support"
    data-testid="CRScamsEmotionalSupport"
  >
    <Heading heading="Emotional support after a scam" headingTag="h2" headingType="medium" />
    <Typography textStyle="sb-text-body-default-regular">
      Being scammed can take a huge toll on your mental health. You might find it helpful to talk to
      someone about what you’re going through. It’s not your fault, and there are plenty of
      non-judgemental advice lines you can call who will understand.
    </Typography>
    <div className={styles.supportCards}>
      <div className={styles.supportCard} data-testid="mind-card">
        {/* @TDOD: Use Seatbelt <Picture> component here */}
        <picture className={styles.scamImage}>
          <source srcSet={mindDesktopWEBP} type="image/webp" media="(min-width: 960px)" />
          <source srcSet={mindDesktopJPG} type="image/jpg" media="(min-width: 960px)" />
          <source srcSet={mindTabletWEBP} type="image/webp" media="(min-width: 768px)" />
          <source srcSet={mindTabletJPG} type="image/jpg" media="(min-width: 768px)" />
          <source srcSet={mindMobileWEBP} type="image/webp" media="(max-width: 767px)" />
          <img src={mindMobileJPG} alt="Mind" sizes="100vw" className={styles.supportCardImage} />
        </picture>
        <Heading heading="Mind" headingTag="h3" headingType="medium" />
        <Typography textStyle="sb-text-body-default-regular">
          Mind has a confidential information and support line, Mind Infoline, available on{' '}
          <Link href="tel:03001233393" className={styles.telephoneLink}>
            0300 123 3393
          </Link>{' '}
          (lines open 9am - 6pm, Monday - Friday).
        </Typography>
        <Link href="https://www.mind.org.uk/" target="_blank" rel="nofollow noopener noreferrer">
          Visit Mind
        </Link>
      </div>
      <div className={styles.supportCard} data-testid="victim-card">
        {/* @TDOD: Use Seatbelt <Picture> component here */}
        <picture className={styles.scamImage}>
          <source srcSet={victimSupportDesktopWEBP} type="image/webp" media="(min-width: 960px)" />
          <source srcSet={victimSupportDesktopJPG} type="image/jpg" media="(min-width: 960px)" />
          <source srcSet={victimSupportTabletWEBP} type="image/webp" media="(min-width: 768px)" />
          <source srcSet={victimSupportTabletJPG} type="image/jpg" media="(min-width: 768px)" />
          <source srcSet={victimSupportMobileWEBP} type="image/webp" media="(max-width: 767px)" />
          <img
            src={victimSupportMobileJPG}
            alt="Victim support"
            sizes="100vw"
            className={styles.supportCardImage}
          />
        </picture>
        <Heading heading="Victim Support" headingTag="h3" headingType="medium" />
        <Typography textStyle="sb-text-body-default-regular">
          Victim Support has a free helpline where you can speak to someone confidentially available
          on{' '}
          <Link href="tel:08081689111" className={styles.telephoneLink}>
            0808 16 89 111
          </Link>{' '}
          (lines open 24/7).
        </Typography>
        <Link
          href="https://www.victimsupport.org.uk/"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Visit Victim Support
        </Link>
      </div>
    </div>
  </div>
)

const mindDesktopJPG =
  'https://media.product.which.co.uk/prod/images/original/55580abbf993-mind-desktop.jpg'
const mindDesktopWEBP =
  'https://media.product.which.co.uk/prod/images/original/ff23afbb4cd4-mind-desktop.webp'
const mindMobileJPG =
  'https://media.product.which.co.uk/prod/images/original/020438a7e830-mind-mobile.jpg'
const mindMobileWEBP =
  'https://media.product.which.co.uk/prod/images/original/e9ca977e9485-mind-mobile.webp'
const mindTabletJPG =
  'https://media.product.which.co.uk/prod/images/original/19d6d10a7908-mind-tablet.jpg'
const mindTabletWEBP =
  'https://media.product.which.co.uk/prod/images/original/b53cc4c8c12c-mind-tablet.webp'
const victimSupportDesktopJPG =
  'https://media.product.which.co.uk/prod/images/original/016dc205396c-victim-support-desktop.jpg'
const victimSupportDesktopWEBP =
  'https://media.product.which.co.uk/prod/images/original/68bcad031ef3-victim-support-desktop.webp'
const victimSupportMobileJPG =
  'https://media.product.which.co.uk/prod/images/original/4bbd3a294910-victim-support-mobile.jpg'
const victimSupportMobileWEBP =
  'https://media.product.which.co.uk/prod/images/original/47b0e3781744-victim-support-mobile.webp'
const victimSupportTabletJPG =
  'https://media.product.which.co.uk/prod/images/original/827833132279-victim-support-tablet.jpg'
const victimSupportTabletWEBP =
  'https://media.product.which.co.uk/prod/images/original/945ff9a82dae-victim-support-tablet.webp'
