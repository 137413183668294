import React from 'react'

export const getDataLayerScripts = <DataLayerItem,>(dataLayerItems: DataLayerItem[] = []) => {
  const additionalDataLayerItems = dataLayerItems.reduce(
    (dataLayerScript, item) => `${dataLayerScript}dataLayer.push(${JSON.stringify(item)});\n\t`,
    ''
  )

  const resetDataLayer = 'dataLayer.push(function() { this.reset(); })'

  return [
    <script key="ga-script">
      {`\n\twindow.dataLayer = window.dataLayer || [];\n\t${resetDataLayer}\n\t${additionalDataLayerItems}`}
    </script>,
  ]
}
