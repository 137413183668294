import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'

import { loadScript } from '../../../../shared/utils/loadScript'

export const SpeedCheck: FunctionComponent<Props> = ({ content }) => {
  useEffect(() => {
    loadScript({
      src: 'https://s.bsd.net/whichcouk/main/page/-/Consumer%20Rights%20Faulty%20goods/snippet-v6.js',
    })
    loadScript({
      src: 'https://speedchecker.s3.amazonaws.com/speedchecker.js',
    })
  }, [])

  return <div data-testid="speed-check" dangerouslySetInnerHTML={{ __html: content }} />
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
