import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import { useOnScreen } from '../../../../shared/hooks/useOnScreen'

export const CoverageMap: FunctionComponent<Props> = ({ content }) => {
  const coverageMapRef = useRef<HTMLDivElement | null>(null)
  const isVisible: boolean = useOnScreen(coverageMapRef, { rootMargin: '300px' })

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [htmlContent, setHtmlContent] = useState<string>('')

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setHtmlContent(content)
      setIsLoaded(true)
    }
  }, [isLoaded, content, isVisible])

  return (
    <div
      ref={coverageMapRef}
      data-testid="coverage-map"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
