import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'

import { loadScript } from '../../../shared/utils/loadScript'

/**
 * @TODO
 * LazyLoad? It is currently set to true in which-deliver
 */
export const Vimeo: FunctionComponent<Props> = ({ videoId, isLazyLoaded = false }) => {
  useEffect(() => {
    loadScript({
      src: 'https://player.vimeo.com/api/player.js',
    })
  }, [])

  const iframeProps = {
    'data-testid': 'vimeo-iframe',
    className: `${isLazyLoaded ? 'lazyload' : ''}`,
    loading: 'lazy',
    src: `https://player.vimeo.com/video/${videoId}?portrait=0`,
    allowFullScreen: true,
    webkitallowfullscreen: 'true',
    mozallowfullscreen: 'true',
  } as const

  return (
    <div className="vimeo-video-wrapper">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe {...iframeProps} title="Vimeo iFrame Player" />
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  videoId: string
  isLazyLoaded?: boolean
}
