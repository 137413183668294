import { dynamicGa4DataLayerPush } from '@which/shared'

import type { LoginMutation } from '../../../generated/frontend'
import { isLocal } from '../../../shared/utils/env-checks'
import { getQueryString } from '../../../shared/utils/get-query-string'
import { getIsValidReferrer } from '../utils'

export const processLoginMutationResponse = (props: {
  login: LoginMutation['login']
  stayLoggedIn: boolean
}) => {
  const { login, stayLoggedIn } = props

  switch (login.__typename) {
    case 'Authentication':
      processAuthenticationResponse({
        login,
        stayLoggedIn,
      })
      break
    case 'AuthenticationError':
      console.error('login failed')
      break
  }
}

const processAuthenticationResponse = (props: {
  login: Extract<LoginMutation['login'], { __typename: 'Authentication' }>
  stayLoggedIn: boolean
}) => {
  const {
    headers,
    loginStatus,
    responseProfile,
    responseSF,
    response: { cookie },
  } = props.login

  const { stayLoggedIn } = props

  dynamicGa4DataLayerPush({
    event: 'login',
  })

  const domainStr = isLocal() ? 'domain=localhost' : 'domain=.which.co.uk'
  const secureStr = 'secure'

  const today = new Date()
  today.setDate(today.getDate() + 30)
  const thirtyDaysFromNowUTC = today.toUTCString()
  const expires = ' Expires=' + thirtyDaysFromNowUTC
  const expiresStr = stayLoggedIn ? expires : ''

  if (loginStatus === '200' && headers) {
    const zephrCookies = headers['set-cookie']

    zephrCookies.forEach((cookies: string) => {
      if (cookies.indexOf('blaize_') > -1) {
        document.cookie = `${cookies};${secureStr};${domainStr};`
      } else {
        document.cookie = cookies
      }
    })

    if (cookie) {
      const cookieArray = cookie.split(';')
      cookieArray[1] = expiresStr
      document.cookie = `${cookieArray.join(';')};${secureStr};${domainStr};`
    }

    if (responseProfile['crm-id']) {
      document.cookie = `identity.cdn.logged_in=${responseProfile['crm-id']};${domainStr};${expiresStr};Path=/;`
      document.cookie = `identity.analytics_flag.logged_in=1;${domainStr};${expiresStr};Path=/;`
    }

    if (responseProfile['master-id'] || responseSF['sf-id']) {
      const contactIdValue = responseProfile['master-id'] || responseSF['sf-id']
      document.cookie = `master_id.analytics_flag=1;${domainStr};Path=/;`
      document.cookie = `master_id=${contactIdValue};${domainStr};${expiresStr};Path=/;`
    }

    const maybeReturnUrl = getQueryString('return_url')
    const returnUrlParamPresent = typeof maybeReturnUrl === 'string'

    const maybeReferrerPath = getQueryString('referrer_path')
    const referrerPathParamPresent = typeof maybeReferrerPath === 'string'
    const isValidReferrer = getIsValidReferrer(document?.referrer)

    if (returnUrlParamPresent) {
      location.replace(`${location.origin}/${maybeReturnUrl}`)
    } else if (isValidReferrer && referrerPathParamPresent) {
      const referrerUrl = new URL(document.referrer)
      location.replace(`${referrerUrl.origin}/${maybeReferrerPath}`)
    } else if (isValidReferrer) {
      location.replace(document.referrer)
    } else {
      location.replace('/')
    }
  }
}
