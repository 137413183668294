export const watchDataLayer = () => {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  const originalPush = window.dataLayer.push
  const event = 'historyChange_datalayerUpdate'

  window.dataLayer.push = (item) => {
    originalPush.call(window.dataLayer, item)

    if (item?.content_type || item?.vertical) {
      window.dataLayer.push({ event })
      window.dispatchEvent(new Event(event))
    }

    return window.dataLayer.length
  }
}
