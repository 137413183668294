import React from 'react'
import { Callout } from '@which/seatbelt'

import type { Callout as CalloutType } from '../../../generated/frontend'
import { useFullUrl } from '../../hooks/useFullUrl'
import styles from './CRScamsNavigation.module.scss'

export const CRScamsNavigation: React.FunctionComponent<Props> = ({
  title,
  imageFormat,
  items = [],
}) => {
  const { getFullUrl } = useFullUrl()
  const url = getFullUrl()
  const subVerticalRoute = '/consumer-rights/scams'

  if (items.length === 0) {
    return null
  }

  const filteredItems = items.filter((item) => !url.includes(item.href))
  const filteredTitle = getFullUrl().includes(subVerticalRoute) ? title : ''

  return (
    <div
      data-testid="callout"
      id="help-and-advice"
      className={
        url.includes(subVerticalRoute) ? styles.subVerticalCalloutWrapper : styles.calloutWrapper
      }
    >
      <Callout
        className={styles.scamsNavigation}
        imageAspectRatio={imageFormat === 'rectangle' ? 'two-to-one' : 'one-to-one'}
        cards={filteredItems}
        title={filteredTitle}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = Pick<CalloutType, 'imageFormat' | 'items' | 'title'>
