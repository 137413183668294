/* istanbul ignore file */
import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, FormField, InputV2, Loader, TypographyV2 as Typography } from '@which/seatbelt'

import { useCreateGardeningCaseInSfMutation } from '../../../generated/frontend'
import { FileUpload } from '../FileUpload'
import styles from './gardeningContactUs.module.scss'

export const GardeningContactUs: FunctionComponent<GardeningContactUsProps> = (
  gardeningContactUsProps?
) => {
  const [files, setFiles] = useState<File[]>([])
  const [gardeningContactUsData, setGardeningContactUsData] = useState(
    gardeningContactUsProps && Object.keys(gardeningContactUsProps).length > 4
      ? gardeningContactUsProps
      : {
          name: '',
          email: '',
          subject: '',
          message: '',
          reChaptaValidated: process.env.NR_ENV === 'preprod' ? true : false,
          reChaptaValue: '',
          submitted: false,
          submitFailed: false,
          submitInProgress: false,
          webformType: 'Gardening',
        }
  )

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })
  const [clearFiles, setClearFiles] = useState(false)

  const [createGardeningCaseInSfMutation] = useCreateGardeningCaseInSfMutation({
    variables: {
      formRequest: {
        subject: gardeningContactUsData.subject || '',
        name: gardeningContactUsData.name || '',
        email: gardeningContactUsData.email || '',
        message: gardeningContactUsData.message || '',
        webformType: 'Gardening',
      },
    },
  })

  const callMiddlewareAPI = async () => {
    try {
      const { data } = await createGardeningCaseInSfMutation()

      if (data && data.createGardeningCaseInSF) {
        const { status, caseId } = data.createGardeningCaseInSF
        setGardeningContactUsData((prevState) => ({
          ...prevState,
          submitInProgress: false,
          submitted: true,
          submitFailed: status !== '201' ? true : false,
        }))

        if (status === '201') {
          const form = document.getElementById('contact-form') as HTMLFormElement
          form.reset()
          setGardeningContactUsData((prevState) => ({
            ...prevState,
            name: '',
            email: '',
            subject: '',
            message: '',
            reChaptaValidated: false,
            reChaptaValue: '',
          }))

          if (files.length) {
            files.forEach((file: File) => {
              // TODO: Move this fetch to data-provider
              // Make use of graphql-upload, apollo-upload-client packages to create Upload scalar type
              fetch(
                `https://${gardeningContactUsProps?.mapiUrl}/attachment/${caseId}/${file.name}`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': file.type,
                  },
                  body: file,
                }
              )
            })
            setClearFiles(true)
          }
        }
      }
    } catch (error) {
      setGardeningContactUsData((prevState) => ({
        ...prevState,
        submitInProgress: false,
        submitted: true,
        submitFailed: true,
      }))
    }
  }

  const handleValidation = () => {
    let validationPassed = true
    setErrors({
      name: '',
      email: '',
      subject: '',
      message: '',
    })

    if (!gardeningContactUsData.name) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        name: 'Enter your full name',
      }))
    }

    if (!gardeningContactUsData.email) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        email: 'Enter your email address',
      }))
    }

    if (!gardeningContactUsData.subject) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        subject: 'Enter the subject of your request',
      }))
    }

    if (!gardeningContactUsData.message) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        message: 'Tell us what you would like help with',
      }))
    }

    return validationPassed
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!handleValidation()) {
      return
    }
    if (gardeningContactUsData.submitInProgress) {
      return
    }
    setGardeningContactUsData((prevState) => ({
      ...prevState,
      submitInProgress: true,
      submitted: false,
    }))

    callMiddlewareAPI()
  }

  const updateFilesCallBack = (callbackFiles) => {
    const arr: any = [],
      keys = Object.keys(callbackFiles)

    for (let i = 0, n = keys.length; i < n; i++) {
      const key = keys[i]
      arr.push(callbackFiles[key])
    }

    setFiles(arr)
  }

  return (
    <div data-testid="which-contact-us" className={styles.gardeningContactUsWrapper}>
      <form
        className={styles.gardeningContactUsForm}
        data-errors=""
        data-sentstatus=""
        id="contact-form"
        onSubmit={handleFormSubmit.bind(gardeningContactUsData)}
      >
        <Typography className={styles.gardeningContactUsHeading} textStyle="sb-text-heading-large">
          Submit a request
        </Typography>
        <div className={styles.gardeningContactUsFormLeftColumn}>
          <FormField
            className={styles.gardeningContactUsFormField}
            label="Name"
            labelFor='"ContactForm[name]"'
            required={true}
            errorMsg={errors.name}
          >
            <InputV2
              id="contact-us-name"
              value={gardeningContactUsData.name || ''}
              placeholder="Name"
              name="ContactForm[name]"
              type="text"
              required
              onChange={(e) => {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.gardeningContactUsFormRightColumn}>
          <FormField
            className={styles.gardeningContactUsFormField}
            label="Email"
            labelFor='"ContactForm[email]"'
            required={true}
            errorMsg={errors.email}
          >
            <InputV2
              id="contact-us-email"
              value={gardeningContactUsData.email || ''}
              placeholder="Email"
              name="ContactForm[email]"
              type="email"
              required
              onChange={(e) => {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.gardeningContactUsMessageWrapper}>
          <FormField
            className={styles.gardeningContactUsFormField}
            label="Subject"
            labelFor='"ContactForm[subject]"'
            required={true}
            errorMsg={errors.subject}
          >
            <InputV2
              id="contact-us-subject"
              value={gardeningContactUsData.subject || ''}
              placeholder="Subject"
              name="ContactForm[subject]"
              required={true}
              onChange={(e) => {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.gardeningContactUsMessageWrapper}>
          <FormField
            className={styles.gardeningContactUsFormField}
            label="Message"
            labelFor='"ContactForm[message]"'
            required={true}
            errorMsg={errors.message}
          >
            <textarea
              id="contact-us-message"
              defaultValue={gardeningContactUsData.message}
              placeholder="Message"
              name="ContactForm[message]"
              rows={8}
              required={true}
              onChange={(e) => {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  message: e.target.value,
                }))
              }}
              className={styles.gardeningContactUsTextArea}
            ></textarea>
          </FormField>
        </div>
        <div className={styles.gardeningContactUsMessageWrapper}>
          <FileUpload
            label="Attachments"
            buttonText="Add jpg or png image files"
            maxFileSizeInBytes={10000000} // 10mb
            numAttachmentsAllowed={10}
            updateFilesCallBack={updateFilesCallBack}
            clearFiles={clearFiles}
          />
        </div>
        <div className={styles.gardeningContactUsFormLeftColumn}>
          <ReCAPTCHA
            className={styles.gardeningContactUsRechaptcha}
            data-testid="contact-us-rechapta"
            sitekey="6LeMHDEgAAAAAIFIfwVWebGbHFAzz9ANPRRAYJPU"
            value={gardeningContactUsData.reChaptaValue}
            name="ContactForm[recaptca]"
            size="compact"
            onChange={(value) => {
              if (value) {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: true,
                  reChaptaValue: value,
                }))
              } else {
                setGardeningContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: false,
                  reChaptaValue: '',
                }))
              }
            }}
          />
          <Button
            data-testid="contact-us-submit-button"
            className={styles.gardeningContactUsSubmit}
            label="Send"
            disabled={!gardeningContactUsData.reChaptaValidated}
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
      {gardeningContactUsData.submitInProgress && (
        <div className={styles.processing}>
          <div className={styles.processingLoader}>
            <p>Processing...</p>
            <Loader />
          </div>
        </div>
      )}
      {gardeningContactUsData.submitted &&
        !gardeningContactUsData.submitFailed &&
        !gardeningContactUsData.submitInProgress && (
          <div className={styles.messageSent}>
            <div className={styles.messageSentBlock}>
              <Typography textStyle="sb-text-heading-standfirst">Message sent.</Typography>
              <Typography textStyle="sb-text-body-default-regular">
                Thank you for contacting us, one of our friendly staff will be happy to assist you.
                We aim to respond within 3 working days.
              </Typography>
            </div>
          </div>
        )}
      {gardeningContactUsData.submitted &&
        gardeningContactUsData.submitFailed &&
        !gardeningContactUsData.submitInProgress && (
          <div className={styles.messageSent}>
            <div className={styles.messageSentBlock}>
              <Typography textStyle="sb-text-heading-standfirst">
                Error while sending message
              </Typography>
              <Typography textStyle="sb-text-body-default-regular">
                We have encountered issues while sending your message. Please try again.
              </Typography>
            </div>
          </div>
        )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type GardeningContactUsProps = {
  mapiUrl: string
  name?: string
  email?: string
  subject?: string
  message?: string
  reChaptaValidated?: boolean
  reChaptaValue?: string
  submitFailed?: boolean
  submitInProgress?: boolean
  submitted?: boolean
}
