import type { FunctionComponent } from 'react'
import React from 'react'
import { ContentType } from '@which/seatbelt'

import { mapIconType } from '../utils/icon-map'
import styles from './PIArticleInfo.module.scss'

export const PIArticleInfo: FunctionComponent<Props> = ({ articleType }) => {
  const PIIcon = mapIconType(articleType)

  return (
    <div className={styles.piArticleInfo} data-testid="ea-article-info">
      <ContentType icon={PIIcon} text={articleType} />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  articleType: string
}
