import type { FunctionComponent } from 'react'
import React from 'react'
import { AccordionItem, TypographyV2 as Typography } from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons'

import classnames from 'classnames'

import { handleAccordionToggle } from './ComparisonTableV2'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonPageExpertReviews: FunctionComponent<ComparisonExpertReviewsProps> = ({
  businessKeys,
  editorialReviews,
  isDesktopOrAbove,
  totalProductColumns = 4,
}) => {
  const renderExpertReviewData = () => {
    return (
      <div
        key={`expert-review-section`}
        className={classnames(styles.compareSection, styles.expertReview)}
      >
        <div
          className={classnames(styles.productFeatureRow, {
            [styles.mobileGreyRow]: !isDesktopOrAbove,
          })}
          data-testid={'editorial-review'}
        >
          <div
            className={classnames(styles.rowLabel, {
              [styles.rowLabelWithPadlock]: editorialReviews[0]?.isPadlocked,
            })}
          >
            {editorialReviews[0]?.isPadlocked && (
              <SolidPadlockIcon
                data-testid="editorial-review-padlock-icon"
                width={15}
                height={15}
                viewBox="0 0 15 15"
                className={styles.padlockIcon}
              />
            )}
            <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
              {editorialReviews[0]?.cta}
            </Typography>
          </div>
          {editorialReviews.map((product, index) => {
            const { verdict, content } = product
            return (
              <div
                className={classnames(styles.tableDataRowValue, styles.editorialReviewSection)}
                key={`editorial-review-${businessKeys[index]}`}
              >
                {verdict && (
                  <Typography
                    className={styles.verdict}
                    textStyle="sb-text-interface-body-small-strong"
                  >
                    {verdict}
                  </Typography>
                )}
                <Typography
                  className={styles.content}
                  textStyle="sb-text-interface-body-small-regular"
                >
                  {content}
                </Typography>
              </div>
            )
          })}
          {isDesktopOrAbove && editorialReviews.length < totalProductColumns && (
            <div className={styles.filler} />
          )}
        </div>
      </div>
    )
  }

  return (
    <AccordionItem
      label="Expert review"
      content={renderExpertReviewData()}
      renderOpen={isDesktopOrAbove}
      animatesOpen={false}
      callback={(isOpen: boolean) => {
        handleAccordionToggle(isOpen, 'Expert review')
      }}
    />
  )
}

type ComparisonExpertReviewsProps = {
  businessKeys: string[]
  editorialReviews: {
    isPadlocked: boolean
    cta: string
    verdict: string
    content: string
  }[]
  isDesktopOrAbove: boolean
  totalProductColumns?: number
}
