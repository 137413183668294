import { pluralize } from '@looop/pluralize';
import { getTaxonomyException } from './get-taxonomy-exception';
import { transformCategoryCase } from './transform-category-case';
export const getSingularCategoryName = _ref => {
  let {
    categoryName = '',
    transform = false
  } = _ref;
  if (!categoryName) {
    return '';
  }
  const singularName = getTaxonomyException(categoryName) || pluralize.singular(categoryName);
  return transform ? transformCategoryCase(singularName) : singularName;
};