import { type FunctionComponent } from 'react'
import { Grid, GridItem, Heading } from '@which/seatbelt'

import classNames from 'classnames'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendationCard } from '../RecommendationCard/ReccomendationCard'
import { SurveyLink } from '../RecommendedForYou/SurveyLink'
import styles from './RecommendedContent.module.scss'

export const RecommendedContent: FunctionComponent<RecommendedContentProps> = ({
  content,
  whichId,
  modelId,
}) => {
  return (
    <Grid data-testid={'r4u-2x2'} className={classNames('w-page-wrapper', styles.sectionSpacing)}>
      <GridItem
        columnStart={{ large: 2 }}
        span={{ large: 10 }}
        className={styles.mobileMaxWidthWrapper}
      >
        <Heading headingType="large" heading="Based on your interests" headingTag="h2" />

        <div className={styles.recommendedArticlesContainer}>
          {content?.map((content, index) => (
            <RecommendationCard
              content={content}
              whichId={whichId}
              modelId={modelId}
              index={index}
              key={content.id}
              large={false}
            />
          ))}
        </div>
        <SurveyLink variationName="2x2" />
      </GridItem>
    </Grid>
  )
}

type RecommendedContentProps = {
  content?: Recommendations['models'][0]['content']
  whichId: string
  modelId: string
}
