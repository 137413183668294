import type { ReactNode } from 'react'
import { Children, cloneElement, isValidElement } from 'react'

import type { VariationSettings } from './getVariantSettings'

/**
 * This mapper will re-structure the table's children from:
 *
 *  [header, header, header]
 *  [provider, score, score]
 *  [provider, score, score]
 *  [provider, score, score]
 *
 * to a single provider cell which will span all the way to the bottom:
 *  [header, header, header]
 *  [provider, score, score]
 *  [         score, score]
 *  [         score, score]
 */

export const mapProvidersLockedTree = (children: ReactNode, variationSettings: VariationSettings) =>
  mapChildren(Children.toArray(children), variationSettings)

///////// IMPLEMENTATION /////////

const mapChildren = (allChildrenArray: ChildrenTree, variationSettings: VariationSettings) =>
  allChildrenArray.map((tableSection) => {
    if (isValidElement(tableSection) && tableSection.type === 'tbody') {
      // Remove one cell from all rows except the first one,
      // which will have a rowspan and colspan applied
      const restructuredRows = Children.toArray(tableSection.props.children).map(
        (row, rowIndex) => {
          if (isValidElement(row)) {
            // First row
            if (rowIndex === 0) {
              const {
                props: { children: firstRowChildren },
              } = row

              const cellsWithSingleCta = Children.toArray(firstRowChildren).map((td, tdIndex) => {
                // First cell
                if (tdIndex === 0 && isValidElement(td)) {
                  const cloned = cloneElement(td, {
                    ...td.props,
                    className: 'singleCtaCell',
                    colSpan: variationSettings?.colSpan,
                    rowSpan: variationSettings?.rowSpan,
                  })

                  return cloned
                }

                return td
              })

              // Clone row with new single CTA cells
              return cloneElement(
                row,
                {
                  ...row.props,
                },
                cellsWithSingleCta
              )
            }

            const {
              props: { children: rowChildren },
            } = row

            return cloneElement(row, {
              ...row.props,
              children: rowChildren.slice(1, rowChildren.length),
            })
          }

          return row
        }
      )

      const clonedTbody = cloneElement(tableSection, {
        ...tableSection.props,
        children: restructuredRows,
      })

      return clonedTbody
    }

    return tableSection
  })

type ChildrenTree = (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
