import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { Accordion, AccordionItem } from '@which/seatbelt'
import { dynamicDatalayerPush, dynamicGa4DataLayerPush } from '@which/shared'

import style from '../../registration-page/RegistrationPage.module.scss'

export const QuestionAccordion: FunctionComponent<Props> = ({ label, content, calledFrom }) => {
  const callDataLayerForAccordion = (event: React.MouseEvent<HTMLElement>) => {
    const input = event.target as HTMLElement
    if (calledFrom === 'registration') {
      dynamicDatalayerPush({
        eventCategory: 'Engagement',
        eventAction: 'Accordion',
        eventLabel: input.innerText,
      })
    } else {
      dynamicGa4DataLayerPush({
        event: 'click_accordion',
        action_group: input.getAttribute('aria-expanded') === 'false' ? 'expand' : 'collapse',
        item_text: input.innerText,
      })
    }
  }

  return (
    <Accordion>
      <AccordionItem
        className={style.registrationPageQuestions}
        label={label}
        onClick={(event) => {
          callDataLayerForAccordion(event)
        }}
        content={content}
        data-testid={`${label}-accordian`}
      ></AccordionItem>
    </Accordion>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  label: string
  content: ReactNode
  calledFrom: string
}
