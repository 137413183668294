import type { FunctionComponent } from 'react'
import React from 'react'
import type { LinkStyleText } from '@which/seatbelt'
import { TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../../../../shared/components/Link'
import styles from './LinkPromo.module.scss'

export const LinkPromo: FunctionComponent<Props> = ({
  linkUrl,
  linkLabel,
  promoHeader,
  promoDescription,
  externalLink,
  textStyle,
}) => {
  return (
    <div data-testid="promo-link" className={styles.wrapper}>
      <div className={styles.linkWrapper}>
        {externalLink ? (
          <Link href={linkUrl} target="_blank">
            {linkLabel}
          </Link>
        ) : (
          <Link includeIcon={true} href={linkUrl} textStyle={textStyle}>
            {linkLabel}
          </Link>
        )}
      </div>
      {promoHeader && (
        <Typography className={styles.promoHeader} textStyle="sb-text-body-default-strong">
          {promoHeader}
        </Typography>
      )}
      <Typography textStyle="sb-text-body-default-regular">{promoDescription}</Typography>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  linkUrl: string
  linkLabel?: string
  promoHeader?: string
  promoDescription?: string
  externalLink?: boolean
  textStyle?: LinkStyleText
}
