import type { FunctionComponent } from 'react'
import React from 'react'
import { PictureCaptioned as SBPictureCaptioned } from '@which/seatbelt'

export const PictureCaptioned: FunctionComponent<Props> = ({
  src,
  alt = '',
  caption,
  sources,
  width,
  height,
}) => (
  <SBPictureCaptioned
    src={src}
    alt={alt}
    caption={caption}
    sources={sources}
    width={width}
    height={height}
  />
)

///////// IMPLEMENTATION /////////

type Props = {
  src: string
  alt?: string
  caption?: string
  sources?: Source[]
  width?: string
  height?: string
}

type Source = {
  srcset?: string
  sizes?: string
  media?: string
}
