import React from 'react'

export const productListing = (
  <path
    data-testid="svg-productListing"
    fill="#b44d76"
    d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,6.6l6.8,3.9L19,12.1l-6.7-3.9
    L15,6.6z M14.5,23.2l-6.8-3.9v-7.8l6.8,3.9V23.2z M15,14.4l-6.8-3.9l3-1.7l6.7,3.9L15,14.4z M22.3,19.3L22.3,19.3l-6.8,3.9v-7.8
    l3.7-2.1c0,0,0,0,0.1,0l3-1.7V19.3z"
  />
)
