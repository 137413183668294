import type { FunctionComponent } from 'react'
import React from 'react'
import { PageTitle, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './ArticleListingHeader.module.scss'

export const ArticleListingHeader: FunctionComponent<Props> = ({
  count = 0,
  name,
  description,
  className = '',
  articleTypeDescriptor = '',
}) => (
  <div
    className={classnames(className, styles.articleListingHeader)}
    data-testid="article-listing-header"
  >
    <PageTitle
      pageTitle={`${name}${articleTypeDescriptor ? ` ${articleTypeDescriptor}` : ''}`}
      pageTitleTag="h1"
    />

    {description && (
      <div className={styles.articleListingSubHeading} data-testid="article-listing-sub-heading">
        <Typography textStyle="sb-text-heading-standfirst">{description}</Typography>
      </div>
    )}
    {count > 0 && (
      <div className={styles.articleListingCount} data-testid="article-listing-count">
        <Typography textStyle="sb-text-body-default-strong">{`${count} article${count > 1 ? 's' : ''}`}</Typography>
      </div>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

type Props = {
  name: string
  description?: string
  className?: string
  count?: number
  articleTypeDescriptor?: string
}
