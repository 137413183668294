/* istanbul ignore file */

import React, { useContext } from 'react'
import type { PageContext, TemplateName } from '@which/glide-ts-types'

import type { SavedItemsQuery } from '../generated/frontend'

export const PagePropsContext = React.createContext<Context>({
  template: '' as TemplateName,
  rootCollectionName: '',
  context: '' as PageContext,
  userAccessState: {
    userLoggedIn: false,
    transformTypeDecision: '',
  },
  savedItems: [],
})

export function usePageProps() {
  return useContext(PagePropsContext)
}

///////// IMPLEMENTATION /////////

export type Context = {
  template?: TemplateName
  rootCollectionName?: string
  context?: PageContext
  userAccessState?: {
    userLoggedIn?: boolean
    transformTypeDecision?: string
  }
  savedItems: SavedItemsQuery['savedItems']
}
