import type { FunctionComponent } from 'react'
import React from 'react'
import type { AspectRatio } from '@which/seatbelt'
import { Picture } from '@which/seatbelt'

import type { ImageWithSources } from '../../../generated/frontend'
import styles from './HeroImage.module.scss'

export const HeroImage: FunctionComponent<HeroImageProps> = ({
  src,
  alt,
  sources,
  dimensions,
  aspectRatio = 'two-to-one',
}) => {
  if (!src) {
    return null
  }

  return (
    <div className={styles.heroImageWrapper} data-testid="hero-image">
      <Picture
        src={src}
        alt={alt}
        aspectRatioMain={aspectRatio}
        width={dimensions?.width}
        height={dimensions?.height}
        lazyLoad={false}
        sources={sources}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type HeroImageProps = ImageWithSources & { aspectRatio?: AspectRatio }
