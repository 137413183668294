import type { FunctionComponent } from 'react'

import type { RecommendedContent as RecommendedContentType } from '../../../../generated/frontend'
import { AdviceCard } from '../AviceCard'
import { ProductReviewCard } from '../ProductReviewCard'

export const RecommendationCard: FunctionComponent<RecommendationCardProps> = ({
  content,
  whichId,
  modelId,
  index,
  large,
}) => {
  if (content.__typename === 'RecommendedArticle') {
    return (
      <AdviceCard
        title={content.title}
        subheading={content.subheading}
        primaryLink={content.href}
        imgObj={{
          imageAlt: content.title,
          str: content.image,
        }}
        ariaLabel={`${content.title} advice card`}
        large={large}
        trackingData={{
          'data-which-id': whichId,
          'data-section': 'Recommended for you',
          'data-card-name': content.title,
          'data-index': (index + 1).toString(),
          'data-content-id': content.id,
          'data-model-id': modelId,
        }}
      />
    )
  } else if (content.__typename === 'RecommendedProduct') {
    return (
      <ProductReviewCard
        title={content.manufacturer}
        model={content.model}
        price={content.price}
        testScore={content.testScore}
        primaryLink={`/${content.href}`}
        large={large}
        imgObj={{
          imageAlt: content.model,
          str: content.image,
        }}
        ariaLabel={`${content.manufacturer} ${content.model} review`}
        trackingData={{
          'data-which-id': whichId,
          'data-section': 'Recommended for you',
          'data-card-name': content.model,
          'data-index': (index + 1).toString(),
          'data-content-id': content.id,
          'data-model-id': modelId,
        }}
      />
    )
  }

  return null
}

type RecommendationCardProps = {
  content: RecommendedContentType
  whichId: string
  modelId: string
  index: number
  large: boolean
}
