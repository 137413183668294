import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Grid, GridItem, Picture, TypographyV2 as Typography } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import type { Colours } from './colours'
import styles from './FullWidthPromo.module.scss'

// TODO CTX-1769: Delete if unused
export const FullWidthPromo: FunctionComponent<Props> = ({
  title,
  description,
  linkText,
  linkUrl,
  image,
  backgroundColour,
}) => (
  <div
    className={classnames('w-page-wrapper', `sb-bg-color-${backgroundColour}`)}
    data-testid="full-width-promo"
  >
    <Grid>
      <GridItem
        className={styles.textArea}
        span={{ medium: 5, large: 5 }}
        columnStart={{ medium: 2, large: 2 }}
      >
        <Typography textStyle="sb-text-heading-large" tag="h3">
          {title}
        </Typography>
        <Typography className={styles.textAreaDescription} textStyle="sb-text-body-default-regular">
          {description}
        </Typography>
        <ButtonLink className={styles.textAreaButton} appearance="secondary" href={linkUrl}>
          <ChevronRightIcon />
          {linkText}
        </ButtonLink>
      </GridItem>
      <GridItem
        className={styles.imageContainer}
        span={{ medium: 6, large: 6 }}
        columnStart={{ medium: 7, large: 7 }}
      >
        <Picture imageClassName={styles.image} {...image} aspectRatioMain="one-to-one" />
      </GridItem>
    </Grid>
  </div>
)

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  description: string
  linkText: string
  linkUrl: string
  image: {
    src: string
    alt?: string
    sources?: Source[]
  }
  backgroundColour: Colours
}

type Source = {
  srcset?: string
  sizes?: string
  media?: string
}
