import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import { useOnScreen } from '../../../../shared/hooks/useOnScreen'
import styles from './Infogram.module.scss'

export const Infogram: FunctionComponent<Props> = ({ content }) => {
  const infogramRef = useRef<HTMLDivElement | null>(null)
  const isVisible: boolean = useOnScreen(infogramRef)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [htmlContent, setHtmlContent] = useState<string>('')

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setHtmlContent(content)
      setIsLoaded(true)
    }
  }, [isLoaded, content, isVisible])

  useEffect(() => {
    const scriptContainer = document.querySelector("[data-testid='infogram']")
    const scriptEl = scriptContainer?.querySelector('script')
    const iframeEl = scriptContainer?.querySelector('iframe')

    if (!scriptContainer || !scriptEl) {
      return
    }

    if (!iframeEl || !isLoaded) {
      const newScript = document.createElement('script')

      newScript.id = scriptEl['id']
      newScript.src = scriptEl['src']
      newScript.title = scriptEl['title']
      newScript.type = scriptEl['type']

      scriptContainer.innerHTML = ''
      scriptContainer.appendChild(newScript)
    }
  })

  return (
    <div
      ref={infogramRef}
      className={styles.infogram}
      data-testid="infogram"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
