import type { BadgeTheme } from '@which/seatbelt'

export const filterBadgeThemes = (badges: string[]) =>
  badges.filter((badge): badge is BadgeTheme =>
    validBadgeThemes.some((validBadge) => validBadge === badge)
  )

export const validBadgeThemes: BadgeTheme[] = [
  'best buy',
  'dont buy',
  'eco buy',
  'first look',
  'great value',
  'brand alert',
  'safety alert',
  'lab tested',
  'security notice',
  'top story',
  'default',
]
