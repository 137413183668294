import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Heading, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './SubscriptionTiers.module.scss'

export const SubscriptionTiers: FunctionComponent<Props> = ({
  title,
  standfirst,
  tiers,
  ctaLink,
  offerTitle,
  offerBody,
}) => {
  if (!tiers.length) {
    return null
  }

  return (
    <section data-testid="subscription-tiers" className={styles.subscriptionTiers}>
      {title && <Heading heading={title} headingTag="h2" headingType="large" />}

      {standfirst && <Typography textStyle="sb-text-heading-standfirst">{standfirst}</Typography>}

      {renderTierList(tiers, ctaLink)}

      {offerTitle && offerBody && (
        <div data-testid="offer-box" className={styles.offer}>
          <Typography
            tag="h3"
            textStyle="sb-text-body-default-strong"
            className={styles.offerTitle}
          >
            {offerTitle}
          </Typography>
          <Typography textStyle="sb-text-body-small-regular">{offerBody}</Typography>
        </div>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////
const renderTierList = (tiers: Tiers, ctaLink: string) => (
  <ul className={styles.tierList}>
    {tiers.map((tier) => (
      <li key={tier.name} className={styles.tier} data-testid="subscription-tier">
        <div>
          <Typography tag="h3" textStyle="sb-text-body-default-strong" className={styles.tierTitle}>
            {tier.name}
          </Typography>
          <Typography textStyle="sb-text-body-default-strong">
            {tier.introPrice ? (
              <>
                <Typography tag="span" textStyle="sb-text-heading-large">
                  £{tier.introPrice}
                </Typography>{' '}
                for first month{' '}
                <Typography
                  tag="span"
                  textStyle="sb-text-interface-body-small-regular"
                  className={styles.tierIntroPriceText}
                >
                  then £{tier.monthlyPrice} a month
                </Typography>
              </>
            ) : (
              <>
                <Typography tag="span" textStyle="sb-text-heading-large">
                  £{tier.monthlyPrice}
                </Typography>{' '}
                a month
              </>
            )}
          </Typography>
        </div>
        {ctaLink && (
          <ButtonLink
            data-which-id={trackingMapper[tier.name]}
            href={ctaLink}
            className={styles.tierCta}
          >
            Find out more
          </ButtonLink>
        )}
      </li>
    ))}
  </ul>
)

const trackingMapper = {
  Digital: 'stwidget-cta-digital',
  'Full Access': 'stwidget-cta-full',
  'Which? Magazine': 'stwidget-cta-magazine',
}

type Props = {
  title?: string
  standfirst?: string
  tiers: Tiers
  ctaLink: string
  offerTitle?: string
  offerBody?: string
}

type Tiers = {
  name: string
  introPrice?: string
  monthlyPrice: string
}[]
