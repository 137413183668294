import type { FunctionComponent } from 'react'
import React from 'react'

export const Twitter: FunctionComponent<Props> = ({ url }) => {
  const tweetId = url.split('/')[url.split('/').length - 1]
  const src = `https://platform.twitter.com/embed/index.html?id=${tweetId}`
  const iframeProps = {
    'data-testid': 'twitter-iframe',
    src,
    allowFullScreen: true,
    webkitallowfullscreen: 'true',
    mozallowfullscreen: 'true',
    style: { width: '100%', maxWidth: '550px', height: '557px', display: 'block' },
    scrolling: 'no',
    frameBorder: '0',
  }

  return (
    <section className="embed-twitter">
      <iframe {...iframeProps} title="X iFrame Widget" />
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  url: string
}
