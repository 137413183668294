import type { ComparisonTableDataType } from '../components/ComparisonTable/types'

export const removeTableDataColumn = (tableData: ComparisonTableDataType, index?: number) => {
  return index !== undefined
    ? tableData.map((section) => {
        return {
          ...section,
          rows: section.rows.map((row) => {
            return {
              ...row,
              rowValues: row.rowValues.filter((_, valueIndex) => valueIndex !== index),
            }
          }),
        }
      })
    : tableData
}
