/*
 * Replaces dynamicDatalayerPush()
 * This util supports keys for Google Analytics 4 (GA4), which replaces Universal Analytics
 * */
export const dynamicGa4DataLayerPush = object => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(object);
};

///////// IMPLEMENTATION /////////