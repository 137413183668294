import type { FunctionComponent } from 'react'
import React from 'react'

export const SwitchEmbed: FunctionComponent<Props> = ({ content }) => (
  <div data-testid="switch-embed" dangerouslySetInnerHTML={{ __html: content }} />
)

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
