import type { FunctionComponent } from 'react'
import React from 'react'
import { DateTime, ReadingTime } from '@which/seatbelt'

import styles from './PIArticleDate.module.scss'

export const PIArticleDate: FunctionComponent<Props> = ({ estimatedReadingTime, date }) => {
  const readTimeText = 'min read'
  const hasReadTime = estimatedReadingTime.includes(readTimeText)
  const readTime = hasReadTime
    ? estimatedReadingTime.replace(readTimeText, '')
    : estimatedReadingTime

  return (
    <div className={styles.piArticleDate}>
      <div className={styles.piArticleDateType}>
        <DateTime dateInput={date} showDateOrTime={'Date'} />

        <div className={styles.piArticleDateReading}>
          <ReadingTime readTime={readTime} />
        </div>
      </div>
    </div>
  )
}

type Props = {
  date: string
  estimatedReadingTime: string
}
