import type {
  BaseSyntheticEvent,
  ComponentPropsWithoutRef,
  FunctionComponent,
  ReactElement,
  ReactNode,
} from 'react'
import React, { Children, useEffect, useState } from 'react'

import classnames from 'classnames'

import { MoreButton } from '../MoreButton'
import styles from './ShowMore.module.scss'

export const defaultCount = 7

export const ShowMore: FunctionComponent<Props> = ({
  children: content,
  contentRenderer,
  callback,
  initialCount = defaultCount,
  buttonLabel = 'Show more',
  className = '',
  moreButtonProps,
  renderFullContent = false,
  ...rest
}) => {
  const [currentCount, setCurrentCount] = useState<number>(initialCount)
  const contentData = getContentData(content)
  const { contentLength } = contentData

  const currentContent = slicedContent(contentData, currentCount)
  const { contentLength: currentContentLength } = getContentData(currentContent)
  const isExpanded = currentContentLength === contentLength

  useEffect(() => {
    if (renderFullContent) {
      setCurrentCount(contentLength)
    }
  }, [contentLength, renderFullContent])

  useEffect(() => {
    if (isExpanded) {
      typeof callback === 'function' && callback()
    }
  }, [callback, isExpanded])

  if (contentLength <= initialCount) {
    return <>{contentRenderer(content)}</>
  }

  return (
    <div
      className={classnames(styles.showMoreWrapper, className)}
      data-testid="show-more"
      {...rest}
    >
      {contentRenderer(currentContent)}
      <MoreButton
        dataTestId="show-more-button"
        hideButton={isExpanded}
        onClick={() => setCurrentCount(contentLength)}
        buttonLabel={buttonLabel}
        {...moreButtonProps}
        className={classnames(styles.showMore, moreButtonProps?.className)}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const slicedContent = (contentData: ContentData, sliceCount: number) => {
  const { contentArray, hasTableContent } = contentData

  if (hasTableContent) {
    const tbody = findTableContent(contentArray, 'tbody')
    const tbodyCopy = { ...tbody }

    const {
      props: { children = [] },
    } = tbodyCopy

    const childrenSliced = children.slice(0, sliceCount)

    return createTableMarkup(contentArray, childrenSliced)
  }

  return contentArray.slice(0, sliceCount)
}

const createTableMarkup = (contentArray: any[], childrenSliced: any[]) => {
  const thead = findTableContent(contentArray, 'thead') || []
  const tbody = findTableContent(contentArray, 'tbody')

  return [thead, React.cloneElement(tbody, { children: childrenSliced })]
}

const findTableContent = (contentArray: any[], tableTag: string): ReactElement =>
  contentArray.find((element) => element?.type === tableTag)

const getTbodyLength = (tbody: ReactElement) => {
  const {
    props: { children = [] },
  } = tbody

  return children.length
}

const getContentData = (content: ReactNode): ContentData => {
  const contentArray = !Array.isArray(content) ? Children.toArray(content) : content

  // Check if content is from table
  const tbody = findTableContent(contentArray, 'tbody')
  const hasTableContent = !!tbody

  return {
    contentArray,
    contentLength: hasTableContent ? getTbodyLength(tbody) : contentArray.length,
    hasTableContent,
  }
}

type Props = {
  initialCount?: number
  callback?: () => void
  /*
   * Render function to allow control over child rendering
   * If rendering list items, this function should be used to wrap the children in a `ul` or `ol`
   * Wrapping ShowMore in the list element would render a `div` inside the list which is invalid HTML
   * */
  contentRenderer: ShowMoreContentRenderer
  buttonLabel?: string
  className?: string
  moreButtonProps?: Partial<Parameters<typeof MoreButton>[0]>
  /*
   * When renderFullContent is true, the full list of content will be displayed
   * The wrapper div element will not be rendered when renderFullContent is true
   * */
  renderFullContent?: boolean
  onClick?: (e?: BaseSyntheticEvent & unknown) => Promise<void> | void
} & ComponentPropsWithoutRef<'div'>

export type ShowMoreContentRenderer = (list: ReactNode) => ReactNode

type ContentData = {
  contentArray: ReactNode[]
  contentLength: number
  hasTableContent: boolean
}
