import { createContext } from 'react'

import type { CompareTray } from '../../shared/types/ReviewsCompareTray'
import type { ArticleCrPageData } from './article-page-types'

export const ArticleTemplateContext = createContext<ArticleTemplateContextType>({
  meta: {
    canonical: '',
    contentId: '',
    dataLayer: '',
    desc: '',
    facebookDesc: '',
    facebookImage: '',
    facebookTitle: '',
    fbAppId: '',
    headCodeBlock: '',
    keywords: [],
    pageType: '',
    publishedDate: '',
    taxonomyHierarchy: {
      category: '',
      subVertical: '',
      superCategory: '',
      vertical: '',
    },
    title: '',
    twitterDesc: '',
    twitterImage: '',
    twitterTitle: '',
    updatedDateTime: Infinity,
    publishedDateTime: Infinity,
    systemContentPublishedDate: '',
    systemContentUpdatedDate: '',
    glideCurationDate: '',
    frequencyOfUpdate: '',
    reviewSchema: '',
    significantUpdate: '',
  },
  compareTray: {
    compareTrayItems: [],
    addProduct: undefined,
    removeProduct_ProductCardHandler: undefined,
  },
})

type ArticleTemplateContextType = {
  meta: ArticleCrPageData['meta']
  compareTray: CompareTray
}
