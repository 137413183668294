import type { FunctionComponent } from 'react'
import React from 'react'
import type { BadgeTheme } from '@which/seatbelt'
import { Badge } from '@which/seatbelt'

import classnames from 'classnames'

import type { ReviewsProduct } from '../../../../../generated/frontend'
import styles from './ProductBadges.module.scss'

export const ProductBadges: FunctionComponent<ProductBadgesProps> = ({
  badges,
  className = '',
}) => {
  if (!badges?.length) {
    return null
  }

  return (
    <ul className={classnames(styles.list, className)}>
      {badges.map((badge, i) => (
        <li className={styles.listItem} key={`${badge}-${i}`}>
          <Badge theme={badge as BadgeTheme} />
        </li>
      ))}
    </ul>
  )
}

type ProductBadgesProps = {
  badges: ReviewsProduct['badges']
  className?: string
}
