import React from 'react'

export const chevron = (
  <g data-testid="svg-chevron" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-380.000000, -1848.000000)" fill="#0065C9">
      <polygon
        transform="translate(387.380952, 1852.606061) scale(1, -1) rotate(-270.000000) translate(-387.380952, -1852.606061) "
        points="382.774892 1846.75961 384.394954 1845.22511 391.987013 1852.6521 384.394954 1859.98701 382.774892 1858.42182 388.778654 1852.62141"
      ></polygon>
    </g>
  </g>
)
