import type { FunctionComponent } from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { ProductHubPage as ProductHubPageType } from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared'
import { ResubscribeBanner } from '../../../../shared/components/ResubscribeBanner'
import type { ProductHubHardcodedProps } from '../../HardcodedProductHub'
import { AdditionalContent } from '../AdditionalContent/AdditionalContent'
import { ProductHubAdvice } from '../ProductHubAdvice/ProductHubAdvice'
import { ProductHubIntro } from '../ProductHubIntro'
import { ProductHubJumpLinks } from '../ProductHubJumpLinks/ProductHubJumpLinks'
import { ProductHubReviews } from '../ProductHubReviews/ProductHubReviews'
import { ProductHubRights } from '../ProductHubRights/ProductHubRights'
import { RelatedProducts } from '../RelatedProducts'
import { ProductHubBrandLinksV2 } from './components/ProductHubBrandLinksV2/ProductHubBrandLinksV2'
import { ProductHubCTACards } from './components/ProductHubCTACards/ProductHubCTACards'
import { ProductHubGridLayout } from './components/ProductHubGridLayout/ProductHubGridLayout'
import { ProductHubLinkSection } from './components/ProductHubLinkSection/ProductHubLinkSection'
import styles from './ProductHubPageV2.module.scss'

export const ProductHubPageV2: FunctionComponent<ProductHubPageProps> = ({
  breadcrumb,
  intro,
  jumpLinks,
  ctaSections,
  advice,
  rights,
  productReviews,
  isLoggedIn,
  products,
  brandLinksV2: brandLinks,
  relatedProducts,
  description,
  otherBrandLinks,
  transformTypeDecision,
  showResubBanner,
}) => {
  const resubBannerFlag = useFeatureIsOn('resubscribe-banner')
  const renderResubscribeBanner = () => {
    if (!resubBannerFlag) {
      return null
    }

    if (!transformTypeDecision || transformTypeDecision !== 'AUTHENTICATED_NO_ACCESS') {
      return null
    }

    return <ResubscribeBanner showBanner={showResubBanner} />
  }

  return (
    <main id="main-content">
      {renderResubscribeBanner()}
      {breadcrumb && <Breadcrumb {...breadcrumb} />}

      <Grid className={styles.maxWidth}>
        {intro && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <ProductHubIntro {...intro} className={styles.sectionSpacing} fullWidthLayout />
          </GridItem>
        )}

        {jumpLinks && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <div className={styles.sectionWithDivider}>
              <ProductHubJumpLinks {...jumpLinks} />
            </div>
          </GridItem>
        )}

        {ctaSections &&
          ctaSections.map((ctaSection, index) => (
            <GridItem
              key={ctaSection.heading}
              className={styles.pageSection}
              span={{ medium: 10, large: 10 }}
              columnStart={{ medium: 2, large: 2 }}
            >
              <ProductHubCTACards {...ctaSection} border={index + 1 === ctaSections.length} />
            </GridItem>
          ))}

        {productReviews && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <ProductHubReviews
              {...productReviews}
              isLoggedIn={isLoggedIn}
              products={products}
              leftAlignButtonCTA
              noTopSpacing
            />
          </GridItem>
        )}

        {brandLinks && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <ProductHubGridLayout featuredImage={brandLinks.featuredImage}>
              <ProductHubBrandLinksV2 {...brandLinks} />
              {otherBrandLinks?.map((links, index) => (
                <ProductHubLinkSection key={index} {...links} />
              ))}
            </ProductHubGridLayout>
          </GridItem>
        )}

        {advice && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <ProductHubGridLayout featuredImage={advice.featuredImage} imageOnLeft>
              <ProductHubAdvice {...advice} fullWidth condensedList />
            </ProductHubGridLayout>
          </GridItem>
        )}

        {rights && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <ProductHubGridLayout featuredImage={rights.featuredImage}>
              <ProductHubRights {...rights} fullWidth headingsWithBorders />
            </ProductHubGridLayout>
          </GridItem>
        )}

        {relatedProducts && (
          <GridItem
            className={styles.pageSection}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <div className={styles.sectionWithDivider}>
              <RelatedProducts
                className={styles.sectionSpacing}
                relatedProducts={relatedProducts}
              />
            </div>
          </GridItem>
        )}

        {description && (
          <GridItem
            className={[styles.pageSection, styles.seoDescriptionSection].join(' ')}
            span={{ medium: 10, large: 10 }}
            columnStart={{ medium: 2, large: 2 }}
          >
            <AdditionalContent {...description} />
          </GridItem>
        )}
      </Grid>
    </main>
  )
}

///////// IMPLEMENTATION /////////

export type ProductHubPageProps = ProductHubHardcodedProps & ProductHubPageType
