import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { Button } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './SkipButton.module.scss'

export const SkipButton: FunctionComponent<SkipButtonProps> = ({
  className,
  children,
  selector,
}) => {
  const shiftFocusToElement = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const element = document.querySelector(selector) as HTMLElement
    element?.focus()
  }
  return (
    <Button
      appearance="secondary"
      className={classnames(styles.skipButton, className)}
      onClick={shiftFocusToElement}
      onKeyDown={(e) => e.key === 'Enter' && shiftFocusToElement(e)}
    >
      {children}
    </Button>
  )
}

type SkipButtonProps = {
  className?: string
  children: ReactNode
  selector: string
}
