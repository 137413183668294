import { useCallback } from 'react'
import { useCookie } from 'react-use'

import type { Comparison } from '../types/ReviewsCompareTray'

export const useCompareCookie = (
  cookieStorageName: string,
  taxonomySlug: string,
  compareTrayItems?: Comparison[]
) => {
  const [cookie, setCookie] = useCookie(cookieStorageName)

  const updateCookie = useCallback(() => {
    const cookieData = (cookie && JSON.parse(cookie)) || {}
    if (compareTrayItems?.length) {
      cookieData[taxonomySlug] = compareTrayItems.map(({ slug }) => slug)
    } else {
      delete cookieData[taxonomySlug]
    }
    setCookie(JSON.stringify(cookieData))
  }, [compareTrayItems, cookie, setCookie, taxonomySlug])

  return { cookie, updateCookie }
}
