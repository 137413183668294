import type { FunctionComponent, PropsWithChildren } from 'react'
import { Grid, GridItem, Picture } from '@which/seatbelt'

import classNames from 'classnames'

import type { ImageWithSources } from '../../../../../../generated/frontend'
import styles from './ProductHubGridLayout.module.scss'

export const ProductHubGridLayout: FunctionComponent<
  PropsWithChildren<ProductHubGridLayoutProps>
> = ({ featuredImage, children, imageOnLeft = false }) => (
  <>
    {featuredImage ? (
      <Grid
        className={styles.sectionWithDivider}
        includeGutters={false}
        data-testid="product-hub-grid-layout"
      >
        <GridItem
          span={{ medium: 6, large: 6 }}
          columnStart={{ medium: 1, large: 1 }}
          className={classNames({
            [styles.pictureWrapper]: imageOnLeft,
            [styles.textWrapper]: !imageOnLeft,
          })}
        >
          {imageOnLeft ? (
            <Picture {...featuredImage} className={styles.sectionFeaturedImage} />
          ) : (
            children
          )}
        </GridItem>
        <GridItem
          span={{ medium: 6, large: 6 }}
          columnStart={{ medium: 7, large: 7 }}
          className={classNames({
            [styles.pictureWrapper]: !imageOnLeft,
            [styles.textWrapper]: imageOnLeft,
          })}
        >
          {imageOnLeft ? (
            children
          ) : (
            <Picture {...featuredImage} className={styles.sectionFeaturedImage} />
          )}
        </GridItem>
      </Grid>
    ) : (
      <Grid
        className={styles.sectionWithDivider}
        includeGutters={false}
        data-testid="product-hub-grid-layout"
      >
        <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
          {children}
        </GridItem>
      </Grid>
    )}
  </>
)

///////// IMPLEMENTATION /////////

export type ProductHubGridLayoutProps = {
  featuredImage?: ImageWithSources
  imageOnLeft?: boolean
}
