import type { FunctionComponent } from 'react'
import React from 'react'
import { CardRow, MultiLinkCard } from '@which/seatbelt'

export const PopularArticles: FunctionComponent<Props> = ({ title, articlesList }) => {
  if (articlesList.length === 0) {
    return null
  }

  return (
    <CardRow title={title}>
      {articlesList.map(({ title: multiLinkTitle, image, articles }, index) => (
        <MultiLinkCard
          key={`${multiLinkTitle}-${index}`}
          title={multiLinkTitle}
          display="scroll-row"
          image={image.src}
          imageSources={image.sources}
          imageAlt={multiLinkTitle}
          links={articles.map(({ title: text, href }) => ({
            text,
            href,
            type: 'standard',
          }))}
        />
      ))}
    </CardRow>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  articlesList: Array<{
    title: string
    image: {
      src: string
      sources: Array<{
        srcset: string
        sizes: string
      }>
    }
    articles: Array<{
      title: string
      href: string
    }>
  }>
}
