import React from 'react'
import { ArticleCard, CardRow, TypographyV2 as Typography } from '@which/seatbelt'

import type { LatestNews } from '../../../generated/frontend'

export type LatestNewsCardsProps = {
  name?: string
  articles: LatestNews['articles']
  viewAllData?: {
    text: string
    href: string
  }
  includeTextIntro?: boolean
  whichId?: string
  className?: string
}

export const LatestNewsCards: React.FC<LatestNewsCardsProps> = ({
  name,
  viewAllData,
  whichId,
  includeTextIntro = true,
  className,
  articles = [],
}) => {
  if (articles.length === 0) {
    return null
  }

  return (
    <CardRow
      title={
        <>
          {includeTextIntro && (
            <Typography textStyle="sb-text-heading-overline">Latest News In</Typography>
          )}
          {name}
        </>
      }
      text={viewAllData?.text ?? ''}
      link={{
        text: viewAllData?.text ?? '',
        href: viewAllData?.href ?? '',
      }}
      className={className}
      data-which-id={whichId}
    >
      {articles.map(({ title, href, imageWithSources, dateInfo, authors = [] }, index) => (
        <ArticleCard
          key={`${title}-${index}`}
          href={href ?? ''}
          title={title}
          image={imageWithSources?.src}
          imageSources={imageWithSources?.sources}
          imageAlt={imageWithSources?.alt}
          authorName={authors[0]?.name}
          authorImage={authors[0]?.image?.src}
          authorImageSources={authors[0]?.image?.sources}
          date={dateInfo?.rendered}
          display="scroll-row"
        />
      ))}
    </CardRow>
  )
}
