import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import classnames from 'classnames'

export const PageTemplateSimple: FunctionComponent<Props> = ({
  children,
  metaTags,
  includePageWrapper = true,
  className,
}) => {
  return (
    <>
      {/* Using index as key since meta tag order is not important */}
      <Helmet>{metaTags?.map((metaTag, idx) => React.cloneElement(metaTag, { key: idx }))}</Helmet>
      <main
        id="main-content"
        className={classnames(includePageWrapper && 'w-page-wrapper', className)}
      >
        {children}
      </main>
    </>
  )
}

///////// IMPLEMENTATION /////////

interface Props {
  metaTags?: ReactElement[]
  includePageWrapper?: boolean
  className?: string
  children: ReactElement
}
