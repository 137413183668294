import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import styles from './TopBanner.module.scss'

type Text = {
  text: string
}

export type BannerConfig = Record<string, Text> | null

interface Props {
  bannerConfig: BannerConfig
  categorySlug: string | null
  fullWidth?: boolean
}

export const TopBanner: React.FC<Props> = ({ bannerConfig, categorySlug, fullWidth = false }) => {
  if (!bannerConfig || !categorySlug) {
    return null
  }

  const bannerText = bannerConfig[categorySlug]?.text ?? bannerConfig['others']?.text

  return (
    <section className="w-page-wrapper">
      <Typography
        className={classNames(styles.banner, { [styles.bannerFullWidth]: fullWidth })}
        dangerouslySetInnerHTML={{
          __html: bannerText,
        }}
        data-testid="banner-text"
        textStyle="sb-text-interface-body-small-strong"
      />
    </section>
  )
}
