import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, SectionHeader, ToolSignpost } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './CampaignSignpost.module.scss'

export const CampaignSignpost: FunctionComponent<Props> = ({
  header = 'SIGN OUR PETITION',
  title,
  button,
  className,
  description,
  image,
  widgetImageAspectRatio,
}) => (
  <Grid data-testid="campaign-signpost" className={classnames('w-page-wrapper')}>
    <GridItem
      span={{ small: 2, medium: 8, large: 8 }}
      columnStart={{ small: 1, medium: 3, large: 3 }}
    >
      <SectionHeader className={styles.campaignSignpostHeader} text={header} />
      <ToolSignpost
        button={button}
        className={className}
        description={description}
        image={image}
        title={title}
        imageAspectRatio={aspectRatioMap[widgetImageAspectRatio]}
      />
    </GridItem>
  </Grid>
)

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  widgetImageAspectRatio: WidgetImageAspectRatio
  button: {
    text: string
    href: string
  }
  className?: string
  description?: string
  header?: string
  image?: {
    src: string
    alt: string
  }
}

type WidgetImageAspectRatio = 'ar_1to1_' | 'ar_2to1_'

const aspectRatioMap = {
  ar_1to1_: 'one-to-one',
  ar_2to1_: 'two-to-one',
} as const
