export const createSlugGenerator = () => {
  const slugs = new Set();
  const createSlug = function (textToSlugify) {
    let preserveHyphensInSlugs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const create = (text, preserveHyphens, attempt) => {
      const textWithSuffix = attempt ? `${text} ${attempt}` : text;
      const slug = preserveHyphens ? textWithSuffix
      // remove non-alphanumerics except spaces and hyphens
      .replace(/[^a-z0-9 -]/gi, '')
      // trim whitespace from beginning and end
      .trim()
      // replace single and multiple spaces or hyphens with a single hyphen
      .replace(/(\s|-)+/g, '-')
      // make lowercase
      .toLowerCase() : textWithSuffix
      // remove non-alphanumerics except spaces
      .replace(/[^a-z0-9 ]/gi, '')
      // remove numerics from start of string
      .replace(/^[0-9]+/g, '')
      // trim whitespace from beginning and end
      .trim()
      // replace double whitespaces with single spaces
      .replace(/\s{2,}/g, ' ')
      // replace single spaces with hyphens
      .replace(/ /g, '-')
      // make lowercase
      .toLowerCase();
      if (slugs.has(slug)) {
        const nextAttempt = attempt == null ? 2 : attempt + 1;
        return create(text, preserveHyphens, nextAttempt);
      }
      slugs.add(slug);
      return slug;
    };
    return create(textToSlugify, preserveHyphensInSlugs);
  };
  return createSlug;
};