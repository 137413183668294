import { getCookie } from '@which/shared'

export const getBlaize = (): string => {
  if (typeof window === 'undefined') {
    return ''
  } else {
    if (window.location?.hostname === 'localhost') {
      return process.env.DEV_BLAIZE_SESSION || ''
    }
    return getCookie(document.cookie, 'blaize_session')
  }
}
