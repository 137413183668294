import type { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useProductHubPageQuery } from '../../generated/frontend'
import type { PageParams } from '../../routes'
import {
  ErrorComponent,
  Loader,
  PageError,
  PageTemplateSimple,
  renderResubscribeBanner,
} from '../../shared'
import { ProductHubPageTemplate } from './components/ProductHubPageTemplate'
import ProductHub from './HardcodedProductHub'
import { useProductHubPageMetaTags } from './hooks/useProductHubPageMetaTags'

const ProductHubPage: FunctionComponent = () => {
  const { getMetaTags } = useProductHubPageMetaTags()
  const { subVerticalSlug, productSlug } = useParams<PageParams>()
  const { loading, error, data } = useProductHubPageQuery({
    variables: { subVerticalSlug, productSlug },
  })
  const resubBannerFlag = useFeatureIsOn('resubscribe-banner')

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data?.productHubPage) {
    return <PageError pageName="Product Hub" />
  } else if (!data.productHubPage.productHubEnabled) {
    const { productHubPage } = data
    const { isLoggedIn, products, transformTypeDecision, showResubBanner } = productHubPage
    // Route to hardcoded product hub template if Glide checkbox doesn't exist
    // TODO: delete once product hub pages are fully Glide-driven
    return (
      <ProductHub
        isLoggedIn={isLoggedIn}
        products={products}
        transformTypeDecision={transformTypeDecision}
        showResubBanner={showResubBanner}
      />
    )
  }

  const { productHubPage } = data

  const {
    transformTypeDecision,
    showResubBanner,
    isLoggedIn,
    breadcrumb,
    header,
    productReviews,
    helpAndAdvice,
  } = productHubPage

  return (
    <PageTemplateSimple
      metaTags={getMetaTags({ meta: productHubPage.meta })}
      includePageWrapper={false}
    >
      {renderResubscribeBanner(resubBannerFlag, transformTypeDecision, showResubBanner)}
      <ProductHubPageTemplate
        isLoggedIn={isLoggedIn}
        productReviews={productReviews}
        helpAndAdvice={helpAndAdvice}
        breadcrumb={breadcrumb}
        header={header}
      />
    </PageTemplateSimple>
  )
}

export default ProductHubPage
