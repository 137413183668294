import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { Link } from '../../Link'
import styles from './TopRated.module.scss'

export const TopRated: FunctionComponent<Props> = ({ link }) => {
  const handleClick = () => {
    dynamicDatalayerPush({
      eventCategory: 'ab test',
      eventAction: 'WHI302',
      eventLabel: 'View all reviews link',
    })
  }

  return (
    <div
      className={classnames(
        styles.wrapper,
        link ? styles.topRatedVariantTwo2366 : styles.topRatedVariantOne2366,
        link ? 'topRatedVariantTwo2366' : 'topRatedVariantOne2366'
      )}
    >
      <Typography tag="h4" textStyle="sb-text-body-small-regular">
        Top rated
      </Typography>
      {link && (
        // TODO: set aria-hidden to false via optimise
        <Link
          href={link}
          onClick={handleClick}
          className={styles.link}
          textStyle="sb-text-interface-body-small-regular"
          data-which-id="top-rated-link"
          aria-hidden="true"
        >
          <Typography tag="span" textStyle="sb-text-body-small-regular">
            View all reviews
          </Typography>
        </Link>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  link?: string
}
