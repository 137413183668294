import type { FunctionComponent } from 'react'
import React from 'react'
import { Blockquote as SBBlockquote } from '@which/seatbelt'

export const Blockquote: FunctionComponent<Props> = ({ text, caption, quotes }) => (
  <SBBlockquote text={text} caption={caption} quotes={quotes} />
)

///////// IMPLEMENTATION /////////

type Props = {
  text: string
  caption?: string
  quotes: boolean
}
