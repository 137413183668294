import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'

import { loadScript } from '../../../shared/utils/loadScript'

export const Facebook: FunctionComponent<Props> = ({ url }) => {
  useEffect(() => {
    loadScript({
      src: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2',
    })
  }, [])

  return (
    <section className="embed-facebook">
      <div className="fb-post" data-href={url} data-testid="facebook-iframe"></div>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  url: string
}
