import type { FunctionComponent } from 'react'
import { Grid } from '@which/seatbelt'

import classnames from 'classnames'

import { GridArticle, GridItemArticle } from '../../grids/article'
import styles from './ArticleGrid.module.scss'

const gridStylesMapper = {
  'Article News Single Page': styles.newsGrid,
}

export const getArticleGrid = ({
  tagName = '',
  className = '',
  templateName = '',
}: GetArticleGridParams): ArticleGridComponent => {
  if (tagName.startsWith('griditemarticle')) {
    const area = tagName.slice(tagName.indexOf('-') + 1)

    return {
      componentToRender: GridItemArticle,
      extraProps: {
        area,
        className: classnames(className, {
          [`gridarea-${area}`]: templateName !== 'Article Policy and Insight Single Page',
        }),
      },
    }
  }

  if (tagName === 'grid') {
    return {
      componentToRender: Grid,
      extraProps: { className },
    }
  }

  if (tagName === 'gridarticle') {
    return {
      componentToRender: GridArticle,
      extraProps: {
        className: classnames(className, gridStylesMapper[templateName]),
        templateName: templateName,
      },
    }
  }

  return {
    componentToRender: tagName || 'div',
    extraProps: { className },
  }
}

///////// IMPLEMENTATION /////////

type ArticleGridComponent = {
  componentToRender: FunctionComponent<any> | string
  extraProps?: {
    area?: string
    className?: string
    templateName?: string
  }
}

type GetArticleGridParams = {
  tagName?: string
  className?: string
  templateName?: string
}
