import type { FunctionComponent } from 'react'
import React from 'react'
import { Share } from '@which/seatbelt'

import classnames from 'classnames'

import type { SharingData } from '../../../generated/frontend'
import styles from './SharingOptions.module.scss'

export const SharingOptions: FunctionComponent<SharingProps> = ({
  sharingData,
  orientation = 'horizontal',
  className,
  showWhatsapp = false,
}) => {
  if (!sharingData) {
    return null
  }

  const {
    facebook,
    twitter,
    whatsapp,
    mail,
    metaData: { url, headline, fbAppId, subject, facebookText, twitterText },
  } = sharingData

  const isVertical = orientation === 'vertical'
  const orientationId = orientation.slice(0, 1).toUpperCase() + orientation.slice(1)

  return (
    <div
      className={classnames(
        `sharing-options-${orientation}`,
        isVertical ? styles.sharingOptionsVertical : styles.sharingOptionsHorizontal,
        className
      )}
      data-testid="sharing-options-wrapper"
      data-which-component={`sharingOptions${orientationId}`}
      data-headline={headline}
      data-url={url}
      data-fbappid={fbAppId}
      data-twitter-text={twitterText}
      data-facebook-text={facebookText}
      data-email-subject={subject}
    >
      <Share
        location={isVertical ? 'bottom' : 'top'}
        headline=""
        orientation={orientation}
        links={{
          facebook,
          twitter,
          mail,
          ...((!isVertical || showWhatsapp) && { whatsapp }),
        }}
        data-testid={`sharing-options-${orientation}`}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type SharingProps = {
  sharingData: SharingData
  orientation?: 'horizontal' | 'vertical'
  className?: string
  showWhatsapp?: boolean
}
