import type { FunctionComponent } from 'react'
import React from 'react'
import type { BadgeProps } from '@which/seatbelt'
import { FeaturedArticleCard as SBFeaturedArticleCard } from '@which/seatbelt'

import type { NewsCard } from '../../../generated/frontend'
import styles from './FeaturedArticleCard.module.scss'

export const FeaturedArticleCard: FunctionComponent<Props> = ({
  article,
  badge,
  className,
  lazyLoad = true,
}) => {
  if (!article) {
    return null
  }

  const articleProps = {
    href: article.url,
    title: article.heading,
    standfirst: article.summary,
    date: article.date.rendered,
    dateTime: article.date.iso,
    image: {
      ...article.image,
      lazyLoad,
    },
  }

  return (
    <div data-testid="featured-article-card-wrapper" className={className}>
      <SBFeaturedArticleCard
        article={articleProps}
        badge={badge}
        className={styles.featuredArticleCard}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type Props = {
  article?: NewsCard
  badge: BadgeProps
  className?: string
  lazyLoad?: boolean
}
