import { Link, Typography } from '@which/seatbelt'

import styles from './SurveyLink.module.scss'

export const SurveyLink = ({ variationName }: { variationName: string }) => {
  return (
    <Typography textStyle="sb-text-body-default-regular" className={styles.feedbackSurvey}>
      This is a new feature. Help us improve these recommendations by{' '}
      <Link
        href={`https://which.qualtrics.com/jfe/form/SV_dh9TZ6UW2uJ0EKi?variation=${variationName}`}
      >
        giving your feedback
      </Link>
    </Typography>
  )
}
