import type { FunctionComponent } from 'react'
import React from 'react'
import { ProsCons } from '@which/seatbelt'

import styles from './ProsConsList.module.scss'

export const ProsConsList: FunctionComponent<Props> = ({ list, type }) => {
  if (!list?.length) {
    return null
  }

  return <ProsCons type={type} list={list} displayCount={false} className={styles.prosConsList} />
}

///////// IMPLEMENTATION /////////

type Props = {
  list: string[]
  type: 'pros' | 'cons'
}
