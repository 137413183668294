import type { ArticleElement, TableBodyContent } from '../CsvTable'
import type { ExtendElementPropsArgs, TableData } from '../CsvTableUnlocked'

export const getCellAlignment = (bodyRowData: string | ArticleElement) => {
  if (typeof bodyRowData === 'string') {
    return 'auto'
  }

  return bodyRowData?.props?.['data-center-align'] === true ? 'centre' : 'auto'
}

export const isValidRowItem = (rowItem: TableBodyContent) => {
  if (!rowItem) {
    return false
  }

  if (typeof rowItem !== 'string') {
    return Boolean(rowItem.innerText) || Boolean(rowItem.props)
  }

  return true
}

export const getInitialTableData = (tableData: TableData) => {
  const { headings, tableBody, columnDataTypes } = tableData

  const indexedHeadings = headings?.reduce((headingData, heading, index) => {
    return {
      ...headingData,
      [index]: {
        value: heading,
        columnDataType: columnDataTypes[index],
        sortDirection: 'default',
      },
    }
  }, {})

  return {
    headings: indexedHeadings,
    sortedTableBody: tableBody,
  }
}

export const getVariantSettings = (
  testVariant: string | undefined,
  tableContent: TableBodyContent[][]
) => {
  if (!tableContent.length || !testVariant) {
    return {
      colSpan: 1,
      rowSpan: 1,
    }
  }

  // Assuming all rows have same cells length
  const [singleRow] = tableContent

  const columnsLength = singleRow.length
  const rowsLength = tableContent.length

  const settingsMapper = {
    scoresLocked: {
      colSpan: columnsLength - 1,
      rowSpan: rowsLength,
    },
    providersLocked: {
      colSpan: 1,
      rowSpan: rowsLength,
    },
  }

  return settingsMapper[testVariant]
}

export const extendElementProps = ({
  bodyRowData,
  rowIndex,
  bodyContent,
  cellIndex,
  row,
}: ExtendElementPropsArgs) => [
  {
    ...bodyRowData,
    props: {
      ...bodyRowData.props,
      isTooltipDisplayedAboveOverride: tooltipWillDisplayAbove(rowIndex, bodyContent),
      tooltipAlignmentOverride: selectHorizontalAlignment(cellIndex, row),
    },
  },
]

const tooltipWillDisplayAbove = (index: number, bodyContent: TableBodyContent[][]) =>
  index + 1 > bodyContent.length / 2

const selectHorizontalAlignment = (index: number, rowData: TableBodyContent[]) => {
  if (index === 0) {
    return 'left'
  }

  if (index + 1 === rowData.length) {
    return 'right'
  }

  return undefined
}
