export const getTaxonomyException = categoryName => exceptions.get(categoryName);

///////// IMPLEMENTATION /////////

const exceptions = new Map();
exceptions.set('Headphones', 'Headphones');
exceptions.set('Baby carriers and baby slings', 'Baby carrier and baby sling');
exceptions.set('Batteries', 'Batteries');
exceptions.set('Dishwasher tablets', 'Dishwasher tablets');
exceptions.set('Grass trimmers and strimmers', 'Grass trimmer and strimmer');
exceptions.set('Nappies', 'Nappies');
exceptions.set('PVRs & set-top boxes', 'PVR & set-top box');
exceptions.set('PVRs and set-top boxes', 'PVR and set-top box');
exceptions.set('Rechargeable batteries', 'Rechargeable batteries');
exceptions.set('Record players and turntables', 'Record player and turntable');
exceptions.set('Smart radiator valves', 'Smart radiator valve');
exceptions.set('Wi-fi routers and extenders', 'Wi-fi router and extender');