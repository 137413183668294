import React, { useState } from 'react'
import { Heading } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Link as LinkType } from '../../../generated/frontend'
import { Link } from '../Link'
import { MoreButton } from '../MoreButton'
import type { Props } from './AssociatedArticles'
import styles from './AssociatedArticlesWithShowMore.module.scss'

const itemClickHandler = (event: string, text: string, position: string) =>
  dynamicGa4DataLayerPush({
    event: event,
    item_text: text,
    item_parent_text: 'Broadband provider reviews',
    item_spec: position,
    item_group: 'streamline cta',
    utagid: 'PNJ192DP01',
  })

const ListItem: React.FC<LinkType & { onClick: (articleUrl: string) => void }> = ({
  text,
  href,
  onClick,
}) => (
  <li className={styles.listItem}>
    <Link
      href={href}
      onClick={onClick}
      linkStyle="secondary"
      type="NavigationLink"
      data-testid="associated-article-link"
    >
      {text}
    </Link>
  </li>
)

export const AssociatedArticlesWithShowMore: React.FC<Props> = ({
  links,
  title,
  position,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  if (!links?.length || !title) {
    return null
  }

  const maxArticlesShown = 6

  return (
    <div
      className={classnames('associated-articles', className)}
      data-testid="associated-article-links-wrapper"
    >
      {title && <Heading heading={title} headingTag="h2" headingType="small" />}

      <div className={styles.showMoreWrapper} data-testid="show-more">
        <ul
          className={classnames(styles.list, isExpanded ? styles.listExpanded : styles.listClosed)}
        >
          {links.map(({ href, text }) => (
            <ListItem
              key={`${text}-${href}`}
              href={href}
              text={text}
              onClick={() => itemClickHandler('click_cta', text, position)}
            />
          ))}
        </ul>
        <MoreButton
          align="center"
          buttonLabel="Show more"
          className={styles.showMore}
          hideButton={links.length <= maxArticlesShown || isExpanded}
          onClick={() => {
            setIsExpanded(true)
            itemClickHandler('click_button', 'Show more', position)
          }}
        />
      </div>
    </div>
  )
}
