import type { FunctionComponent } from 'react'
import { Heading, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { Author } from '../../../../../../generated/frontend'
import { ContentCardWithLink } from '../../../ContentCardWithLink/ContentCardWithLink'
import { Fact } from '../../../ProductHubAdvice/Fact/Fact'
import styles from './ProductHubCTACards.module.scss'

export const ProductHubCTACards: FunctionComponent<ProductHubCTACardsProps> = ({
  heading,
  cards,
  border = false,
  description,
  expertInfo,
}) => (
  <section
    aria-label={heading}
    data-testid="product-types"
    className={classnames({
      [styles.sectionWithBorder]: border,
    })}
    id={heading.toLowerCase().replaceAll(' ', '-')}
  >
    <Heading heading={heading} headingTag="h2" headingType="large" />

    {expertInfo && (
      <div className={styles.expertWrapper}>
        <Fact
          body={expertInfo.body}
          title={expertInfo.title}
          author={expertInfo.author}
          headingTextStyle="sb-text-heading-standfirst"
          descriptionClassName={styles.authorDescription}
        />
      </div>
    )}

    {description && (
      <Typography
        textStyle="sb-text-body-default-regular"
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}

    <div className={styles.cards}>
      {cards.map((card, index) => (
        <div className={styles.cardWrapper} key={card.heading}>
          <ContentCardWithLink
            sectionTitle={heading}
            title={card.heading}
            description={card.description}
            primaryLinkHref={card.primaryLinkHref}
            primaryLinkTracking={{ dataIndex: index + 1 }}
            secondaryLink={
              card.secondaryLink
                ? {
                    ...card.secondaryLink,
                    dataIndex: index,
                  }
                : undefined
            }
          />
        </div>
      ))}
    </div>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubCTACardsProps = {
  heading: string
  description?: string
  cards: ProductHubCardProps[]
  border?: boolean
  expertInfo?: {
    author: Author
    title: string
    body: string
  }
}

type ProductHubCardProps = {
  heading: string
  description: string
  primaryLinkHref?: string
  secondaryLink?: {
    contentHtmlId: string
    href: string
    label: string
  }
}
