import type { ProductHubPageQuery } from '../../../generated/frontend'
import { useBackStack } from '../../../shared/BrowserBackStackProvider'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { getDFPScripts } from '../../../shared/dfp'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { PageInfo } from '../../../shared/types'
import { removeFalsy, removeUrlQueryStringAndFragment } from '../../../shared/utils'
import { getPreviousUrl } from '../../../shared/utils/getPreviousUrl/getPreviousUrl'

export const useProductHubPageMetaTags = () => {
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useDataLayerItems()
  const { getFullUrl } = useFullUrl()

  return {
    getMetaTags: ({
      meta,
      imageUrl,
      imageAlt,
    }: {
      meta: Meta
      imageUrl?: string
      imageAlt?: string
    }) => [
      ...getBaseMetaTags({ meta, imageUrl, imageAlt, twitterCard: 'summary_large_image' }),
      ...getDataLayerItems(meta, getFullUrl()),
    ],
  }
}

///////// IMPLEMENTATION /////////

type Meta = ProductHubPageQuery['productHubPage']['meta']

const useDataLayerItems = () => {
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack: backStack })

  return {
    getDataLayerItems(meta: Meta, fullUrl: string) {
      const { dataLayer = [] } = meta || {}
      const pageInfo: PageInfo = {
        vertical: meta ? meta.taxonomyHierarchy.vertical.slug : '',
        sub_vertical: meta ? meta.taxonomyHierarchy.subVertical.slug : '',
        category: meta ? meta.taxonomyHierarchy.category.slug : '',
        category_id: meta
          ? meta.taxonomyHierarchy.category.additional_items?.custom_taxonomy_configuration
              ?.meta_data?.akeneo_code
          : '',
        content_type: 'product-hub',
        wcdPageUrl: removeUrlQueryStringAndFragment(fullUrl),
        ...(previousUrl && { previousWcdPageUrl: previousUrl }),
      }

      return [
        ...getDataLayerScripts([...dataLayer, removeFalsy(pageInfo)]),
        ...getDFPScripts(dataLayer, pageInfo),
      ]
    },
  }
}
