import React from 'react'

export const article = (
  <path
    data-testid="svg-article"
    fill="#2196f3"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15s15-6.7,15-15C30,6.7,23.3,0,15,0z M8.7,21.4V6.5h10.4v14.9H8.7z M20,22.3V8.6h1.2v14.9H10.8v-1.2H20z"
  />
)
