import type { FunctionComponent } from 'react'
import React from 'react'
import type { AlertTypes } from '@which/seatbelt'
import { AlertBadge, Picture, PlaceholderImage } from '@which/seatbelt'

import type { ProductDetail } from '../../../../../../generated/frontend'
import styles from './ProductDetailThumbnail.module.scss'

export const ProductDetailThumbnail: FunctionComponent<ProductDetailThumbnailProps> = ({
  image,
  alertType,
}) => (
  <div className={styles.wrapper}>
    <AlertBadge type={alertType as AlertTypes} className={styles.safetyAlert} />

    {image?.src ? (
      <Picture {...image} className={styles.image} />
    ) : (
      <PlaceholderImage className={styles.image} />
    )}
  </div>
)

export type ProductDetailThumbnailProps = {
  image?: ProductDetail['image']
  alertType?: ProductDetail['alertType']
}
