export type GetInstanceConfig = {
  /** When Growthbook fails to initialize, this function is called. Use for custom error reporting/logging. */
  errorCallback?: (err: Error) => void
  /** Growthbook clientKey override - optional; will use prod if undefined */
  clientKey?: string
  /** Growthbook apiHost override - optional; will use prod if undefined */
  apiHost?: string
  /** Growthbook enableDevMode override - optional; will use false by default */
  enableDevMode?: boolean
  /** Growthbook nonce for visual Editor scripts - Optional nonce for the script tag */
  jsInjectionNonce?: string
}

export const defaultGbHost = 'https://cdn.growthbook.io' as const
export const defaultSdkKey = 'sdk-HiXC19gXofXiYsT' as const
