export const getTaggedAmazonUrl = (url, tag) => {
  const isAmazonLinkMissingValidTag = new RegExp(`^(?!.*(?:[?&]tag=${tag}(?:&|$)))(https?:\/\/(?:www\.)?amazon\.(?:com|co\.uk)\/(?:[^/?#]*\/)?[^/?#]+)`).test(url);
  if (isAmazonLinkMissingValidTag) {
    try {
      const amazonUrl = new URL(url);
      amazonUrl.searchParams.set('tag', tag);
      return amazonUrl.toString();
      /* v8 ignore next 3 */
    } catch {
      return url;
    }
  }
  return url;
};