export const hasValidCRToolEntryOptions = (options) => {
  if (!options) {
    return false
  }
  for (const option of options) {
    if (
      !option.hasOwnProperty('label') ||
      !option.hasOwnProperty('value') ||
      !option.hasOwnProperty('link')
    ) {
      return false
    }
  }
  return true
}
