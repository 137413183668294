import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, TypographyV2 as Typography } from '@which/seatbelt'
import { PadlockIcon, SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous'

import classnames from 'classnames'

import { Link } from '../Link'
import styles from './SinglePadlockCta.module.scss'

export const SinglePadlockCta: FunctionComponent<Props> = ({ type }) => (
  <div className={classnames(styles.ctaWrapper, type === 'desktop' && styles['ctaWrapperDesktop'])}>
    <div className={styles.icon}>
      <PadlockIcon />
    </div>
    <div className={styles.ctaContent}>
      <Typography textStyle="sb-text-heading-small">Sign up to reveal</Typography>
      <Typography textStyle="sb-text-body-small-regular" className={styles.contentWrapper}>
        Get instant access to this and all our scores and recommendations.
      </Typography>

      <ButtonLink
        appearance="secondary"
        href="https://join.which.co.uk/join/subscribe"
        className={styles.cta}
        data-which-id="nstunlockedprovider-signup-cta"
      >
        <SolidPadlockIcon width={15} height={15} viewBox="0 0 15 15" />
        Unlock table
      </ButtonLink>

      <Typography textStyle="sb-text-body-small-regular">
        First month £5, then £11.99 per month, cancel at any time
      </Typography>
      <Typography textStyle="sb-text-body-default-regular">
        <span>Already a member? </span>
        <Link href="/login">Log in</Link>
      </Typography>
    </div>
  </div>
)

type Props = {
  type?: string
}
