import type { FunctionComponent } from 'react'
import {
  type AspectRatio,
  CardContainer,
  type ImageSource,
  Picture,
  type Rest,
  TypographyV2,
} from '@which/seatbelt'

import styles from './AdviceCard.module.scss'

export const AdviceCard: FunctionComponent<AdviceCardProps> = ({
  title,
  subheading,
  primaryLink,
  trackingData,
  imgObj,
  ariaLabel,
  large = false,
}) => {
  return (
    <CardContainer
      arrangement="horizontal"
      primaryLink={primaryLink}
      trackingData={trackingData}
      ariaLabel={ariaLabel}
    >
      <div className={large ? styles.adviceCardLarge : styles.adviceCardSmall}>
        {imgObj?.str && (
          <div className={styles.cardImg} data-testid="advice-card-image">
            <Picture
              alt={imgObj.imageAlt}
              aspectRatioMain="four-to-three"
              lazyLoad={imgObj.lazyLoad}
              sources={imgObj.imageSources}
              src={imgObj.str}
            />
          </div>
        )}
        <div>
          <div>
            <TypographyV2
              tag="h3"
              className={styles.title}
              textStyle={large ? 'sb-text-interface-heading-large' : 'sb-text-heading-small'}
            >
              {title}
            </TypographyV2>
          </div>
          <div>
            <TypographyV2
              tag="span"
              textStyle="sb-text-body-default-regular"
              className={styles.adviceCardDescription}
            >
              {subheading}
            </TypographyV2>
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

type AdviceCardProps = {
  title: string
  subheading: string
  primaryLink: string
  imgObj?: {
    imageAlt: string
    imageSources?: Sources[]
    lazyLoad?: boolean
    str: string
    aspectRatioMain?: AspectRatio
  } | null
  trackingData: Record<string, string>
  ariaLabel: string
  large?: boolean
}

type Sources = ImageSource & Rest
