export const loadScript = ({
  src,
  async = false,
  defer = true,
  callback = undefined,
}: ScriptOptions) => {
  if (!isScriptLoaded(src)) {
    const script = document.createElement('script')

    script.src = src
    script.async = async
    script.defer = defer

    script.onerror = () => new Error(`Failed loading script ${src}`)

    if (callback) {
      script.onload = () => callback()
    }

    document.body.appendChild(script)
  }
}

export const isScriptLoaded = (src: string) =>
  document.querySelector(`script[src="${src}"]`) ? true : false

export type ScriptOptions = {
  src: string
  async?: boolean
  defer?: boolean
  callback?: () => void
}
