import React from 'react'

export const whatsapp = (
  <g data-testid="svg-whatsapp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <circle fill="#25D366" cx="16" cy="16" r="16"></circle>
            <g transform="translate(7.000000, 7.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M15.3700389,2.63496105 C13.6735857,0.93691395 11.4163369,0.0017577 9.0114172,0 C4.05566515,0 0.0210957869,4.03066395 0.0193379172,8.9859375 C0.0193379172,10.5697264 0.432463857,12.1166014 1.2200399,13.4789062 L0,18 L4.70963454,16.8873048 C6.02284694,17.6027346 7.50131035,17.980664 9.00614315,17.980664 L9.00965889,17.980664 C13.9654109,17.980664 17.9982224,13.9482423 18,8.99472645 C18.003496,6.5917971 17.0682495,4.3347654 15.3700389,2.63496105 Z M13.4098886,12.1992188 C13.2217843,12.7230471 12.3058757,13.2292971 11.8927498,13.2662111 C11.4813821,13.3031906 11.0946261,13.452539 9.1977631,12.7037111 C6.91414405,11.8037111 5.47084074,9.4640625 5.36008775,9.31464855 C5.24757688,9.16347645 4.44242124,8.0964846 4.44242124,6.9908202 C4.44242124,5.88515625 5.02255516,5.3419923 5.22999729,5.11875 C5.4356811,4.89375 5.67828287,4.8375 5.82946912,4.8375 C5.9788975,4.8375 6.12832633,4.8375 6.25841679,4.84277355 C6.42015115,4.8498048 6.59594938,4.85683605 6.76471567,5.23125 C6.96512542,5.67597645 7.40110502,6.78515625 7.45736045,6.89765625 C7.51361588,7.01015625 7.55053385,7.1419923 7.47669837,7.29140625 C7.40110502,7.4408202 7.36418751,7.5339846 7.25167664,7.6658202 C7.13916578,7.79765625 7.01610725,7.959375 6.91590237,8.0595702 C6.80339151,8.1703125 6.68560658,8.2933596 6.81569705,8.5183596 C6.94754583,8.7433596 7.39758928,9.478125 8.06562253,10.0722654 C8.92351787,10.8386721 9.64780655,11.0742188 9.87282828,11.1884765 C10.09785,11.3009765 10.2279405,11.2816404 10.3597892,11.1304688 C10.4898802,10.9810548 10.9205857,10.4765625 11.0700141,10.2515625 C11.2194429,10.0265625 11.3706292,10.0634765 11.576313,10.1390625 C11.7819972,10.2128904 12.8860096,10.7560548 13.1110313,10.8685548 C13.3360531,10.9810548 13.4854819,11.0373048 13.5417373,11.1304688 C13.5962349,11.2236327 13.5962349,11.6736327 13.4098886,12.1992188 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
)
