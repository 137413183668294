import { regexValidator } from '../utils/regex-validator'
import { useFullUrl } from './useFullUrl'

export const useRegex = function useRegex() {
  const { getFullUrl } = useFullUrl()

  return {
    isCRurl() {
      const fullUrl = getFullUrl()
      return regexValidator.urlCR.test(fullUrl)
    },
    getQueryParamValue(str: string) {
      return str.match(regexValidator.getQueryParamValue)
    },
  }
}
