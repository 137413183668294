import { GrowthBook } from '@growthbook/growthbook'

/**
 * Given an Growthbook Instance, retrieves the experimentation features and attributes from instance.
 * These features and attributes are serialized into a JSON string and returned within a script tag
 *
 * @param {GrowthBook} gbInstance growthbook Instance.
 * @param {string} nonce - Optional nonce for the script tag.
 */
export const getExperimentsSsrScriptTag = async (
  gbInstance: GrowthBook,
  nonce?: string
) => {
  const experimentationFeatures = gbInstance?.getFeatures() || {}
  const experimentationAttributes = gbInstance?.getAttributes() || {}
  const experimentationExperiments = gbInstance?.getExperiments() || []

  const nonceAttribute = nonce ? ` nonce="${nonce}"` : ''

  const ssrScriptTag = `<script${nonceAttribute}>
        window.__EXPERIMENTATION_FEATURES__=${JSON.stringify(
          JSON.stringify(experimentationFeatures)
        ).replace(/</g, '\\u003c')};
        window.__EXPERIMENTATION_EXPERIMENTS__=${JSON.stringify(
          JSON.stringify(experimentationExperiments)
        ).replace(/</g, '\\u003c')};
        window.__EXPERIMENTATION_ATTRIBUTES__=${JSON.stringify(
          JSON.stringify(experimentationAttributes)
        ).replace(/</g, '\\u003c')};
    </script>`

  return ssrScriptTag
}
