import type { FunctionComponent } from 'react'
import React from 'react'

import styles from './PreviewIndicator.module.scss'

export const PreviewIndicator: FunctionComponent<Props> = ({ preview }) => {
  if (!preview) {
    return null
  }
  return <div className={styles.previewIndicator}>Preview enabled</div>
}

///////// IMPLEMENTATION /////////

type Props = {
  preview: boolean
}
