import type { FunctionComponent, SyntheticEvent } from 'react'
import React from 'react'
import type { Rest } from '@which/shared'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { Link } from '../../../../../shared/components/Link'
import { totalProductColumns } from '../ComparisonTable'
import styles from './AddProducts.module.scss'

export const AddProducts: FunctionComponent<Props> = ({
  taxonomySlug,
  numberOfProducts,
  className,
  ...rest
}) => {
  return (
    <div className={classnames(styles.addProductsContainer, className)} {...rest}>
      <Link
        href={`/reviews/${taxonomySlug}`}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          dynamicDatalayerPush({
            eventCategory: 'Product Compare',
            eventAction: 'Add Products',
            eventLabel: `Current Products - ${numberOfProducts}`,
          })
        }}
        textStyle="sb-text-body-default-regular"
        className={styles.link}
      >
        {numberOfProducts === totalProductColumns - 1 ? 'Add product' : 'Add products'}
      </Link>
    </div>
  )
}

type Props = {
  taxonomySlug: string
  numberOfProducts: number
  className?: string
} & Rest
