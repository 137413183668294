import { IncomingMessage } from 'http'
import cookie from 'cookie'
import { CookieName } from '../shared/CookieName'
import { WhichGbAttributes } from '../shared/WhichGbAttributes'

const getWmValue = (cookiesObj: Record<string, string>): 'True' | 'False' => {
  return cookiesObj[CookieName.WM_COOKIE_NAME] === 'True' ? 'True' : 'False'
}

const getExperimentationIdValue = (cookiesObj: Record<string, string>) => {
  const id = cookiesObj[CookieName.EXPERIMENTATION_USER_ID]
  if (id === undefined) {
    throw Error(`Missing expected cookie ${CookieName.EXPERIMENTATION_USER_ID}`)
  }
  return id
}

const getMemberIdValue = (
  cookiesObj: Record<string, string>
): string | undefined => {
  return (
    cookiesObj[CookieName.MASTER_ID] || cookiesObj[CookieName.IDENTITY_COOKIE]
  )
}

const getAllCookiesFromMessage = (
  msg: IncomingMessage
): Record<string, string> => {
  const cookieStr = msg.headers.cookie

  if (cookieStr === undefined) {
    throw Error('No cookie header found')
  }

  return cookie.parse(cookieStr)
}

export const getAttributesFromMessage = (
  msg: IncomingMessage
): WhichGbAttributes => {
  const cookies = getAllCookiesFromMessage(msg)

  const memberId = getMemberIdValue(cookies)
  const id = getExperimentationIdValue(cookies)
  const wmCookie = getWmValue(cookies)

  return {
    id,
    wmCookie,
    // Only include memberId key if it has a value (will be undefined for signed-out users)
    ...(memberId !== undefined ? { memberID: memberId } : {})
  }
}
