import type { FunctionComponent, KeyboardEvent } from 'react'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CardRow, Grid, GridItem, LinkButton } from '@which/seatbelt'

import classnames from 'classnames'

import { AddProducts } from '../../../../pages/reviews/product-comparison/components'
import { ButtonWithCount } from '../../../../pages/reviews/shared/components/ButtonWithCount'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { useTabLoop } from '../../../hooks/useTabLoop'
import { usePageProps } from '../../../usePageProps'
import { SkipButton } from '../../SkipButton'
import { CompareCard } from '../CompareCard/CompareCard'
import type { Props as CompareTrayProps } from '../ReviewsCompareTray'
import styles from '../ReviewsCompareTray.module.scss'

export const Tray: FunctionComponent<Props> = ({
  className,
  compareHandler,
  handleEnterPress,
  products = [],
  removeAllHandler,
  removeHandler,
  taxonomySlug,
}) => {
  const history = useHistory()
  const { firstElementRef, lastElementRef, containerTabEventHandler } = useTabLoop()
  const { template } = usePageProps()
  const isProductPage = template === 'Reviews Product Page'
  const isTabletOrAbove = useMatchMedia('(min-width: 768px)')

  const buttonHandler = useCallback(() => {
    compareHandler && compareHandler()

    window.location.href = `/reviews/${taxonomySlug}/compare`

    history.push({ state: { from: `/${taxonomySlug}/compare` } })
  }, [compareHandler, history, taxonomySlug])

  const cardList = (
    <>
      {products.map(({ manufacturer, model, slug, businessKey }, index) => (
        <CompareCardItem
          businessKey={businessKey}
          manufacturer={manufacturer}
          model={model}
          slug={slug}
          index={index}
          removeHandler={removeHandler}
          handleEnterPress={handleEnterPress}
          firstElementRef={firstElementRef}
        />
      ))}
      {products.length < 4 && isProductPage && (
        <li>
          <AddProducts
            taxonomySlug={taxonomySlug}
            numberOfProducts={products?.length || 0}
            className={styles.addProducts}
            data-testid="add-products"
          />
        </li>
      )}
    </>
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={classnames(className, styles.baseTray)}
      onKeyDown={containerTabEventHandler}
      data-testid="reviews-compare-tray"
      aria-label="Reviews Compare Tray"
      role="region"
    >
      <Grid className={styles.trayGrid}>
        <GridItem span={{ small: 2, medium: 12, large: 10 }}>
          <ul className={styles.list}>
            {isTabletOrAbove ? (
              cardList
            ) : (
              <CardRow
                includeGutters={false}
                className={styles.cardRow}
                includeControls={false}
                includeScrollbar={false}
              >
                {cardList}
              </CardRow>
            )}
          </ul>
        </GridItem>
        <GridItem
          className={styles.buttonWrapper}
          span={{ medium: 12, large: 2 }}
          columnStart={{ large: 11 }}
        >
          <ButtonWithCount
            className={products.length > 1 ? styles.compareButton : styles.compareButtonDisabled}
            onClick={buttonHandler}
            disabled={products.length < 2}
            data-testid="reviews-compare-button"
            count={products.length}
          >
            Compare
          </ButtonWithCount>
          <SkipButton
            className={styles.compareTraySkipButton}
            selector='article[data-testid="sb-product-card"]'
          >
            Skip to Products
          </SkipButton>
          <SkipButton
            className={styles.compareTraySkipButton}
            selector='button[aria-label*="current page"]'
          >
            Skip to end of content
          </SkipButton>
          <LinkButton
            onClick={removeAllHandler}
            onKeyPress={handleEnterPress(removeAllHandler)}
            tabIndex={0}
            data-testid="reviews-compare-remove-all-button"
            forwardRef={lastElementRef}
          >
            Remove all
          </LinkButton>
        </GridItem>
      </Grid>
    </div>
  )
}

const CompareCardItem: FunctionComponent<CompareCardsProps> = ({
  businessKey,
  manufacturer,
  model,
  slug,
  index,
  removeHandler,
  handleEnterPress,
  firstElementRef,
}) => (
  <li key={businessKey}>
    <CompareCard
      {...{
        handleEnterPress,
        manufacturer,
        model,
        removeItem: () => removeHandler(businessKey),
        slug,
      }}
      ref={index === 0 ? firstElementRef : undefined}
      className={styles.card}
    />
  </li>
)

///////// IMPLEMENTATION /////////

type Props = CompareTrayProps & {
  handleEnterPress: (callback: (() => void) | undefined) => (e: KeyboardEvent) => void
}

type CompareCardsProps = {
  businessKey: string
  manufacturer: string
  model: string
  slug: string
  index: number
  removeHandler: (businessKey: string, index?: number | undefined) => void
  handleEnterPress: (callback: (() => void) | undefined) => (e: KeyboardEvent) => void
  firstElementRef: React.MutableRefObject<null>
}
