import React from 'react'
import type { ArticleUIData } from '@which/shared'

import classnames from 'classnames'

import { renderArticleElements } from './article-element-renderer'

// @TODO remove this as part of https://whichonline.atlassian.net/browse/WAB-59
export const articleBodyRenderer = (
  articleBodyTemplate: ArticleUIData[],
  additionalClasses = ''
) => (
  <div className={classnames('article-wrapper', additionalClasses)}>
    {renderArticleElements(articleBodyTemplate)}
  </div>
)
