import type { FunctionComponent } from 'react'
import React from 'react'

import { Breadcrumb } from '../../Breadcrumb'

export const PIHeader: FunctionComponent<Props> = (props) => {
  return (
    <section data-testid="ea-header" role="navigation">
      <Breadcrumb {...props} />
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = Parameters<typeof Breadcrumb>[0]
