export const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    const hostName = window.location?.hostname

    if (
      hostName.includes('www.which.co.uk') ||
      (hostName.includes('prod-deliver.components.product.which.co.uk') &&
        !hostName.includes('preprod-deliver.components.product.which.co.uk'))
    ) {
      return 'prod'
    }

    const [, envName] = hostName.match(/^(.*?)[-\.]/) || []

    return envName || 'prod'
  }
}

export const isLocal = () => {
  if (typeof window === 'undefined') {
    return process.env.NR_ENV === 'local'
  }

  if (window.location?.hostname === 'localhost') {
    return true
  }

  return false
}

export const isProd = () => {
  if (typeof window === 'undefined') {
    return process.env.NR_ENV === 'prod'
  }

  if (getEnvironment() === 'prod') {
    return true
  }

  return false
}

export const isPreProd = () => {
  if (typeof window === 'undefined') {
    return process.env.NR_ENV === 'preprod'
  }

  if (getEnvironment() === 'preprod') {
    return true
  }

  return false
}
