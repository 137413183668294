export const getImageSources = (image, ratio, renditions) => {
  const getImageWidth = (width) => {
    return width.substring(0, width.indexOf('x'))
  }

  return renditions
    .map(
      (rendition) =>
        `https://media.product.which.co.uk/prod/images/${ratio}_${rendition}/${image}.webp ${getImageWidth(
          rendition
        )}w`
    )
    .join(', ')
}
