import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import styles from './PISection.module.scss'

export const PISection: FunctionComponent<Props> = ({
  children,
  sectionHeader,
  sectionText,
  cssClassName,
  showViewAllArticles,
  dataTestId,
}) => {
  const { pathname } = useLocation()

  return (
    <section
      data-testid="ea-section"
      className={classnames(styles.section, styles[`section-${cssClassName}`])}
    >
      <div className={styles.sectionHeader}>
        <Heading
          headingType="large"
          heading={sectionHeader}
          headingTag="h1"
          subHeading={sectionText}
        />
      </div>

      <div
        data-testid={`ea-section-content-${dataTestId}`}
        className={classnames(styles.sectionContent, styles[`section-${cssClassName}Content`])}
      >
        {children}
      </div>
      {showViewAllArticles && (
        <div className={styles.sectionViewArticlesButton} data-testid="ea-section-view-all">
          <Button appearance="secondary">
            <ChevronRightIcon />
            <span className={styles.sectionViewAll}>
              <a data-testid={`ea-view-all-${dataTestId}`} href={`${pathname}/search`}>
                View all content
              </a>
            </span>
          </Button>
        </div>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  children?: ReactNode
  sectionHeader: string
  sectionText: string
  cssClassName: string
  showViewAllArticles?: boolean
  filter?: string
  dataTestId: string
}
