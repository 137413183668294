import { useParams } from 'react-router-dom'

import type { PageParams } from '../../routes'
import { usePageProps } from '../usePageProps'

// Note: this hook relies on the 'vertical/sub-vertical/category' URL structure.
// This means it is only currently suitable for news and parts of consumer rights.
export const usePaths: () => Paths = () => {
  const { context: verticalSlug } = usePageProps()
  const { subVerticalSlug, categorySlug } = useParams<PageParams>()

  const verticalPath = verticalSlug && `/${verticalSlug}`
  const subVerticalPath = verticalSlug && subVerticalSlug && `/${verticalSlug}/${subVerticalSlug}`
  const categoryPath =
    verticalSlug && subVerticalSlug && categorySlug && `${subVerticalPath}/${categorySlug}`

  const currentPath = categoryPath || subVerticalPath || verticalPath

  return {
    verticalSlug,
    verticalPath,
    subVerticalSlug,
    subVerticalPath,
    categorySlug,
    categoryPath,
    currentPath,
  }
}

type Paths = {
  verticalSlug?: string
  verticalPath?: string
  subVerticalSlug?: string
  subVerticalPath?: string
  categorySlug?: string
  categoryPath?: string
  currentPath?: string
}
