import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { ArticleCard, Grouper, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { BaseNewsArticleListingData } from '../../../generated/frontend'
import { usePaths } from '../../hooks/usePaths'
import styles from './GroupedArticles.module.scss'

export const GroupedArticles: FunctionComponent<Props> = ({ articles }) => {
  const { currentPath } = usePaths()
  const [currentArticles, setCurrentArticles] = useState<BaseNewsArticleListingData[] | undefined>(
    articles
  )
  const grouperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (articles) {
      // Logic to apply focus to the top item of newly loaded articles
      if (grouperRef?.current && currentArticles?.length) {
        const titleLinkNodeList = grouperRef.current?.querySelectorAll(
          '[class^="ArticleCard__articleCardText"] > a:first-child'
        )

        if (titleLinkNodeList) {
          const filteredByHeadline = Array.from(titleLinkNodeList).filter((titleLink) => {
            const itemHeadline = titleLink.querySelector('h2 span')?.textContent
            const articleToFocus = articles[currentArticles.length]

            return itemHeadline === articleToFocus?.headline
          })

          if (filteredByHeadline.length) {
            const headingToFocus = filteredByHeadline[0] as HTMLElement
            headingToFocus.focus()
          }

          // Update current articles
          setCurrentArticles(articles)
        }
      }
    }
  }, [articles, currentArticles, grouperRef])

  if (!articles?.length) {
    return null
  }

  return (
    <div data-testid="grouper-wrapper" ref={grouperRef}>
      <Grouper groups={mapGroups(articles, currentPath)} />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const mapGroups = (articles: BaseNewsArticleListingData[], currentPath?: string) => {
  // Skip lazy loading on the first batch
  // 20 items is our page default length
  const lazyLoadImages = articles?.length > 20

  return [...articles].reduce((groups: Groups, article) => {
    const [articleYear] = article.updatedDate?.iso.split('-')
    const existingYearGroup = groups.find((group) => group.name === articleYear)

    if (existingYearGroup) {
      existingYearGroup.children.push(
        getChild({ article, isFirstInGroup: false, lazyLoadImages, currentPath })
      )
    } else {
      groups.push({
        name: articleYear,
        key: articleYear,
        children: [getChild({ article, isFirstInGroup: true, lazyLoadImages, currentPath })],
      })
    }

    return groups
  }, [])
}

const getChild = ({ article, isFirstInGroup, lazyLoadImages, currentPath }: GetChildParams) => {
  const imageProps = {
    image: article.image?.src,
    imageAlt: article.image?.alt,
    imageSources: article.image?.sources,
    orientation: 'horizontal',
    lazyLoad: lazyLoadImages,
  }

  return {
    key: article.url,
    content: (
      <article className={styles.article}>
        <Typography
          className={classnames(styles.time, {
            [styles.timeOffset]: isFirstInGroup,
          })}
          dateTime={article.updatedDate?.iso}
          tag="time"
          textStyle="sb-text-interface-body-small-regular"
        >
          {article.updatedDate?.rendered}
        </Typography>
        <ArticleCard
          className={styles.card}
          href={article.url}
          title={article.headline}
          singleLinkData={{
            href: article.primaryTaxonomy.url,
            text: article.primaryTaxonomy.name,
            disabled: currentPath === article.primaryTaxonomy.url,
          }}
          tag="div"
          {...(article.image && imageProps)}
        />
      </article>
    ),
  }
}

type Groups = Array<{
  name: string
  key: string
  children: {
    key: string
    content: React.ReactNode
  }[]
}>

type GetChildParams = {
  article: BaseNewsArticleListingData
  isFirstInGroup: boolean
  lazyLoadImages: boolean
  currentPath?: string
}

export type Props = {
  articles?: BaseNewsArticleListingData[]
}
