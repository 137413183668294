import React from 'react'

import ForgotPasswordPage from '../../pages/account/forgot-password-page/ForgotPasswordPage'
import LoginPage from '../../pages/account/login-page/LoginPage'
import RegistrationPage from '../../pages/account/registration-page/RegistrationPage'
import ResetPasswordPage from '../../pages/account/reset-password-page/ResetPasswordPage'
import type { WCDRouteProps } from '..'

export const accountRoutes: WCDRouteProps = [
  {
    live: false,
    exact: true,
    prod: false,
    preprod: true,
    path: '/registration',
    component: () => <RegistrationPage />,
  },
  {
    live: false,
    exact: true,
    prod: false,
    preprod: true,
    path: '/login',
    component: () => <LoginPage />,
  },
  {
    live: false,
    exact: true,
    prod: false,
    preprod: true,
    path: '/forgot-password',
    component: () => <ForgotPasswordPage />,
  },
  {
    live: false,
    exact: true,
    prod: false,
    preprod: true,
    path: '/reset-password',
    component: () => <ResetPasswordPage />,
  },
]
