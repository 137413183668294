import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './GridItemArticle.module.scss'

export const GridItemArticle: FunctionComponent<GridArticleItemProps> = ({
  className,
  area,
  children,
}) => <section className={classnames(styles[area], className)}>{children}</section>

///////// IMPLEMENTATION /////////

export type GridArticleItemProps = {
  className?: string
  area: GridArticleAreas
}

export type GridArticleAreas =
  | 'author'
  | 'body'
  | 'date'
  | 'footer'
  | 'header'
  | 'hero'
  | 'jumplinks'
  | 'pageheader'
  | 'related'
  | 'share'
