import { getBlaize } from '../getBlaize'

export const environment = (): string => {
  if (typeof window === 'undefined') {
    return 'prod'
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
      case 'dev-deliver.components.product.which.co.uk':
      case 'dev.internal.which.co.uk':
        return 'dev'
      case 'test-deliver.components.product.which.co.uk':
      case 'test.internal.which.co.uk':
        return 'test'
      case 'qa-deliver.components.product.which.co.uk':
        return 'qa'
      case 'preprod-deliver.components.product.which.co.uk':
      case 'preprod.internal.which.co.uk':
        return 'preprod'

      default:
        return 'prod'
    }
  }
}

const noEntitlements = {
  tech: false,
  money: false,
  legal: {
    buying: false,
    home: false,
    holiday: false,
    cars: false,
    employment: false,
    wills: false,
    dataProtection: false,
    medical: false,
  },
}

export const getEntitlements = async (): Promise<typeof noEntitlements> => {
  const sessionId = getBlaize()
  if (!sessionId) {
    return noEntitlements
  }
  try {
    const response = await fetch(
      `https://${environment()}-appointment-booking-entitlements-validator.api.product.which.co.uk/user-entitlements?sessionId=${sessionId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      return noEntitlements
    }

    return response.json()
  } catch (error) {
    return noEntitlements
  }
}
