import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import type { Props } from '.'
import * as svgs from './svgs'
import styles from './SVGUtil.module.scss'

const processTitle = (title): svgs.SVGName => title.toLowerCase().replace(/[^a-z]/g, '-')
const makeTitleId = (title): string => `svg-title-${processTitle(title)}`

export const SVGUtil: FunctionComponent<Props> = ({
  className = '',
  height,
  name,
  title,
  viewBox,
  width,
}) => {
  const titleId = title && makeTitleId(title)
  const SVG = title ? React.cloneElement(svgs[name], { role: 'presentation' }) : svgs[name]

  return (
    <svg
      aria-labelledby={titleId}
      className={classnames(styles.svg, className)}
      data-testid={titleId}
      focusable="false"
      height={height}
      role={title && 'img'}
      version="1.1"
      viewBox={viewBox}
      width={width}
      xlinkHref="https://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlns="https://www.w3.org/2000/svg"
    >
      {title && <title id={titleId}>{title}</title>}
      {SVG}
    </svg>
  )
}
