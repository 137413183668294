import './CookieSettingsButton.module.scss'

import React from 'react'
import { Button } from '@which/seatbelt'

export const CookieSettingsButton = () => (
  <Button id="ot-sdk-btn" className="ot-sdk-show-settings">
    Manage cookie preferences
  </Button>
)
