import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Radio, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import style from './MarketingPreferences.module.scss'

export const MarketingConsent: FunctionComponent<MarketingConsentProps> = ({ radioType }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()
  const [radioBtnValue, setRadioBtnValue] = useState('')
  const name = `${radioType}Consent`
  const yesID = `${radioType}-yes`
  const noID = `${radioType}-no`
  const yesChecked = radioBtnValue === 'Yes'
  const noChecked = radioBtnValue === 'No'

  const callDataLayerForMarketingConsent = (field = '') => {
    dynamicDatalayerPush({
      eventCategory: 'Signup Marketing Consent',
      eventAction: 'Click',
      eventLabel: field,
    })
  }
  const consentRadios = (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <Radio
            {...field}
            id={yesID}
            key={yesID}
            value="Yes"
            label="Yes"
            className={style.marketingConsentRadioWrapper}
            checked={yesChecked}
            aria-checked={yesChecked}
            onChange={() => {
              setValue(name, 'Yes')
              setRadioBtnValue('Yes')
              callDataLayerForMarketingConsent(`${name}-Yes`)
            }}
          />
          <Radio
            {...field}
            id={noID}
            key={noID}
            value="No"
            label="No"
            className={style.marketingConsentRadioWrapper}
            checked={noChecked}
            aria-checked={noChecked}
            onChange={() => {
              setValue(name, 'No')
              setRadioBtnValue('No')
              callDataLayerForMarketingConsent(`${name}-No`)
            }}
          />
        </>
      )}
    />
  )

  return (
    <div className={style.marketingConsent}>
      <Typography
        tag="span"
        className={style.marketingConsentType}
        textStyle="sb-text-body-default-regular"
      >
        {radioType}
      </Typography>
      {consentRadios}
      {errors[name] && getValues(`${radioType}Consent`) === '' && (
        <Typography
          className={style.marketingConsentValidationError}
          textStyle="sb-text-body-small-regular"
        >
          Please indicate your preference
        </Typography>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type MarketingConsentProps = {
  radioType: string
}
