import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { ContentCardV2, Grid, GridItem, Heading } from '@which/seatbelt'

import type { HelpAndAdviceImage } from '../../../../generated/frontend'
import { MoreButton } from '../../../../shared/components/MoreButton'
import styles from './ProductHubHelpAndAdvice.module.scss'

export const ProductHubHelpAndAdvice: FunctionComponent<Props> = (props) => {
  const { title, subtitle, topArticles, featuredArticle, allArticles } = props
  const [allAdviceVisible, setAllAdviceVisible] = useState<boolean>(false)

  const handleShowMoreClick = () => {
    setAllAdviceVisible(true)
  }

  return (
    <section aria-label={title} id="product-hub-help-and-advice" className={styles.adviceLinks}>
      <Grid className={styles.adviceLinksGrid} includeGutters={false}>
        <GridItem>
          <Heading heading={title} headingTag="h2" headingType="large" subHeading={subtitle} />
        </GridItem>
        {featuredArticle && (
          <GridItem
            columnStart={{ medium: 1, large: 1 }}
            span={{ medium: 6, large: 6 }}
            aria-label={'Featured Article'}
          >
            <ContentCardV2
              title={featuredArticle.title}
              clampDescText={true}
              description={featuredArticle.description}
              ariaLabel={featuredArticle.title}
              primaryLink={featuredArticle.path}
              imgObj={{
                aspectRatioMain: 'four-to-three',
                imageSources: featuredArticle.image?.sources,
                str: featuredArticle.image?.src ?? '',
                imageAlt: featuredArticle.image?.alt ?? '',
              }}
              trackingData={{
                'data-which-id': 'producthub-link',
                'data-section': title,
                'data-index': '1',
              }}
            />
          </GridItem>
        )}
        {topArticles.length > 0 && (
          <GridItem columnStart={{ medium: 7, large: 7 }} span={{ medium: 6, large: 6 }}>
            <ul className={styles.adviceLinksTopList}>
              {topArticles.map((article, index) => (
                <li key={article.path}>
                  <ContentCardV2
                    title={article.title}
                    description={article.description}
                    primaryLink={article.path}
                    trackingData={{
                      'data-which-id': 'producthub-link',
                      'data-section': title,
                      'data-index': `${index + 2}`,
                    }}
                  />
                </li>
              ))}
            </ul>
          </GridItem>
        )}
      </Grid>
      {allArticles.length > 0 && (
        <Grid className={styles.adviceLinksGrid} includeGutters={false}>
          <GridItem>
            <MoreButton
              align="center"
              aria-label="Show more"
              hideButton={allAdviceVisible}
              onClick={handleShowMoreClick}
              omitBg
              data-which-id={'more-button'}
              data-section={title}
            />
          </GridItem>
          <GridItem>
            <ul className={
              allAdviceVisible ?
                styles.adviceLinksListVisible :
                styles.adviceLinksListHidden
            }>
              {allArticles.map((article, index) => (
                <li key={article.path}>
                  <ContentCardV2
                    cardContainerId={styles.productHubShowMoreCard}
                    title={article.title}
                    description={article.description}
                    primaryLink={article.path}
                    trackingData={{
                      'data-which-id': 'producthub-link',
                      'data-section': title,
                      'data-index': `${index + topArticles.length + 2}`,
                    }}
                  />
                </li>
              ))}
            </ul>
          </GridItem>
        </Grid>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Article = {
  title: string
  description: string
  path: string
  image: HelpAndAdviceImage
}

type Props = {
  title: string
  subtitle: string
  featuredArticle: Article
  topArticles: Omit<Article, 'image'>[]
  allArticles: Omit<Article, 'image'>[]
}
