import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import loadable from '@loadable/component'

import { getQueryString } from '../../utils/get-query-string'
import { getSessionStorageItem } from '../../utils/get-session-storage-item'
import { Loader } from '../Loader'
import { ToolFramework404Error } from '../ToolFramework404Error'

const LoadToolsRenderer = loadable.lib<never, ToolsApi>(() => import('@which/tool-framework'), {
  ssr: true,
  fallback: <Loader />,
})

type ToolsApi = {
  RenderTool: (props: ToolProps) => JSX.Element
}

const getIsLoggedIn = (transformTypeDecision: string) =>
  ['AUTHENTICATED_FULL_ACCESS', 'AUTHENTICATED_NO_ACCESS', 'FULL_ACCESS'].includes(
    transformTypeDecision
  )

export const ToolFramework = ({
  toolName,
  optionsJson,
  CRFooter,
  showFeedback,
  toolUrl,
  title,
  questionText,
  standfirst,
  buttonText,
  transformTypeDecision,
}: ToolProps) => {
  const surveySessionData = getSessionStorageItem('impactSurveyData')

  const location = useLocation()

  if (toolName === 'ImpactSurvey') {
    if (typeof window === 'undefined') {
      return null
    }
    if (
      (!surveySessionData || !surveySessionData.email || !surveySessionData.toolID) &&
      ((typeof window !== 'undefined' && !window.location.search) ||
        !getQueryString('email') ||
        !getQueryString('tool'))
    ) {
      return <ToolFramework404Error message="impact survey error" />
    }
  } else if (
    toolName !== 'EnergyCostCalculator' &&
    toolName !== 'PriceRiseCalculator' &&
    typeof transformTypeDecision === 'undefined'
  ) {
    return <Loader />
  } else if (toolName === 'BookAppointment' && !getIsLoggedIn(transformTypeDecision || '')) {
    return <Redirect to={`/login?return_url=${location.pathname.slice(1)}${location.search}`} />
  }

  return (
    <LoadToolsRenderer>
      {({ RenderTool }) => {
        return (
          <RenderTool
            {...{
              toolName,
              optionsJson,
              CRFooter,
              showFeedback,
              toolUrl,
              title,
              questionText,
              standfirst,
              buttonText,
            }}
          />
        )
      }}
    </LoadToolsRenderer>
  )
}

type ToolProps = {
  toolName: string
  optionsJson: string
  CRFooter: React.ReactNode
  showFeedback: boolean
  title: string
  toolUrl: string
  questionText: string
  standfirst: string
  buttonText: string
  transformTypeDecision?: string
}
