import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { AnimationWrapper, Button } from '@which/seatbelt'
import { PhoneIcon } from '@which/seatbelt/src/components/Icons/Navigational/Phone'

import { Link } from '../../../../shared/components/Link'
import { isProd } from '../../../../shared/utils/env-checks'
import { loadScript } from '../../../../shared/utils/loadScript'

export const CallUs: FunctionComponent<Props> = ({
  type,
  liveChat,
  linkUrl,
  linkId,
  buttonText,
  liveChatIsOpen,
}) => {
  useEffect(() => {
    if (liveChat && liveChatIsOpen) {
      if (!isProd()) {
        loadScript({
          src: 'https://www.which.co.uk/static/deliver/livechat.js',
        })
      }
      loadScript({
        src: 'https://www.which.co.uk/static/deliver/livechatButton.js',
      })
    }
  }, [liveChat, liveChatIsOpen])

  // @TODO: phone icon needs adding to LinkIcon mapper in SB then we can just pass in icon prop to Link
  return (
    <div data-testid="call-us">
      {liveChat ? (
        <Button appearance={type} id={linkId} disabled={liveChatIsOpen ? false : true}>
          {liveChatIsOpen ? buttonText : 'Currently unavailable'}
        </Button>
      ) : (
        <Link href={linkUrl} noAnimation={true}>
          <PhoneIcon /> <AnimationWrapper>{buttonText}</AnimationWrapper>
        </Link>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  type: 'primary' | 'secondary'
  buttonText: string
  linkUrl: string
  liveChat?: boolean
  linkId?: string
  liveChatIsOpen: boolean
}
