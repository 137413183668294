import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './GridItemProductListing.module.scss'

export const GridItemProductListing: FunctionComponent<GridItemProps> = ({
  className,
  area,
  children,
}) => <section className={classnames(styles[area], className)}>{children}</section>

///////// IMPLEMENTATION /////////

export type GridItemProps = {
  className?: string
  area: GridAreas
}

export type GridAreas = 'filter' | 'foot' | 'product' | 'sortby'
