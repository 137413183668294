import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import {
  Button,
  Buttons,
  CardRow,
  CarouselPortal,
  Modal,
  Picture,
  SlideImage,
} from '@which/seatbelt'

import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'

import { isTouchDevice } from '../../../utils/is-touch-device'
import styles from './PIVisualDataCarousel.module.scss'

export const PIVisualDataCarousel: FunctionComponent<Props> = ({ slides = [], label }) => {
  const [modalOpen, SetModalOpen] = useState(false)
  const [portalConfig, setPortalConfig] = useState<PortalConfig>({ displayPortal: false })
  const [currentSlide, setCurrentSlide] = useState(0)
  const cardRowTitle = label ? label : 'Visual data in article'
  const touchDevice = isTouchDevice()

  if (!slides?.length) {
    return null
  }

  const openModalHandler = (src: string) => {
    const indexOfClickedSlide = slides?.map((slide) => slide.src).indexOf(src) || 0
    const clickedSlide = slides[indexOfClickedSlide]

    if (touchDevice) {
      setPortalConfig({ zoomSrc: clickedSlide.zoomSrc, alt: clickedSlide.alt, displayPortal: true })
    } else {
      setCurrentSlide(indexOfClickedSlide)
      SetModalOpen(true)
    }
  }

  const closeModalHandler = () => {
    SetModalOpen(false)
  }

  const renderSlides = () =>
    slides?.map(({ src, alt }) => (
      <article className={styles.imageWrapper} key={src} data-testid="ea-carousel-slide">
        <Picture src={src} alt={alt} aspectRatioMain="four-to-three" />
        <Button
          data-testid="ea-carousel-lightbox-button"
          appearance="secondary"
          className={styles.button}
          onClick={() => openModalHandler(src)}
        />
      </article>
    ))

  return (
    <>
      {touchDevice && portalConfig.displayPortal && (
        <CarouselPortal
          setPortalConfig={setPortalConfig}
          zoomSrc={portalConfig.zoomSrc}
          alt={portalConfig.alt}
          portalZIndex={1003}
        />
      )}

      <div className={styles.visualDataCarousel} data-testid="ea-visual-data-carousel">
        {!touchDevice && modalOpen && (
          <Modal closeModal={closeModalHandler}>
            <CarouselProvider
              naturalSlideWidth={0}
              naturalSlideHeight={0}
              orientation="horizontal"
              totalSlides={slides.length}
              currentSlide={currentSlide}
              aria-label={`Carousel of ${slides.length} images`}
            >
              <section className={styles.carouselWrapper}>
                <Slider aria-label="Carousel" classNameTray={styles.slider}>
                  {slides.map(({ src, alt, zoomSrc, zoomWidth, zoomHeight }, index) => (
                    <Slide tabIndex={-1} key={`image_${src}`} index={index} aria-hidden="true">
                      <SlideImage
                        {...{
                          alt,
                          src,
                          zoomSrc,
                          zoomWidth,
                          zoomHeight,
                          setPortalConfig,
                          carouselBgColor: 'grey',
                        }}
                      />
                    </Slide>
                  ))}
                </Slider>
              </section>
              <Buttons current={currentSlide} total={slides.length} onChange={setCurrentSlide} />
            </CarouselProvider>
          </Modal>
        )}
        <CardRow title={cardRowTitle}>{renderSlides()}</CardRow>
      </div>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  label?: string
  slides?: Slide[]
}

type Slide = {
  src: string
  alt: string
  zoomSrc?: string
  zoomWidth?: number
  zoomHeight?: number
  imageClassName?: string
  wrapperClassName?: string
}

type PortalConfig = {
  zoomSrc?: string
  alt?: string
  displayPortal: boolean
}
