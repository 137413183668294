import React from 'react'

export const escalateCR = (
  <g fill="none" fillRule="evenodd" data-testid="svg-escalateCR">
    <path
      d="M68.35 39.08l-7.3 8.25c5.42 1.9 6.6 5.84 6.6 5.84l7.3-8.25s-4.05-.7-6.6-5.84z"
      stroke="#424242"
      strokeWidth="2"
    />
    <path
      d="M74 27.15l-10.18-9-10.17-8.99s-.7 4.05-5.84 6.6l10.18 9 10.17 9c1.9-5.42 5.84-6.61 5.84-6.61"
      fill="#FFF"
    />
    <path
      d="M74 27.15l-10.18-9-10.17-8.99s-.7 4.05-5.84 6.6l10.18 9 10.17 9c1.9-5.42 5.84-6.61 5.84-6.61z"
      stroke="#424242"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M56.19 46.99l-10.18-9L35.84 29c-1.9 5.42-5.84 6.6-5.84 6.6l10.17 9 10.18 9s.7-4.06 5.84-6.61"
      fill="#FFF"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      d="M55.74 47.8l-10.17-9-10.18-8.99c-1.9 5.42-5.84 6.6-5.84 6.6l10.18 9 10.17 9s.7-4.06 5.84-6.6z"
      stroke="#424242"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M44.61 61.74L22.92 42.57a1.3 1.3 0 01-.1-1.84l4.22-4.79a1.3 1.3 0 011.85-.11L50.56 55c.54.48.6 1.3.12 1.84l-4.23 4.79a1.3 1.3 0 01-1.85.11"
      fill="#FFF"
    />
    <path
      d="M44.61 61.74L22.92 42.57a1.3 1.3 0 01-.1-1.84l4.22-4.79a1.3 1.3 0 011.85-.11L50.56 55c.54.48.6 1.3.12 1.84l-4.23 4.79a1.3 1.3 0 01-1.85.11z"
      stroke="#424242"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M46.04 14.2a1.3 1.3 0 00-1.84.11l-6.11 6.91-4.58 5.19a1.3 1.3 0 00.11 1.84l23.9 21.12a1.3 1.3 0 001.83-.12l4.59-5.18 6.1-6.9a1.3 1.3 0 00-.1-1.85L46.03 14.2z"
      fill="#FFF"
    />
    <path
      d="M46.04 14.2a1.3 1.3 0 00-1.84.11l-6.11 6.91-4.58 5.19a1.3 1.3 0 00.11 1.84l23.9 21.12a1.3 1.3 0 001.83-.12l4.59-5.18 6.1-6.9a1.3 1.3 0 00-.1-1.85L46.03 14.2z"
      stroke="#424242"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M80.63 21L58.94 1.83a1.3 1.3 0 00-1.84.11l-4.23 4.79a1.3 1.3 0 00.11 1.84l21.69 19.17a1.3 1.3 0 001.84-.11l4.23-4.79a1.3 1.3 0 00-.11-1.84"
      fill="#FFF"
    />
    <path
      d="M80.63 21L58.94 1.83a1.3 1.3 0 00-1.84.11l-4.23 4.79a1.3 1.3 0 00.11 1.84l21.69 19.17a1.3 1.3 0 001.84-.11l4.23-4.79a1.3 1.3 0 00-.11-1.84z"
      stroke="#424242"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      fill="#E0E0E0"
      style={{ mixBlendMode: 'multiply' }}
      d="M48.9 54.08L29.53 36.95l2.86-3.23 19.38 17.13zm19.25-21.32L48.82 15.67l2.85-3.22L71 29.54z"
    />
    <path
      d="M125.54 97a9.18 9.18 0 01-13.17.6l-44.57-42a1.78 1.78 0 01-.1-2.47l7.21-8.17a1.78 1.78 0 012.47-.2l47.14 39.09c4 3.3 4.45 9.26 1.02 13.14"
      fill="#FFF"
    />
    <path
      d="M125.54 97a9.18 9.18 0 01-13.17.6l-44.57-42a1.78 1.78 0 01-.1-2.47l7.21-8.17a1.78 1.78 0 012.47-.2l47.14 39.09c4 3.3 4.45 9.26 1.02 13.14z"
      stroke="#424242"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M126.27 98.13a9.2 9.2 0 01-13.19.58l-44.7-42.1a1.78 1.78 0 01-.1-2.48l3.22-3.65.96-1.09"
      fill="#E0E0E0"
      style={{ mixBlendMode: 'darken' }}
    />
    <path d="M81.96 51.85l22.66 19.75" stroke="#FFF" strokeWidth="2" strokeLinecap="round" />
    <path
      fill="#E0E0E0"
      style={{ mixBlendMode: 'multiply' }}
      d="M80.47 47.33l-9.84 11.13 6.57 6.2 10.23-11.57z"
    />
    <path
      d="M52.38 100.9H6.54a4.05 4.05 0 01-4.04-4.04 4.06 4.06 0 014.04-4.04h45.84a4.06 4.06 0 014.04 4.04 4.05 4.05 0 01-4.04 4.04z"
      stroke="#424242"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M47.98 92H11.02C9.9 92 9 90.88 9 89.5s.9-2.5 2.02-2.5h36.96C49.1 87 50 88.13 50 89.5c0 1.38-.9 2.5-2.02 2.5z"
      stroke="#424242"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.52 96.86a4.06 4.06 0 014.04-4.04H52.4a4.06 4.06 0 014.04 4.04"
      fill="#E0E0E0"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path d="M80.46 102.3a15.94 15.94 0 110-31.9 15.94 15.94 0 010 31.9z" fill="#01892E" />
    <path
      d="M96.4 86.35a15.94 15.94 0 11-31.89 0 15.94 15.94 0 0131.89 0z"
      stroke="#FFF"
      strokeWidth="3"
    />
    <path stroke="#FFF" strokeWidth="3" d="M72.74 87.1l4.79 4.78 10.41-10.41" />
  </g>
)
