import type { FunctionComponent } from 'react'
import { GridItem } from '@which/seatbelt'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Loader } from '../../../../../shared/components/Loader'
import { compareProductsStorageName } from '../../../../../shared/constants/compare-products-storage-name'
import { useCompareTray } from '../../../../../shared/hooks/useCompareTray'
import { usePaths } from '../../../../../shared/hooks/usePaths'
import { EmptyCompareLandingPage } from '../EmptyCompareLandingPage/EmptyCompareLandingPage'
import { ComparisonTableV2 } from './v2/ComparisonTableV2'

export const ComparisonTable: FunctionComponent = () => {
  const { categorySlug } = usePaths()
  const taxonomySlug = categorySlug || ''
  const { compareTrayItems } = useCompareTray(compareProductsStorageName, taxonomySlug)

  const renderWithAccordion = useFeatureIsOn('whc-compare-accordion')

  if (!compareTrayItems) {
    return <Loader />
  }

  return (
    <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
      {compareTrayItems.length ? (
        <ComparisonTableV2 taxonomySlug={taxonomySlug} renderWithAccordion={renderWithAccordion} />
      ) : (
        <EmptyCompareLandingPage
          data-testid="emptyCompareLandingPage"
          taxonomySlug={taxonomySlug}
          numberOfProducts={compareTrayItems.length}
        />
      )}
    </GridItem>
  )
}

//////IMPLEMENTATION//////

export const totalProductColumns = 4
