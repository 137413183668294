import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import styles from './StarRating.module.scss'

export const StarRating: FunctionComponent<StarRatingProps> = ({ value }) => {
  return (
    <Typography
      className={styles.stars}
      textStyle="sb-text-interface-body-small-regular"
      tag="span"
    >
      {value}
    </Typography>
  )
}

export type StarRatingProps = {
  value: string
}
