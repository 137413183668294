import { useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedContent } from '../RecommendedContent'
import { TabbedRecommendations } from '../TabbedRecommendations'

interface RecommendedForYouContainerProps {
  recommendations: Recommendations
  whichId: string
  modelId: string
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
  modelId,
}: RecommendedForYouContainerProps) => {
  const modelToUse = useFeatureValue('recommended-for-you-layout', '')
  const matchingModel = recommendations.models.find((model) => model.model === modelToUse)

  if (!matchingModel) {
    console.warn(`R4U: could not find model: ${modelToUse}`)
    return null
  }

  if (!matchingModel.content && !matchingModel.groups) {
    console.warn(`R4U: could not find content for model: ${modelToUse}`)
    return null
  }

  switch (matchingModel.model) {
    case 'mixed/adviceOnly':
      return (
        <RecommendedContent content={matchingModel.content} whichId={whichId} modelId={modelId} />
      )
    case 'tabbed/adviceOnly':
      return (
        <TabbedRecommendations groups={matchingModel.groups} whichId={whichId} modelId={modelId} />
      )
    default:
      console.warn(
        'R4U: Unknown model received from Growthbook. Recommended for you will not be shown'
      )
      return null
  }
}
