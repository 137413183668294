import React from 'react'

import { JumplinksInline } from '../../../../../../shared/components/JumplinksInline'
import { useFullUrl } from '../../../../../../shared/hooks/useFullUrl'
import { mapScamsJumpLinks } from '../../../../../../shared/utils/mapScamsJumplinks'

export const CRScamsJumpLinks = () => {
  const { getFullUrl } = useFullUrl()
  const jumpLinkData = CR_SCAM_ARTICLES_JUMPLINKS.find(({ path }) => getFullUrl().includes(path))

  if (!jumpLinkData) {
    return null
  }

  return <JumplinksInline links={mapScamsJumpLinks(jumpLinkData)} />
}

///////// IMPLEMENTATION /////////

export const CR_SCAM_ARTICLES_JUMPLINKS = [
  {
    path: 'how-to-spot-a-scam',
    labels: ['How to spot a scam', 'Beware of these scams', 'Scams Protection Checklist'],
  },
  {
    path: 'how-to-get-your-money-back-after-a-scam',
    labels: ['Get your money back', 'Emotional support'],
  },
  {
    path: 'how-to-report-a-scam',
    labels: ['Report scams to warn others', 'How to report scams', 'Emotional support'],
  },
]
