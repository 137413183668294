import type { FunctionComponent, PropsWithChildren } from 'react'
import React from 'react'

import styles from './Letter.module.scss'

export const Letter: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className={styles.articleLetter}>
      <div className={styles.letterWrapper}>{children}</div>
    </div>
  )
}
