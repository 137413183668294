import type { FunctionComponent } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, TypographyV2 as Typography } from '@which/seatbelt'

import type { TaxonomyHierarchyWithCounts } from '../../../generated/frontend'
import { usePaths } from '../../hooks/usePaths'
import styles from './CategorySelection.module.scss'

export const CategorySelection: FunctionComponent<Props> = ({ taxonomyHierarchyWithCounts }) => {
  const history = useHistory()
  const { currentPath, subVerticalPath } = usePaths()

  if (!taxonomyHierarchyWithCounts || !subVerticalPath) {
    return null
  }

  const dropdownOptions = getDropdownOptions(taxonomyHierarchyWithCounts, subVerticalPath)

  const navigateToPath = (selectedPath) => {
    if (selectedPath !== currentPath) {
      history.push(`${selectedPath}`)
    }
  }

  return (
    <nav
      aria-label="Article categories"
      className={styles.categorySelection}
      data-testid="category-selection"
    >
      <Typography
        className={styles.label}
        id="category-selection-label"
        tag="span"
        textStyle="sb-text-body-default-regular"
      >
        Select a category:
      </Typography>
      <Dropdown
        options={dropdownOptions}
        defaultValue={currentPath}
        callback={navigateToPath}
        aria-label="Select a category"
        wrapperClassName={styles.dropdownWrapper}
        variant="regular"
      />
    </nav>
  )
}

///////// IMPLEMENTATION /////////

function getDropdownOptions(structure: TaxonomyHierarchyWithCounts, subVerticalPath: string) {
  const subVerticalOption = {
    label: `All ${structure.name} news (${structure.count})`,
    value: subVerticalPath,
  }

  const categoryOptions = structure.categories.map(({ name, slug, count }) => ({
    label: `${name} (${count})`,
    value: `${subVerticalPath}/${slug}`,
  }))

  return [subVerticalOption, ...categoryOptions]
}

type Props = {
  taxonomyHierarchyWithCounts: TaxonomyHierarchyWithCounts
}
