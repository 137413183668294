import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Heading, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { Link } from '../../../../shared/components/Link'
import styles from './ProductHubRights.module.scss'

export const ProductHubRights: FunctionComponent<ProductHubRightsProps> = ({
  heading,
  content,
  fullWidth = false,
  headingsWithBorders = false,
}) => (
  <section aria-label={heading} id="your-rights">
    <Heading headingTag="h2" headingType="large" heading={heading} />

    <Grid includeGutters={false}>
      {content.map((block) => {
        const key = block.heading.replace(/\s+/g, '-').toLowerCase()
        const gridSpanWidth = fullWidth ? 12 : 8

        return (
          <GridItem
            key={key}
            className={styles.rightsContentBlock}
            columnStart={{ medium: 1, large: 1 }}
            span={{ medium: gridSpanWidth, large: gridSpanWidth }}
          >
            <div className={classnames({ [styles.headingWithBorder]: headingsWithBorders })}>
              <Heading headingTag="h3" headingType="medium" heading={block.heading} />
            </div>
            <Typography textStyle="sb-text-body-default-regular">{block.text}</Typography>

            <ul className={styles.linkList}>
              {block.links.map((link, index) => (
                <li key={link.href}>
                  <Link
                    href={link.href}
                    data-which-id="producthub-link"
                    data-section={heading.toLowerCase()}
                    data-subsection={block.heading.toLowerCase()}
                    data-index={index + 1}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </GridItem>
        )
      })}
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubRightsProps = {
  heading: string
  content: ProductHubRightsContent[]
  fullWidth?: boolean
  headingsWithBorders?: boolean
}

type ProductHubRightsContent = {
  heading: string
  text: string
  links: Array<{
    text: string
    href: string
  }>
}
