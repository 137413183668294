import type { ElementType, FunctionComponent } from 'react'
import React from 'react'
import { ContentCard, GridItem, TypographyV2 as Typography } from '@which/seatbelt'
import { LetterIcon, RegulationIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous'

import classnames from 'classnames'

import styles from './CategoryArticlesList.module.scss'

export const CategoryArticlesList: FunctionComponent<Props> = ({
  title,
  description,
  icon,
  label = '',
  articles,
  viewType = 'list-view',
}) => {
  if (articles.length === 0) {
    return null
  }

  return (
    <>
      <GridItem
        columnStart={viewType === 'page-view' ? { medium: 2, large: 2 } : {}}
        span={viewType === 'page-view' ? { medium: 10, large: 10 } : {}}
      >
        <div className={styles[viewType]}>
          <Typography
            className={styles.title}
            tag={viewType === 'page-view' ? 'h1' : 'h2'}
            textStyle={
              viewType === 'page-view' ? 'sb-text-heading-page-title' : 'sb-text-heading-large'
            }
          >
            {title}
          </Typography>
          {description && (
            <Typography
              className="category-articles-description"
              textStyle="sb-text-heading-standfirst"
            >
              {description}
            </Typography>
          )}
        </div>
      </GridItem>

      <GridItem className={classnames(styles.categoryArticlesSection)}>
        <div className={styles.grid}>
          {articles.map((article) => (
            <ContentCard
              key={article.href}
              href={article.href}
              label={label}
              icon={icon === 'letter' ? LetterIcon : RegulationIcon}
              strapline={article.strapline}
              title={article.title}
            />
          ))}
        </div>
      </GridItem>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  description?: string
  icon: ElementType | 'letter' | 'icon'
  label?: string
  viewType?: 'list-view' | 'page-view'
  articles: Array<{
    title: string
    href: string
    strapline: string
  }>
}
