import type { FunctionComponent } from 'react'
import React from 'react'
import type { NewsletterSource } from '@which/glide-ts-types'
import { Grid, GridItem, Picture } from '@which/seatbelt'

import classnames from 'classnames'

import type { ImageWithSources } from '../../../generated/frontend'
import { NewsletterSignUp } from '../NewsletterSignUp'
import styles from './NewsletterSignUpWithImage.module.scss'

export const NewsletterSignUpWithImage: FunctionComponent<Props> = ({
  className,
  source,
  image,
}) => {
  if (!source) {
    return null
  }
  return (
    <Grid
      data-testid="newsletter-signup-with-image"
      className={classnames('w-page-wrapper', styles.newsletterSignUpWithImage)}
    >
      <GridItem
        className={styles.newsletterWrapper}
        span={{ medium: 6, large: 4 }}
        columnStart={{ medium: 1, large: 2 }}
      >
        <NewsletterSignUp
          className={classnames(className, styles.newsletterSignup)}
          source={source}
        />
      </GridItem>
      <GridItem
        className={styles.imageGridItem}
        span={{ medium: 6, large: 7 }}
        columnStart={{ medium: 7, large: 6 }}
      >
        {image && (
          <div className={styles.imageWrapper}>
            <Picture imageClassName={styles.image} aspectRatioMain="one-to-one" {...image} />
          </div>
        )}
      </GridItem>
    </Grid>
  )
}

export type Props = {
  className?: string
  source?: NewsletterSource
  image: ImageWithSources
}
