import type { FunctionComponent } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { NavigationLinkBlock } from '@which/seatbelt'

import styles from './PICategories.module.scss'

export const PICategories: FunctionComponent<Props> = ({ categories }) => {
  const { pathname } = useLocation()

  if (categories.length <= 1) {
    return null
  }

  const policyAndInsightCategories = categories.map((element) => ({
    children: element.name,
    href: `${pathname}/search?filters.category=${capitalize(element.name.toLowerCase())}`,
  }))

  const alphaBeticalLinks = [...policyAndInsightCategories].sort((firstLink, nextLink) =>
    firstLink.children.localeCompare(nextLink.children)
  )

  return (
    <div data-testid="ea-categories" className={styles.categoriesWrapper}>
      <NavigationLinkBlock className={styles.categoriesLinkBlock} links={alphaBeticalLinks} />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  categories: any
}

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
