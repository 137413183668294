import type {
  DetailedReactHTMLElement,
  FunctionComponent,
  InputHTMLAttributes,
  ReactElement,
} from 'react'
import { createElement } from 'react'
import type { Component } from '@which/glide-ts-types'
import type { TemplateName } from '@which/glide-ts-types'
import { Grid, GridItem } from '@which/seatbelt'

import { getTemplateComponentProps } from './template-component-props'
import { getArticleGrid } from './template-grids/article-grid'
import { getProductListingGrid } from './template-grids/product-listing-grid'
import type { Config, TemplateAdditionalData } from './template-types'
import { determineTemplateType } from './utils/determine-template-type'

const NODE_BLOCK = 'node/block'
const NODE_WIDGET = 'node/widget'

export const templateRenderer = ({
  components,
  templateName,
  templateData = { json_tree: [], additionalData: null },
}: TemplateRendererParams) => {
  const { json_tree: glideTemplateJsonTree, additionalData } = templateData
  const e = createElement as CreateReactElement

  const renderTemplate = (
    componentsToRender: Record<Component, FunctionComponent<any>>,
    glideTemplateToRender: GlideTemplateJsonTree[] = []
  ): ReactElement[] => {
    return glideTemplateToRender.reduce(
      (
        elements,
        {
          id,
          css_classes: cssClasses,
          type,
          visible,
          child_nodes: childNodes,
          configuration: config,
          additional_items: additionalItems,
        }
      ) => {
        const isNodeBlock = type === NODE_BLOCK
        const isWidgetBlock = type === NODE_WIDGET
        const { component: templateComponent = '' as Component } = config || {}

        if (isNodeBlock && childNodes) {
          const tagName = additionalItems?.tag_name?.toLowerCase()
          const { componentToRender, extraProps } = calculateGridComponentToRender({
            templateName,
            tagName,
            className: getClassNames(cssClasses),
          })

          return elements.concat(
            e(
              componentToRender,
              {
                key: id,
                ...extraProps,
                ...(tagName === 'main' && { id: 'main-content' }),
              },
              renderTemplate(componentsToRender, childNodes)
            )
          )
        }

        if (isWidgetBlock && visible) {
          const isGridItemProp = config.grid_item ? { isGridItem: config.grid_item } : {}
          return elements.concat(
            e(
              componentsToRender[templateComponent] || GridItem,
              {
                ...getTemplateComponentProps(templateComponent, config, additionalData),
                className: getClassNames(cssClasses),
                key: id,
                ...isGridItemProp,
              },
              null
            )
          )
        }

        return elements.concat(
          e(
            'div',
            {
              className: getClassNames(cssClasses),
              key: id,
            },
            null
          )
        )
      },
      [] as CreatedReactElement[]
    )
  }

  return renderTemplate(components, glideTemplateJsonTree)
}

///////// IMPLEMENTATION /////////

type TemplateData = {
  json_tree: GlideTemplateJsonTree[]
  additionalData: TemplateAdditionalData | null
}

type GlideTemplateJsonTree = {
  id: string
  css_classes: { [key: string]: string }
  type: string
  visible: string
  child_nodes: GlideTemplateJsonTree[]
  configuration: Config
  additional_items?: {
    tag_name?: string
  }
}

type GridComponentToRender = {
  componentToRender: FunctionComponent<any> | string
  extraProps?: Record<string, unknown>
}

type GridCalculationInputs = {
  templateName: TemplateName
  className?: string
  tagName?: string
  isFullBleedArticle?: boolean
}

type TemplateRendererParams = {
  components: Record<Component, FunctionComponent<any>>
  templateName: TemplateName
  templateData?: TemplateData
}

type CreatedReactElement = DetailedReactHTMLElement<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
type CreateReactElement = (
  type: FunctionComponent<any> | string,
  props: Record<string, unknown>,
  children: ReactElement[] | null
) => CreatedReactElement

const getClassNames = (cssClasses: { [key: string]: string }): string =>
  Object.values(cssClasses).toString().replace(',', ' ')

const calculateGridComponentToRender = ({
  templateName,
  className = '',
  tagName = '',
}: GridCalculationInputs): GridComponentToRender => {
  if (templateName === 'Reviews Product Listing') {
    return getProductListingGrid(tagName, className)
  }

  if (determineTemplateType(templateName) === 'Article') {
    return getArticleGrid({ tagName, className, templateName })
  }

  return tagName === 'grid'
    ? { componentToRender: Grid, extraProps: { className } }
    : { componentToRender: tagName || 'div', extraProps: { className } }
}
