import type { FunctionComponent } from 'react'
import { BaseLink, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { groupLinksByType } from '../../utils/group-links-by-type'
import styles from './AssociatedArticlesImages.module.scss'

export const AssociatedArticlesImages: FunctionComponent<Props> = ({
  links,
  title,
  isOrdered = false,
  maxNumLinks = 5,
  componentType,
  position = 'bottom',
  className,
}) => {
  if (!links?.length || !title) {
    return null
  }

  const ListElement = isOrdered ? 'ol' : 'ul'
  const groupedLinks =
    (groupLinksByType(links, { standard: maxNumLinks }) as Array<ArticleLink>) || []

  return (
    <div
      className={classnames(styles.associatedArticleImageLinksWrapper, className)}
      data-testid="associated-article-links-wrapper"
    >
      <section className={className} data-testid="multi-image-link-wrapper">
        <Typography
          tag="h2"
          textStyle="sb-text-heading-small"
          className="sb-padding-bottom-3"
          data-testid="multi-link-title"
        >
          {title}
        </Typography>

        {groupedLinks.length && (
          <ListElement data-testid="list-element" className={styles.multiLinkList}>
            {renderLinks({ links: groupedLinks, componentType, position })}
          </ListElement>
        )}
      </section>
    </div>
  )
}

///////// IMPLEMENTATION /////////

const renderLinks = ({ links, componentType, position }: RenderLinks) =>
  links.map((link, index) => {
    const { href, img, text } = link

    return (
      <li key={`${text}-${index}`} className={styles.multiLinkListItem}>
        <BaseLink
          appearance="secondary"
          aria-label={text}
          className={classnames('sb-link-secondary', styles.imageTextWrapper)}
          data-testid="navigation-link"
          data-which-id={componentType && `${componentType}-${position}-link-${index + 1}`}
          href={href}
        >
          <Picture
            alt=""
            aspectRatioMain="four-to-three"
            aspectRatioSm="one-to-one"
            className={styles.image}
            data-testid="navigation-link-image"
            src={img}
          />
          <Typography
            className={styles.text}
            tag="span"
            textStyle="sb-text-interface-body-default-regular"
          >
            <span className="sb-link-animation-wrapper">{text}</span>
          </Typography>
        </BaseLink>
      </li>
    )
  })

type Props = {
  links?: ArticleLink[]
  title: string
  isOrdered?: boolean
  maxNumLinks?: number
  componentType: string
  position: string
  className?: string
}

type RenderLinks = {
  links: ArticleLink[]
  componentType: string
  position: string
}

type ArticleLink = {
  href: string
  text: string
  img: string
}
