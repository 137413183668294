import React from 'react'
import { Picture } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './LegacyCTA.module.scss'

export const LegacyCTA = ({ image, url, linkAnchor }: LegacyCTAProps) => {
  if (!image?.src || !url || !linkAnchor) {
    return null
  }

  return (
    <a className={styles.legacyCta} href={url} id={linkAnchor} target="_blank">
      <Picture {...image} />
    </a>
  )
}

///////// IMPLEMENTATION /////////

type LegacyCTAProps = {
  image: ImageWithSources
  linkAnchor: string
  url: string
}
