import type { FunctionComponent } from 'react'
import React from 'react'
import { Quote as SBQuote } from '@which/seatbelt'

export const Quote: FunctionComponent<Props> = ({ quote, authorName, jobTitle }) => (
  <SBQuote quote={quote} authorName={authorName} jobTitle={jobTitle} />
)

///////// IMPLEMENTATION /////////

type Props = {
  quote: string
  authorName?: string
  jobTitle?: string
}
