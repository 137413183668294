import type { FunctionComponent } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { Author as SBAuthor } from '@which/seatbelt'

import classnames from 'classnames'

import type { Author } from '../../../../../generated/frontend'
import styles from './Fact.module.scss'

// To be Replaced by Seatbelt component (doesn't currently allow an image)
export const Fact: FunctionComponent<FactProps> = ({
  title,
  body,
  author,
  headingTextStyle = 'sb-text-heading-medium',
  descriptionClassName,
}) => {
  return (
    <div className={classnames(styles.container)} data-testid="fact">
      <SBAuthor
        name=""
        className={styles.pre}
        src={author.image?.src}
        sources={author.image?.sources}
      />

      <Typography tag="p" textStyle={headingTextStyle} className={styles.title}>
        {title}
      </Typography>

      <Typography
        tag="p"
        textStyle="sb-text-body-default-regular"
        className={classnames(styles.body, descriptionClassName)}
      >
        {body}
      </Typography>
    </div>
  )
}

///IMPLEMENTATION////

export type FactProps = {
  title: string
  body: string
  author: Author
  headingTextStyle?: 'sb-text-heading-medium' | 'sb-text-heading-standfirst'
  descriptionClassName?: string
}
