import { useEffect } from 'react'
import { getCookie } from '@which/shared'

import type { GeneralSize } from '../../shared/types'

export const useDfpSlot = ({ dfp, path, size, id, isOOP, pos, panelName }: Props) => {
  useEffect(() => {
    if (!window || !dfp || !id || !panelName) {
      return
    }

    const googletag = window.googletag || { cmd: [] }
    const dfpSlots = window.dfpSlots || { triggers: { [panelName]: { triggered: false } } }

    if (dfpSlots[panelName]) {
      googletag.destroySlots([dfpSlots[panelName]])
      dfpSlots[panelName] = null
    }

    const cookieConsent =
      window.getFunctionalPerformance &&
      window.getFunctionalPerformance(getCookie(document.cookie, 'OptanonConsent')) === 'denied'
        ? true
        : false

    dfpSlots.triggers[panelName] = {
      triggered: false,
      fn: () => {
        dfpSlots.triggers[panelName].triggered = true

        if (!document.getElementById(id)) {
          return
        }

        googletag.cmd.push(() => {
          const mapping = googletag
            .sizeMapping()
            .addSize([1270, 0], [[600, 250]])
            .addSize([0, 0], [[300, 250]])
            .build()

          dfpSlots[panelName] =
            !isOOP && size
              ? googletag.defineSlot(path, size, id)
              : googletag.defineOutOfPageSlot(path, id)

          if (!isOOP) {
            dfpSlots[panelName].defineSizeMapping(mapping)
          }

          dfpSlots[panelName]
            .setTargeting('pos', [pos])
            .setTargeting('vertical', [dfp.vertical])
            .setTargeting('sub_vertical', [dfp.sub_vertical])
            .setTargeting('category', [dfp.category])
            .setTargeting('tags', dfp.tags)
            .setTargeting('login_status', [dfp.login_status])
            .setTargeting('page_type', [dfp.page_type])
            .setTargeting('content_id', [dfp.content_id])
            .setTargeting('consent', [cookieConsent])
            .addService(googletag.pubads())

          googletag.pubads().enableSingleRequest()
          googletag.enableServices()

          googletag.pubads().setPrivacySettings({
            limitedAds: cookieConsent,
          })
        })

        googletag.cmd.push(function () {
          googletag.display(id)
        })
      },
    }

    dfpSlots.triggers[panelName].fn()

    if (window.dfpSlots) {
      Object.assign(window.dfpSlots.triggers, { [panelName]: dfpSlots.triggers[panelName] })
    } else {
      window.dfpSlots = dfpSlots
    }
  }, [dfp, path, size, id, isOOP, pos, panelName])
}

type Dfp = {
  vertical?: string
  sub_vertical?: string
  category?: string
  page_type?: string
  tags?: string[] | string
  keywords?: string[] | string
  login_status?: string
  content_id?: string
}

type Props = {
  dfp: Dfp
  path: string
  size?: GeneralSize
  id: string
  isOOP?: boolean
  pos?: string
  panelName: string
}
