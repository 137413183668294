import { type ReactElement, useRef, useState } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './Tabs.module.scss'

interface Props {
  tabs: TabData[]
  onChange?: (idx: number, title: string) => void
}

interface TabData {
  title: string
  content: ReactElement
}

export function Tabs({ tabs, onChange }: Props) {
  const [activeTab, setActiveTab] = useState(0)

  // prefix is a unique string to use in the ids. This is so we have ids needed for aria attributes,
  // but they are always unique in case we have multiple components on one page
  const prefix = useRef(String(Math.random())).current

  const handleClick = (idx: number) => {
    setActiveTab(idx)
    if (onChange) {
      onChange(idx, tabs[idx].title)
    }
  }

  return (
    <div className={styles.tabWrapper}>
      <ul role="tablist" className={styles.tabList}>
        {tabs.map((t, idx) => (
          <li key={t.title} className={styles.tab}>
            <button
              role="tab"
              aria-selected={activeTab === idx}
              aria-controls={getPanelId(prefix, idx)}
              onClick={() => handleClick(idx)}
              id={getTabId(prefix, idx)}
              className={classnames({ [styles.isActive]: activeTab === idx })}
            >
              {t.title}
            </button>
          </li>
        ))}
      </ul>
      <div>
        {tabs.map((t, idx) => (
          <div
            key={t.title}
            aria-labelledby={getTabId(prefix, idx)}
            id={getPanelId(prefix, idx)}
            className={classnames(styles.panel, { [styles.isActive]: idx === activeTab })}
            role="tabpanel"
            aria-hidden={idx !== activeTab}
          >
            {t.content}
          </div>
        ))}
      </div>
    </div>
  )
}

function getTabId(prefix: string, idx: number) {
  return `${prefix}-tab-${idx}`
}
function getPanelId(prefix: string, idx: number) {
  return `${prefix}-panel-${idx}`
}
