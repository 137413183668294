import type { FunctionComponent } from 'react'
import React from 'react'
import { Download as SBDownload } from '@which/seatbelt'

export const Download: FunctionComponent<Props> = ({ file, label }) => {
  if (!file) {
    return null
  }

  return <SBDownload file={file} label={label} buttonAppearance="primary" />
}

///////// IMPLEMENTATION /////////

type Props = {
  file: {
    path: string
    size: number
    type: string
  }
  label?: string
}
