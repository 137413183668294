import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'

import { loadScript } from '../../../../shared/utils/loadScript'
import { removeScriptTag } from '../../../../shared/utils/remove-script-tag'
import { isValidUrl } from '../../utils/isValidUrl'
import style from './SquirrelEmbed.module.scss'

/**
 * To prevent the SquirrelEmbed DOM manipulation script's changes being overwritten by React on hydrate,
 * this component is only rendered on the client side.
 */

type Props = {
  squirrelId: string
  squirrelMobileDealsId: string
  squirrelPlaylistId: string
  customCSS: string
}

export const SquirrelEmbed: FunctionComponent<Props> = ({
  squirrelId,
  squirrelMobileDealsId,
  squirrelPlaylistId,
  customCSS,
}) => {
  const { SQUIRREL_WIDGET_SCRIPT_SRC = '' } = process.env
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (isValidUrl(SQUIRREL_WIDGET_SCRIPT_SRC) && hasMounted) {
      loadScript({
        src: SQUIRREL_WIDGET_SCRIPT_SRC,
      })
    }

    return () => {
      removeScriptTag(SQUIRREL_WIDGET_SCRIPT_SRC)
    }
  }, [SQUIRREL_WIDGET_SCRIPT_SRC, hasMounted])

  if (!hasMounted) {
    return null
  }

  return (
    <div className={style.squirrelEmbedWrapper}>
      <div
        className="squirrel_widget"
        data-squirrel-id={squirrelId}
        data-squirrel-mobile-widget-id={squirrelMobileDealsId}
        data-squirrel-playlist-id={squirrelPlaylistId}
        data-testid="squirrel"
        data-loaded="false"
        data-style-id={customCSS}
      />
    </div>
  )
}
