import type { FunctionComponent } from 'react'
import React from 'react'
import { Callout as SBCallout } from '@which/seatbelt'

import type { Callout as CalloutType } from '../../../generated/frontend'

export const Callout: FunctionComponent<Props> = ({
  byline,
  description,
  imageFormat,
  items,
  title,
}) => (
  <div data-testid="callout">
    <SBCallout
      byline={byline}
      imageAspectRatio={imageFormat === 'rectangle' ? 'two-to-one' : 'one-to-one'}
      title={title}
      description={description}
      cards={items}
    />
  </div>
)

///////// IMPLEMENTATION /////////

type Props = CalloutType
