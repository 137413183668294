import type { ProductOffer } from '../../generated/frontend'

export const formatRetailers = (offers: ProductOffer[]): ProductOffer[] =>
  offers
    .filter((offer) => !!offer.url && offer.availableInStock)
    .sort((a, b) => {
      const result = a.priceValue - b.priceValue

      return result !== 0 ? result : a.retailer.name?.localeCompare(b.retailer.name)
    })
    .slice(0, 3)
