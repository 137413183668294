export const groupLinksByType = (links: Links, maxLinksLength?: LinksLength) => {
  if (!links?.length) {
    return null
  }

  const categorisedLinks = links.map((link) => ({ ...link, type: link.type ?? 'standard' }))

  const [bestBuy, dontBuy, standard] = linkTypes.map((linkType) =>
    categorisedLinks.filter((link) => link.type === linkType)
  )

  const linksLength = { ...defaultLinksLength, ...maxLinksLength }

  const productLinks = [...bestBuy, ...dontBuy].slice(0, linksLength.product)
  const standardLinks = [...standard].slice(0, linksLength.standard)

  return [...productLinks, ...standardLinks]
}

///////// IMPLEMENTATION /////////

const linkTypes = ['bestBuy', 'dontBuy', 'standard']

const defaultLinksLength = {
  standard: 6,
  product: 2,
}

type LinksLength = {
  standard?: number
  product?: number
}

type LinkTypes = 'bestBuy' | 'dontBuy' | 'standard'

export type LinkItem = {
  text: string
  href: string
  img?: string
  type?: LinkTypes
}

export type Links = LinkItem[] | null
