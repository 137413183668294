import type { FC } from 'react'
import { Button, TypographyV2 as Typography } from '@which/seatbelt'
import { FilterFunnelIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classNames from 'classnames'

import { Badge } from '../../../../../shared/components/Badge/Badge'
import styles from './ButtonWithCount.module.scss'

export const ButtonWithCount: FC<ButtonWithCountProps> = ({
  count = 0,
  onClick,
  className,
  disabled = false,
  filterButton = false,
  children,
  ...rest
}) => (
  <Button
    appearance="primary"
    className={classNames(styles.filterButtonInSortby, className)}
    onClick={onClick}
    disabled={disabled}
    aria-disabled={disabled}
    {...rest}
  >
    {count > 0 && (
      <Badge
        colour="blue"
        backgroundColour="light"
        className={classNames(styles.badge, filterButton && styles.filterBadge)}
      >
        <Typography tag="span" textStyle="sb-text-body-small-regular">
          {count}
        </Typography>
      </Badge>
    )}
    {count === 0 && filterButton && <FilterFunnelIcon className={styles.filterFunnel} />}
    {children}
  </Button>
)

///////// IMPLEMENTATION /////////

type ButtonWithCountProps = {
  count?: number
  onClick: () => void
  className?: string
  disabled?: boolean
  filterButton?: boolean
}
