import type { FunctionComponent } from 'react'
import React from 'react'
import { Heading, TypographyV2 as Typography } from '@which/seatbelt'
import { DownloadFileIcon } from '@which/seatbelt/src/components/Icons/Social/DownloadFile'

import classNames from 'classnames'

import styles from './PIArticleDownload.module.scss'

export const PIArticleDownload: FunctionComponent<Props> = ({ files, className }) => {
  if (files?.length === 0 || !files) {
    return null
  }

  const renderDownloadLinks = (file: fileProps) => {
    const { label, link, type } = file

    return (
      <div
        className={styles.linkWrapper}
        data-testid="ea-article-download"
        key={`download-${type}`}
      >
        <a className={styles.link} href={link} data-testid={'ea-download-link'} target="_blank">
          <DownloadFileIcon className={styles.downloadFileIcon} />
          <Typography textStyle="sb-text-body-default-regular">
            <span className={styles.linkText}>{label}</span>
          </Typography>
        </a>
      </div>
    )
  }

  return (
    <div
      data-testid="ea-article-downloads"
      className={classNames(styles.downloadsWrapper, className)}
    >
      <Heading
        data-testid="ea-checkbox-label"
        heading="Downloads"
        headingTag="h2"
        headingType="medium"
      />
      {files.map((file) => renderDownloadLinks(file))}
    </div>
  )
}

type fileProps = {
  link?: string
  label?: string
  type?: string
}

export type Props = {
  files?: fileProps[]
  className?: string
}
