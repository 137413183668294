import { useLocalStorage } from 'react-use'

import type { Comparison } from '../types/ReviewsCompareTray'

export const useCompareLocalStorage = (
  cookieStorageName: string,
  taxonomySlug: string,
  compareTrayItems: Comparison[] = []
) => {
  const [storageValue, setStorageValue] = useLocalStorage(cookieStorageName, {})

  const addProductToStorage = ({ manufacturer, model, slug, businessKey }: Comparison) => {
    const newStorageValue = { ...storageValue }
    newStorageValue[taxonomySlug] = [
      ...compareTrayItems,
      { manufacturer, model, slug, businessKey },
    ]
    setStorageValue(newStorageValue)
  }

  const removeCategoryFromStorage = () => {
    const newStorage = { ...storageValue }
    delete newStorage[taxonomySlug]
    setStorageValue(newStorage)
  }

  const removeProductFromStorage = (businessKey: string) => {
    const newCompareTrayItems = compareTrayItems.filter((item) => item.businessKey !== businessKey)

    if (newCompareTrayItems.length) {
      const newStorage = { ...storageValue }
      newStorage[taxonomySlug] = newCompareTrayItems
      setStorageValue(newStorage)
    } else {
      removeCategoryFromStorage()
    }
  }

  return {
    storageValue,
    addProductToStorage,
    removeCategoryFromStorage,
    removeProductFromStorage,
  }
}
