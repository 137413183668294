import type { FunctionComponent } from 'react'
import React from 'react'
import type { AspectRatio } from '@which/seatbelt'
import { ContentCardV2 } from '@which/seatbelt'

import styles from './PIMoreFromItem.module.scss'

export const PIMoreFromItem: FunctionComponent<PIMoreFromItemProps> = ({
  title,
  standfirst,
  image,
  url,
}) => {
  const aspectRatio: AspectRatio = 'one-to-one'

  const imgObj =
    image && image?.src
      ? {
          imageAlt: image.alt,
          lazyLoad: false,
          str: image.src,
          aspectRatioMain: aspectRatio,
        }
      : null

  return (
    <div className={styles.setSquareImage}>
      <ContentCardV2
        title={title}
        titleTag={'h3'}
        description={standfirst}
        imgObj={imgObj}
        primaryLink={url}
        arrangement={'center'}
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type PIMoreFromItemProps = {
  title: string
  standfirst: string
  image: { src: string; alt: string }
  url: string
}
