import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useToggle } from 'react-use'
import { TypographyV2 as Typography } from '@which/seatbelt'
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  CrossIcon,
  NavigationalHomeIcon,
} from '@which/seatbelt/src/components/Icons/Navigational'
import type { Breadcrumb as BreadcrumbType, BreadcrumbLink, LocalNavigation } from '@which/shared'

import classnames from 'classnames'

import { useFullUrl } from '../../hooks/useFullUrl'
import { checkSessionStorageExists } from '../../utils/checkSessionStorageExists'
import { Link } from '../Link'
import styles from './Breadcrumb.module.scss'
import { BreadcrumbMenu } from './BreadcrumbMenu'
import { breadcrumbJsonLd } from './utils/breadcrumbJsonLd'

export const Breadcrumb: FunctionComponent<Props> = ({
  heading,
  links,
  currentPage,
  baseUrl,
  localNavProps = null,
  className = '',
}) => {
  const { getFullUrl } = useFullUrl()
  const urlSplit = getFullUrl().split('/')

  const [urlLink, setUrlLink] = useState<BreadcrumbLink[]>(links)
  const [open, toggleOpen] = useToggle(false)
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
  const Icon = open ? CrossIcon : ChevronDownIcon
  const headerOnly = !urlLink || !urlLink?.length
  const menuNavProps = mapNavigation(localNavProps, { url: getFullUrl() })

  useEffect(() => {
    const policyAndInsightPage = checkSessionStorageExists('policyAndInsightPage')
      ? sessionStorage.getItem('policyAndInsightPage')
      : null

    const previousUrl = checkSessionStorageExists('policyAndInsightPage')
      ? sessionStorage.getItem('pageUrl')
      : null

    if (
      urlSplit[3] &&
      urlSplit[3] === 'policy-and-insight' &&
      policyAndInsightPage &&
      policyAndInsightPage === 'search' &&
      previousUrl &&
      !urlSplit[4].includes('search')
    ) {
      setUrlLink([{ text: 'Back to search results', href: previousUrl }])

      if (checkSessionStorageExists('policyAndInsightPage')) {
        sessionStorage.removeItem('policyAndInsightPage')
      }

      if (checkSessionStorageExists('pageUrl')) {
        sessionStorage.removeItem('pageUrl')
      }
    }
  }, [urlSplit])

  return (
    <section className={classnames(className, styles.breadcrumb)} data-testid="breadcrumb">
      {heading && (
        <header className={classnames(styles.breadcrumb, className)}>
          <Helmet>
            <script type="application/ld+json">
              {breadcrumbJsonLd({
                heading: heading,
                links: urlLink,
                currentPage: currentPage,
                baseUrl: baseUrl,
              })}
            </script>
          </Helmet>
          <div className={styles.breadcrumbWrapper}>
            <nav aria-label="Breadcrumb" className={styles.breadcrumbNav}>
              <ol className={styles.breadcrumbList}>
                <li data-testid="link-home" className={styles.hideOnMobile}>
                  <HomeIcon />
                </li>
                {heading.text && (
                  <>
                    {heading.href ? (
                      <li
                        key={heading.text}
                        className={classnames(
                          styles.breadcrumbItem,
                          urlLink && urlLink?.length && styles.hideOnMobile
                        )}
                        data-testid={`link-${heading.text.replace(/\s/g, '').toLowerCase()}`}
                      >
                        <Link
                          href={heading.href}
                          linkStyle="primary"
                          textStyle="sb-text-interface-body-small-regular"
                          data-testid="breadcrumb-header-title-link"
                          data-baseurl={baseUrl}
                          data-which-id="breadcrumb"
                          className={styles.breadcrumbHeaderLink}
                          includeAnimation={true}
                          {...(headerOnly && { icon: ArrowLeftIcon })}
                          type="NavigationLink"
                        >
                          {heading.text}
                        </Link>
                      </li>
                    ) : (
                      <>
                        {headerOnly && (
                          <li data-testid="link-home" className={styles.breadcrumbHomeMobile}>
                            <HomeIcon />
                          </li>
                        )}
                        <li
                          key={heading.text}
                          data-testid={`link-${heading.text.replace(/\s/g, '').toLowerCase()}`}
                          className={classnames(
                            urlLink.length ? styles.hideOnMobile : '',
                            styles.breadcrumbItemVertical
                          )}
                        >
                          <Typography
                            textStyle="sb-text-interface-body-small-regular"
                            tag="span"
                            data-testid="breadcrumb-header-title-no-link"
                            className={styles.breadcrumbHeaderLink}
                          >
                            {heading.text}
                          </Typography>
                        </li>
                      </>
                    )}{' '}
                  </>
                )}
                {urlLink && (
                  <>
                    {urlLink.map(({ href, text }, index) => (
                      <li
                        key={text}
                        className={classnames(
                          styles.breadcrumbItem,
                          !shouldShowUrlLinkOnMobile(index, urlLink.length) && styles.hideOnMobile
                        )}
                        data-testid={`link-${text.replace(/\s/g, '').toLowerCase()}`}
                      >
                        <Link
                          href={href}
                          linkStyle="primary"
                          textStyle="sb-text-interface-body-small-regular"
                          data-baseurl={baseUrl && getBaseUrl(href, baseUrl)}
                          data-which-id="breadcrumb"
                          data-testid="breadcrumb-link"
                          className={styles.breadcrumbItemLink}
                          {...(shouldShowUrlLinkOnMobile(index, urlLink.length) && {
                            icon: ArrowLeftIcon,
                          })}
                          type="NavigationLink"
                        >
                          {text}
                        </Link>
                      </li>
                    ))}
                    {
                      // Note: The below current page <li> element does not render in the UI
                      // It's disabled via CSS display: none and only enabled for printing
                      currentPage?.text && (
                        <li
                          aria-hidden
                          className={classnames(
                            styles.breadcrumbItem,
                            styles.breadcrumbCurrentPage
                          )}
                          data-testid="current-page-link"
                        >
                          <Link
                            href={currentPage.href}
                            appearance="secondary"
                            textStyle="sb-text-interface-body-small-regular"
                            data-baseurl={getBaseUrl(currentPage.href, baseUrl || '')}
                          >
                            {currentPage.text}
                            type="NavigationLink"
                          </Link>
                        </li>
                      )
                    }
                  </>
                )}
              </ol>
            </nav>
            {menuNavProps && (
              <button
                aria-expanded={open}
                className={classnames(styles.breadcrumbButton, open && styles.breadcrumbButtonOpen)}
                onClick={toggleOpen}
                type="button"
                data-testid="breadcrumb-menu-button"
              >
                <Typography
                  textStyle="sb-text-body-default-strong"
                  tag="span"
                  className={styles.breadcrumbButtonText}
                >
                  Menu
                </Typography>
                <Icon className={styles.breadcrumbButtonIcon} />
              </button>
            )}
          </div>
        </header>
      )}
      {open && menuNavProps && (
        <>
          <div className={styles.breadcrumbScrim} data-testid="breadcrumb-header-scrim" />
          {typeof selectedIndex !== 'undefined' && <div className={styles.breadcrumbGradient} />}
          <BreadcrumbMenu
            className={styles.breadcrumbBreadcrumbMenu}
            {...menuNavProps}
            {...{
              toggleOpen,
              selectedIndex,
              setSelectedIndex,
            }}
          />
        </>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = BreadcrumbType & {
  className?: string
  localNavProps?: null | LocalNavigation[]
}

export type BreadcrumbProps = {
  heading?: {
    href?: string
    text: string
  }
  links?: BreadcrumbLink[]
  currentPage?: BreadcrumbLink | null
  baseUrl?: string
  className?: string
}

// @TODO: PIE We need to remove this - everything below seems to be consumer rights only
// However does seem to be used on certain money pages (article, article-listing, sub-vertical) via moneyLocalNavigation query
// The Breadcrumb component should be generic
const mapNavigation = (navigation: null | LocalNavigation[], options: { url: string }) => {
  if (!navigation) {
    return null
  }

  const sectionLinks = navigation.filter((item) => item.items !== null)
  const navFooterLink = navigation.find((item) => item.items === null)

  return {
    // verticalName renders wrong aria label on non cr usage via <BreadcrumbMenu />
    verticalName: 'Consumer Rights',
    currentUrl: options.url,
    sections: sectionLinks.map((item) => ({
      label: item.text,
      breadCrumbMenuContentProps: {
        links: item.items?.links?.map(({ text, href }) => ({ label: text, href })) ?? [],
        viewAllLink: item.items?.viewAllLink
          ? {
              label: item.items.viewAllLink.text ?? '',
              href: item.items.viewAllLink.href ?? '',
            }
          : null,
      },
    })),
    navFooterLink: navFooterLink
      ? {
          linkText: navFooterLink.text,
          linkHref: navFooterLink.href,
          iconCategory: 'navigational',
          iconName: 'phone',
        }
      : null,
  }
}

const getBaseUrl = (href: string, baseUrl: string) => (!href.startsWith(baseUrl) ? baseUrl : '')

const HomeIcon: FunctionComponent = () => (
  <Link href="/" type="BaseLink" includeAnimation={false}>
    <span>
      <NavigationalHomeIcon />
    </span>
    <span className={styles.breadcrumbHomeAriaText}>Home</span>
  </Link>
)

//TODO CDS-63: Better solution for this
const shouldShowUrlLinkOnMobile = (index: number, numItems: number) => {
  const isProductHubArticleOrProductPage = numItems === 3

  if (isProductHubArticleOrProductPage) {
    return index === numItems - 2
  } else {
    return index === numItems - 1
  }
}
