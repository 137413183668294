import type { FunctionComponent } from 'react'
import React from 'react'
import { Picture, SignupButton, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './ProductHubSubscribe.module.scss'

export const ProductHubSubscribe: FunctionComponent = () => (
  <div className={styles.Subscribe}>
    <Picture
      src="https://media.which.gpp.io/prod/images/original/f677308c4577-php-best-buy-guage-1.jpg"
      className={styles.SubscribeImage}
    />
    <div className={styles.SubscribeCopy}>
      <Typography textStyle="sb-text-heading-small" className={styles.SubscribeCopyTitle}>
        Members see more
      </Typography>
      <Typography textStyle="sb-text-body-default-regular">
        Which? members can access our full expert reviews. See Best Buys, Great Value products,
        Don't Buys and more — plus, complete test results and the top pros and cons.
      </Typography>
    </div>
    <div className={styles.SubscribeCTA}>
      <SignupButton buttonLink={{ text: 'Unlock reviews' }} withPadlock={true} withLogin={true} />
    </div>
  </div>
)
