import type { FunctionComponent } from 'react'
import React from 'react'
import { Fact as SBFact } from '@which/seatbelt'

export const Fact: FunctionComponent<Props> = ({ title, body }) => <SBFact {...{ title, body }} />

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  body: string
}
