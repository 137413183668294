import type { FunctionComponent } from 'react'
import React from 'react'

import type { GeneralSize } from '../../../shared/types'
import { useDfpSlot } from '../../hooks/useDfpSlot'
import type { PageInfo } from '../../types'
import styles from './GamPanel.module.scss'

export const GamPanel: FunctionComponent<Props> = ({
  pageInfo,
  gamId,
  isOOP,
  login,
  pos,
  panelName,
}) => {
  const dfp = {
    vertical: pageInfo?.vertical ?? '',
    sub_vertical: pageInfo?.sub_vertical ?? '',
    category: pageInfo?.category ?? '',
    tags: JSON.stringify(pageInfo?.tags ?? []),
    keywords: JSON.stringify(pageInfo?.keywords ?? []),
    login_status: login?.loginStatus ?? 'loggedOut',
    content_id: pageInfo?.content_id ?? '',
    content_type: pageInfo?.content_type ?? '',
    page_type: pageInfo?.pageType ?? '',
  }

  const path =
    dfp.vertical === 'homepage'
      ? '/30585109/cdn/homepage'
      : `/30585109/cdn/${dfp.vertical}/${dfp.sub_vertical}/${dfp.category}/${dfp.page_type}`
  const size: GeneralSize = [600, 250]

  useDfpSlot({ dfp, path, size, id: gamId, isOOP, pos, panelName })

  return <div data-testid={gamId} id={gamId} className={styles.gamWrapper} />
}

export type Props = {
  pageInfo: PageInfo
  gamId: string
  isOOP?: boolean
  login?: { loginStatus: string }
  pos?: string
  panelName: string
}
