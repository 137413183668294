import type { FunctionComponent } from 'react'
import { CategoryHero, Grid, GridItem } from '@which/seatbelt'
import type { Breadcrumb } from '@which/shared'

import { useFeatureValue } from '@growthbook/growthbook-react'

import type { HelpAndAdvice, ImageWithSources, ProductReviews } from '../../../generated/frontend'
import { Breadcrumb as Breadcrumbs } from '../../../shared'
import { type BannerConfig, TopBanner } from '../../reviews/product-listing/components/TopBanner'
import { ProductHubHelpAndAdvice } from './ProductHubHelpAndAdvice/ProductHubHelpAndAdvice'
import styles from './ProductHubPageTemplate.module.scss'
import { ProductHubReviewsTemplate } from './ProductHubReviews/ProductHubReviewsTemplate'
import { ProductHubSubscribe } from './ProductHubSubscribe/ProductHubSubscribe'

export const ProductHubPageTemplate: FunctionComponent<ProductHubTemplateProps> = ({
  isLoggedIn,
  productReviews,
  helpAndAdvice,
  breadcrumb,
  header,
}) => {
  const bannerConfig = useFeatureValue<BannerConfig>('WHC-BF24-Banner', null)

  return (
    <>
      <Breadcrumbs {...breadcrumb} links={[...breadcrumb.links.slice(0, 2)]} />

      <div className={styles.categoryHeroBleed}>
        <div className="w-page-wrapper">
          <Grid includeGutters={false} className={styles.productHubGrid}>
            <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
              <CategoryHero
                heading={header.title || ''}
                description={header.description || ''}
                image={header.image.src === '' ? undefined : header.image}
              />
            </GridItem>
          </Grid>
        </div>
      </div>

      <Grid>
        <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
          <TopBanner
            bannerConfig={bannerConfig}
            categorySlug={productReviews.productSlug}
            fullWidth={true}
          />
        </GridItem>
      </Grid>

      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          {productReviews && productReviews.productData?.length > 0 && (
            <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
              <ProductHubReviewsTemplate productReviews={productReviews} />
            </GridItem>
          )}
          {!isLoggedIn && (
            <GridItem
              span={{ small: 'full-bleed', medium: 12, large: 12 }}
              columnStart={{ medium: 1, large: 1 }}
            >
              <ProductHubSubscribe />
            </GridItem>
          )}
        </Grid>
      </div>

      {helpAndAdvice && helpAndAdvice.topArticles?.length > 0 && (
        <div className={styles.sectionGreyBleed}>
          <div className="w-page-wrapper">
            <Grid includeGutters={false} className={styles.productHubGrid}>
              <GridItem
                span={{ small: 'full-bleed', medium: 12, large: 12 }}
                columnStart={{ medium: 1, large: 1 }}
              >
                <ProductHubHelpAndAdvice {...helpAndAdvice} />
              </GridItem>
            </Grid>
          </div>
        </div>
      )}
    </>
  )
}

export type ProductHubTemplateProps = {
  isLoggedIn: boolean
  productReviews: ProductReviews
  helpAndAdvice: HelpAndAdvice
  breadcrumb: Breadcrumb
  header: ProductHubHeader
}

type ProductHubHeader = {
  title: string
  description: string
  image: ImageWithSources
}
