import React from 'react'

export const twitter = (
  <g data-testid="svg-twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g>
      <circle fill="#1DA1F2" cx="16" cy="16" r="16"></circle>
      <path
        d="M12.2898723,25 C19.8372766,25 23.9646809,18.8449939 23.9646809,13.5077231 C23.9646809,13.3327993 23.9611064,13.1587132 23.9529362,12.9855487 C24.7541277,12.4154545 25.4505532,11.7041144 26,10.8941703 C25.2646809,11.2157017 24.4735319,11.4323458 23.6435745,11.5300284 C24.4906383,11.0300546 25.1411064,10.2387924 25.4478298,9.29572848 C24.6548936,9.75833831 23.7770213,10.0949494 22.842383,10.2761564 C22.093617,9.49100981 21.027234,9 19.847234,9 C17.5810213,9 15.7433191,10.8088865 15.7433191,13.0388301 C15.7433191,13.3558376 15.7794043,13.6640487 15.8497872,13.9597772 C12.4395745,13.7910527 9.41557447,12.183647 7.392,9.73974009 C7.03923404,10.3364749 6.83625532,11.0300546 6.83625532,11.7697946 C6.83625532,13.1711958 7.56068085,14.4082289 8.66221277,15.1319678 C7.9892766,15.1116103 7.35685106,14.9294817 6.80382979,14.6267161 C6.80289362,14.643555 6.80289362,14.6604777 6.80289362,14.6781544 C6.80289362,16.6345701 8.21753191,18.267695 10.0948936,18.6380677 C9.75029787,18.7303886 9.38757447,18.7801514 9.01319149,18.7801514 C8.74885106,18.7801514 8.49174468,18.7543485 8.24187234,18.7072665 C8.76417021,20.3119915 10.2788936,21.4798257 12.0751489,21.5126658 C10.6704681,22.5961379 8.9013617,23.2417141 6.97880851,23.2417141 C6.64774468,23.2417141 6.32110638,23.2230321 6,23.1857518 C7.816,24.3313018 9.97217021,25 12.2898723,25"
        fill="#FFFFFE"
      ></path>
    </g>
  </g>
)
