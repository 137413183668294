import React from 'react'

export const facebook = (
  <g data-testid="svg-facebook" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g>
      <circle fill="#3B5998" cx="16" cy="16" r="16"></circle>
      <path
        d="M22.0826484,20.5145631 L22.8082192,15.8446602 L18.2694064,15.8446602 L18.2694064,12.8141772 C18.2694064,11.5365801 18.9034018,10.2912621 20.9363744,10.2912621 L23,10.2912621 L23,6.31553398 C23,6.31553398 21.1271644,6 19.3366667,6 C15.5984749,6 13.1552511,8.23650485 13.1552511,12.2854369 L13.1552511,15.8446602 L9,15.8446602 L9,20.5145631 L13.1552511,20.5145631 L13.1552511,31.8037379 C13.9884429,31.9327913 14.842411,32 15.7123288,32 C16.5822466,32 17.4362146,31.9327913 18.2694064,31.8037379 L18.2694064,20.5145631 L22.0826484,20.5145631"
        fill="#FFFFFF"
      ></path>
    </g>
  </g>
)
