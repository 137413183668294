import React from 'react'
import type { Link } from 'react-router-dom'

const ReactRouterContext = React.createContext<RouterContextType>({})
export const ReactRouterProvider = ReactRouterContext.Provider
export const ReactRouterConsumer = ReactRouterContext.Consumer

export type RouterContextType = {
  checkRoute?: (href: string) => boolean
  Link?: typeof Link
}
