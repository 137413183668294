export const change = (
  <g fill="none" data-testid="svg-change">
    <path fill="#fff" d="M0 0h16v16H0z" />
    <path
      stroke="#0050B3"
      strokeWidth="2"
      d="M6 1 3 4m0 0 3 3M3 4h8c2 0 3 1 3 4M10 15l3-3m0 0-3-3m3 3H5c-2 0-3-1-3-4"
    />
  </g>
)
