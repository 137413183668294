import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink as SBButton, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './PIRole.module.scss'

export const PIRole: FunctionComponent<Props> = ({ headingText, buttons }) => (
  <div className={styles.piRole} data-testid="ea-role">
    <Typography textStyle="sb-text-body-default-strong">{headingText}</Typography>
    {buttons &&
      buttons.map((button) => {
        const { id, displayText, href } = button
        return (
          <SBButton
            appearance="secondary"
            className={styles.piRoleButton}
            data-testid="ea-role-buttons"
            data-which-id="quick-search-button"
            id={id}
            key={id}
            href={href}
          >
            {displayText}
          </SBButton>
        )
      })}
  </div>
)

type Props = {
  headingText: string
  buttons: Array<{ id: string; displayText: string; href: string }>
}
