import React, { useEffect } from 'react'

import styles from './GreenNCAPEmbed.module.scss'

export const GreenNCAPEmbed = () => {
  // this code was taken directly from the Green NCAP docs
  const messageEventHandler = (event: MessageEvent) => {
    if (event.origin === 'https://www.greenncap.com') {
      const iframe = document.getElementById('iframe-lca')
      if (event.data.action === 'resize' && iframe) {
        iframe.style.height = event.data.height + 'px'
      }
      if (event.data.action === 'scroll' && iframe) {
        window.scrollTo({ top: iframe.offsetTop + event.data.offset, behavior: 'smooth' })
      }
    } else {
      return
    }
  }

  useEffect(() => {
    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [])

  return (
    <iframe
      src="https://www.greenncap.com/lca-widget?apikey=b50c6fbbc0f17fbe"
      className={styles.GreenNCAPEmbedIframe}
      id="iframe-lca"
      data-testid="green-ncap-embed"
      title="Green ncap iFrame embed"
    />
  )
}
