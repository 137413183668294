import { createContext } from 'react'

// TODO: CDS consider renaming this provider to HeadersProvider
export const FullUrlContext = createContext<FullUrlContextType>({
  getFullUrl: () => '',
  userAgent: '',
})
export const FullUrlProvider = FullUrlContext.Provider

///////// IMPLEMENTATION /////////

type FullUrlContextType = {
  getFullUrl: () => string
  userAgent?: string
}
