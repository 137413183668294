/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const liveReportsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/news-live/:liveReportSlug',
    component: () => (
      <Page pagePath="live-report/LiveReportPage" template="Live Report" context="live-reports" />
    ),
  },
]
