import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'

import { loadScript } from '../../../../shared/utils/loadScript'

export const Pym: FunctionComponent<Props> = ({ whichToolId, whichToolUrl }) => {
  const pymSrc = 'https://pym.nprapps.org/pym.v1.min.js'

  useEffect(() => {
    let script: HTMLScriptElement | null = null

    loadScript({
      src: pymSrc,
      callback: () => {
        script = document.createElement('script')

        script.type = 'text/javascript'
        script.innerHTML = `var pymParent = new pym.Parent('${whichToolId}', '${whichToolUrl}', {})`

        document.body.appendChild(script)
      },
    })

    return () => {
      const pymScript = document.querySelector(`script[src="${pymSrc}"]`)

      // Remove script instance
      if (script) {
        document.body.removeChild(script)
        script = null
      }

      // Remove Pym script
      if (pymScript) {
        document.body.removeChild(pymScript)
      }
    }
  }, [whichToolId, whichToolUrl])

  return <div id={whichToolId}></div>
}

///////// IMPLEMENTATION /////////

type Props = {
  whichToolId: string
  whichToolUrl: string
}
