export const eyeClose = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M21.846 10.534a1 1 0 0 0-1.691-1.068l-.006.01-.028.04a15 15 0 0 1-.584.81 17 17 0 0 1-1.752 1.949C16.267 13.717 14.296 15 12.158 15c-2.147 0-4.2-1.292-5.803-2.742a19 19 0 0 1-2.367-2.597l-.156-.216-.006-.01h-.001a1 1 0 0 0-1.65 1.13L3 10c-.825.565-.826.564-.825.565l.002.003.003.005.012.016a7 7 0 0 0 .192.266 21 21 0 0 0 1.055 1.292l-1.146 1.146a1 1 0 1 0 1.414 1.414l1.129-1.128.177.162a16 16 0 0 0 1.872 1.47l-1.197 1.9a1 1 0 0 0 1.692 1.066l1.252-1.987a9 9 0 0 0 2.368.72V19a1 1 0 1 0 2 0v-2.048c1.062-.12 2.053-.457 2.956-.911l1.346 2.136a1 1 0 1 0 1.692-1.067l-1.337-2.12c.557-.409 1.06-.843 1.505-1.265l.076-.072 1.055 1.054a1 1 0 0 0 1.414-1.414l-1.103-1.103a20 20 0 0 0 1.046-1.363 12 12 0 0 0 .18-.268l.01-.017.003-.005zL21 10z"
      clipRule="evenodd"
    />
  </svg>
)
export const eyeOpen = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M6.607 15.604C5.021 14.3 3.815 12.8 3.224 12c.591-.8 1.797-2.3 3.383-3.604C8.251 7.043 10.18 6 12.175 6c2.001 0 3.876 1.05 5.448 2.405 1.519 1.31 2.644 2.81 3.182 3.595-.538.785-1.663 2.285-3.182 3.595C16.051 16.95 14.176 18 12.175 18c-1.995 0-3.924-1.043-5.568-2.396M12.175 4C9.503 4 7.128 5.377 5.336 6.851 3.528 8.34 2.188 10.03 1.566 10.88a1.89 1.89 0 0 0 0 2.242c.622.849 1.962 2.54 3.77 4.028C7.128 18.623 9.503 20 12.176 20c2.694 0 5.023-1.398 6.754-2.89 1.744-1.505 3.001-3.209 3.57-4.046a1.88 1.88 0 0 0 0-2.128c-.569-.837-1.826-2.54-3.57-4.045C17.198 5.398 14.87 4 12.175 4M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
      clipRule="evenodd"
    />
  </svg>
)
