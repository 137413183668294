/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const newsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/news/:year([0-9]{4})/:month([0-9]{2})/:articleSlug',
    component: () => (
      <Page pagePath="article/ArticlePage" template="Article News Single Page" context="news" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/news/article/:articleSlug',
    component: () => (
      <Page pagePath="article/ArticlePage" template="Article News Single Page" context="news" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(news)/:filterSlug(author)/:filterValue',
    component: () => (
      <Page
        pagePath="article-listing/ArticleListingPage"
        template="News Author Listing"
        context="news"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(news)/:filterSlug(tag)/:filterValue',
    component: () => (
      <Page
        pagePath="article-listing/ArticleListingPage"
        template="News Tag Listing"
        context="news"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(news)/latest',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="News Full Listing" context="news" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(news)/:subVerticalSlug',
    component: () => (
      <Page
        pagePath="sub-vertical/SubVerticalPage"
        template="News Category Listing"
        context="news"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/news/:subVerticalSlug/:categorySlug',
    component: () => (
      <Page
        pagePath="article-listing/ArticleListingPage"
        template="News Category Listing"
        context="news"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(news)',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="News Vertical" context="news" />
    ),
  },
]
