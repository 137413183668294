import { CookieName } from '../shared/CookieName'
import { WhichGbAttributes } from '../shared/WhichGbAttributes'

export const getExperimentAttributes = (): WhichGbAttributes => {
  const gbUserId = getCookieValue(CookieName.EXPERIMENTATION_USER_ID) || ''
  const wmCookie =
    getCookieValue(CookieName.WM_COOKIE_NAME) === 'True' ? 'True' : 'False'
  const memberID =
    getCookieValue(CookieName.MASTER_ID) ||
    getCookieValue(CookieName.IDENTITY_COOKIE)

  if (memberID) {
    return { id: gbUserId, wmCookie, memberID }
  }

  return { id: gbUserId, wmCookie }
}

// Function to get the value of a specific cookie by name
const getCookieValue = (name: string) => {
  const cookieString = document.cookie
  const cookies = cookieString.split('; ')
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=')
    if (cookieName === name) {
      return cookieValue
    }
  }

  return null
}
