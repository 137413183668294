import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './GridProductListing.module.scss'

export const GridProductListing: FunctionComponent<GridProps> = ({ className, children }) => (
  <div className={classnames(className)}>
    <section className={classnames(styles.grid, className)}>{children}</section>
  </div>
)

///////// IMPLEMENTATION /////////

export type GridProps = {
  className?: string
}
