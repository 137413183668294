import type { FunctionComponent } from 'react'
import React from 'react'
import { Picture as SBPicture } from '@which/seatbelt'

export const Picture: FunctionComponent<Props> = ({ src, alt = '', sources }) => (
  <SBPicture src={src} alt={alt} sources={sources} />
)

///////// IMPLEMENTATION /////////

type Props = {
  src: string
  alt?: string
  sources?: Source[]
}

type Source = {
  srcset?: string
  sizes?: string
  media?: string
}
