import React from 'react'
import { Redirect as RRRedirect } from 'react-router-dom'

import { Status } from '../StatusContext'

export const Redirect = ({ to, code = '301' }: Props) => (
  <Status code={code} to={to}>
    <RRRedirect to={to} />
  </Status>
)

///////// IMPLEMENTATION /////////

type Props = {
  to: string
  code?: string
}
