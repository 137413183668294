import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { InfoBox as SBInfoBox } from '@which/seatbelt'

import styles from './InfoBox.module.scss'

export const InfoBox: FunctionComponent<Props> = ({ children, type = '', label }) => {
  const infoBoxChildren = [] as ReactElement[]
  let heading

  const hasChildren = React.Children.count(children) > 0

  if (hasChildren && children) {
    const contentChildren = children[0]?.props?.children

    let headingFound = false
    React.Children.forEach(contentChildren, (childItem) => {
      if (!headingFound && childItem.type === 'h3') {
        heading = childItem.props.children
        headingFound = true
      } else {
        infoBoxChildren.push(childItem)
      }
    })
  }

  return hasChildren ? (
    <SBInfoBox
      className={styles.infoBox}
      heading={heading}
      byline={label}
      borderColour={colorMap[type.toLowerCase()]}
      children={infoBoxChildren}
    />
  ) : null
}

///////// IMPLEMENTATION /////////

type Props = {
  type?: string
  label?: string
}

const colorMap = {
  'info - blue': 'blue',
  'info - green': 'green',
  caution: 'yellow',
  warning: 'red',
}
