import type { ApolloError } from '@apollo/client'

export const getErrorStatusCode = (error: ApolloError): string => {
  const { graphQLErrors, networkError } = error

  if (networkError) {
    return '500'
  }
  /*
   * Any graphql errors not explicitly set to '500' or '404' by us are 500 errors
   * https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  const { code = '' } = graphQLErrors[0].extensions

  return ['500', '404', '403'].includes(code as string) ? (code as string) : '500'
}
