import type { FunctionComponent } from 'react'
import { Heading, TypographyV2 as Typography } from '@which/seatbelt'

import { Link } from '../../../../../../shared/components/Link'
import styles from './ProductHubLinkSection.module.scss'

export const ProductHubLinkSection: FunctionComponent<ProductHubLinkSectionProps> = ({
  heading,
  description,
  links,
}) => (
  <div className={styles.productHubLinkSection}>
    <div className={styles.heading} id={heading.toLowerCase().replaceAll(' ', '-')}>
      <Heading headingType="medium" headingTag="h3" heading={heading} />
    </div>

    <Typography textStyle="sb-text-body-default-regular" className={styles.description}>
      {description}
    </Typography>

    {links.length === 1 && (
      <Link
        appearance="primary"
        href={links[0].href}
        textStyle="sb-text-body-default-regular"
        data-which-id="producthub-link"
        data-section={heading}
        data-index={1}
      >
        {links[0].text}
      </Link>
    )}

    {links.length > 1 && (
      <ul className={styles.linkSectionList}>
        {links.map(({ text, href }, index) => (
          <li className={styles.linkSectionListItem} key={href}>
            <Link
              appearance="primary"
              href={href}
              textStyle="sb-text-body-default-regular"
              data-which-id="producthub-link"
              data-section={heading}
              data-index={index + 1}
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

export type ProductHubLinkSectionProps = {
  heading: string
  description?: string
  links: Array<{
    text: string
    href: string
  }>
}
