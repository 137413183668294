import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Accordion, Checkbox, TooltipWithButton, TypographyV2 } from '@which/seatbelt'
import { CrossBoldIcon, TickBoldIcon } from '@which/seatbelt/src/components/Icons'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { dynamicDatalayerPush, dynamicGa4DataLayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import { compareProductsStorageName } from '../../../../../../shared'
import { useCompareTray } from '../../../../../../shared/hooks/useCompareTray'
import { useMatchMedia } from '../../../../../../shared/hooks/useMatchMedia'
import { usePageProps } from '../../../../../../shared/usePageProps'
import { StarRating } from '../../../../product-page/components/StarRating'
import { StarRatingBadge } from '../../../../product-page/components/StarRatingBadge'
import type { ComparisonTableState } from '../../../ComparisonTableContext'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import {
  REMOVE_TECH_SPECS_COLUMN,
  TABLE_ROWS_TO_BE_REFINED,
} from '../../../reducers/comparisonTableActionTypes'
import type { OnTableRowCheckboxArgs } from '../types'
import { ComparisonPageExpertReviews } from './ComparisonPageExpertReviews'
import { ComparisonPageHeader } from './ComparisonPageHeader'
import { ComparisonPageProducts } from './ComparisonPageProducts'
import { ComparisonPageProsCons } from './ComparisonPageProsCons'
import { ComparisonPageSection } from './ComparisonPageSection'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonTableV2: FunctionComponent<ComparisonTablePageProps> = ({
  taxonomySlug,
  renderWithAccordion,
}) => {
  const {
    state: { productDetails, tableData, refineStatus, rowsToBeRefined },
    dispatch,
  } = useContext(ComparisonTableContext)

  const isDesktopOrAbove: boolean = useMatchMedia('(min-width: 1024px)')
  const renderExpertReview = useFeatureIsOn('whc-compare-expert-review')
  const renderProsAndCons = useFeatureIsOn('whc-compare-proscons')
  const useStarRatingBadge = useFeatureIsOn('whc-compare-star-rating')
  const useTickCrossIcons = useFeatureIsOn('whc-compare-tick-cross')
  const totalProductColumns = 4

  const [maxProductsToCompare, setMaxProductsToCompare] = useState(2)

  const filterProductDetails = () => {
    return Array.from(productDetails?.keys())?.splice(0, maxProductsToCompare)
  }

  const [productIndexesForComparison, setProductIndexesForComparison] =
    useState<number[]>(filterProductDetails())

  useEffect(() => {
    isDesktopOrAbove ? setMaxProductsToCompare(4) : setMaxProductsToCompare(2)
  }, [isDesktopOrAbove])

  useEffect(() => {
    setProductIndexesForComparison(filterProductDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxProductsToCompare, productDetails])

  const showRefine: boolean = isDesktopOrAbove && refineStatus === 'refine'

  const { userAccessState } = usePageProps()
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )
  const { compareTrayItems, removeProduct_CompareTrayHandler } = useCompareTray(
    compareProductsStorageName,
    taxonomySlug
  )

  const removeProductColumn = (businessKey: string, index?: number) => {
    removeProduct_CompareTrayHandler(businessKey, 'Remove From Compare - Compare Page')
    dispatch({
      type: REMOVE_TECH_SPECS_COLUMN,
      index,
    })

    setProductIndexesForComparison(filterProductDetails())
  }

  const hasOneProductBadge = useMemo(
    () => productDetails?.some((productDetail) => productDetail.badges.length),
    [productDetails]
  )

  const onTableRowCheckbox = ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => {
    dispatch({
      type: TABLE_ROWS_TO_BE_REFINED,
      row: value,
      sectionLabel,
    })

    dynamicDatalayerPush({
      eventCategory: 'Refine Results',
      eventAction: `${taxonomySlug} - ${sectionLabel.toLowerCase()} - ${rowLabel.toLowerCase()}`,
      eventLabel: `${!rowsToBeRefined[sectionLabel]?.includes(value)}`,
    })
  }

  const tooltipOpenHandler = useCallback(() => {
    dynamicGa4DataLayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Tooltip',
      eventLabel: `${taxonomySlug} - Product Card - Test Score`,
    })
  }, [taxonomySlug])

  const changeProductHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    indexToReplace: number
  ) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      const newIndex = parseInt(currentTarget?.value)

      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: currentTarget[currentTarget.selectedIndex].textContent ?? undefined,
      })

      setProductIndexesForComparison((currentIndexes) =>
        currentIndexes.map((currentIndex, i) => (i === indexToReplace ? newIndex : currentIndex))
      )
    }
  }

  const selectClickHandler = (event: React.MouseEvent<HTMLSelectElement>) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: 'change product',
      })
    }
  }

  const productDetailsForComparison = productIndexesForComparison
    .map((i) => productDetails[i])
    .filter(Boolean)

  const tableDataForComparison = tableData.map((comparisonTableData) => ({
    ...comparisonTableData,
    rows: comparisonTableData.rows.map((row) => ({
      ...row,
      rowValues: productIndexesForComparison.map((i) => row.rowValues[i]).filter(Boolean),
    })),
  }))

  const { testResults, techSpecs } = tableDataForComparison.reduce(
    (acc, item) => {
      if (item.dataType === 'test-result') {
        acc.testResults.push(item)
      } else {
        acc.techSpecs.push(item)
      }
      return acc
    },
    {
      testResults: [],
      techSpecs: [],
    } as {
      testResults: ComparisonTableState['tableData']
      techSpecs: ComparisonTableState['tableData']
    }
  )

  if (!productDetails?.length || !tableData?.length) {
    return null
  }

  return (
    <div
      className={classnames(styles.tableWrapper, {
        [styles.compareWithAccordion]: renderWithAccordion,
        [styles.tableWrapperDesktop]: isDesktopOrAbove,
      })}
      data-testid="comparison-table-v2"
    >
      <ComparisonPageHeader
        isDesktopOrAbove={isDesktopOrAbove}
        taxonomySlug={taxonomySlug}
        compareTrayItems={compareTrayItems}
        allProductDetails={productDetails.map((product, index) => ({
          businessKey: product.businessKey,
          manufacturer: product.manufacturer.name,
          model: product.model,
          compareIndex: index,
        }))}
        productIndexesForComparison={productIndexesForComparison}
        removeProductColumn={removeProductColumn}
        changeProductHandler={changeProductHandler}
        selectClickHandler={selectClickHandler}
      />

      <ComparisonPageProducts
        productDetailsForComparison={productDetailsForComparison}
        taxonomySlug={taxonomySlug}
        hasOneProductBadge={hasOneProductBadge}
        tooltipOpenHandler={tooltipOpenHandler}
        isDesktopOrAbove={isDesktopOrAbove}
        totalProductColumns={totalProductColumns}
      />

      {renderWithAccordion ? (
        <div className={styles.accordionWrapper} data-testid="accordion-wrapper">
          <Accordion>
            {/* Review as part of WHC-581 - Refactor ComparisonTableV2 */}
            {isPaidMember ? (
              <>
                {renderProsAndCons && productDetails && (
                  <ComparisonPageProsCons
                    pros={productDetailsForComparison.map((product) => product.pros)}
                    cons={productDetailsForComparison.map((product) => product.cons)}
                    businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
                    isDesktopOrAbove={isDesktopOrAbove}
                    totalProductColumns={totalProductColumns}
                    isPaidMember={isPaidMember}
                  />
                )}
                <ComparisonPageSection
                  label="Test results"
                  isDesktopOrAbove={isDesktopOrAbove}
                  tableData={testResults}
                  useTickCrossIcons={useTickCrossIcons}
                  useStarRatingBadge={useStarRatingBadge}
                  showRefine={showRefine}
                  onTableRowCheckbox={onTableRowCheckbox}
                  totalProductColumns={totalProductColumns}
                />
                {renderExpertReview && productDetails && (
                  <ComparisonPageExpertReviews
                    businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
                    editorialReviews={productDetailsForComparison.map(
                      (product) => product.editorialReview
                    )}
                    isDesktopOrAbove={isDesktopOrAbove}
                    totalProductColumns={totalProductColumns}
                  />
                )}
                <ComparisonPageSection
                  label="Specifications"
                  isDesktopOrAbove={isDesktopOrAbove}
                  tableData={techSpecs}
                  useTickCrossIcons={useTickCrossIcons}
                  useStarRatingBadge={useStarRatingBadge}
                  showRefine={showRefine}
                  onTableRowCheckbox={onTableRowCheckbox}
                  totalProductColumns={totalProductColumns}
                />
              </>
            ) : (
              <>
                {renderExpertReview && productDetails && (
                  <ComparisonPageExpertReviews
                    businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
                    editorialReviews={productDetailsForComparison.map(
                      (product) => product.editorialReview
                    )}
                    isDesktopOrAbove={isDesktopOrAbove}
                    totalProductColumns={totalProductColumns}
                  />
                )}
                <ComparisonPageSection
                  label="Specifications"
                  isDesktopOrAbove={isDesktopOrAbove}
                  tableData={techSpecs}
                  useTickCrossIcons={useTickCrossIcons}
                  useStarRatingBadge={useStarRatingBadge}
                  showRefine={showRefine}
                  onTableRowCheckbox={onTableRowCheckbox}
                  totalProductColumns={totalProductColumns}
                />
                {renderProsAndCons && productDetails && (
                  <ComparisonPageProsCons
                    pros={productDetailsForComparison.map((product) => product.pros)}
                    cons={productDetailsForComparison.map((product) => product.cons)}
                    businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
                    isDesktopOrAbove={isDesktopOrAbove}
                    totalProductColumns={totalProductColumns}
                    isPaidMember={isPaidMember}
                  />
                )}
                <ComparisonPageSection
                  label="Test results"
                  isDesktopOrAbove={isDesktopOrAbove}
                  tableData={testResults}
                  useTickCrossIcons={useTickCrossIcons}
                  useStarRatingBadge={useStarRatingBadge}
                  showRefine={showRefine}
                  onTableRowCheckbox={onTableRowCheckbox}
                  totalProductColumns={totalProductColumns}
                />
              </>
            )}
          </Accordion>
        </div>
      ) : (
        renderTableData({
          tableData: tableDataForComparison,
          useStarRatingBadge,
          useTickCrossIcons,
          showRefine,
          onTableRowCheckbox,
          isDesktopOrAbove,
          totalProductColumns,
        })
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

export const handleAccordionToggle = (isOpen: boolean, sectionName: string) => {
  dynamicGa4DataLayerPush({
    event: 'click_accordion',
    utagid: 'WHC424DP01',
    item_text: sectionName,
    action_group: isOpen ? 'expand' : 'collapse',
    item_group: 'product compare',
  })
}

const getStarRating = (value: string, useStarRatingBadge: boolean) => {
  return useStarRatingBadge ? <StarRatingBadge value={value} /> : <StarRating value={value} />
}

export const getValueElement = ({
  value,
  dataType,
  useStarRatingBadge,
  useTickCrossIcons,
}: {
  value: string
  dataType: string
  useStarRatingBadge: boolean
  useTickCrossIcons: boolean
}) => {
  if (value === '') {
    return (
      <SolidPadlockIcon
        className={styles.padlock}
        data-testid="padlock-icon"
        width={15}
        height={15}
        viewBox="0 0 15 15"
      />
    )
  }

  const formattedValue = isNaN(Number(value)) ? value.toLowerCase().trim() : value

  const renderIcon = (IconComponent: React.ElementType, ariaLabel: string, icon: string) => (
    <>
      <TypographyV2
        textStyle="sb-text-interface-body-small-regular"
        tag="span"
        className={styles.visuallyHidden}
      >
        {value}
      </TypographyV2>
      <IconComponent
        className={styles[`${icon}Icon`]}
        aria-label={ariaLabel}
        data-testid={`${icon}-icon`}
      />
    </>
  )

  if (formattedValue === 'yes' || formattedValue === 'no') {
    const icon = formattedValue === 'yes' ? TickBoldIcon : CrossBoldIcon
    return useTickCrossIcons
      ? renderIcon(icon, formattedValue, formattedValue === 'yes' ? 'tick' : 'cross')
      : value
  }

  return (
    <TypographyV2
      textStyle="sb-text-interface-body-small-regular"
      tag="span"
      className={styles.tableValue}
    >
      {dataType === 'test-result' ? getStarRating(value, useStarRatingBadge) : value}
    </TypographyV2>
  )
}

export const renderTableData = ({
  tableData,
  useStarRatingBadge,
  useTickCrossIcons,
  showRefine,
  onTableRowCheckbox,
  accordionHeading = '',
  isDesktopOrAbove,
  totalProductColumns = 4,
}: RenderTableDataArgs) =>
  tableData?.map(({ label: sectionLabel, rows, dataType, groupHelpText }, sectionIndex) => (
    <div key={`${sectionLabel}-${sectionIndex}`} className={styles.compareSection}>
      {sectionLabel && (
        <div className={styles.sectionLabelWrapper}>
          <TypographyV2 textStyle="sb-text-body-default-strong" tag="span">
            {sectionLabel}
          </TypographyV2>
          {groupHelpText && (
            <TooltipWithButton
              ariaLabel={sectionLabel}
              title={sectionLabel}
              className={styles.groupTooltip}
              contents={groupHelpText}
              openHandler={() =>
                dynamicGa4DataLayerPush({
                  event: 'click_tooltip',
                  utagid: 'WHC517DP01',
                  item_text: sectionLabel,
                  item_parent_text: accordionHeading,
                  item_group: 'compare column',
                })
              }
            />
          )}
        </div>
      )}

      {rows.map(({ rowLabel, rowValues, helpText }, rowIndex) => {
        return (
          <div
            className={classnames(styles.productFeatureRow, {
              [styles.mobileGreyRow]: !isDesktopOrAbove && rowIndex % 2 === 0,
            })}
            key={`${rowLabel}-${rowIndex}`}
            data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
          >
            <div className={styles.rowLabel}>
              {showRefine ? (
                <Checkbox
                  id={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  name={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  label={rowLabel}
                  value={rowLabel}
                  onChangeCallback={({ value }) =>
                    value && onTableRowCheckbox({ value, sectionLabel, rowLabel })
                  }
                />
              ) : (
                <>
                  <TypographyV2
                    textStyle="sb-text-interface-body-small-regular"
                    className={styles.rowLabelText}
                    tag="span"
                  >
                    {rowLabel}
                  </TypographyV2>
                  {helpText && (
                    <TooltipWithButton
                      ariaLabel={rowLabel}
                      title={rowLabel}
                      contents={helpText}
                      openHandler={() =>
                        dynamicGa4DataLayerPush({
                          event: 'click_tooltip',
                          utagid: 'WHC517DP01',
                          item_text: rowLabel,
                          item_parent_text: sectionLabel,
                          item_group: 'compare column',
                        })
                      }
                    />
                  )}
                </>
              )}
            </div>
            {rowValues.map(({ value }, valIndex) => (
              <div className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                {getValueElement({ value, dataType, useStarRatingBadge, useTickCrossIcons })}
              </div>
            ))}
            {isDesktopOrAbove && rowValues.length < totalProductColumns && (
              <div className={styles.filler} />
            )}
          </div>
        )
      })}
    </div>
  ))

type RenderTableDataArgs = {
  tableData: ComparisonTableState['tableData']
  useStarRatingBadge: boolean
  useTickCrossIcons: boolean
  showRefine: boolean
  onTableRowCheckbox: ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => void
  accordionHeading?: string
  isDesktopOrAbove?: boolean
  totalProductColumns?: number
}

export type ComparisonTablePageProps = {
  taxonomySlug: string
  renderWithAccordion: boolean
}
