import React from 'react'

export const complaintCR = (
  <g fill="none" fillRule="evenodd" data-testid="svg-complaintCR">
    <path
      d="M22.7 54.52L59.73 16.2a3.07 3.07 0 014.33-.13l42.83 38.21c.81.76 1.33 2.3 1.28 3.73"
      fill="#FFF"
    />
    <path
      d="M22.7 54.52L59.73 16.2a3.07 3.07 0 014.33-.13l42.83 38.21c.81.76 1.33 2.3 1.28 3.73"
      stroke="#424242"
      strokeWidth="3"
    />
    <path
      d="M108.17 57.06l.04 41.39c0 1.2-1 2.19-2.23 2.19H22.73a2.21 2.21 0 01-2.23-2.2V60.93"
      fill="#FFF"
    />
    <path
      d="M108.17 57.06l.04 41.39c0 1.2-1 2.19-2.23 2.19H22.73a2.21 2.21 0 01-2.23-2.2V60.93"
      stroke="#424242"
      strokeWidth="3"
    />
    <path
      d="M29.63 78.21a.93.93 0 01-.92-.93V9.8A2.3 2.3 0 0131 7.5h67.2a2.3 2.3 0 012.31 2.3v67.48a.93.93 0 01-.92.93H29.63z"
      fill="#FFF"
    />
    <path
      d="M98.22 6H31a3.8 3.8 0 00-3.8 3.8v67.48a2.43 2.43 0 002.43 2.43H99.6a2.43 2.43 0 002.42-2.43V9.8a3.8 3.8 0 00-3.8-3.8m0 3c.44 0 .8.36.8.8v66.91H30.21V9.8c0-.44.35-.8.8-.8h67.2"
      fill="#424242"
    />
    <path
      d="M37.72 16.07H91.1c.48 0 .87.38.87.85v6.74c0 .47-.4.84-.88.84H37.73a.86.86 0 01-.88-.84v-6.74c0-.47.4-.85.88-.85m-.44 12.88h32.34c.25 0 .44.21.44.46v2.74a.45.45 0 01-.44.46H37.28a.45.45 0 01-.43-.46v-2.74c0-.25.2-.46.43-.46m38.21.2H91.1c.48 0 .87.36.87.81v21.07c0 .45-.39.81-.87.81H75.5c-.48 0-.87-.36-.87-.8V29.95c0-.45.4-.8.87-.8"
      fill="#E0E0E0"
    />
    <path
      d="M37.78 36.63h30.83m-30.83 7.1h30.83m-30.83-3.41h30.83m-30.83 6.95h30.83m-30.83 3.55h30.83"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M36.29 55.5h55.68c.5 0 .9.4.9.9v5.48a.91.91 0 01-.9.91H36.29a.91.91 0 01-.91-.9V56.4c0-.5.4-.9.91-.9"
      fill="#E0E0E0"
    />
    <path
      d="M22.26 99.33l40.48-30.14a2.63 2.63 0 013.23 0l40.48 30.14c.44.32.25 1.14-.27 1.14H22.53c-.51 0-.7-.82-.27-1.14"
      fill="#FFF"
    />
    <path
      d="M22.26 99.33l40.48-30.14a2.63 2.63 0 013.23 0l40.48 30.14c.44.32.25 1.14-.27 1.14H22.53c-.51 0-.7-.82-.27-1.14"
      stroke="#424242"
      strokeWidth="3"
    />
    <path d="M26.17 51.27s.13-.36-3.97 3.74c0 0-1.7 2.05-1.7 5.9" fill="#FFF" />
    <path
      d="M26.17 51.27s.13-.36-3.97 3.74c0 0-1.7 2.05-1.7 5.9"
      stroke="#424242"
      strokeWidth="3"
    />
    <path d="M20.86 57.54l36.66 15.43" fill="#FFF" />
    <path d="M20.86 57.54l36.66 15.43" stroke="#424242" strokeWidth="2" />
    <path d="M71.03 72.97l36.65-16.4" fill="#FFF" />
    <path d="M71.03 72.97l36.65-16.4" stroke="#424242" strokeWidth="2" />
    <path fill="#FFF" d="M26.17 60.91l28.84 12.06-28.84 21.88z" />
    <path fill="#424242" d="M22.7 53.5l5.47-5.7v3.46l-.54.58L22 57.06z" />
    <path fill="#FFF" d="M106.7 58.02L73.7 72.97l17.1 12.84 4.8-9.53 4.73-3.99 6.35-3z" />
    <g>
      <path d="M109.46 102.3a15.94 15.94 0 110-31.9 15.94 15.94 0 010 31.9z" fill="#01892E" />
      <path
        d="M125.4 86.35a15.94 15.94 0 11-31.89 0 15.94 15.94 0 0131.89 0z"
        stroke="#FFF"
        strokeWidth="3"
      />
      <path stroke="#FFF" strokeWidth="3" d="M101.74 87.1l4.79 4.78 10.41-10.41" />
    </g>
  </g>
)
