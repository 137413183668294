import { Experiment, Result } from '@growthbook/growthbook'

export const trackingCallbackClient = <T>(
  experiment: Experiment<T>,
  result: Result<T>
) => {
  window.dataLayer?.push({
    event: 'experiment',
    event_category: 'experiment',
    experiment_id: experiment.key,
    variation_id: result.variationId.toString()
  })
}
