import { TypographyV2 } from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons'

import styles from './TestScoreInline.module.scss'

export function TestScoreInline({ score, variation = 'default' }: Readonly<TestScoreInlineProps>) {
  const scoreComponent =
    variation === 'default' ? (
      <span className={styles.score}>{score}%</span>
    ) : (
      <SolidPadlockIcon className={styles.padlockIcon} width={13} height={16} />
    )
  return (
    <TypographyV2
      textStyle="sb-text-interface-body-small-strong"
      className={styles.testScoreInline}
    >
      test score {scoreComponent}
    </TypographyV2>
  )
}

export interface TestScoreInlineProps {
  score?: number
  variation?: 'default' | 'obfuscated'
}
