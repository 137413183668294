import type { FunctionComponent } from 'react'
import { ButtonLink, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { Link } from '../../../../../../shared/components/Link'
import styles from './ProductHubBrandLinksV2.module.scss'

export const ProductHubBrandLinksV2: FunctionComponent<ProductHubBrandLinksV2Props> = ({
  heading,
  brandLinks,
  ctaLink,
}) => (
  <div className={styles.productHubBrandLinks}>
    <div className={styles.heading} id={heading.toLowerCase().replaceAll(' ', '-')}>
      <Heading heading={heading} headingType="medium" headingTag="h3" />
    </div>

    <ul className={styles.brandLinksList}>
      {brandLinks.map(({ text, href }) => (
        <li className={styles.brandLink} key={href}>
          <ButtonLink href={href} appearance="secondary" data-which-id="producthub-button">
            <ChevronRightIcon />
            {text}
          </ButtonLink>
        </li>
      ))}
    </ul>

    {ctaLink && (
      <Link
        appearance="primary"
        href={ctaLink.href}
        textStyle="sb-text-body-default-regular"
        data-which-id="producthub-button"
      >
        {ctaLink.text}
      </Link>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

export type ProductHubBrandLinksV2Props = {
  heading: string
  brandLinks: Array<{
    text: string
    href: string
  }>
  ctaLink?: {
    href: string
    text: string
  }
}
