import type { FunctionComponent } from 'react'
import React from 'react'

/**
 * @TODO
 * LazyLoad? It is currently set to true in which-deliver
 */
export const YouTube: FunctionComponent<Props> = ({ videoId, isLazyLoaded = false }) => {
  const src = `https://www.youtube-nocookie.com/embed/${videoId}`
  const iframeProps = {
    'data-testid': 'youtube-iframe',
    className: `${isLazyLoaded ? 'lazyload' : ''} embed-responsive-item`,
    loading: 'lazy',
    ...(isLazyLoaded ? { 'data-src': src } : { src }),
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen: true,
  } as const

  return (
    <div className="youtube-wrapper">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe {...iframeProps} title="YouTube iFrame Player" />
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  videoId: string
  isLazyLoaded?: boolean
}
