import { GrowthBook } from '@growthbook/growthbook'
import { getExperimentAttributes } from './getExperimentAttributes'
import { trackingCallbackClient } from './getTrackingCallbackClient'

import {
  GetInstanceConfig,
  defaultSdkKey,
  defaultGbHost
} from '../shared/InstanceConfig'

/**
 * @param {GetInstanceConfig} config Optional overrides for customizing the initialization logic.
 * @returns {Promise<GrowthBook>} An initialized Growthbook Object. See https://docs.growthbook.io/lib/js#growthbook-instance-reference
 *
 * Extract relevant cookies (id, member status etc.) and return a configured Growthbook Instance.
 */
export const getClientSideInstance = async (
  config?: GetInstanceConfig
): Promise<GrowthBook> => {
  try {
    const clientKey = config?.clientKey ?? defaultSdkKey
    const apiHost = config?.apiHost ?? defaultGbHost
    const enableDevMode = config?.enableDevMode ?? false
    const jsInjectionNonce = config?.jsInjectionNonce ?? ''

    const attributes = getExperimentAttributes()

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []

      const expConfig = window.__EXPERIMENTATION_ATTRIBUTES__
        ? {
            features: JSON.parse(window.__EXPERIMENTATION_FEATURES__ || '{}'),
            attributes: JSON.parse(
              window.__EXPERIMENTATION_ATTRIBUTES__ || '{}'
            ),
            experiments: JSON.parse(
              window.__EXPERIMENTATION_EXPERIMENTS__ || '[]'
            )
          }
        : {
            apiHost,
            clientKey,
            attributes
          }

      // Create a GrowthBook instance
      const gbInstance = new GrowthBook({
        ...expConfig,
        // Enable easier debugging during development
        enableDevMode,
        // Only required for A/B testing
        // Called every time a user is put into an experiment
        trackingCallback: trackingCallbackClient,
        jsInjectionNonce
      })

      // Download features and experiments from the CDN
      // Also, start running any Visual Editor or URL Redirect experiments not in SSR mode
      if (!window.__EXPERIMENTATION_ATTRIBUTES__) {
        await gbInstance.init()
      }

      return gbInstance
    }
  } catch (e) {
    if (e instanceof Error) {
      const errorCallback =
        config?.errorCallback ??
        ((e: Error) =>
          console.error(
            `Unable to setup Growthbook instance; experiments will use fallback behavior. error: ${e}`
          ))

      errorCallback(e)
    }
  }

  return new GrowthBook({
    features: {},
    attributes: {}
  })
}
