import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { Heading } from '@which/seatbelt'

import styles from './PIArticleSection.module.scss'

export const PIArticleSection: FunctionComponent<Props> = ({
  children,
  sectionHeader,
  sectionText,
}) => {
  return (
    <section data-testid="ea-article-section" className={styles.articleSectionWrapper}>
      <div className={styles.articleSection}>
        <div className={styles.articleSectionHeader}>
          <Heading
            headingType="large"
            heading={sectionHeader}
            headingTag="h1"
            subHeading={sectionText}
          />
        </div>

        <div data-testid="ea-article-articles" className={styles.articleSectionArticles}>
          {children}
        </div>
      </div>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  children?: ReactNode
  sectionHeader: string
  sectionText: string
}
