import type { ReactElement, ReactNode } from 'react'
import { Children, isValidElement } from 'react'

// Returns type ('providersLocked' or 'scoresLocked'),
// colspan and rowspan values based on the table's children
export const getVariantSettings = (formattedChildren: ReactNode): VariationSettings => {
  const tbody = Children.toArray(formattedChildren).filter(
    (child) => isValidElement(child) && child.type === 'tbody'
  )

  if (!tbody.length) {
    return
  }

  const [tbodyChildren] = tbody

  // Inspect table body data
  if (isValidElement(tbodyChildren)) {
    const {
      props: { children: bodyRows },
    } = tbodyChildren

    if (bodyRows.length) {
      // Take first row for data inspection
      const [firstRow] = bodyRows

      if (isValidElement(firstRow as ReactNode)) {
        const {
          props: { children: columns },
        } = firstRow

        if (columns?.length) {
          // We check first or second cell only
          // to see whether they have a padlock or not
          const [firstCell, secondCell] = columns

          // First cell has padlock: is "Providers locked" variation
          if (isLockedCell(firstCell as ReactElement)) {
            return {
              type: 'providersLocked',
              colSpan: 1,
              rowSpan: bodyRows.length,
            }
          }

          // Second cell has padlock: is "Scores locked" variation
          if (isLockedCell(secondCell as ReactElement)) {
            return {
              type: 'scoresLocked',
              colSpan: columns.length - 1,
              rowSpan: bodyRows.length,
            }
          }
        }
      }
    }
  }
}

// Utility function that looks up for the classname `w-table-icon-member`
// meaning the cell has a padlock in it
// TODO: Find a way to not rely on a classname
const isLockedCell = (cell: ReactElement) => {
  if (!cell) {
    return false
  }

  const cellContent = cell.props?.children

  let hasPadlock = false

  if (Array.isArray(cellContent) && cellContent.length > 0) {
    cellContent.forEach((element: ReactNode) => {
      if (isValidElement(element)) {
        const {
          props: { className },
        } = element

        if (className?.includes('w-table-icon-member')) {
          hasPadlock = true
          return
        }
      }
    })
  }

  return hasPadlock
}

export type VariationSettingsType = 'providersLocked' | 'scoresLocked'

export type VariationSettings =
  | {
      type: VariationSettingsType
      colSpan: number
      rowSpan: number
    }
  | undefined
