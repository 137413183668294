import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './IntroParagraph.module.scss'

export const IntroParagraph: FunctionComponent<Props> = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <Typography
      className={styles.introParagraph}
      data-testid="typography-intro-paragraph"
      textStyle="sb-text-heading-standfirst"
    >
      {text}
    </Typography>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  text?: ArticleReviewsPageData['introParagraph']
}
