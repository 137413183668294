import type { FunctionComponent } from 'react'
import { CardContainer, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import styles from './ContentCardWithLink.module.scss'

export const ContentCardWithLink: FunctionComponent<ContentCardWithLinkProps> = ({
  title,
  sectionTitle,
  description,
  image,
  primaryLinkHref,
  primaryLinkTracking,
  secondaryLink,
  smallText = false,
}) => (
  <CardContainer>
    {image && (
      <div data-testid="content-card-image" className={styles.cardImg}>
        <Picture {...image} lazyLoad />
      </div>
    )}
    <Typography
      tag="h3"
      textStyle="sb-text-heading-small"
      data-testid="content-card-title"
      className={styles.cardTitle}
    >
      {primaryLinkHref ? (
        <Link
          includeIcon={false}
          href={primaryLinkHref}
          appearance={'secondary'}
          data-which-id="producthub-card"
          textStyle="sb-text-heading-small"
          data-section={sectionTitle}
          data-subsection={title}
          data-index={primaryLinkTracking?.dataIndex}
        >
          {title}
        </Link>
      ) : (
        title
      )}
    </Typography>
    <Typography
      textStyle={smallText ? 'sb-text-body-small-regular' : 'sb-text-body-default-regular'}
      data-testid="content-card-description"
      className={styles.cardDesc}
    >
      {description}
    </Typography>
    {secondaryLink?.href && (
      <div id={secondaryLink.contentHtmlId}>
        <Link
          href={secondaryLink.href}
          includeIcon={true}
          data-which-id="producthub-card"
          data-section={sectionTitle}
          textStyle={smallText ? 'sb-text-body-small-regular' : 'sb-text-body-default-regular'}
          data-subsection={secondaryLink.label}
          data-index={`${secondaryLink.dataIndex + 1}`}
        >
          {secondaryLink.label}
        </Link>
      </div>
    )}
  </CardContainer>
)

export type ContentCardWithLinkProps = {
  title: string
  description: string
  sectionTitle: string
  image?: ImageWithSources
  primaryLinkHref?: string
  primaryLinkTracking?: {
    dataIndex: number
  }
  secondaryLink?: {
    contentHtmlId: string
    href: string
    label: string
    dataIndex: number
  }
  smallText?: boolean
}
