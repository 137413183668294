/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const energyRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/energy',
    component: () => <Page pagePath="energy/EnergyPage" rootCollectionName="Which? Energy" />,
  },
]
