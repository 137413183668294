export const categorySlugToAkeneoMap = {
  'adjustable-beds': 'adb',
  'indoor-aerials': 'aer',
  'air-fryers': 'afr',
  'all-in-one-pcs': 'aip',
  'air-conditioners': 'air',
  'travel-health': 'ajl',
  airports: 'apo',
  'air-purifiers': 'aps',
  airlines: 'arl',
  'artificial-grass': 'art',
  'air-source-heat-pumps': 'ash',
  'burglar-alarms-and-home-security': 'bal',
  'burglar-alarms-and-smart-security-systems': 'bass',
  batteries: 'bat',
  'bathroom-scales': 'bathroomscales',
  broadband: 'bb',
  'baby-bouncers': 'bbb',
  'baby-feeding-products': 'bbfp',
  barbecues: 'bbq',
  'baby-carriers-and-baby-slings': 'bby',
  'bridge-cameras': 'bcs',
  'baby-food': 'bf',
  'food-and-drink': 'bfd',
  'bikes-and-scooters': 'bikesandscooters',
  binoculars: 'bin',
  'blood-pressure-monitors': 'blo',
  'baby-monitors': 'bm',
  'birthing-options': 'bo',
  boilers: 'bol',
  painkillers: 'bpk',
  'baby-products-you-need': 'bpyn',
  'bread-makers': 'brd',
  'breast-pumps': 'brp',
  breastfeeding: 'brs',
  'boiler-cover': 'bsc',
  'best-shower-brands': 'bsh',
  'bath-aids': 'bta',
  'calpol-and-pain-relief': 'cal',
  'new-and-used-cars': 'car',
  carpets: 'carp',
  'cat-repellents': 'cat',
  'cot-beds': 'cb',
  'cruise-lines': 'cbc',
  cbd: 'cbd',
  'carpet-cleaners': 'ccl',
  'cutting-your-energy-bills': 'ceb',
  'coffee-grinders': 'cgr',
  'car-hire': 'ch',
  'cooker-hoods': 'chd',
  chainsaws: 'chn',
  'boxing-day-and-january-sales': 'chs',
  'moses-baskets-and-cribs': 'cmb',
  'carbon-monoxide-detectors': 'cmd',
  'coffee-machines': 'cof',
  conservatories: 'con',
  'cordless-phones': 'cor',
  'cot-mattresses': 'cot',
  childproofing: 'cp',
  compost: 'cpt',
  'child-car-seats': 'cs',
  'coffee-cups-and-travel-mugs': 'ctr',
  childcare: 'cuk',
  'cordless-vacuum-cleaners': 'cv',
  'dash-cams': 'dbc',
  'compact-cameras': 'dc',
  'black-friday': 'dea',
  'best-deals-on-baby-products': 'debp',
  dentists: 'den',
  destinations: 'dest',
  'double-glazing': 'dgc',
  'dog-harnesses': 'dh',
  dehumidifiers: 'dhm',
  'blu-ray-dvd-players': 'dp',
  'desktop-pcs': 'dpc',
  'dishwasher-tablets': 'dsh',
  duvets: 'dv',
  dishwashers: 'dw',
  damp: 'dwd',
  'electric-bikes': 'eb',
  'ebook-readers': 'ebk',
  drills: 'edb',
  'electric-fans': 'efans',
  'electric-heaters': 'elh',
  'electric-showers': 'els',
  'simple-mobile-phones': 'emb',
  'energy-companies': 'enc',
  'electric-toothbrushes': 'eth',
  'exercise-equipment': 'exercise',
  extensions: 'ext',
  'laser-eye-surgery': 'eye',
  'first-aid': 'fa',
  'face-masks': 'facemasks',
  tents: 'famt',
  bathrooms: 'fba',
  'fitted-bedroom-furniture': 'fbf',
  'food-processors': 'fdp',
  'folding-electric-bikes': 'feb',
  'feed-in-tariffs': 'fee',
  'fridge-freezers': 'ff',
  'fitted-kitchens': 'fk',
  'formula-milk': 'fm',
  'food-and-brexit': 'foodandbrexit',
  'fire-pits': 'fpt',
  fridges: 'fr',
  'freestanding-cookers': 'fsc',
  'filter-coffee-machines': 'ftm',
  'finding-a-tradesman': 'ftr',
  freezers: 'fz',
  'ground-and-air-source-heat-pumps': 'gahp',
  'clothes-steamers': 'gar',
  'gardening-for-wildlife': 'gfw',
  'giving-birth': 'gb',
  'gas-and-electric-fires-and-stoves': 'ges',
  greenhouses: 'gr',
  'garden-shredders': 'gs',
  'gardening-through-the-year': 'gty',
  'grow-your-own': 'gyo',
  'hair-dryers': 'hairdryers',
  'hearing-aid-providers': 'hap',
  'hand-blenders': 'hb',
  hobs: 'hbs',
  'holiday-cottage-company-reviews': 'hccr',
  headphones: 'hdp',
  'heating-oil': 'heo',
  'home-grants': 'hg',
  'high-chairs': 'hgh',
  'home-heating-systems': 'hhs',
  'cabin-bags': 'hl',
  'travel-agents': 'hlc',
  'head-lice-and-nits': 'hln',
  'xhose-and-other-expandable-hoses': 'hos',
  'hot-tubs': 'hottubs',
  'health-accessories': 'hpn',
  'uk-hotel-reviews': 'hrv',
  'uk-hotel-chains': 'htl',
  'hedge-trimmers': 'htm',
  'handheld-vacuum-cleaners': 'hv',
  'hot-water-taps': 'hwt',
  insulation: 'ins',
  'tv-streamers': 'itb',
  blenders: 'jb',
  juicers: 'ju',
  kettles: 'ke',
  'washing-powder-and-laundry-detergent': 'lad',
  laptops: 'lap',
  'light-bulbs': 'lbu',
  'loft-conversions': 'lco',
  'leaf-blowers': 'lea',
  'lawn-mowers': 'lr',
  suitcases: 'lug',
  'mobile-phone-providers': 'mbm',
  microwaves: 'mcw',
  menopause: 'men',
  mattresses: 'mf',
  'milk-frothers': 'mfr',
  'mini-greenhouses': 'mgr',
  'mini-hi-fi-systems': 'mic',
  'mobile-phones': 'mob',
  'computer-monitors': 'mon',
  'music-streaming': 'mstr',
  'mattress-toppers': 'mt',
  '10-money-tips-for-parents': 'mtp',
  nappies: 'nb',
  'coffee-pods': 'ncc',
  'nursery-furniture': 'nurs',
  'outdoor-accessories': 'oac',
  'opticians-stores': 'opt',
  'outdoor-clothing': 'out',
  'outdoor-living': 'outdoorliving',
  'built-in-ovens': 'ov',
  'pregnancy-and-birth': 'pab',
  'portable-power-bank-chargers': 'pbs',
  'pressure-cookers': 'pco',
  'pet-food': 'pet',
  pillows: 'pil',
  'pulse-oximeters': 'pox',
  'printers-and-ink': 'pr',
  probiotics: 'probiotics',
  'price-predictor': 'prp',
  'potty-training': 'ptr',
  pushchairs: 'pu',
  'pvrs-and-set-top-boxes': 'pv',
  'pressure-washers': 'pw',
  'pizza-ovens': 'pzo',
  'rechargeable-batteries': 'rbat',
  recycling: 'rce',
  'river-cruises': 'rcr',
  radios: 'rd',
  'record-players-and-turntables': 'rdp',
  'range-cookers': 'rgc',
  'renewable-heat-incentive': 'rhi',
  'robot-vacuum-cleaners': 'rob',
  'robot-lawn-mowers': 'roblm',
  'riser-recliner-chairs': 'rrc',
  'reusable-water-bottles': 'rwb',
  'scans-appointments-and-classes': 'sa',
  'sad-lamps': 'sad',
  'mobility-scooters': 'sco',
  'sewing-machines': 'sew',
  'stair-gates': 'sg',
  sheds: 'shd',
  shredders: 'shr',
  'smart-home-security-systems': 'shs',
  'slow-cookers': 'slo',
  'digital-cameras': 'slr',
  smartwatches: 'sma',
  'smart-video-doorbells': 'smdb',
  'smart-meters': 'sme',
  'smoke-alarms': 'smoa',
  'smart-plugs': 'smp',
  toys: 'smt',
  'sound-bars': 'snd',
  sofas: 'sof',
  'solar-panels': 'sol',
  'wireless-and-bluetooth-speakers': 'spd',
  spiralizers: 'spi',
  'soup-makers': 'spm',
  'grass-trimmers-and-strimmers': 'sri',
  'smart-radiator-valves': 'srvs',
  'stand-mixers': 'sta',
  'steam-cleaners': 'stc',
  'smart-thermostats': 'sth',
  'steam-irons': 'sti',
  stairlifts: 'stl',
  'sat-navs': 'stn',
  'sun-creams': 'sun',
  supermarkets: 'sup',
  'electric-shavers': 'sv',
  'fitness-trackers': 'swf',
  'antivirus-software-packages': 'sys',
  tablets: 'tab',
  'travel-cots': 'tc',
  'tumble-dryers': 'td',
  'digital-thermometers': 'thm',
  'best-and-worst-shops': 'tos',
  'tv-providers-and-services': 'tpa',
  trampolines: 'tra',
  trains: 'trn',
  toasters: 'ts',
  'travel-money': 'tvm',
  televisions: 'tvs',
  'underfloor-heating-systems': 'uhs',
  'vacuum-cleaners': 'vc',
  'vented-hobs': 'vob',
  'vpn-software': 'vpn',
  'wood-burning-stoves': 'wbs',
  'week-by-week': 'wbw',
  'wine-coolers': 'wco',
  'washer-dryers': 'wd',
  'wood-flooring': 'wf',
  wheelbarrows: 'whl',
  'wi-fi-routers-and-extenders': 'wir',
  'washing-machines': 'wm',
  'working-from-home': 'workingfromhome',
  'wireless-security-cameras': 'wsc',
  'washing-up-liquid': 'wul',
}
