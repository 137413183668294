import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Heading, Hr, PromoCard } from '@which/seatbelt'

import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './AdditionalContent.module.scss'

export const AdditionalContent: FunctionComponent<AdditionalContentProps> = ({
  id,
  heading,
  content,
  borderBlock = false,
  greyBackground = false,
  additionalContentLinks,
}) => (
  <section
    aria-label={heading}
    id={id}
    className={classnames(styles.additionalContentWrapper, {
      [styles.additionalContentWrapperBorderBlock]: borderBlock,
      [styles.additionalContentWrapperBackground]: greyBackground,
    })}
  >
    <Grid className={styles.grid}>
      <GridItem>
        {borderBlock && <Hr className={styles.hr} />}
        <div className={styles.additionalContent} id={id}>
          {heading && <Heading headingTag="h2" headingType="large" heading={heading} />}
          {additionalContentLinks &&
            additionalContentLinks.map((additionalContentLinksSection, index) => (
              <div
                className={classnames(
                  styles.additionalContentLinksSection,
                  `width-${additionalContentLinksSection.links.length}`
                )}
                key={index}
              >
                {additionalContentLinksSection.subheading && (
                  <Heading
                    heading={additionalContentLinksSection.subheading}
                    headingTag="h3"
                    headingType="small"
                  />
                )}
                <div className={styles.links}>
                  {additionalContentLinksSection.links.map((link, linkIndex) => (
                    <div className={styles.link} key={linkIndex}>
                      <PromoCard
                        {...link}
                        imageAspectRatio="two-to-one"
                        gtmProps={{
                          'data-which-id': 'producthub-card',
                          'data-section': `${heading}`,
                          'data-subsection': `${additionalContentLinksSection.subheading}`,
                          'data-index': linkIndex + 1,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          {content && (
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </GridItem>
    </Grid>
  </section>
)

///////// IMPLEMENTATION /////////

export type AdditionalContentLinks = {
  subheading?: string
  links: {
    href: string
    title: string
    image: ImageWithSources
  }[]
}[]

export type AdditionalContentProps = {
  id: string
  content?: string
  heading?: string
  borderBlock?: boolean
  greyBackground?: boolean
  additionalContentLinks?: AdditionalContentLinks
}
