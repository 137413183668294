import React, { useCallback, useState } from 'react'
import { Table as t } from '@which/seatbelt'

import type { ShowMoreContentRenderer } from '../ShowMore/ShowMore'
import { SinglePadlockCta } from '../SinglePadlockCta'
import type { ArticleElement, Props, TableBodyContent } from './CsvTable'
import styles from './CsvTable.module.scss'
import type { CsvTableState, Heading } from './CsvTableUnlocked'
import { extendElementProps, getCellAlignment, getInitialTableData, isValidRowItem } from './utils'

export const CsvTableProviderScoreVariation: React.FC<Props> = (data) => {
  const { restrictions, ...tableData } = data

  const defaultTableData = getInitialTableData(tableData)
  const [sortableTableData] = useState<CsvTableState>(defaultTableData)
  const { headings, sortedTableBody } = sortableTableData

  const revealColumnsToIndex = restrictions?.findIndex((value) => value === 'restrict') ?? -1

  const tableRenderer = useCallback<ShowMoreContentRenderer>(
    (children) => {
      const bodyContent = children as TableBodyContent[][]

      return (
        <t.Table tableClassName="tableVariation" tableId={tableData.id}>
          <t.Head>
            <t.Row>
              {Object.values(headings).map((heading: Heading, columnIndex: number) => {
                return <t.Header key={`header_${columnIndex}`}>{heading.value}</t.Header>
              })}
            </t.Row>
          </t.Head>
          <t.Body>
            {bodyContent.map((row, rowIndex) => {
              const [firstItem] = row

              if (!isValidRowItem(firstItem)) {
                return null
              }

              return (
                <t.Row key={`row_${rowIndex}`}>
                  {row.map((bodyRowData: string | ArticleElement, cellIndex) => {
                    if (revealColumnsToIndex === -1 || cellIndex < revealColumnsToIndex) {
                      return (
                        <t.Cell
                          key={`cell_${rowIndex}_${cellIndex}`}
                          align={getCellAlignment(bodyRowData)}
                        >
                          {typeof bodyRowData === 'string'
                            ? bodyRowData
                            : tableData.renderArticleElements(
                                extendElementProps({
                                  bodyContent,
                                  rowIndex,
                                  bodyRowData,
                                  cellIndex,
                                  row,
                                })
                              )}
                        </t.Cell>
                      )
                    }
                    if (rowIndex === 0 && cellIndex === revealColumnsToIndex) {
                      return (
                        <t.Cell
                          key={`cell_${rowIndex}_${cellIndex}`}
                          colSpan={row.length - revealColumnsToIndex}
                          rowSpan={bodyContent.length - 1}
                          className="lockCtaCell leftAlign"
                        >
                          <SinglePadlockCta type="desktop" />
                        </t.Cell>
                      )
                    }
                  })}
                </t.Row>
              )
            })}
          </t.Body>
        </t.Table>
      )
    },
    [headings, tableData, revealColumnsToIndex]
  )

  return (
    <div className={styles.csvTable}>
      <>
        {tableRenderer(sortedTableBody)}
        <SinglePadlockCta />
      </>

      {tableData?.notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: tableData?.notes || '' }}
        />
      )}
    </div>
  )
}
