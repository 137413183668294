import React from 'react'

export const memberContent = (
  <path
    data-testid="svg-memberContent"
    d="M4 14a4 4 0 012.2.7c-.4.7-.7 1.5-.7 2.3 0 .9.2 1.6 1 2l.5.1c-.8.2-1.9.1-3 .1-1.3 0-2.6.1-3.4-.2-.5-.2-.6-.7-.6-1.4C0 16 1.8 14 4 14zm16 0c2.2 0 4 2 4 3.6 0 .7-.1 1.2-.6 1.4-.8.3-2.1.2-3.4.2h-3l.6-.3c.7-.3.9-1 .9-2 0-.7-.3-1.5-.7-2.2A4 4 0 0120 14zm-8-2c3 0 5.5 2.7 5.5 4.8 0 .9-.3 1.6-1 1.9-1 .4-2.8.3-4.5.3-1.8 0-3.6.1-4.6-.3-.7-.3-.9-1-.9-1.9 0-2.1 2.4-4.8 5.4-4.8zm8-3a2 2 0 110 4 2 2 0 010-4zM4 9a2 2 0 110 4 2 2 0 010-4zm8-4a3 3 0 110 6 3 3 0 010-6z"
    fill="#676767"
    fillRule="evenodd"
  />
)
