import type { FunctionComponent, PropsWithChildren } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './Badge.module.scss'

export const Badge: FunctionComponent<Props> = ({
  colour,
  backgroundColour,
  className,
  children,
}) => (
  <span className={classnames(styles.badge, styles[colour], styles[backgroundColour], className)}>
    {children}
  </span>
)

///////// IMPLEMENTATION /////////

type Props = PropsWithChildren<{
  colour: 'blue' | 'white'
  backgroundColour: 'light' | 'dark'
  className?: string
}>
