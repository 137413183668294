import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, InputV2, Label } from '@which/seatbelt'
import { SmallSearchIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { UpdateUrl } from '../../../../pages/search-results/utils/UpdateUrl'
import styles from './PISearchBox.module.scss'

export const PISearchBox: FunctionComponent<Props> = ({ placeholderText, buttonText }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const searchTermInUrl = new URLSearchParams(search).get('filters.searchTerm')
  const searchTerm = searchTermInUrl ? searchTermInUrl : ''
  const [value, setValue] = useState(searchTerm)

  useEffect(() => {
    setValue(searchTerm)
  }, [searchTerm])

  const handleChange = (ev) => {
    setValue(ev.currentTarget.value)
  }

  const handleOnClick = () => {
    if (value.trim().length !== 0) {
      const buildUrlProps = {
        keys: ['filters.searchTerm', 'filters.page'],
        values: [value.trim(), '1'],
        pathname: pathname,
        search: search,
      }

      dynamicGa4DataLayerPush({ event: 'search', item_text: value })

      history.push(UpdateUrl(buildUrlProps))
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <Label className="sr-only" htmlFor="search-box" labelText={placeholderText} />
        <InputV2
          name="search-box"
          onChange={handleChange}
          value={value}
          placeholder={placeholderText}
          data-testid="ea-search-box-input"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === 'Enter' && handleOnClick()
          }
          aria-controls="ea-search-results"
          autoComplete="off"
        />
        <Button
          data-testid="ea-mobile-search-button"
          appearance="secondary"
          className={styles.mobileSearchButton}
          onClick={handleOnClick}
          aria-controls="ea-search-results"
        >
          <SmallSearchIcon className={styles.searchIcon} />
        </Button>
      </div>
      <Button
        className={styles.desktopSearchButton}
        data-testid="ea-desktop-search-button"
        onClick={handleOnClick}
        aria-controls="ea-search-results"
      >
        {buttonText}
      </Button>
    </div>
  )
}

type Props = {
  placeholderText: string
  buttonText: string
  onClick?: (event: any) => void
}
