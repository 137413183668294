export { article } from './Article'
export { bestBuy } from './BestBuy'
export { change } from './Change'
export { chevron } from './Chevron'
export { complaintCR } from './ComplaintCR'
export { dontBuy } from './DontBuy'
export { downArrow } from './DownArrow'
export { escalateCR } from './EscalateCR'
export { facebook } from './Facebook'
export { mail } from './Mail'
export { productListing } from './ProductListing'
export { rightsCR } from './RightsCR'
export { twitter } from './Twitter'
export { whatsapp } from './Whatsapp'
export { memberContent } from './MemberContent'
export { thumbsUp } from './ThumbsUp'
export { thumbsDown } from './ThumbsDown'
export { switching } from './Switching'

export enum SVGName {
  Article = 'article',
  BestBuy = 'bestBuy',
  Change = 'change',
  Chevron = 'chevron',
  ComplaintCR = 'complaintCR',
  DontBuy = 'dontBuy',
  DownArrow = 'downArrow',
  EscalateCR = 'escalateCR',
  Facebook = 'facebook',
  Mail = 'mail',
  ProductListing = 'productListing',
  RightsCR = 'rightsCR',
  Twitter = 'twitter',
  Whatsapp = 'whatsapp',
  MemberContent = 'memberContent',
  ThumbsUp = 'thumbsUp',
  ThumbsDown = 'thumbsDown',
  Switching = 'switching',
}
