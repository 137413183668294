import type { FunctionComponent } from 'react'
import React from 'react'
import type { Taxonomy } from '@which/glide-ts-types'
import {
  Accordion,
  AccordionItem,
  GridItem,
  Table as t,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous'
import { dynamicDatalayerPush } from '@which/shared'

import classNames from 'classnames'

import { Link } from '../../../../shared/components/Link'
import styles from './BrandScores.module.scss'

export const BrandScores: FunctionComponent<Props> = ({
  recommendedProvider,
  logo,
  brandLongTitle,
  brandShortTitle,
  signUp,
  ctaSmallPrint,
  brandScores,
  logIn,
}) => {
  const callDataLayerForAccordion = (event: React.MouseEvent<HTMLElement>) => {
    const input = event.target as HTMLElement
    dynamicDatalayerPush({
      eventCategory: 'Engagement',
      eventAction: 'Accordion',
      eventLabel: input.innerText,
    })
  }
  return (
    <div
      data-testid="brand-scores"
      className={classNames('w-page-wrapper', styles.brandScoresWrapper)}
    >
      <GridItem span={{ medium: 12, large: 12 }} className={styles.brandScoresHeader}>
        <div className={styles.brandScoresInfo}>
          <div className={styles.brandScoresTitleColumn}>
            {recommendedProvider && (
              <div className={styles.recommenderProvider}>
                <Typography
                  className={styles.recommenderProviderTitle}
                  textStyle="sb-text-body-x-small-regular"
                >
                  RECOMMENDED PROVIDER
                </Typography>
              </div>
            )}
            <Typography className={styles.brandScoresInfoText} textStyle="sb-text-heading-medium">
              {brandLongTitle}
            </Typography>
          </div>
          <div className={styles.brandScoresInfoImage}>
            <img src={logo} alt={`${brandShortTitle} logo`} />
          </div>
        </div>
        <div className={styles.brandScoresTable}>
          <t.Table
            className={styles.brandScores}
            tableClassName="scrollable"
            sizing={!signUp ? ['large', 'medium', 'large'] : ['large', 'large']}
          >
            <t.Head>
              {getTableRow({
                description: brandShortTitle,
                score: 'Score',
                overallRanking: ' Overall ranking',
                header: true,
                signUp,
              })}
            </t.Head>

            <t.Body>
              {brandScores?.map((row) => {
                return getTableRow({
                  description: row?.scoreTaxonomy?.name,
                  score: row?.score,
                  overallRanking: row?.description,
                  header: false,
                  signUp,
                })
              })}
            </t.Body>
          </t.Table>
        </div>
      </GridItem>
      {signUp ? (
        <GridItem data-testid="brand-scores-signup" className={styles.brandScoreSignUpRestricted}>
          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.brandScoreSignUpRestrictedText}
          >
            Members can read full review. Not a member yet?
          </Typography>
          <Link
            href={signUp.url ?? ''}
            appearance="secondary"
            className={styles.brandScoreSignUpLink}
          >
            {signUp.label}
          </Link>
          <Typography
            className={styles.brandScoreSignUpRestrictedCTAText}
            textStyle="sb-text-body-x-small-regular"
          >
            {ctaSmallPrint}
          </Typography>
          <Typography textStyle="sb-text-body-x-small-regular">
            {'Already member?  '}
            <Link
              textStyle="sb-text-interface-body-x-small-regular"
              appearance="secondary"
              href={logIn?.url ?? 'https://www.which.co.uk/login'}
            >
              {logIn?.label || 'Login'}
            </Link>
          </Typography>
        </GridItem>
      ) : (
        <></>
      )}
      <GridItem>
        <Accordion data-testid="scores-accordion">
          <AccordionItem
            label="Our Scores"
            onClick={(event) => {
              callDataLayerForAccordion(event)
            }}
            content={brandScores?.map((score) => (
              <div className={styles.scoreRow}>
                <Typography className={styles.scoreRowName} textStyle="sb-text-body-default-strong">
                  {score?.scoreTaxonomy?.name}
                </Typography>
                <Typography textStyle="sb-text-body-default-regular">
                  {
                    score?.scoreTaxonomy?.additional_items?.custom_taxonomy_configuration?.meta_data
                      ?.brand_score_description
                  }
                </Typography>
              </div>
            ))}
          ></AccordionItem>
        </Accordion>
      </GridItem>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  recommendedProvider?: boolean
  logo?: string
  brandShortTitle?: string
  brandLongTitle?: string
  brandScores?: {
    score: string | null
    taxonomy: number
    scoreTaxonomy: Taxonomy
    description: string
  }[]
  signUp?: {
    url?: string
    label?: string
  }
  logIn?: {
    url?: string
    label?: string
  }
  loggedOutLinkText?: string
  loggedOutLink?: string
  ctaSmallPrint?: string
  loggedInLink?: string
  loggedInLinkText?: string
}

const getTableRow = ({ description, score, overallRanking, header, signUp }) => {
  const textStyle = header ? 'sb-text-body-x-small-regular' : 'sb-text-interface-body-small-regular'
  return (
    <t.Row key={`row_${description}`}>
      {header ? (
        <t.Cell>
          <Typography textStyle={textStyle}>{description}</Typography>
        </t.Cell>
      ) : (
        <t.Cell>
          <div className={styles.descriptionCell}>
            <Typography textStyle={textStyle}>{description}</Typography>
          </div>
        </t.Cell>
      )}

      {header ? (
        <t.Cell>
          <Typography textStyle={textStyle}>{score}</Typography>
        </t.Cell>
      ) : score === null ? (
        <t.Cell>
          <SolidPadlockIcon
            data-testid="padlock-icon"
            width={15}
            height={15}
            viewBox="0 0 15 15"
            className={styles.padlockIcon}
          />
        </t.Cell>
      ) : (
        <t.Cell align="right">
          <Typography textStyle={textStyle}>{score}</Typography>
        </t.Cell>
      )}

      {!signUp ? (
        <t.Cell>
          <Typography textStyle={textStyle}>{overallRanking}</Typography>
        </t.Cell>
      ) : (
        <></>
      )}
    </t.Row>
  )
}
