import type { FunctionComponent } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './StarRatingBadge.module.scss'

export const StarRatingBadge: FunctionComponent<StarRatingProps> = ({ value }) => {
  const numericRating = mapStarsToNumber(value)

  return (
    <div className={styles.starRating}>
      <Typography
        className={styles.visuallyHidden}
        textStyle="sb-text-interface-body-small-regular"
        tag="span"
      >{`${numericRating} star rating`}</Typography>
      <div
        data-testid="star-rating-badge"
        className={classnames(
          {
            [styles.oneStar]: numericRating === 1,
            [styles.twoStar]: numericRating === 2,
            [styles.threeStar]: numericRating === 3,
            [styles.fourStar]: numericRating === 4,
            [styles.fiveStar]: numericRating === 5,
          },
          styles.starRatingBadge
        )}
      >
        <div className={styles.rating}>
          <Typography
            className={styles.starRatingNumber}
            textStyle="sb-text-interface-body-small-regular"
            tag="span"
            data-testid="star-rating"
            aria-hidden="true"
          >
            {numericRating === 0 ? 'No rating' : numericRating}
          </Typography>
          {numericRating > 0 && (
            <Typography
              className={styles.star}
              textStyle="sb-text-interface-body-small-regular"
              tag="span"
              aria-hidden="true"
            >
              ★
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

//////IMPLEMENTATION//////

// To be refactored in data-provider once the Star Rating A/B test is completed (possibly experiment number WHC 006 in GB)
const mapStarsToNumber = (stars: string): number => {
  switch (stars) {
    case '★☆☆☆☆':
      return 1
    case '★★☆☆☆':
      return 2
    case '★★★☆☆':
      return 3
    case '★★★★☆':
      return 4
    case '★★★★★':
      return 5
    default:
      return 0
  }
}

export type StarRatingProps = {
  value: string
}
