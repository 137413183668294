/*
 * Deprecated: this util supports keys for Universal Analytics.
 * These are backwards compatible, but new tracking work should use new keys for Google Analytics 4 (GA4)
 * Please use dynamicGa4DataLayerPush() instead
 * */
export const dynamicDatalayerPush = _ref => {
  let {
    eventCategory,
    eventAction,
    eventLabel,
    eventValue = 0,
    eventInteractive = false,
    event = 'tEvent',
    ...rest
  } = _ref;
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    eventInteractive,
    event,
    ...rest
  });
};

///////// IMPLEMENTATION /////////