import type React from 'react'
import { useEffect, useState } from 'react'

export const useOnScreen = (
  element: React.RefObject<HTMLElement> | HTMLElement | null,
  options: IntersectionObserverInit = {},
  includeAboveViewport = false
) => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    if (element) {
      const observedElement = element instanceof HTMLElement ? element : element?.current

      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(
          entry.isIntersecting || (includeAboveViewport && entry.boundingClientRect.top < 0)
        )
      }, options)
      if (observedElement) {
        observer.observe(observedElement)
      }

      return () => {
        if (observedElement) {
          observer.unobserve(observedElement)
        }
      }
    }
  }, [element, options, includeAboveViewport])

  return isIntersecting
}
