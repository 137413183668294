import { useState } from 'react'

import style from '../PasswordField/PasswordFieldView.module.scss'
import { eyeClose, eyeOpen } from './Images/EyeSvg'

export const AnimateEyeSvg = ({ className, eyeTransition, name }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleEye = () => {
    setIsOpen(!isOpen)
    eyeTransition()
  }

  const assistText = isOpen ? 'Hide password' : 'Show password'

  return (
    <div className={className}>
      <label htmlFor={name} title={assistText}>
        <input type="checkbox" onClick={toggleEye} name={name} id={name} className={style.srOnly} />

        {isOpen ? (
          <span aria-hidden="true">{eyeOpen}</span>
        ) : (
          <span aria-hidden="true">{eyeClose}</span>
        )}
        <span className={style.srOnly}>{assistText}</span>
      </label>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className: string
  eyeTransition: () => void
  name: string
}
