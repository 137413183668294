import {
  DataArticleIcon,
  InsightArticleIcon,
  PageIcon,
  PolicyArticleIcon,
  PolicyPaperArticleIcon,
  PolicyResearchPaperArticleIcon,
  PolicySubmissionArticleIcon,
  PressReleaseArticleIcon,
  PressStatementArticleIcon,
} from '@which/seatbelt/src/components/Icons/PolicyAndInsight'

export const mapIconType = (topicName: string) => iconMap[topicName] || PolicyPaperArticleIcon

const iconMap = {
  'Press release': PressReleaseArticleIcon,
  PressReleaseArticleIcon: PressReleaseArticleIcon,
  'Press statement': PressStatementArticleIcon,
  PressStatementArticleIcon: PressStatementArticleIcon,
  'Policy paper': PolicyPaperArticleIcon,
  PolicyPaperArticleIcon: PolicyPaperArticleIcon,
  'Policy submission': PolicySubmissionArticleIcon,
  PolicySubmissionArticleIcon: PolicySubmissionArticleIcon,
  'Insight article': InsightArticleIcon,
  InsightArticleIcon: InsightArticleIcon,
  Data: DataArticleIcon,
  DataArticleIcon: DataArticleIcon,
  Page: PageIcon,
  PageIcon: PageIcon,
  'Policy research paper': PolicyResearchPaperArticleIcon,
  PolicyResearchPaperArticleIcon: PolicyResearchPaperArticleIcon,
  'Policy article': PolicyArticleIcon,
  PolicyArticleIcon: PolicyArticleIcon,
}
