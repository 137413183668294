import type { FunctionComponent, PropsWithChildren } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { useFullUrl } from '../shared/hooks/useFullUrl'

const BrowserBackStackContext = createContext<string[]>([])

const useBrowserBackStack = () => {
  const history = useHistory()
  const [backStack, setBackStack] = useState<string[]>([])
  const { getFullUrl } = useFullUrl()

  let fullUrl = getFullUrl()
  if (!fullUrl) {
    fullUrl = 'https://www.which.co.uk'
  }

  const url = new URL(fullUrl)

  useEffect(() => {
    if (backStack.length === 0) {
      setBackStack([`${url.protocol}//${url.host}${url.pathname}`])
    }

    const unlisten = history.listen((location, action) => {
      setBackStack((prevBackStack) => {
        switch (action) {
          case 'POP':
            return prevBackStack.slice(0, prevBackStack.length - 1)
          case 'PUSH':
            return [...prevBackStack, `${url.protocol}//${url.host}${location.pathname}`]
          case 'REPLACE':
            return [
              ...prevBackStack.slice(0, prevBackStack.length - 1),
              `${url.protocol}//${url.host}${location.pathname}`,
            ]
        }
      })
    })

    return () => {
      unlisten()
    }
  }, [history, url.host, url.protocol, url.pathname, backStack])

  return backStack
}

export const BrowserBackStackProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const backStack = useBrowserBackStack()
  return (
    <BrowserBackStackContext.Provider value={backStack}>
      {children}
    </BrowserBackStackContext.Provider>
  )
}

export const useBackStack = () => {
  return useContext(BrowserBackStackContext)
}
