import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React, { useEffect, useRef } from 'react'
import type { SocialIconName } from '@which/seatbelt'
import {
  Accordion,
  AccordionItem,
  BaseLink,
  InPageAnchorLink,
  NavigationLink,
  ProductLink,
  SocialLink,
} from '@which/seatbelt'

import classnames from 'classnames'

import { Link } from '../../Link'
import styles from './BreadcrumbMenu.module.scss'
import type { Props as BreadcrumbMenuContentProps } from './BreadcrumbMenuContent'
import { BreadcrumbMenuContent } from './BreadcrumbMenuContent'

const Links = { BaseLink, Link, NavigationLink, ProductLink, SocialLink, InPageAnchorLink }

export const BreadcrumbMenu: FunctionComponent<Props> = ({
  currentUrl,
  verticalName,
  className,
  sections,
  navFooterLink,
  contentProps,
  toggleOpen,
  selectedIndex,
  setSelectedIndex,
  ...rest
}) => {
  const htmlElement = typeof document !== 'undefined' ? document.createElement('nav') : null
  const wrapperRef = useRef(htmlElement)

  useEffect(() => {
    const handleClickOutSideWrapper = (event: MouseEvent) => {
      const { target } = event

      if (!wrapperRef.current?.contains(target as Node)) {
        toggleOpen && toggleOpen(false)
        setSelectedIndex && setSelectedIndex(undefined)
      }
    }

    document.addEventListener('click', handleClickOutSideWrapper)

    return () => {
      document.removeEventListener('click', handleClickOutSideWrapper)
    }
  }, [toggleOpen, setSelectedIndex])

  const NavFooterLink = () => {
    const [linkComponent] = Object.keys(Links).filter((linkKey) => {
      const [linkType] = linkKey.split('Link')
      return (
        navFooterLink?.iconCategory?.includes(linkType.toLowerCase()) ||
        navFooterLink?.iconCategory === linkType.toLowerCase()
      )
    })
    const FooterLink = Links[linkComponent as LinksUnionType] ?? Links['Link']

    return (
      <div
        className={styles.breadCrumbMenuFooterWrapper}
        data-testid="breadcrumb-menu-footer-wrapper"
      >
        <FooterLink
          className={classnames(
            styles.breadCrumbMenuFooterLink,
            currentUrl === navFooterLink?.linkHref && styles.breadCrumbMenuFooterLinkReset
          )}
          href={navFooterLink?.linkHref ?? ''}
          icon={navFooterLink?.iconName as SocialIconName}
          data-testid="breadcrumb-menu-footer-link"
        >
          {navFooterLink?.linkText}
        </FooterLink>
      </div>
    )
  }

  return (
    <div className={styles.breadCrumbMenuWrapper}>
      <nav
        aria-label={`${verticalName} navigation`}
        className={classnames(styles.breadCrumbMenu, className)}
        ref={wrapperRef}
        data-testid="breadcrumb-menu"
        {...rest}
      >
        {contentProps ? (
          <BreadcrumbMenuContent
            className={styles.breadCrumbMenuContentOnly}
            {...{ ...contentProps, currentUrl }}
            data-testid="breadcrumb-menu-content-only"
          />
        ) : (
          <Accordion
            className={styles.breadCrumbMenuAccordion}
            data-testid="breadcrumb-menu-accordion"
          >
            {sections.map(({ label, breadCrumbMenuContentProps }, i) => (
              <AccordionItem
                key={`Item ${i + 1}`}
                className={styles.breadCrumbMenuAccordionItem}
                label={label}
                disabled={i !== selectedIndex}
                navItem
                content={
                  <BreadcrumbMenuContent
                    {...{ ...breadCrumbMenuContentProps, currentUrl }}
                    data-testid={`breadcrumb-menu-content-${i + 1}`}
                  />
                }
                data-testid={`breadcrumb-menu-button-${i + 1}`}
                {...(setSelectedIndex
                  ? { onClick: () => setSelectedIndex(i !== selectedIndex ? i : undefined) }
                  : {})}
              />
            ))}
          </Accordion>
        )}
        {navFooterLink && <NavFooterLink />}
      </nav>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type LinksUnionType = Exclude<keyof typeof Links, 'BaseLink' | 'InPageAnchorLink'>

export interface Props extends ComponentPropsWithoutRef<'nav'> {
  currentUrl?: string
  verticalName: string
  className?: string
  contentProps?: BreadcrumbMenuContentProps
  sections: {
    label: string
    breadCrumbMenuContentProps: BreadcrumbMenuContentProps
  }[]
  navFooterLink?: {
    iconName?: string
    iconCategory?: string
    linkHref: string
    linkText: string
  } | null
  toggleOpen?: (open: boolean) => void
  selectedIndex?: number
  setSelectedIndex?: (index?: number) => void
}

export { BreadcrumbMenuContent } from './BreadcrumbMenuContent'
