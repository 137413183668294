import type { FunctionComponent } from 'react'
import React from 'react'
import { Route } from 'react-router'

export const Status: FunctionComponent<Props> = ({ code, to, children }) => (
  <Route
    render={({ staticContext }: { staticContext?: StaticContextType }) => {
      if (staticContext) {
        staticContext.statusCode = parseInt(code, 10)
        if (to) {
          staticContext.redirectUrl = to
        }
      }
      return children
    }}
  />
)

///////// IMPLEMENTATION /////////

type Props = {
  code: string
  to?: string
}

type StaticContextType = {
  statusCode?: number
  redirectUrl?: string
}
