export const checkUrlExistsInList = (url: string) => urlList.includes(url)

// Pages to be tested
const urlList = [
  '/reviews/mobile-phone-providers/article/mobile-phone-provider-reviews/best-mobile-networks-overview-amhDx1F0z41t',
  '/reviews/fitted-kitchens/article/best-kitchen-brands/best-and-worst-kitchen-brands-am0wR5V2seJ3',
  '/reviews/hearing-aids/article/best-hearing-aid-providers/best-hearing-aid-providers-overview-a0bwU2e7Kzyu',
  '/reviews/fitted-kitchens/article/planning-a-kitchen/kitchen-costs-aNKz99x2RUQy',
  '/reviews/broadband/article/broadband-provider-reviews/best-broadband-providers-aIIx34f51krz',
  '/reviews/hearing-aids/article/best-hearing-aid-providers/nhs-vs-private-hearing-aid-providers-a5ZIQ2N4VZ1j',
  '/reviews/antivirus-software-packages/article/how-to-choose-the-best-antivirus-software-apUAV8K23gJj',
  '/money/pensions-and-retirement/personal-pensions/what-is-a-sipp-aBlOf9l200cM',
  '/reviews/opticians-stores/article/best-and-worst-opticians-stores/best-places-to-buy-glasses-aLf0K1V2D3dU',
  '/reviews/double-glazing/article/best-double-glazing-companies/double-glazing-companies-rated-ar4xT8B80sp0',
  '/reviews/sofas/article/best-sofa-shops-aqxMD5Y8HdJS',
  '/reviews/dehumidifiers/article/top-dehumidifier-brands-a7Ki73K858hq',
  '/reviews/bathrooms/article/best-bathroom-brands/best-and-worst-bathroom-brands-aoWaF8x1wRwH',
  '/reviews/vacuum-cleaners/article/top-vacuum-cleaner-brands-aTcBG9X7M9fl',
  '/reviews/new-and-used-cars/article/best-car-breakdown-cover/best-car-breakdown-providers-a5md04Q0BcmZ',
  '/reviews/washing-machines/article/best-washing-machine-brands-a2Z6T7H782pB',
  '/reviews/duvets/article/choosing-the-best-type-of-duvet-aXUX91B9AImk',
  '/reviews/washing-powder-and-laundry-detergent/article/full-laundry-detergent-test-ratings-aIVZc3D36seF',
  '/money/investing/investment-platforms-and-fund-supermarkets/investment-platforms-reviewed/best-and-worst-investment-platforms-a2Rra4r9bgT8',
  '/reviews/double-glazing/article/best-double-glazing-companies/double-glazing-companies-rated-ar4xT8B80sp0',
  '/reviews/new-and-used-cars/article/car-reliability/most-reliable-car-brands-aIgru9f1jeFC',
  '/reviews/vacuum-cleaners/article/top-vacuum-cleaner-brands-aTcBG9X7M9fl',
  '/reviews/printers-and-ink/article/best-cheap-printer-ink/best-cheap-ink-cartridges-aaOXG7F0Tgol',
  '/reviews/freestanding-cookers/article/best-freestanding-cooker-brands-aeGSz8Q36YMU',
  '/reviews/washer-dryers/article/top-washer-dryer-brands-apLGA5V0g4cM',
  '/reviews/double-glazing/article/best-double-glazing-companies/double-glazing-companies-rated-ar4xT8B80sp0',
  '/reviews/air-fryers/article/which-air-fryer-brand-to-buy-afid98U6UDPM',
  '/reviews/mattress-toppers/article/choosing-the-best-type-of-mattress-topper-awRRS5f5gyKs',
  '/reviews/sewing-machines/article/best-sewing-machine-brands-arU7h1o8t5Kr',
  '/reviews/antivirus-software-packages/article/antivirus-for-mac-ansv57I1Lh1K',
  '/reviews/electric-heaters/article/best-electric-heaters/best-electric-heaters-from-our-tests-aeXs65b7VHCm',
  '/reviews/mattresses/article/best-electric-blankets-and-heated-throws-avrLG0b2886v',
  '/reviews/mobility-scooters/article/best-mobility-scooters-aW4Wf3x8qbZT',
  '/reviews/duvets/article/choosing-the-best-type-of-duvet-aXUX91B9AImk',
  '/reviews/pillows/article/choosing-the-best-type-of-pillow-awJ4c7G4f111',
  '/reviews/bread-makers/article/best-bread-makers-a7FsI7S891Tq',
  '/reviews/electric-shavers/article/best-electric-shavers-ajYEM0I504HW',
  '/reviews/kitchen-knives/article/best-chefs-knives-atg0P6b9M2PM',
  '/reviews/pizza-ovens/article/best-pizza-ovens-akDsc8P6T7qy',
  '/reviews/cookware/article/best-saucepans-aS2Cs1S7bvP3',
  '/reviews/cookware/article/best-non-stick-frying-pans-aS2U36a9dld8',
  '/reviews/ear-plugs/article/best-ear-plugs-for-sleeping-aeOAT7A4eNnb',
]

export const providerScorePaths = [
  '/reviews/tv-providers-and-services/article/tv-and-broadband-provider-reviews/best-tv-and-broadband-providers',
  '/reviews/broadband/article/broadband-provider-reviews/best-broadband-providers',
  '/reviews/mobile-phone-providers/article/mobile-phone-provider-reviews/best-mobile-networks-overview',
]
