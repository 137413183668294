import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { ColNumbers } from '@which/seatbelt'
import { ButtonLink, Grid, GridItem, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Author } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import { MoreButton } from '../../../../shared/components/MoreButton'
import { Fact } from './Fact/Fact'
import styles from './ProductHubAdvice.module.scss'

export const ProductHubAdvice: FunctionComponent<ProductHubAdviceProps> = ({
  heading,
  description,
  legalCopy,
  sections,
  allAdvice,
  buttonCTAText,
  buttonCTAHref,
  expertInfo,
  fullWidth = false,
  condensedList = false,
}) => {
  const maxAdviceLinksPerColumn = 4
  const [allAdviceVisible, setAllAdviceVisible] = useState<boolean>(false)

  const handleShowMoreClick = () => {
    setAllAdviceVisible(true)

    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Show more',
    })
  }

  return (
    <section aria-label={heading} id="product-hub-advice">
      <Heading heading={heading} headingTag="h2" headingType="large" subHeading={description} />
      {legalCopy && (
        <div className={styles.legalCopy} dangerouslySetInnerHTML={{ __html: legalCopy }} />
      )}
      {expertInfo && (
        <div className={styles.expertViewWrapper}>
          <Fact body={expertInfo.body} title={expertInfo.title} author={expertInfo.author} />
        </div>
      )}
      <Grid
        includeGutters={false}
        className={classnames(styles.grid, {
          [styles.gridWithAllAdvice]: allAdvice,
          [styles.condensedList]: condensedList,
        })}
      >
        {sections.map((section, index) => {
          const calculateStartColumn = () => {
            return fullWidth ? 1 : [1, 5, 9][index] || 1
          }
          const gridSpan = fullWidth ? 12 : 4
          const key = section.title.replace(/\s+/g, '-').toLowerCase()

          return (
            <GridItem
              id={key}
              key={key}
              columnStart={{
                medium: calculateStartColumn() as ColNumbers,
                large: calculateStartColumn() as ColNumbers,
              }}
              span={{ medium: gridSpan, large: gridSpan }}
            >
              <div
                className={styles.listHeading}
                id={section.title.toLowerCase().replaceAll(' ', '-')}
              >
                <Heading heading={section.title} headingTag="h3" headingType="medium" />
              </div>
              <ul className={styles.list}>
                {section.links.map((link, index) => (
                  <li className={styles.listItem} key={link.href}>
                    <Link
                      href={link.href}
                      data-which-id="producthub-link"
                      data-section={heading.toLowerCase()}
                      data-subsection={section.title.toLowerCase()}
                      data-index={index + 1}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </GridItem>
          )
        })}
      </Grid>
      {buttonCTAText && buttonCTAHref && (
        <div className={styles.ctaWrapper}>
          <ButtonLink appearance="secondary" href={buttonCTAHref} data-which-id="producthub-button">
            <ChevronRightIcon />
            {buttonCTAText}
          </ButtonLink>
        </div>
      )}
      {allAdvice && (
        <>
          <MoreButton
            align="center"
            aria-label={`Show more ${heading}`}
            hideButton={allAdviceVisible}
            onClick={handleShowMoreClick}
          />

          {allAdviceVisible && (
            <div
              className={classnames(styles.allAdvice, {
                [styles.condensedList]: condensedList,
              })}
            >
              <div className={styles.listHeading}>
                <Heading heading={allAdvice.title} headingType="medium" headingTag="h3" />
              </div>

              <ul
                className={classnames(styles.list, styles.allAdviceList, {
                  [styles.condensedList]: condensedList,
                })}
                data-testid="all-advice-list"
              >
                {condensedList
                  ? allAdvice.links.map((link, index) => convertToListItem(link, heading, index))
                  : [1, 2, 3].map((col) => {
                      const linksForColumn = allAdvice.links.slice(
                        (col - 1) * maxAdviceLinksPerColumn,
                        col * maxAdviceLinksPerColumn
                      )

                      return (
                        <ul className={styles.nestedList} key={`advice-column-${col}`}>
                          {linksForColumn.map((link, index) =>
                            convertToListItem(link, heading, index)
                          )}
                        </ul>
                      )
                    })}
              </ul>
            </div>
          )}
        </>
      )}
    </section>
  )
}

///////// IMPLEMENTATION /////////

export type ProductHubAdviceProps = {
  heading: string
  description: string
  legalCopy?: string
  sections: ProductHubAdviceSection[]
  buttonCTAText?: string
  buttonCTAHref?: string
  allAdvice?: ProductHubAdviceSection
  expertInfo?: {
    author: Author
    title: string
    body: string
  }
  fullWidth?: boolean
  condensedList?: boolean
}

export type ProductHubAdviceSection = {
  title: string
  links: Array<{
    text: string
    href: string
  }>
}

const convertToListItem = (
  link: { href: string; text: string },
  heading: string,
  index: number
) => (
  <li className={styles.listItem} key={link.href}>
    <Link
      href={link.href}
      data-which-id="producthub-link"
      data-section={heading.toLowerCase()}
      data-subsection="All advice"
      data-index={index + 1}
    >
      {link.text}
    </Link>
  </li>
)
