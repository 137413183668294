import { useEffect, useState } from 'react'

import { debounce } from '../utils/debounce'

export const useMatchMedia = (query: MediaQueries) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)

    if (mediaQuery.matches !== matches) {
      setMatches(mediaQuery.matches)
    }

    const mqListener = debounce(() => setMatches(mediaQuery.matches), 250)

    mediaQuery.addEventListener('change', mqListener)

    return () => mediaQuery.removeEventListener('change', mqListener)
  }, [query, matches])

  return matches
}

///////// IMPLEMENTATION /////////

export type MediaQueries = '(min-width: 768px)' | '(min-width: 1024px)' | '(min-width: 1270px)'
