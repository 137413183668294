import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import styles from './TaxonomyConversionBanner.module.scss'

export const TaxonomyConversionBanner: FunctionComponent<Props> = ({
  title,
  description,
  descriptionMobile,
  buttonText,
  buttonURL,
}) => {
  if (!title && !description && !descriptionMobile && (!buttonText || !buttonURL)) {
    return null
  }

  return (
    <div className={styles.conversionBanner} data-testid="conversion-banner">
      <div className={styles.conversionBannerContent}>
        {title && <div className={styles.conversionBannerTitle}>{title}</div>}
        {description && <span className={styles.conversionBannerText}>{description}</span>}
        {descriptionMobile && (
          <span className={styles.conversionBannerTextMobile}>{descriptionMobile}</span>
        )}
      </div>
      {buttonURL && buttonText && (
        <ButtonLink className={styles.conversionBannerLink} href={buttonURL}>
          {buttonText}
          <ChevronRightIcon />
        </ButtonLink>
      )}
    </div>
  )
}
///////// IMPLEMENTATION /////////

type Props = {
  title?: string
  description?: string
  descriptionMobile?: string
  buttonText?: string
  buttonURL?: string
}
