import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'

import { loadScript } from '../../../../shared/utils/loadScript'
import styles from './Riddle.module.scss'

/**
 * To prevent the riddle DOM manipulation script's changes being overwritten by React on hydrate,
 * this component is only rendered on the client side.
 */
export const Riddle: FunctionComponent<Props> = ({ content }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      loadScript({
        src: '//www.riddle.com/embed/files/js/embed.js',
      })
    }
  }, [hasMounted])

  if (!hasMounted) {
    return null
  }

  return (
    <div
      data-testid="riddle-container"
      className={styles.riddleContainer}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  content: string
}
