import React from 'react'

export const thumbsUp = (
  <>
    <defs>
      <path id="a" d="M0 0h18v24H0z" />
    </defs>
    <g data-testid="svg-thumbsUp" transform="translate(3)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M0 22.3S.9 24 3.4 24h10.3s2.6 0 2.6-1.7-.9-2.1-2.2-2.1c0 0 3 .4 3-2.2 0-1.7-2.1-2.1-2.1-2.1s3 .4 3-2.1c0-.9-.9-1.8-2.6-1.8 0 0 1.7 0 1.7-1.7 0-.8 0-2.5-2.5-2.5H7.7S11 2.2 7 0C5-.8 4.3 4.4 4.3 4.4S2.6 7.8 1.7 8.6C1 9.5 0 11.2 0 11.2v11z"
        fill="white"
        mask="url(#b)"
      />
    </g>
  </>
)
