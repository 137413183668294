import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './LoggedOutImage.module.scss'

export const LoggedOutImage: FunctionComponent<Props> = ({ className, ...rest }) => {
  return (
    <div
      data-testid="logged-out-image"
      className={classnames(className, styles.loggedOutImage)}
      {...rest}
    />
  )
}

type Props = {
  className?: string
}
